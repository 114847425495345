<template>
  <div class="add-datasource-step1">
    <Form :model="addDataSourceForm" label-position="right" :label-width="100"
          :rules="addDataSourceRule">
      <FormItem :label="$t('bu-shu-lei-xing')" prop="instanceType" style="margin-right: 80px">
        <RadioGroup v-model="addDataSourceForm.instanceType" type="button"
                    @on-change="handleDeployTypeChange">
          <Radio v-for="(type) of dataSourceDeployTypes" :label="type.deployEnvType" :key="type.deployEnvType">
            {{ type.i18nName }}
          </Radio>
        </RadioGroup>
        <Tooltip placement="right-start" transfer>
          <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
          <div slot="content">
            <P><span>{{ $t('zi-jian-shu-ju-ku') }}</span>{{ $t('shi-yong-hu-zi-ji-bu-shu-de-shu-ju-ku-shi-li') }}</P>
            <p><span>{{ $t('a-li-yun-0') }}</span>{{ $t('shi-yong-hu-zaiali-yun-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
            <p><span>{{ $t('ya-ma-xun-aws') }}</span>{{ $t('shi-yong-hu-zai-ya-ma-xun-aws-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
            <p><span>{{ $t('wei-ruan-azure') }}</span>{{ $t('shi-yong-hu-zai-wei-ruan-azure-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
          </div>
        </Tooltip>
      </FormItem>
      <FormItem :label="$t('shu-ju-ku-lei-xing')" prop="type">
        <RadioGroup v-model="addDataSourceForm.type" type="button"
                    @on-change="handleDataSourceChange">
          <Radio v-for="(type) of dataSourceTypes" :label="type" :disabled="supportedDsType[type] === 'NOT_AUTHED'" :key="type" style="width: 190px;text-align: center">
            {{ getShowNameByDeployTypeAndDsName(addDataSourceForm.instanceType, type) }}
            <DataSourceIcon :type="type"
                            :instanceType="addDataSourceForm.instanceType"></DataSourceIcon>
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :label="$t('di-qu')" prop="region"
                v-if="addDataSourceForm.instanceType!=='SELF_MAINTENANCE'">
<!--        <RadioGroup v-model="addDataSourceForm.region" type="button">-->
<!--          <Radio v-for="(region) of regions" :label="region.region"-->
<!--                 :disabled="supportedRegions.indexOf(region)===-1"-->
<!--                 :key="region.region">{{ region.i18nName }}-->
<!--          </Radio>-->
<!--        </RadioGroup>-->
        <Dropdown trigger="click" style="margin-left: 20px" placement="bottom-start">
          <a href="javascript:void(0)">
            <span class="selected-region">{{getRegionI18n(addDataSourceForm.region)}}</span>
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <template #list>
            <div class="region-container">
              <div v-for="area in regionAreas" :key="area.regionArea">
                <div class="region-group" v-if="getRegions(area.regionArea).length>0">
                  <h3>{{area.i18nName}}</h3>
                  <div>
                    <RadioGroup v-model="addDataSourceForm.region" type="button">
                      <Radio v-for="region in getRegions(area.regionArea)" :key="region.region" :disabled="supportedRegions.indexOf(region)===-1" :label="region.region">{{region.i18nName}}</Radio>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Dropdown>
      </FormItem>
      <FormItem :label="$t('huan-jing')" prop="env">
        <Select style="width: 180px" v-model="addDataSourceForm.envId">
          <Option  v-for="env in envData" :key="env.id" :value="env.id">{{env.envName}}</Option>
        </Select>
      </FormItem>
      <FormItem :label="$t('huo-qu-fang-shi')" prop="fetchType">
        <RadioGroup v-model="addDataSourceForm.fetchType">
          <Radio v-for="type in addDataSourceForm.fetchTypeList" :key="type.fetchType" :label="type.fetchType">
            {{type.i18nName}}
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem v-if="isAk">
        <pd-button ghost style="display: inline-block;"
                   :loading="loadingRdsList"
                   type="primary"
                   @click="handleListMysql"
        >
          {{ $t('huo-qu-shu-ju-yuan-shi-li') }}
        </pd-button>
        <span v-if="showNoData" style="margin-left: 10px">
          <i class="iconfont iconTIP" style="margin-right: 8px;color: #FFA30E;font-size: 14px"></i>
          {{ $t('gai-di-qu-mei-you-shu-ju-yuan') }}
        </span>
      </FormItem>
    </Form>
    <div v-if="isManual">
      <div class="datasource-setting-title">
        {{ $t('adddatasourceforminstancetype-alibabacloudhosted-mappingaliyuntypeadddatasourceformtype-adddatasourceformtype-shu-ju-yuan-she-zhi', [addDataSourceForm.type]) }}
      </div>
      <Form ref="addLocalDs" :model="addDataSourceForm" label-position="right" :label-width="140"
            :rules="addDataSourceRule">
          <FormItem :label="isStarRocks(addDataSourceForm.type) ? $t('client-di-zhi') : $t('wang-luo-di-zhi')">

            <div class="host-list-container">
              <div style="display: inline-block;vertical-align: middle">
                <div v-if="addDataSourceForm.hostList[0].display" style="display: flex;">
                  <FormItem prop="host" key="host">
                    <Input v-model.trim="addDataSourceForm.hostList[0].host"
                           style="width: 280px"
                           :placeholder="addDataSourceForm.type==='RabbitMQ'?'ip(or domain):amqp_port:http_port':!separatePort(addDataSourceForm.type) ? 'ip:port,domain:port' : 'ip,domain'">
                      <!--                      <span slot="prepend">内网</span>-->
                      <Select v-model="addDataSourceForm.hostList[0].type" slot="prepend" style="width: 80px;" @on-change="handleHostTypeChange(0, $event)">
                        <Option value="private">{{ $t('nei-wang') }}</Option>
                        <Option value="public">{{ $t('wai-wang') }}</Option>
                      </Select>
                    </Input>
                  </FormItem>
                  <div style="margin: 0 5px;" v-if="separatePort(addDataSourceForm.type)">:</div>
                  <FormItem prop="port" v-if="separatePort(addDataSourceForm.type)" key="port">
                    <Input style="width: 120px;" v-model.trim="addDataSourceForm.hostList[0].port"
                           placeholder="port"/>
                  </FormItem>
                  <Icon class="add-item-btn add-item-btn-add" @click="handleAddHost"
                        v-if="!addDataSourceForm.hostList[1].display"
                        type="ios-add-circle-outline"/>
                  <Icon class="add-item-btn add-item-btn-remove" @click="handleRemoveHost(0)"
                        v-else
                        type="ios-remove-circle-outline"/>
                </div>
                <div v-if="addDataSourceForm.hostList[1].display" style="display: flex;margin-top: 20px;">
                  <FormItem prop="publicHost" key="publicHost">
                    <Input v-model.trim="addDataSourceForm.hostList[1].host"
                           style="width: 280px"
                           :placeholder="!separatePort(addDataSourceForm.type) ? 'ip:port,domain:port' : 'ip,domain'">
                      <Select v-model="addDataSourceForm.hostList[1].type" slot="prepend" style="width: 80px;" @on-change="handleHostTypeChange(1, $event)">
                        <Option value="private">{{ $t('nei-wang') }}</Option>
                        <Option value="public">{{ $t('wai-wang') }}</Option>
                      </Select>
                    </Input>
                  </FormItem>
                  <div style="margin: 0 5px;" v-if="separatePort(addDataSourceForm.type)">:</div>
                  <FormItem prop="publicPort" v-if="separatePort(addDataSourceForm.type)"
                            key="publicPort">
                    <Input style="width: 120px;" v-model.trim="addDataSourceForm.hostList[1].port"
                           placeholder="port"/>
                  </FormItem>
                  <Icon class="add-item-btn add-item-btn-remove" @click="handleRemoveHost(1)"
                        type="ios-remove-circle-outline"/>
                </div>
              </div>
            </div>
            </FormItem>
          <FormItem :label="$t('lian-jie-fang-shi')" v-if="isOracle(addDataSourceForm.type) && !isHana(addDataSourceForm.type)">
            <Select v-model="addDataSourceForm.connectType" style="width:280px;">
              <Option v-for="type in oracleConnectTypeList" :value="type.connectType" :key="type.connectType">{{type.i18nName}}</Option>
            </Select>
          </FormItem>
          <FormItem key="connectTypeValue" v-if="isOracle(addDataSourceForm.type) && !isHana(addDataSourceForm.type)" :label="addDataSourceForm.connectType" prop="connectTypeValue">
            <Input v-model="addDataSourceForm.connectTypeValue" style="width: 150px" :placeholder="addDataSourceForm.connectType"/>
          </FormItem>
          <FormItem key="securityType" :label="$t('ren-zheng-fang-shi')" prop="securityType"
                    v-if="securitySetting.length>1">
            <Select v-model="addDataSourceForm.securityType" style="width:280px" @on-change="handleSecurityTypeChange">
              <Option v-for="security in securitySetting" :value="security.securityType"
                      :key="security.securityType">
                {{ security.securityTypeI18nName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('kerberos-pei-zhi-wen-jian')" prop="securityType"
                    v-if="getSecurity(addDataSourceForm.securityType).needKrb5File">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)">{{ $t('kerberos-ke-hu-duan-pei-zhi-yi-ban-wei-yu-yi-jia-ru-ren-zheng-ti-xi-ji-qi-de-etckrb5conf') }}</span>
          </FormItem>
          <FormItem :label="$t('keytab-wen-jian')" prop="securityType"
                    v-if="getSecurity(addDataSourceForm.securityType).needKeyTabFile">
            <input @change="handleKeyTabFileChange" type="file" name="uploadKeytabFile"
                   id="uploadKeytabFile"/>
            <span
              style="margin-left: 10px;color: rgb(128, 134, 149)">{{ $t('jian-yi-zhong-xin-sheng-cheng-he-cheng-hive-he-dui-ying-hdfs-principal-ren-zheng') }}</span>
          </FormItem>
          <FormItem :label="$t('mo-ren-shu-ju-ku')" porp="default"
                    prop="dbName" key="dbName"
                    :rules="[{required: true, message: this.$t('mo-ren-shu-ju-ku-bu-neng-wei-kong'), trigger: 'blur'}]"
                    v-if="getSecurity(addDataSourceForm.securityType).needDbName && (isDb2(addDataSourceForm.type) || isHana(addDataSourceForm.type))"
          >
            <Input v-model="addDataSourceForm.dbName"  style="width: 280px"/>
            <a v-if="showFaq" style="margin-left: 10px" :href="urlForFaq" target="_blank">FAQ</a>
          </FormItem>
        <FormItem :label="$t('mo-ren-shu-ju-ku')" porp="noValidateDbName"
                  prop="noValidateDbName" key="noValidateDbName"
                  v-if="getSecurity(addDataSourceForm.securityType).needDbName && !(isDb2(addDataSourceForm.type) || isHana(addDataSourceForm.type))"
        >
          <Input v-model="addDataSourceForm.noValidateDbName"  style="width: 280px"/>
        </FormItem>
          <FormItem :label="$t('zhang-hao')" v-if="getSecurity(addDataSourceForm.securityType).needUserName"
                    :rules="[{required: true, message: $t('zhang-hao-bu-neng-wei-kong'), trigger: 'blur'}]" prop="account"
                    key="account">
            <Input v-model="addDataSourceForm.account" style="width: 280px"
                   autocomplete="new-password"/>
            <Checkbox style="margin-left: 16px"
                      v-if="DataSourceGroup.oracle.indexOf(addDataSourceForm.type)>-1"
                      v-model="addDataSourceForm.asSysDba">{{ $t('yi-sysdba-shen-fen-deng-ru') }}
            </Checkbox>
            <a v-if="showPermissionPrepare" style="margin-left: 10px" :href="urlForAuthPrepare" target="_blank">{{ $t('quan-xian-zhun-bei') }}</a>
          </FormItem>
          <FormItem :label="$t('mi-ma')" v-if="getSecurity(addDataSourceForm.securityType).needPassword"
                    :rules="[{required: true, message: $t('mi-ma-bu-neng-wei-kong'), trigger: 'blur'}]"
                    prop="password" key="password">
            <Input v-model="addDataSourceForm.password" style="width: 280px" type="password" password
                   autocomplete="new-password"/>
            <Tooltip placement="right-start">
              <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
              <div slot="content">
                {{ $t('mi-ma-jing-guo-jia-mi-cun-chu-bao-zhang-an-quan-hou-xu-chuang-jian-shu-ju-ren-wu-ke-zhi-jie-lian-jie-wu-xu-zhong-xin-tian-xie') }}
              </div>
            </Tooltip>
          </FormItem>
          <FormItem :label="$t('ke-hu-duan-truststore-mi-ma')"
                    v-if="getSecurity(addDataSourceForm.securityType).needClientTrustStorePassword"
                    prop="clientTrustStorePassword"
                    key="clientTrustStorePassword">
            <Input v-model="addDataSourceForm.clientTrustStorePassword" style="width: 280px"
                   type="password" password
                   autocomplete="new-password"/>
            <Tooltip placement="right-start">
              <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
              <div slot="content">
                {{ $t('mi-ma-jing-guo-jia-mi-cun-chu-bao-zhang-an-quan-hou-xu-chuang-jian-shu-ju-ren-wu-ke-zhi-jie-lian-jie-wu-xu-zhong-xin-tian-xie') }}
              </div>
            </Tooltip>
          </FormItem>
          <FormItem :label="$t('ssl-pei-zhi-wen-jian')" prop="securityFile" key="securityFile"
                    v-if="getSecurity(addDataSourceForm.securityType).needTlsFile">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile1"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
          </FormItem>
          <FormItem :label="$t('ca-zheng-shu')" prop="caFile" key="caFile"
                  v-if="getSecurity(addDataSourceForm.securityType).needCaFile">
          <input @change="handleCaFileChange" type="file" name="uploadfile" id="uploadfile1"/>
          <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
        </FormItem>
        <FormItem :label="$t('ban-ben-hao')" key="version" v-if="versionMap[addDataSourceForm.type]">
            <Select v-model="addDataSourceForm.version" style="width: 280px">
              <Option v-for="version in versionMap[addDataSourceForm.type]" :key="version" :value="version">
                {{version}}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('qu-dong')" key="driver" v-if="driverMap[addDataSourceForm.type]">
            <Select v-model="addDataSourceForm.driver" style="width: 280px">
              <Option v-for="driver in driverMap[addDataSourceForm.type]" :key="driver" :value="driver">
                {{driver}}
              </Option>
            </Select>
          </FormItem>
          <FormItem v-if="includesCC && Mapping.testSecurityType.includes(addDataSourceForm.securityType) && canTestyDsList.includes(addDataSourceForm.type)"
                    key="testConnection">
            <Button @click="handleTestConnection">{{ $t('ce-shi-lian-jie') }}</Button>
          </FormItem>
          <FormItem :label="$t('miao-shu')" key="desc">
            <Input v-model="addDataSourceForm.instanceDesc" style="width: 280px"/>
            <Tooltip placement="right-start">
              <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
              <div slot="content">
                {{ $t('bei-zhu-bian-yu-ji-yi-de-ming-zi-fang-bian-shi-yong-shi-shi-bie-ru-jiao-yi-ku-yong-hu-ku-ce-shi-ku-deng') }}
              </div>
            </Tooltip>
          </FormItem>
<!--          <AddHive :getSecurity="getSecurity" :addDataSourceForm="addDataSourceForm"-->
<!--                   :handleFileChange="handleFileChange"-->
<!--                   :handleKeyTabFileChange="handleKeyTabFileChange"-->
<!--                   v-if="addDataSourceForm.type==='Hive'"></AddHive>-->
          <FormItem :label="$t('wu-li-wei-zhi')" prop="region"
                    v-if="addDataSourceForm.instanceType!=='ALIBABA_CLOUD_HOSTED'" key="region">
<!--            <RadioGroup v-model="addDataSourceForm.region" type="button">-->
<!--              <Radio v-for="(region) of regions" :label="region.region"-->
<!--                     :disabled="supportedRegions.indexOf(region)===-1"-->
<!--                     :key="region.region">{{ region.i18nName }}-->
<!--              </Radio>-->
<!--            </RadioGroup>-->
            <Dropdown trigger="click" style="margin-left: 20px" placement="bottom-start">
              <a href="javascript:void(0)">
                <span class="selected-region">{{getRegionI18n(addDataSourceForm.region)}}</span>
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <template #list>
                <div class="region-container">
                  <div v-for="area in regionAreas" :key="area.regionArea">
                    <div class="region-group" v-if="getRegions(area.regionArea).length>0">
                      <h3>{{area.i18nName}}</h3>
                      <div>
                        <RadioGroup v-model="addDataSourceForm.region" type="button">
                          <Radio :disabled="supportedRegions.indexOf(region)===-1" v-for="region in getRegions(area.regionArea)" :key="region.region" :label="region.region">{{region.i18nName}}</Radio>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </FormItem>
          <FormItem :label="$t('e-wai-can-shu')" v-if="addDataSourceForm.dsKvConfigs.length">
            <config-params-edit :ds-kv-configs="addDataSourceForm.dsKvConfigs"/>
          </FormItem>
      </Form>
    </div>
    <div v-if="isAk">
      <Row>
        <Col :span="8">
          <p class="transfer-title">{{ $t('dai-xuan-ze-shu-ju-yuan') }}</p>
          <div class="transfer-left">
            <div class="transfer-left-search">
              <Checkbox v-model="checkAll" @on-change="handleSelectAllDs" v-if="showData.length"/>
              <Input style="padding-right: 70px" prefix="ios-search" v-model="searchKey"
                     :placeholder="$t('qing-shu-ru-shu-ju-yuan-miao-shu-shi-li-id-cha-xun')" @on-enter="handleFilter"/>
              <pd-button v-if="addDataSourceForm.instanceType!=='SELF_MAINTENANCE'" type="primary"
                         @click="handleFilter">{{ $t('shai-xuan') }}
              </pd-button>
            </div>
            <div style="overflow: auto;padding-bottom: 60px;height: 400px">
              <div v-for="(rds,index) in showData" :key="index" class="transfer-left-item">
                <Checkbox
                  :disabled="rds.existInDb||store.state.addedRdsList.indexOf(rds.instanceId)>-1"
                  v-model="checkList[rds.instanceId]" @on-change="handleSelectRds"></Checkbox>
                <p>
                  <span>{{ rds.instanceId }} <span v-if="rds.version">
                    ({{rds.version}})</span></span>
                  <DataSourceIcon :type="rds.dataSourceType"
                                  :instanceType="addDataSourceForm.instanceType"></DataSourceIcon>
                  <Tooltip :content="$t('nin-huo-nin-de-tong-shi-yi-tian-jia-guo-gai-shu-ju-yuan')" placement="right">
                    <span v-if="rds.existInDb"
                          style="color: #FF6E0D;margin-left: 10px;cursor: pointer">{{ $t('yi-tian-jia') }}</span>
                  </Tooltip>
                  <span style="margin-left:10px;color: #FF6E0D"
                        v-if="store.state.addedRdsList.indexOf(rds.instanceId)>-1&&!rds.existInDb">{{ $t('yi-xuan-ze') }}</span>
                </p>
                <p style="margin-top: 10px">
                  <span style="margin-right: 6px">{{ $t('miao-shu-rdsinstancedesc', [rds.instanceDesc]) }}</span>
                  <span>{{ $t('di-yu-rdsaliyunregion', [rds.aliyunRegion]) }}</span>
                </p>
              </div>
            </div>
            <div class="transfer-left-footer">
              <Page size=small :total="total" show-total :page-size="size"
                    @on-change="handlePageChange"/>
            </div>
          </div>
        </Col>
        <Col :span="2">
          <div class="transfer-btns">
            <div>
              <pd-button :type="hasChecked?'primary':'default'" @click="handleSelectDataSource">
                <Icon type="ios-arrow-forward"/>
              </pd-button>
            </div>
            <div style="margin-top: 16px">
              <pd-button :type="needCancelList.length>0?'primary':'default'"
                         @click="handleCancelDataSource">
                <Icon type="ios-arrow-back"/>
              </pd-button>
            </div>
          </div>
        </Col>
        <Col :span="14">
          <p class="transfer-title">
            {{ $t('yi-xuan-ze-shu-ju-yuan') }}
            <span
              v-if="addDataSourceForm.rdsList.length">
              ({{addDataSourceForm.rdsList.length}})</span>
          </p>
          <div class="transfer-right">
            <Table size="small" height="460" border stripe
                   @on-selection-change="handleSelectCancelList"
                   :columns="selectedDataSourceColumn" :data="addDataSourceForm.rdsList"
                   style="margin-bottom: 20px">
              <template slot-scope="{row}" slot="host">
                <div class="host-type">
                  <p v-if="row.privateHost"><span class="host-type-label">{{ $t('nei') }}</span>{{
                      row.privateHost
                    }}</p>
                  <p v-if="row.publicHost" style="margin-top: 3px"><span
                    class="host-type-label">{{ $t('wai') }}</span>{{ row.publicHost }}</p>
                </div>
              </template>
              <template slot-scope="{row}" slot="version">
                <div>
                  <p v-if="row.version">{{row.version}}</p>
                  <Select v-if="!row.version&&versionMap[row.dataSourceType]" v-model="row.selectedVersion" @on-change="handleChangeVersion(row,$event)" style="width: 100px" transfer>
                    <Option v-for="version in versionMap[row.dataSourceType]" :key="version" :value="version">
                      {{version}}
                    </Option>
                  </Select>
                </div>
              </template>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
    <Modal
      v-model="showTips"
      :title="$t('a-li-yun-shu-ju-yuan-qing-xian-tian-jia-ip-bai-ming-dan')"
    >
      <div>
        <p>
          {{ $t('bu-tian-xie-aksk-xin-xi-lai-chuang-jianali-yun-shang-de-shu-ju-yuan-xu-yao-xian-shou-dong-zaiali-yun-kong-zhi-tai-shang-tian-jia-ru-xia-ip-di-zhi-de-bai-ming-dan-que-bao-cloudcanal-neng-gou-zheng-chang-fang-wen-dao-nin-de-shu-ju-ku-fou-ze-ke-neng-wu-fa-zheng-chang-chuang-jian-tong-bu-ren-wu') }}</p>
        <p style="font-size:13px;margin-top: 10px;font-weight: 500">{{ $t('xu-tian-jia-de-ip-di-zhi-wei-consoleip', [consoleIp]) }}</p>
        <p style="margin-top: 10px"><a
          href="https://help.aliyun.com/document_detail/43185.html?spm=5176.13597144.0.0.5f2e1450A3sJt4"
          target="_blank">{{ $t('ru-he-tian-jia-ip-bai-ming-dan') }}</a></p>
      </div>
    </Modal>
    <StToken ref="stToken" :nextStep="listMySql"></StToken>
    <AliyunAKSK ref="aliyunAKSK" :next-step="handleListMysql"></AliyunAKSK>
    <test-connection-modal :visible="showTestConnectionModal" :test-connection="testConnection"
                           :datasource="addDataSourceForm"
                           :handle-close-modal="hideTestConnectionModal"/>
  </div>
</template>
<script>
import { listDataSource, queryInstancesNetInfo, queryDsDefaultKVConfig } from '@/services/cc/api/rdpDs';
import {
  listRegions,
  needTestBeforeAddDsTypes, oraConnectType, supportedDsType
} from '@/services/cc/api/constant';
import {
  listDatasourceDeployType, listDataSourceTypesByDeployType, dsSecurityOption, listRegionAreas, listDsSupportedRegions, dsConnectType
} from '@/services/cc/api/rdpConstant';
import _ from 'lodash';
import {
  isDb2, isHana, isOracle, isStarRocks, separatePort
} from '@/utils';
import { CONNECT_TYPE, ORACLE_CONTENT_TYPE } from '@/const/ccIndex';
import ConfigParamsEdit from '@/views/system/ConfigParamsEdit';
import Mapping from '@/views/util';
import utilMixin from '@/mixins/utilMixin';
import store from '@/store';
import { mapGetters } from 'vuex';
import DataSourceIcon from '../DataSourceIcon';
// import AddHive from './AddHive';
import StToken from '../ApplyStToken';
import DataSourceGroup from '../../../views/dataSourceGroup';
import AliyunAKSK from '../ApplyAKSK';
import TestConnectionModal from './TestConnectionModal';

export default {
  name: 'DataSourceInfo',
  mixins: [utilMixin],
  components: {
    ConfigParamsEdit,
    TestConnectionModal,
    DataSourceIcon,
    StToken,
    AliyunAKSK
  },
  props: {
    addDataSourceForm: Object,
    setSecuritySetting: Function,
    versionMap: Object,
    driverMap: Object
  },
  created() {
    this.listDatasourceDeployType();
    // this.listRegions();
    this.listDataSourceTypes();
    if (store.state.rdsData.length > 0) {
      this.filterData = _.cloneDeep(store.state.rdsData);
      this.total = this.filterData.length;
      this.showData = this.filterData.slice((this.page - 1) * this.size, this.page * this.size);
    }
    this.getSecurityType();
    this.getDefaultKVConfig();
    if (this.includesCC) {
      this.needTestBeforeAddDsTypes();
    }
    this.listRegionAreas();
    this.listEnv();
  },
  beforeDestroy() {
    clearInterval(this.checkNetInfo);
  },
  data() {
    return {
      kvConfig: [],
      CONNECT_TYPE,
      oracleConnectTypeList: [],
      ORACLE_CONTENT_TYPE,
      showTestConnectionModal: false,
      checkAll: false,
      securitySetting: [],
      store,
      DataSourceGroup,
      showNoData: false,
      hasChecked: false,
      needCancelList: [],
      envData: [],
      checkList: {},
      dataSourceDeployTypes: [],
      dataSourceDeployTypesObj: {},
      dataSourceTypes: [],
      regions: [],
      supportedDsType: {},
      supportedRegions: [],
      regionAreas: [],
      canTestyDsList: ['MySQL'],
      checkNetInfo: '',
      page: 1,
      size: 10,
      total: 0,
      noMoreData: true,
      searchKey: '',
      Mapping,
      showTips: false,
      showConfirmPublic: false,
      checkPermission: false,
      selectedRow: {},
      consoleIp: '',
      filterData: [],
      showData: [],
      selectedDataSourceColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('shi-li-id'),
          key: 'instanceId'
        },
        {
          title: this.$t('miao-shu'),
          key: 'instanceDesc'
        },
        {
          title: this.$t('host'),
          key: 'host',
          slot: 'host',
          width: 470
        },
        {
          title: this.$t('ban-ben-hao'),
          key: 'version',
          slot: 'version',
          minWidth: 120
        }
      ],
      loadingRdsList: false,
      addDataSourceRuleAkSk: {
        aliyunAk: [
          {
            required: true,
            message: this.$t('the-ak-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        aliyunSk: [
          {
            required: true,
            message: this.$t('the-sk-cannot-be-empty'),
            trigger: 'blur'
          }
        ]
      },
      addDataSourceRule: {
        fetchType: [
          { required: true, message: this.$t('qing-xuan-ze-huo-qu-fang-shi'), trigger: 'blur' }
        ],
        host: [
          {
            validator: (rule, value, callback) => {
              if (!this.addDataSourceForm.hostList[0].host) {
                return callback(new Error(this.$t('isstarrocksthisadddatasourceformtype-client-wang-luo-di-zhi-bu-neng-wei-kong', [isStarRocks(this.addDataSourceForm.type) ? this.$t('client') : this.$t('wang-luo')])));
              }
              // if ((this.addDataSourceForm.host && this.addDataSourceForm.host.indexOf(':') === -1)
              //       || (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicHost.indexOf(':') === -1)) {
              //   return callback(new Error('缺少端口信息。请正确填写数据源信息。'));
              // }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        port: [
          {
            validator: (rule, value, callback) => {
              if (!this.addDataSourceForm.hostList[0].port) {
                return callback(new Error(this.$t('isstarrocksthisadddatasourceformtype-client-wang-luo-duan-kou-bu-neng-wei-kong', [isStarRocks(this.addDataSourceForm.type) ? this.$t('client') : this.$t('wang-luo')])));
              }
              // if ((this.addDataSourceForm.host && this.addDataSourceForm.host.indexOf(':') === -1)
              //       || (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicHost.indexOf(':') === -1)) {
              //   return callback(new Error('缺少端口信息。请正确填写数据源信息。'));
              // }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        publicHost: [
          {
            validator: (rule, value, callback) => {
              if (!this.addDataSourceForm.hostList[1].host) {
                return callback(new Error(this.$t('isstarrocksthisadddatasourceformtype-client-wang-luo-di-zhi-bu-neng-wei-kong', [isStarRocks(this.addDataSourceForm.type) ? this.$t('client') : this.$t('wang-luo')])));
              }
              // if ((this.addDataSourceForm.host && this.addDataSourceForm.host.indexOf(':') === -1)
              //       || (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicHost.indexOf(':') === -1)) {
              //   return callback(new Error('缺少端口信息。请正确填写数据源信息。'));
              // }
              return callback();
            },
            trigger: 'blur'
          }
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.indexOf(':') === -1 && ['ClickHouse', 'ElasticSearch', 'RabbitMQ', 'RocketMQ'].includes(this.addDataSourceForm.type)) {
          //       return callback(new Error('缺少端口信息。请正确填写数据源信息。'));
          //     }
          //     return callback();
          //   },
          //   trigger: 'blur'
          // }
        ],
        publicPort: [
          {
            validator: (rule, value, callback) => {
              if (!this.addDataSourceForm.hostList[0].port) {
                return callback(new Error(this.$t('isstarrocksthisadddatasourceformtype-client-wang-luo-duan-kou-bu-neng-wei-kong', [isStarRocks(this.addDataSourceForm.type) ? this.$t('client') : this.$t('wang-luo')])));
              }
              // if ((this.addDataSourceForm.host && this.addDataSourceForm.host.indexOf(':') === -1)
              //       || (this.addDataSourceForm.publicHost && this.addDataSourceForm.publicHost.indexOf(':') === -1)) {
              //   return callback(new Error('缺少端口信息。请正确填写数据源信息。'));
              // }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        instanceId: [
          {
            required: true,
            message: this.$t('the-instanceid-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        connectTypeValue: [
          {
            required: true,
            message: this.$t('thisadddatasourceformconnecttype-bu-neng-wei-kong', [this.addDataSourceForm.connectType]),
            trigger: 'change'
          }
        ],
        region: [
          {
            required: true,
            type: 'string',
            message: this.$t('the-region-cannot-be-empty'),
            trigger: 'change'
          }
        ],
        hdfsIp: [
          {
            required: true,
            message: this.$t('the-hdfsip-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        hdfsPort: [
          {
            required: true,
            message: this.$t('the-hdfsport-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        hdfsDwDir: [
          {
            required: true,
            message: this.$t('the-hdfsdwdir-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        account: [
          {
            required: true,
            message: this.$t('the-account-cannot-be-empty'),
            trigger: 'blur'
          }
        ],
        hdfsSecurityType: [
          {
            required: true,
            type: 'string',
            message: this.$t('the-hdfssecuritytype-cannot-be-empty'),
            trigger: 'change'
          }
        ],
        instanceType: [
          {
            required: true,
            message: this.$t('please-select-type'),
            trigger: 'change'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('the-type-cannot-be-empty'),
            trigger: 'change'
          }
        ],
        clientTrustStorePassword: [
          {
            required: true,
            message: this.$t('ke-hu-duan-truststore-mi-ma-bu-neng-wei-kong'),
            trigger: 'change'
          }
        ],
        securityFile: [
          {
            required: true,
            message: this.$t('ssl-pei-zhi-wen-jian-bu-neng-wei-kong')
          }
        ],
        caFile: [
          {
            required: true,
            message: this.$t('ca-zheng-shu-bu-neng-wei-kong-0')
          }
        ],
        version: [
          {
            required: true,
            message: this.$t('ban-ben-hao-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        driver: [
          {
            required: true,
            message: this.$t('qu-dong-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters([
      'includesCC'
    ]),
    isManual() {
      return this.addDataSourceForm.fetchType && this.addDataSourceForm.fetchType === 'MANUAL_ENTRY';
    },
    isAk() {
      return this.addDataSourceForm.fetchType && this.addDataSourceForm.fetchType === 'ACCESS_KEY';
    },
    getRegions() {
      return (area) => {
        const regionsByArea = [];
        this.regions.forEach((region) => {
          if (region.regionArea === area) {
            regionsByArea.push(region);
          }
        });
        return regionsByArea;
      };
    },
    getRegionI18n() {
      return (theRegion) => {
        let i18nName = '';
        this.regions.forEach((region) => {
          if (region.region === theRegion) {
            i18nName = region.i18nName;
          }
        });
        return i18nName;
      };
    },
    urlForAuthPrepare() {
      let url = store.state.docUrlPrefix;
      if (this.addDataSourceForm.type === 'MySQL') {
        url += '/cc-doc/dataMigrationAndSync/database/privs_for_mysql';
      } else if (this.addDataSourceForm.type === 'Oracle') {
        url += '/cc-doc/dataMigrationAndSync/database/privs_for_oracle';
      } else if (this.addDataSourceForm.type === 'Kafka') {
        url += '/cc-doc/dataMigrationAndSync/database/privs_for_kafka';
      } else if (this.addDataSourceForm.type === 'Db2') {
        url += '/cc-doc/dataMigrationAndSync/database/prepare_for_db2';
      } else if (this.addDataSourceForm.type === 'SQLServer') {
        url += '/cc-doc/dataMigrationAndSync/database/privs_for_sqlserver';
      } else if (this.addDataSourceForm.type === 'Hana') {
        url += '/cc-doc/dataMigrationAndSync/database/privs_for_hana';
      }
      return url;
    },
    urlForFaq() {
      let url = store.state.docUrlPrefix;
      if (this.addDataSourceForm.type === 'Hana') {
        url += '/cc-doc/faq/solve_hana_test_connection_fail';
      }
      return url;
    },
    showPermissionPrepare() {
      const showDsList = ['MySQL', 'Oracle', 'Kafka', 'Db2', 'SQLServer', 'Hana'];
      if (showDsList.includes(this.addDataSourceForm.type)) {
        return true;
      }
      return false;
    },
    showFaq() {
      const showDsList = ['Hana'];
      if (showDsList.includes(this.addDataSourceForm.type)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    isHana,
    isDb2,
    isOracle,
    isStarRocks,
    async getDefaultKVConfig() {
      const res = await queryDsDefaultKVConfig({
        dataSourceType: this.addDataSourceForm.type,
        deployEnvType: this.addDataSourceForm.instanceType
      });

      if (res.data.success) {
        this.addDataSourceForm.dsKvConfigs = res.data.data;
      }
    },
    async getOracleConnectType() {
      const res = await dsConnectType();
      this.oracleConnectTypeList = [];
      if (res.data.success) {
        res.data.data.forEach((type) => {
          if (type.dataSourceType === 'Oracle') {
            this.oracleConnectTypeList.push(type);
          }
        });
        for (let i = 0; i < this.oracleConnectTypeList.length; i++) {
          const connectType = res.data.data[i];
          if (connectType.defaultCheck) {
            this.addDataSourceForm.connectType = connectType.connectType;
          }
        }
      }
    },
    handleHostTypeChange(index, type) {
      const beforeType = this.addDataSourceForm.hostList[index].type;
      this.addDataSourceForm.host = '';
      this.addDataSourceForm.port = '';
      this.addDataSourceForm.publicHost = '';
      this.addDataSourceForm.publicPort = '';
      if (beforeType === type) {
        // console.log(beforeType);
        // const zeroType = this.hostList[0].type;
        // this.hostList[0].type = this.hostList[1].type;
        // this.hostList[1].type = zeroType;
        if (index === 0) {
          this.addDataSourceForm.hostList[1].type = type === 'public' ? 'private' : 'public';
        } else {
          this.addDataSourceForm.hostList[0].type = type === 'public' ? 'private' : 'public';
        }
      }
    },
    handleTestConnection() {
      this.$refs.addLocalDs.validate((val) => {
        if (val) {
          const { hostList } = this.addDataSourceForm;
          if (hostList[0].type === 'public') {
            this.addDataSourceForm.publicHost = hostList[0].host;
            this.addDataSourceForm.publicPort = hostList[0].port;
            this.addDataSourceForm.host = hostList[1].host;
            this.addDataSourceForm.port = hostList[1].port;
          } else {
            this.addDataSourceForm.publicHost = hostList[1].host;
            this.addDataSourceForm.publicPort = hostList[1].port;
            this.addDataSourceForm.host = hostList[0].host;
            this.addDataSourceForm.port = hostList[0].port;
          }
          this.showTestConnectionModal = true;
        }
      });
    },
    hideTestConnectionModal() {
      this.showTestConnectionModal = false;
    },
    testConnection() {

    },
    separatePort,
    listMySql() {
      this.loadingRdsList = true;
      store.state.addedRdsList = [];
      store.state.addedRdsList = [];
      this.addDataSourceForm.rdsList = [];
      listDataSource({
        searchKey: this.searchKey,
        region: this.addDataSourceForm.region,
        dataSourceType: this.addDataSourceForm.type
      })
        .then((res) => {
          if (res.data.code === '1') {
            store.state.rdsData = res.data.data;
            this.showNoData = store.state.rdsData.length === 0;
            this.noMoreData = res.data.data.length < this.size;
            store.state.rdsData.map((item) => {
              this.addDataSourceForm.rdsList.map((rds) => {
                if (item.instanceId === rds.instanceId) {
                  store.state.addedRdsList.push(rds.instanceId);
                  this.checkList[rds.instanceId] = true;
                }
                return null;
              });
              if (item.existInDb) {
                item._disabled = true;
              }
              this.checkList[item.instanceId] = false;
              return null;
            });
            store.state.rdsData.map((rds) => {
              if (rds.publicHost) {
                rds.whiteListAddType = 'PUBLIC_IP_ONLY';
              } else {
                rds.whiteListAddType = 'PRIVATE_IP_ONLY';
              }
              queryInstancesNetInfo({
                aliyunRegion: rds.aliyunRegion,
                userAk: this.addDataSourceForm.aliyunAk,
                userSk: this.addDataSourceForm.aliyunSk,
                instanceId: rds.instanceId,
                dataSourceType: this.addDataSourceForm.type
              })
                .then((response) => {
                  if (response.data.code === '1') {
                    rds.netInfo = response.data.data;
                  }
                  store.state.rdsData = Object.assign([], store.state.rdsData);
                });
              return null;
            });
            this.filterData = _.cloneDeep(res.data.data);
            this.total = this.filterData.length;
            this.showData = this.filterData.slice((this.page - 1) * this.size, this.page * this.size);
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '2011') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
          this.loadingRdsList = false;
        })
        .catch(() => {
          this.loadingRdsList = false;
        });
    },
    handleListMysql() {
      this.listMySql();
    },
    handleUploadKeytab(res) {
      if (res.data.code === 1) {
        this.addDataSourceForm.kerberosKeyTabFile = res.data.data;
      }
    },
    handleUploadKerberosConfig(res) {
      if (res.data.code === 1) {
        this.addDataSourceForm.kerberosConfigFile = res.data.data;
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.addDataSourceForm.securityFile = file;
        this.$refs.addLocalDs.validateField('securityFile');
      }
    },
    handleCaFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.addDataSourceForm.securityFile = file;
        this.addDataSourceForm.caFile = file;
        this.$refs.addLocalDs.validateField('caFile');
      }
    },
    handleKeyTabFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.addDataSourceForm.secretFile = file;
      }
    },
    handlePre() {
      this.page--;
      this.handleListMysql();
    },
    handleNext() {
      this.page++;
      this.handleListMysql();
    },
    async listDatasourceDeployType() {
      const res = await listDatasourceDeployType();
      if (res.data.code === '1') {
        this.dataSourceDeployTypes = res.data.data;
        const obj = {};
        this.dataSourceDeployTypes.forEach((deployType) => {
          obj[deployType.deployEnvType] = deployType;
          if (!this.addDataSourceForm.fetchType && deployType.defaultCheck) {
            this.addDataSourceForm.instanceType = deployType.deployEnvType;
            this.addDataSourceForm.fetchTypeList = deployType.fetchTypeList;
            for (let i = 0; i < deployType.fetchTypeList.length; i++) {
              const fetchType = deployType.fetchTypeList[i];
              if (fetchType.defaultCheck) {
                this.addDataSourceForm.fetchType = fetchType.fetchType;
                break;
              }
            }
          }
        });
        this.dataSourceDeployTypesObj = obj;
      }
    },
    async listDataSourceTypes() {
      const res = await listDataSourceTypesByDeployType({ deployType: this.addDataSourceForm.instanceType });
      if (res.data.code === '1') {
        this.dataSourceTypes = res.data.data;
        if (!this.dataSourceTypes.includes(this.addDataSourceForm.type)) {
          this.addDataSourceForm.type = this.dataSourceTypes[0];
        }
        this.listSupportedRegions();
        this.getSecurityType();
        this.getDefaultKVConfig();
        // this.listSupportedDsType();
      }
    },
    async listEnv() {
      this.loading = true;
      const data = {
        envName: null
      };
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        this.envData = res.data;
        if (res.data[0]) {
          this.addDataSourceForm.envId = res.data[0].id;
        }
      }
    },
    listRegions() {
      listRegions()
        .then((res) => {
          if (res.data.code === '1') {
            this.regions = res.data.data;
          }
        });
    },
    listSupportedDsType() {
      supportedDsType({
        deployType: this.addDataSourceForm.instanceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.supportedDsType = res.data.data;

            const supportDs = [];

            Object.keys(res.data.data)
              .forEach((item) => {
                if (res.data.data[item]) {
                  supportDs.push(item);
                }
              });

            if (supportDs.length && !supportDs.includes(this.addDataSourceForm.type)) {
              this.addDataSourceForm.type = supportDs[0];
            }

            this.listSupportedRegions();
            this.getSecurityType();
            this.getDefaultKVConfig();
          }
        });
    },
    listSupportedRegions() {
      listDsSupportedRegions({
        cloudOrIdcName: this.addDataSourceForm.instanceType,
        dataSourceType: this.addDataSourceForm.type
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.regions = res.data.data;
            this.supportedRegions = res.data.data;
            this.supportedRegions.forEach((region) => {
              if (region.defaultCheck) {
                this.addDataSourceForm.region = region.region;
              }
            });
          }
        });
    },
    listRegionAreas() {
      listRegionAreas().then((res) => {
        if (res.data.code === '1') {
          this.regionAreas = res.data.data;
        }
      });
    },
    handleDeployTypeChange(type) {
      this.addDataSourceForm.fetchTypeList = this.dataSourceDeployTypesObj[type].fetchTypeList;
      for (let i = 0; i < this.dataSourceDeployTypesObj[type].fetchTypeList.length; i++) {
        const fetchType = this.dataSourceDeployTypesObj[type].fetchTypeList[i];
        this.addDataSourceForm.fetchType = fetchType.fetchType;
      }
      this.listDataSourceTypes();
      if (this.isManual) {
        this.addDataSourceForm.region = 'customer';
      } else {
        this.addDataSourceForm.region = 'hangzhou';
      }
    },
    handleSelectDataSource() {
      Object.keys(this.checkList)
        .map((key) => {
          if (this.checkList[key]) {
            store.state.rdsData.map((item) => {
              if (item.instanceId === key) {
                // this.addDataSourceForm.rdsList.push(item);
                store.state.addedRdsList.push(key);
                this.checkList[key] = false;
                this.checkList = { ...this.checkList };
                let hasPublic = false;
                let privateHost = '';
                let
                  publicHost = '';

                item.netInfo.map((net) => {
                  if (net.netIpType === 'VPC_Public' || net.netIpType === 'Classical_Public') {
                    if (net.complexHost) {
                      publicHost = net.connectionString;
                    } else {
                      publicHost = `${net.connectionString}:${net.port}`;
                    }
                    hasPublic = true;
                  } else if (net.netIpType === 'VPC_Private' || net.netIpType === 'Classical_Private') {
                    if (net.complexHost) {
                      privateHost = net.connectionString;
                    } else {
                      privateHost = `${net.connectionString}:${net.port}`;
                    }
                  }
                  return null;
                });
                if (!item.version && this.versionMap[item.dataSourceType]) {
                  item.selectedVersion = this.versionMap[item.dataSourceType][this.versionMap[item.dataSourceType].length - 1];
                }
                this.addDataSourceForm.rdsList.push({
                  instanceId: item.instanceId,
                  host: privateHost,
                  privateHost,
                  publicHost,
                  instanceDesc: item.instanceDesc,
                  hostType: hasPublic ? 'PUBLIC' : 'PRIVATE',
                  dataSourceType: item.dataSourceType,
                  password: '',
                  account: '',
                  securityType: this.addDataSourceForm.securityType,
                  version: item.version,
                  selectedVersion: item.selectedVersion
                });
              }
              return null;
            });
          }
          return null;
        });
      this.hasChecked = false;
      this.checkAll = false;
    },
    handleCancelDataSource() {
      this.needCancelList.map((item) => {
        this.addDataSourceForm.rdsList.map((rds, index) => {
          if (item.instanceId === rds.instanceId) {
            this.addDataSourceForm.rdsList.splice(index, 1);
            store.state.addedRdsList.map((r, i) => {
              if (r === rds.instanceId) {
                store.state.addedRdsList.splice(i, 1);
              }
              return null;
            });
          }
          return null;
        });
        return null;
      });
      this.addDataSourceForm.rdsList.push('');
      this.addDataSourceForm.rdsList.pop();
      this.checkAll = false;
      this.needCancelList = [];
    },
    handleSelectCancelList(selection) {
      this.needCancelList = selection;
    },
    handleSelectAllDs(checked) {
      this.filterData.forEach((ds) => {
        if (!ds._disabled) {
          this.checkList[ds.instanceId] = checked;
        }
      });
    },
    handleSelectRds() {
      let hasChecked = false;

      Object.keys(this.checkList)
        .map((key) => {
          if (this.checkList[key]) {
            hasChecked = true;
          }
          return null;
        });
      this.hasChecked = hasChecked;
    },
    handleFilter() {
      this.page = 1;
      this.filterData = [];
      store.state.rdsData.map((item) => {
        if (item.instanceDesc.indexOf(this.searchKey) > -1 || item.instanceId.indexOf(this.searchKey) > -1) {
          this.filterData.push(item);
        }
        return null;
      });
      this.total = this.filterData.length;
      this.showData = this.filterData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.filterData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handleDataSourceChange() {
      this.getDefaultKVConfig();
      if (this.$refs.addLocalDs) {
        this.$refs.addLocalDs.resetFields();
      }
      if (this.isManual) {
        this.addDataSourceForm.port = '';
        this.addDataSourceForm.publicPort = '';
        this.addDataSourceForm.dsKvConfigs = [];
        this.addDataSourceForm.hostList = [{
          type: 'private', display: true, host: '', port: ''
        }, {
          type: 'public', display: false, host: '', port: ''
        }];
        if (this.addDataSourceForm.type === 'MySQL') {
          this.addDataSourceForm.port = '3306';
          this.addDataSourceForm.publicPort = '3306';
          this.addDataSourceForm.hostList[0].port = '3306';
          this.addDataSourceForm.hostList[1].port = '3306';
        } else if (this.addDataSourceForm.type === 'PostgreSQL') {
          this.addDataSourceForm.port = '5432';
          this.addDataSourceForm.publicPort = '5432';
          this.addDataSourceForm.hostList[0].port = '5432';
          this.addDataSourceForm.hostList[1].port = '5432';
        } else if (this.addDataSourceForm.type === 'Greenplum') {
          this.addDataSourceForm.port = '5432';
          this.addDataSourceForm.publicPort = '5432';
          this.addDataSourceForm.hostList[0].port = '5432';
          this.addDataSourceForm.hostList[1].port = '5432';
        } else if (this.addDataSourceForm.type === 'Hive') {
          this.addDataSourceForm.port = '10000';
          this.addDataSourceForm.publicPort = '10000';
          this.addDataSourceForm.hostList[0].port = '10000';
          this.addDataSourceForm.hostList[1].port = '10000';
        } else if (this.addDataSourceForm.type === 'TiDB') {
          this.addDataSourceForm.port = '4000';
          this.addDataSourceForm.publicPort = '4000';
          this.addDataSourceForm.hostList[0].port = '4000';
          this.addDataSourceForm.hostList[1].port = '4000';
        } else if (this.addDataSourceForm.type === 'Oracle') {
          this.getOracleConnectType();
          this.addDataSourceForm.port = '1521';
          this.addDataSourceForm.publicPort = '1521';
          this.addDataSourceForm.hostList[0].port = '1521';
          this.addDataSourceForm.hostList[1].port = '1521';
        } else if (this.addDataSourceForm.type === 'OceanBase') {
          this.addDataSourceForm.port = '2881';
          this.addDataSourceForm.publicPort = '2881';
          this.addDataSourceForm.hostList[0].port = '2881';
          this.addDataSourceForm.hostList[1].port = '2881';
        } else if (isDb2(this.addDataSourceForm.type)) {
          this.addDataSourceForm.port = '50000';
          this.addDataSourceForm.publicPort = '50000';
          this.addDataSourceForm.hostList[0].port = '50000';
          this.addDataSourceForm.hostList[1].port = '50000';
        }
        if (!this.addDataSourceForm.version && this.versionMap[this.addDataSourceForm.type]) {
          this.addDataSourceForm.version = this.versionMap[this.addDataSourceForm.type][this.versionMap[this.addDataSourceForm.type].length - 1];
        }

        if (!this.addDataSourceForm.driver && this.driverMap[this.addDataSourceForm.type]) {
          this.addDataSourceForm.driver = this.driverMap[this.addDataSourceForm.type][this.driverMap[this.addDataSourceForm.type].length - 1];
        }
      } else if (this.addDataSourceForm.type === 'Kafka') {
        this.addDataSourceForm.securityType = 'USER_PASSWD_WITH_TLS';
      } else {
        this.addDataSourceForm.securityType = this.securitySetting[0].securityType;
      }
      this.addDataSourceForm.account = '';
      this.addDataSourceForm.password = '';
      this.listSupportedRegions();
      this.getSecurityType();
    },
    getSecurityType() {
      dsSecurityOption({
        deployEnvType: this.addDataSourceForm.instanceType,
        dataSourceType: this.addDataSourceForm.type
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.securitySetting = res.data.data.securityOptions;
            this.setSecuritySetting(res.data.data.securityOptions);
            if (res.data.data.securityOptions.length) {
              res.data.data.securityOptions.forEach((securityOption) => {
                if (securityOption.defaultCheck) {
                  this.addDataSourceForm.securityType = securityOption.securityType;
                }
              });
            }
          }
        });
    },
    handleSecurityTypeChange() {
      this.addDataSourceForm.account = '';
      this.addDataSourceForm.password = '';
    },
    handleAddHost() {
      this.addDataSourceForm.hostList[1].display = true;
    },
    handleRemoveHost(index) {
      this.addDataSourceForm.hostList[index].display = false;
      this.addDataSourceForm.hostList[index].host = '';
      this.addDataSourceForm.hostList[index].port = '';

      if (index === 0) {
        [this.addDataSourceForm.hostList[0], this.addDataSourceForm.hostList[1]] = [this.addDataSourceForm.hostList[1], this.addDataSourceForm.hostList[0]];
      }
    },
    getSecurity(type) {
      let security = {};

      this.securitySetting.map((item) => {
        if (item.securityType === type) {
          security = item;
        }
        return null;
      });
      return security;
    },
    needTestBeforeAddDsTypes() {
      needTestBeforeAddDsTypes().then((res) => {
        if (res.data.code === '1') {
          this.canTestyDsList = res.data.data;
        }
      });
    },
    handleChangeVersion(row, data) {
      this.addDataSourceForm.rdsList.forEach((rds) => {
        if (rds.instanceId === row.instanceId) {
          rds.selectedVersion = data;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.add-datasource-step1 {
  padding: 20px;

  .ivu-alert-with-desc.ivu-alert-with-icon {
    margin-bottom: 0;
  }
}

.transfer-title {
  font-weight: 500;
  margin-bottom: 7px;
}

.transfer-left {
  width: 100%;
  height: 460px;
  border: 1px solid #DADADA;
  position: relative;

  .transfer-left-search {
    padding: 10px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #DADADA;
    position: relative;
    display: flex;
    align-items: center;

    button {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .transfer-left-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-top: 1px solid #DADADA;
    background: #ffffff;
  }

  .transfer-left-item {
    padding: 16px 16px 15px 52px;
    border-bottom: 1px solid #DADADA;
    position: relative;

    .ivu-checkbox-wrapper {
      position: absolute;
      left: 16px;
      top: 34px
    }
  }
}

.transfer-btns {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  margin-top: 200px;
  /*line-height: 500px;*/
}

.datasource-setting-title {
  font-weight: 500;
  margin-bottom: 20px;
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  //width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}

.second-host-item {
  margin-top: 20px;
}

.selected-region {
  color: #333333;
  padding-right: 16px;
}

.region-container {
  padding: 20px;
  max-height: 500px;
  width: 1000px;
  overflow: auto;

  .region-group {
    margin-bottom: 20px;
    h3 {
      margin-bottom: 6px;
    }

    .ivu-radio-group-item {
      width: 180px;
      text-align: center;
      margin: 4px;
      height: 36px;
      line-height: 34px;
    }
  }

  .region-btn {
    width: 100%;
  }
}

</style>
