export const userApi = {
  sendCode: '/rdp/console/api/v1/verify/sendcode',
  sendCodeInLoginState: '/rdp/console/api/v1/verify/sendcodeinloginstate',
  register: '/register',
  login: '/login',
  logout: '/logout',
  getGlobalSettings: '/global_settings',
  dmGlobalSettings: '/clouddm/console/api/v1/dm_global_settings',
  watermark: '/rdp/console/api/v1/user/waterMark',
  listOrg: '/list_org',
  checkSupplement: '/check_supplement',
  getUserInfo: '/rdp/console/api/v1/user/queryLoginUser',
  resetPassword: '/rdp/console/api/v1/user/manager/resetpasswd',
  resetPwdUnLogin: '/resetpwdunlogin',
  getSubAccountList: '/rdp/console/api/v1/user/manager/listsubaccounts',
  updateSubAccount: '/rdp/console/api/v1/user/manager/updatesubaccount',
  addSubAccount: '/rdp/console/api/v1/user/manager/addsubaccount',
  deleteSubAccount: '/rdp/console/api/v1/user/manager/deletesubaccount',
  updateUserRole: '/rdp/console/api/v1/user/manager/updateuserrole',
  stopSubAccount: '/rdp/console/api/v1/user/manager/updateaccountability',
  checkSubAccountDuplicate: '/rdp/console/api/v1/user/manager/checksubaccountduplicate',
  getResourceSummary: '/rdp/console/api/v1/user/resourceSummary',
  resetOpPwd: '/rdp/console/api/v1/user/resetOpPasswd',
  getDbOpAudits: '/clouddm/console/api/v1/user/dbopaudits', // 数据源操作记录
  updatealiyunaksk: '/rdp/console/api/v1/user/updateAliyunAkSk',
  verifyOpPwd: '/rdp/console/api/v1/user/opPasswdVerify',
  cleanaliyunaksk: '/rdp/console/api/v1/user/cleanaliyunaksk',
  listMyAuth: '/rdp/console/api/v1/user/listMyAuth',
  getCurrUserConfigs: '/rdp/console/api/v1/user/config/getcurruserconfigs',
  updateUserConfigs: '/rdp/console/api/v1/user/config/upsertuserconfigs'
};
