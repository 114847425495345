<template>
  <div class="content-wrapper">
    <Breadcrumb>
      <BreadcrumbItem to="/data/job/list">{{ $t('tong-bu-ren-wu') }}</BreadcrumbItem>
      <BreadcrumbItem to="/data/job/create/process">{{type === 'create'? $t('chuang-jian-ren-wu'): $t('xiu-gai-ding-yue')}}</BreadcrumbItem>
    </Breadcrumb>
    <div class="create-task-process">
      <div class="create-task-step">
        <Steps :current="currentStep" v-if="type==='create'">
          <Step :title="$t('yuan-mu-biao-she-zhi')"></Step>
          <Step :title="$t('gong-neng-pei-zhi')"></Step>
          <Step :title="$t('biao-action-guo-lv')"></Step>
          <Step :title="$t('shu-ju-chu-li')"></Step>
          <Step :title="$t('chuang-jian-que-ren')"></Step>
        </Steps>
        <Steps :current="editCurrentStep" v-else-if="type==='edit'">
          <Step :title="$t('biao-action-guo-lv')"></Step>
          <Step :title="$t('shu-ju-chu-li')"></Step>
          <Step :title="$t('xiu-gai-que-ren')"></Step>
        </Steps>
      </div>
      <div class="create-task-body">
        <OriginalConfig v-if="currentStep===0" ref="OriginalConfig" :deployType="deployType"
                        :updateStep="handleNextStep"
                        :updateLoading="updateLoading"
                        :taskInfo="taskInfo" :updateNextDisabled="updateNextDisabled"
                        :updateShowAddDataSource="updateShowAddDataSource"></OriginalConfig>
        <FunctionConfig v-if="currentStep===1" ref="functionConfig" :deployType="deployType"
                        :updateStep="handleNextStep" :updateNextDisabled="updateNextDisabled"
                        :updateLoading="updateLoading" :taskInfo="taskInfo"
                        :updateShowAddDataSource="updateShowAddDataSource" :handleCreateTask="handleCreateTask"></FunctionConfig>
        <TableFilter :updateLoading="updateLoading" :taskInfo="taskInfo"
                     v-if="currentStep===2" :updateNextDisabled="updateNextDisabled"
                     ref="TableFilter" :defaultSelected="defaultSelected"
                     :updateStep="handleNextStep" :type="type"></TableFilter>
        <CleanData :updateLoading="updateLoading" :taskInfo="taskInfo" ref="CleanData" :type="type"
                   :handleStep="handleStep" :updateNextDisabled="updateNextDisabled"
                   v-if="currentStep===3"></CleanData>
        <TaskInfo :taskInfo="taskInfo"
                  v-if="(DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1 && !isMongoDB(taskInfo.sourceType) && !isRedis(taskInfo.sourceType))&&(currentStep===4)"></TaskInfo>
        <TaskInfoWithNoSourceDb :taskInfo="taskInfo"
                                v-if="(DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1||isMongoDB(taskInfo.sourceType) || (isRedis(taskInfo.sourceType)))&&
                                        (currentStep===4)"></TaskInfoWithNoSourceDb>
      </div>
    </div>
    <div class="create-task-tools">
      <div style="position: absolute;right: 50%;display: block">
        <Checkbox v-if="editType==='1'&&taskInfo.processType==='edit'&&taskInfo.showChooseInitialSync&&(currentStep===4 || (currentStep===2&&taskInfo.schemaWhiteListLevel === 'DB'))"
                  v-model="taskInfo.initialSync">{{ $t('quan-liang-shu-ju-chu-shi-hua') }}
          <Tooltip placement="top" transfer :content="$t('ru-bu-gou-xuan-quan-liang-shu-ju-chu-shi-hua-jiang-cong-zeng-liang-shu-ju-kai-shi-qian-yi-ru-xu-wan-zheng-de-shu-ju-qing-wu-bi-gou-xuan-shu-ju-chu-shi-hua')">
            <Icon style="font-size: 14px" type="ios-information-circle" />
          </Tooltip>
        </Checkbox>
        <Button v-if="currentStep===0" @click="handleReturn">{{ $t('fan-hui-ren-wu-guan-li') }}</Button>
        <pd-button :loading="loading" @click="handleRefresh"
                   v-if="currentStep === 2 || currentStep === 3"><Icon
          type="md-refresh"/>{{ $t('shua-xin-shu-ju') }}</pd-button>
        <Button v-if="(type === 'create' && currentStep > 0) || (type === 'edit' && currentStep > 2)" @click="handleStep('pre')">{{ $t('shang-yi-bu') }}</Button>
        <Button :loading="loading" type="primary" @click="handleStep('next')" :disabled="nextDisabled"
                v-if="(taskInfo.schemaWhiteListLevel !== 'DB')&&currentStep!==4||(taskInfo.schemaWhiteListLevel === 'DB'&&currentStep<2)">{{ $t('xia-yi-bu') }}</Button>
        <pd-button type="primary" @click="handleCreateTask" :loading="modifyLoading"
                   v-if="currentStep===4||(currentStep===2&&taskInfo.schemaWhiteListLevel === 'DB')">{{taskInfo.processType === 'create' ? $t('chuang-jian-ren-wu') : $t('xiu-gai-ding-yue')}}</pd-button>
        <!--                             <Button :loading="loading" type="primary" @click="handleStep('next')"-->
        <!--                                     v-if="currentStep!==4">下一步</Button>-->
        <!--                <Button type="primary" @click="handleCreateTask"-->
        <!--                        v-if="currentStep===4">创建任务</Button>-->
        <!--                <span style="margin-left: 20px;position: absolute;right: -240px;top: -20px"-->
        <!--                      v-if="taskInfo.processType==='create' && (currentStep===4||(currentStep===2&&taskInfo.schemaWhiteListLevel === 'DB'))" >-->
        <!--                <Checkbox v-model="makeSure"></Checkbox>-->
        <!--                <a style="color: #333333" href="https://doc-cloudcanal.clougence.com/protocol/sla"-->
        <!--                   target="_blank">{{ $t('cloudcanal-fu-wu-deng-ji-xie-yi-sla') }}</a>-->
        <!--                </span>-->
      </div>
    </div>
    <Modal
      v-model="showConfirm"
      :title="$t('chuang-jian-que-ren')"
      footer-hide
      @on-ok="handleConfirm">
      <div>
        <p>{{ $t('que-ren-yao-chuang-jian-gai-ren-wu-ma') }}</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button :loading="createLoading" type="primary" @click="handleConfirm">{{ $t('que-ren') }}</pd-button>
          <Button @click="handleCancel">{{ $t('qu-xiao') }}</Button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showConfirmEdit"
      :title="$t('xiu-gai-ding-yue-que-ren')"
      footer-hide
      @on-ok="handleConfirm">
      <div>
        <p>{{ $t('que-ren-yao-jin-hang-gai-xiu-gai-ma') }}</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <Button type="primary" @click="handleConfirm">{{ $t('que-ren') }}</Button>
          <Button @click="handleCancel">{{ $t('qu-xiao') }}</Button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showCreateStatus"
      :mask-closable="false"
      :footer-hide="true"
      :title="$t('ren-wu-chuang-jian-zhuang-tai')">
      <div>
        <Row :gutter="16">
          <Col span="12">
            <Form label-position="right" :label-width="120">
              <FormItem :label="$t('ren-wu-miao-shu')">
                {{ creatingStatus.dataJobName }}
              </FormItem>
              <FormItem :label="$t('ren-wu-lei-xing')">
                {{ creatingStatus.dataJobType }}
              </FormItem>
            </Form>
          </Col>
          <Col span="12">
            <div style="margin-top: 30px" v-if="creatingStatus.state==='PRECHECK'">
              <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
                {{ $t('yu-jian-zhong-0') }}</p>
            </div>
            <div style="margin-top: 20px" v-if="creatingStatus.state==='CREATING'">
              <Progress :percent="currentPercentage" :stroke-width="20" status="active"
                        text-inside/>
              <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
                {{ $t('chuang-jian-zhong') }}</p>
            </div>
            <div style="color: #19be6b;text-align: center;"
                 v-if="creatingStatus.state==='INIT'">
              <Icon style="font-size: 60px" type="ios-checkmark-circle"/>
              <p style="padding-left:10px;margin-top: 16px;font-weight:500">{{ $t('chuang-jian-cheng-gong') }}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
    <Modal
      v-model="showPreCheck"
      :closable="false"
      :footer-hide="true"
      :mask-closable="false"
      width="1000px"
      :title="$t('yu-jian-zhong')">
      <div>
        <div class="preCheck-title">
          <span class="preCheck-title-desc">{{ preCheckStatusDesc[preCheckStatus].desc }}</span>
          {{ $t('gong-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItems.length }}</span>
          {{ $t('xiang-yi-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItemsCheckedCount }}</span>{{ $t('xiang-shi-bai') }}
          <span class="preCheck-title-count">{{ preCheckItemsErrorCount }}</span>{{ $t('xiang') }}
        </div>
        <div style="max-height: 400px;overflow: auto">
          <Table stripe border size="small" :columns="preCheckColumns" :data="preCheckItems">
            <template slot-scope="{ row }" slot="preCheckResult">
              <div style="font-size: 18px">
                <Icon type="md-checkmark" style="color: #19be6b" v-if="row.success&&row.checked"/>
                <Icon type="md-close" style="color: #ed4014" v-if="!row.success&&row.checked"/>
                <span v-if="!row.checked" style="font-size: 12px;color: #808695;">{{ $t('dai-jian-cha') }}</span>
              </div>
            </template>
          </Table>
        </div>
        <p style="margin: 20px 0;text-align: center">
          <pd-button style="min-width: 120px;height: 32px;line-height: 30px;margin-right: 20px" type="default"
                     @click="handleHidePrecheck">{{ $t('guan-bi') }}
          </pd-button>
          <pd-button style="min-width:120px;height: 32px;line-height: 30px;padding: 0 10px" type="default"
                     @click="handleHidePrecheck($event,true)">{{ $t('hu-lve-bing-ji-xu') }}
          </pd-button>
        </p>
      </div>
    </Modal>
    <Modal
      v-model="showCreateError"
      type="error"
      :title="$t('chuang-jian-shi-bai')"
    >
      <Alert type="error">{{showCreateErrorMsg}}</Alert>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { finalCheck, updateTransferObject, updateTransferObjectForFull } from '@/services/cc/api/job';
import { createJob } from '@/services/cc/api/upload';
import OriginalConfig from '@/components/function/dataJob/createJob/OriginalConfig';
import CleanData from '@/components/function/dataJob/createJob/CleanData';
import TableFilter from '@/components/function/dataJob/createJob/TableFilter';
import TaskInfo from '@/components/function/dataJob/createJob/TaskInfo';
import FunctionConfig from '@/components/function/dataJob/createJob/FunctionConfig';
import CreateJobMixin from '@/components/function/mixin/createJobMixin';
import TaskInfoWithNoSourceDb from '@/components/function/dataJob/createJob/TaskInfoWithNoSourceDb';
import {
  isKafka, isMongoDB, isRedis, isHana
} from '@/utils';
import { getCurrUserConfigs } from '@/services/cc/api/rdpUser';
import store from '@/store/index';
import DataSourceGroup from '../dataSourceGroup';
import Mapping from '../util';

export default {
  mixins: [CreateJobMixin],
  components: {
    OriginalConfig,
    CleanData,
    TableFilter,
    TaskInfo,
    FunctionConfig,
    TaskInfoWithNoSourceDb
  },
  async created() {
    this.getCurrUserConfigs();
    if (this.$route.name === 'taskEdit/process' || store.state.jobData) {
      let jobData = {};
      if (this.$route.name === 'taskEdit/process') {
        this.type = 'edit';
        this.taskInfo.processType = 'edit';
        this.currentStep = 2;
        this.editType = this.$route.query.type;
        this.taskInfo.editType = this.editType;
      } else {
        jobData = store.state.jobData;
        this.taskInfo.jobData = jobData;

        // const jobData = store.state.jobData;
        this.taskInfo.clusterId = jobData.clusterId;
        this.taskInfo.sourceDataSourceIdd = jobData.sourceDsVO.id;
        this.taskInfo.sourceInstanceType = jobData.sourceDsVO.deployType;
        this.taskInfo.sourceType = jobData.sourceDsVO.dataSourceType;
        this.taskInfo.sourceInstance = jobData.sourceDsVO.host;
        this.taskInfo.sourceHostType = jobData.sourceDsHostType;
        this.taskInfo.sourceDataSourceId = jobData.sourceDsVO.id;
        this.taskInfo.sourcePrivateHost = jobData.sourceDsVO.privateHost;
        this.taskInfo.sourceInstanceId = jobData.sourceDsVO.instanceId;
        this.taskInfo.sourcePublicHost = jobData.sourceDsVO.publicHost;
        this.taskInfo.consumerGroupId = jobData.sourceDsVO.mqConsumerGroupId;
        this.taskInfo.sourceInstanceValue = `${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourceDataSourceId}|${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourcePublicHost}|${
          this.taskInfo.sourceInstanceId}`;
        this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
        this.taskInfo.sinkInstanceType = jobData.targetDsVO.deployType;
        this.taskInfo.sinkType = jobData.targetDsVO.dataSourceType;
        this.taskInfo.sinkInstance = jobData.targetDsVO.host;
        this.taskInfo.targetHostType = jobData.targetDsHostType;
        this.taskInfo.targetDataSourceId = jobData.targetDsVO.id;
        this.taskInfo.sinkPrivateHost = jobData.targetDsVO.privateHost;
        this.taskInfo.sinkInstanceId = jobData.targetDsVO.instanceId;
        this.taskInfo.sinkPublicHost = jobData.targetDsVO.publicHost;
        this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkPrivateHost}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
          this.taskInfo.sinkInstanceId}`;
        this.taskInfo.type = jobData.dataJobType;
        this.taskInfo.desc = jobData.dataJobDesc;

        if (this.taskInfo.type === 'CHECK') {
          this.taskInfo.checkMode = 'checkOnce';
        } else {
          this.taskInfo.checkMode = 'noCheck';
        }

        if (jobData.createReviseTask) {
          this.taskInfo.type = 'REVISE';
          this.taskInfo.reviseBindCheckJobId = jobData.dataJobId;
          this.taskInfo.reviseBindCheckTaskId = jobData.checkTaskId;
        }
        if (jobData.processorConfigListJson) {
          const processList = JSON.parse(jobData.processorConfigListJson);
          processList.forEach((process) => {
            if (process.processorConfigType === 'FIELD_MAKER_PROCESSOR') {
              if (process.allProcessorContextJson) {
                this.taskInfo.virtualColumns = [];
                this.taskInfo.customVirtualColumns = [];
                const fieldProcessList = JSON.parse(process.allProcessorContextJson);
                fieldProcessList.forEach((fieldProcess) => {
                  this.taskInfo.virtualColumns.push(fieldProcess);
                  this.taskInfo.virtualColumnData[fieldProcess.fieldPathName] = [];
                  if (fieldProcess.customFieldList) {
                    fieldProcess.customFieldList.forEach((item) => {
                      const virtualColumn = {};
                      virtualColumn.customName = item.fieldName;
                      virtualColumn.targetVirtualName = item.fieldName;
                      virtualColumn.columnLength = item.length;
                      virtualColumn.columnValue = item.fieldValue;
                      virtualColumn.genRule = item.genRule;
                      virtualColumn.isCustom = item.needAutoCreate;
                      virtualColumn.sqlType = item.sqlType;
                      virtualColumn.targetVirtualType = item.typeName;
                      this.taskInfo.virtualColumnData[fieldProcess.fieldPathName].push(virtualColumn);
                    });
                  }
                });
              }
            } else if (process.processorConfigType === 'CUSTOM_PKG_PROCESSOR') {
              this.taskInfo.customPkgProcess = process;
            }
          });
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.getPercantage);
    store.state.jobData = null;
  },
  computed: {
    editCurrentStep() {
      return this.currentStep - 2;
    }
  },
  data() {
    return {
      createLoading: false,
      modifyLoading: false,
      hasNewAddData: false,
      type: 'create',
      editType: 1,
      jobData: {},
      store,
      DataSourceGroup,
      showNotNullTable: false,
      loading: false,
      nextDisabled: false,
      deployType: 'PUBLIC_CLOUD',
      getPercantage: '',
      clusterList: [],
      showSolution: false,
      currentPage: 0,
      Mapping,
      showAddDataSource: false,
      showAddDataSourceException: false,
      showAddClusterException: false,
      addDataSourceType: '',
      makeSure: true,
      createData: '',
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      preCheckStatus: 'error',
      preCheckItems: [],
      preCheckColumns: [
        {
          title: this.$t('lei-xing'),
          key: 'preCheckType',
          width: 200
        },
        {
          title: this.$t('biao-ti'),
          key: 'title'
        },
        {
          title: this.$t('cuo-shi'),
          key: 'passRequirement'
        },
        {
          title: this.$t('shi-bai-mu-biao'),
          render: (h, params) => {
            let value = '';

            if (params.row.contextDbName) {
              if (params.row.contextTableName) {
                if (params.row.contextColumnName) {
                  if (params.row.varActualValue) {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}:${params.row.varActualValue}`;
                  } else {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}`;
                  }
                } else {
                  value = `${params.row.contextDbName}.${params.row.contextTableName}`;
                }
              } else {
                value = `${params.row.contextDbName}`;
              }
            }
            return h('div', {}, params.row.checked && !params.row.success ? value : '');
          }
        },
        {
          title: this.$t('jian-cha-jie-guo'),
          slot: 'preCheckResult',
          width: 100
        }
      ],
      preCheckItemsSuccessCount: 0,
      preCheckItemsErrorCount: 0,
      preCheckItemsCheckedCount: 0,
      showPreCheck: false,
      showConfirm: false,
      showConfirmEdit: false,
      showCreateStatus: false,
      showCreateError: false,
      showCreateErrorMsg: '',
      taskType: '',
      currentStep: 0,
      preCheckStatusDesc: {
        processing: {
          desc: this.$t('yu-jian-zhong'),
          color: ''
        },
        success: {
          desc: this.$t('yu-jian-cheng-gong'),
          color: '#19be6b'
        },
        error: {
          desc: this.$t('yu-jian-shi-bai'),
          color: '#ed4014'
        }
      },
      currentPercentage: 0,
      taskInfo: {
        advancedSetting: {},
        originDecodeMsgFormat: null,
        keyConflictStrategy: null,
        keyConflictStrategyList: [],
        columnMappingIndex: 0,
        columnMappingRule: [],
        tableMappingIndex: 0,
        tableMappingRule: [],
        obTenant: 'sys',
        oraIncrMode: '',
        oraIncrModeList: [],
        oraBuildRedoDicWhenCreate: true,
        targetTimeDefaultStrategy: null,
        dstMqDefaultTopic: null,
        dstMqDefaultTopicPartitions: null,
        dstMqDdlTopic: null,
        dstMqDdlTopicPartitions: null,
        dstSrOrDorisTableModel: null,
        dstSrOrDorisTableModelList: [],
        sourceInstanceValue: '',
        sinkInstanceValue: '',
        migrationBucketNumber: 4,
        migrationPropertiesConfigWithSr: 'PROPERTIES("replication_num" = "1")',
        migrationPropertiesConfig: 'PROPERTIES("replication_num" = "1")',
        showChooseInitialSync: false,
        initialSync: true,
        dbMapObj: {},
        preSelectedTablesObj: {},
        mappingDef: [],
        firstEdit: false,
        taskInfoNewData: [],
        taskInfoReduceData: [],
        taskInfoUpdateData: [],
        processType: 'create',
        // edit
        reduceData: {},
        newData: {},
        // original
        originalConfigHistory: false,
        dataSourceDeployTypes: [],
        srcDeployTypes: [],
        dstDeployTypes: [],
        sourceConnection: false,
        sinkConnection: false,
        sourceConnectionLoading: false,
        sinkConnectionLoading: false,
        showSourceConnection: false,
        showSinkConnection: false,
        clusterList: [],
        clusterId: 0,
        sourceDataSourceTypes: [],
        sinkDataSourceTypes: [],
        sourceDbList: [],
        sinkDbList: [],
        sourceInstanceList: [],
        sinkInstanceList: [],
        sourceCharsetList: [],
        targetCharsetList: [],
        showSinkAddWhiteList: false,
        showSourceAddWhiteList: false,
        mappingMode: 'advanced',
        dbMap: [
          {
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'public',
            sourceSchema: 'public'
          }
        ],
        schemaTypeList: [],
        engineTypeList: [],
        dstSchemaLessFormat: 'CLOUDCANAL_JSON_FOR_MQ',
        srcSchemaLessFormat: 'CLOUDCANAL_JSON_FOR_MQ',
        consumerGroupId: '',
        srcRabbitMqVhost: '',
        dstRabbitMqVhost: '',
        targetCaseSensitive: 'true',
        dstRabbitExchange: '',
        sourceType: 'MySQL',
        sinkType: 'MySQL',
        sourceInstance: '',
        sinkInstance: '',
        sourceInstanceId: '',
        sinkInstanceId: '',
        sourceAccount: '',
        sourcePasswd: '',
        sinkAccount: '',
        sinkPasswd: '',
        sourceInstanceType: 'SELF_MAINTENANCE',
        sinkInstanceType: 'SELF_MAINTENANCE',
        sourceHostType: 'PRIVATE',
        targetHostType: 'PRIVATE',
        sourcePrivateHost: '',
        sourcePublicHost: '',
        sinkPrivateHost: '',
        sinkPublicHost: '',
        targetDataSourceId: 0,
        sourceDataSourceId: 0,
        // functional
        // functionalConfigHistory: false,
        // jobTypeList: {},
        // resourceData: {},
        // loopRunTypes: {},
        // type: 'SYNC',
        // ddl: 'false',
        // mode: {
        //   synchronize: false,
        //   init: true,
        //   shortTermNum: 7
        // },
        // specsMap: {},
        // checkMode: 'noCheck',
        // fullPeriod: false,
        // specKind: 'Balance',
        // spec: {},
        functionalConfigHistory: false,
        jobTypeList: {},
        resourceData: [],
        loopRunTypes: {},
        type: 'SYNC',
        ddl: 'false',
        cleanTargetBeforeFull: 'false',
        reSchemaMigration: 'false',
        mode: {
          synchronize: false,
          init: false,
          shortTermNum: 7
        },
        specsMap: {},
        checkMode: 'noCheck',
        fullPeriod: false,
        specKind: 'Balance',
        spec: null,
        desc: '',
        checkPeriodDate: {
          dayType: '',
          day: '',
          time: ''
        },
        fullPeriodDate: {
          dayType: '',
          day: '',
          time: '',
          hour: ''
        },
        autoStart: true,
        // tableFilter
        commonRule: '',
        firstToTableFilter: false,
        compareSelectedDbs: [],
        selectedTables: {},
        sinkTableList: {},
        // cleanData
        firstToCleanData: false,
        selectedColumns: {},
        tableInfo: [],
        setPkAndIdFlags: {},
        sinkColumns: {},
        // 默认值 无修改
        sourceCaseSensitive: 'true',
        enableAutoIncrement: 'true',
        sourceSpecifiedUserPassword: false,
        useQualifiers: 'true',
        taskName: 'db1_db1_migrate_001',
        fullJVMHeapMB: 2048,
        checkJVMHeapMB: 2048,
        incrementJVMHeapMB: 2048,
        whiteList: ['db', 'table', 'column'],
        // old
        showLoading: {},
        ddlAction: ['create', 'alter', 'drop'],
        checkPeriodCronExpr: '',
        fullPeriodCronExpr: '',
        srcSchemaIsNotWhiteList: false,
        pkgDescription: '',
        firstTime: true,
        sinkExtraSetting: true,
        sourceExtraSetting: true,
        globalTimeZone: '+08:00',
        sourceAccountRole: false,
        sinkAccountRole: '',
        virtualColumns: [],
        virtualColumnData: {},
        customVirtualColumns: [],
        sourceCharset: 'utf8',
        targetCharset: 'utf8',
        treeData: [],
        dstCkTableEngine: null,
        kuduNumReplicasSelected: false,
        kuduNumBucketsSelected: false,
        kuduNumReplicas: 3,
        kuduNumBuckets: 32,
        customPkgFile: null,
        defaultSelected: true,
        partitionData: {
          partition: [
            {
              key: '',
              func: ''
            }
          ]
        }
      },
      creatingStatus: {
        state: 'CREATING',
        dataJobId: 1,
        dataJobName: 'db1-db2-migrate',
        dataJobType: 'MIGRATION',
        createTime: '2020-01-20 12:12:12'
      },
      coreConfigWrapper: {
        processorConfigList: []
      }
    };
  },
  methods: {
    isRedis,
    isMongoDB,
    async getCurrUserConfigs() {
      const res = await getCurrUserConfigs();
      if (res.data.code === '1') {
        res.data.data.forEach((config) => {
          if (config.configName === 'jobTableDefaultSelectAll') {
            this.defaultSelected = JSON.parse(config.configValue);
          }
        });
      }
    },
    handleStep(type) {
      if (type === 'next') {
        if (this.currentStep === 0) {
          this.$refs.OriginalConfig.handleShowPreCheck(true);
          this.taskInfo.originalConfigHistory = true;
        } else if (this.currentStep === 1) {
          this.taskInfo.functionalConfigHistory = true;
          if (this.taskInfo.fullPeriod
            && (!this.taskInfo.fullPeriodDate.dayType
              || (this.taskInfo.fullPeriodDate.dayType === 'MONTH'
                && (!this.taskInfo.fullPeriodDate.date || !this.taskInfo.fullPeriodDate.time))
              || (this.taskInfo.fullPeriodDate.dayType === 'DAY' && !this.taskInfo.fullPeriodDate.time)
              || (this.taskInfo.fullPeriodDate.dayType === 'HOUR'
                && !this.taskInfo.fullPeriodDate.hour && this.taskInfo.fullPeriodDate.hour !== 0)
              || (this.taskInfo.fullPeriodDate.dayType === 'YEAR'
                && (!this.taskInfo.fullPeriodDate.month || !this.taskInfo.fullPeriodDate.date || !this.taskInfo.fullPeriodDate.time)))) {
            this.$Modal.warning({
              title: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-xin-xi'),
              content: this.$t('qing-tian-xie-wan-zheng-de-ding-shi-ren-wu-xin-xi')
            });
          } else if (this.taskInfo.checkMode === 'checkPeriod'
            && (!this.taskInfo.checkPeriodDate.dayType
              || (this.taskInfo.checkPeriodDate.dayType === 'MONTH'
                && (!this.taskInfo.checkPeriodDate.date || !this.taskInfo.checkPeriodDate.time))
              || (this.taskInfo.checkPeriodDate.dayType === 'DAY' && !this.taskInfo.checkPeriodDate.time)
              || (this.taskInfo.checkPeriodDate.dayType === 'HOUR'
                && !this.taskInfo.checkPeriodDate.hour && this.taskInfo.checkPeriodDate.hour !== 0)
              || (this.taskInfo.checkPeriodDate.dayType === 'YEAR'
                && (!this.taskInfo.checkPeriodDate.month || !this.taskInfo.checkPeriodDate.date || !this.taskInfo.checkPeriodDate.time)))) {
            this.$Modal.warning({
              title: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-xin-xi'),
              content: this.$t('qing-tian-xie-wan-zheng-de-zhou-qi-xing-xiao-yan-yu-ding-zheng-ren-wu-xin-xi')
            });
          } else {
            this.$refs.functionConfig.handleShowPreCheck(true);
          }
        } else if (this.currentStep === 2) {
          let noEmptyDb = true;
          let noSinkTable = true;
          this.taskInfo.dbMap.forEach((db) => {
            db.selectedTables.forEach((table) => {
              if (table.hasInJob && table._checked && (!_.isEqual(table.action, table.originalAction) || table.sinkTable !== table.originalSinkTable)) {
                console.log('edit', table.action, table.originalAction, table.sinkTable !== table.originalSinkTable);
                table.edit = true;
                db.edit = true;
              }
            });
          });
          this.taskInfo.dbMap.map((item) => {
            if (!item.selectedTables) {
              noEmptyDb = false;
              this.$Modal.warning({
                title: this.$t('ren-wu-chuang-jian-ti-shi'),
                content: this.$t('yuan-ku-itemsourcedb-wei-kong-ku-biao-de-shu-liang-wei-0-bu-zhi-chi-qian-yi', [item.sourceDb])
              });
            } else if (item.selectedTables && item.selectedTables.length < 1) {
              noEmptyDb = false;
              this.$Modal.warning({
                title: this.$t('ren-wu-chuang-jian-ti-shi'),
                content: this.$t('mei-ge-ku-qing-zhi-shao-xuan-ze-yi-zhang-biao-dang-qian-itemsourcedb-ku-mei-you-xuan-ze-biao', [item.sourceDb])
              });
            } else {
              let selectedTableCount = 0;

              item.selectedTables.map((table) => {
                if (table.selected) {
                  selectedTableCount++;
                  if (!table.sinkTable) {
                    noSinkTable = false;
                    this.$Modal.warning({
                      title: this.$t('ren-wu-chuang-jian-ti-shi'),
                      content: this.$t('qing-wu-bi-xuan-ze-mu-biao-biao-dang-qian-itemsourcedbtablesourcetable-mei-you-xuan-ze-mu-biao-biao', [item.sourceDb, table.sourceTable])
                    });
                  }
                }
                return null;
              });
              if (selectedTableCount < 1) {
                noEmptyDb = false;
                this.$Modal.warning({
                  title: this.$t('ren-wu-chuang-jian-ti-shi'),
                  content: this.$t('mei-ge-ku-qing-zhi-shao-xuan-ze-yi-zhang-biao-dang-qian-itemsourcedb-ku-mei-you-xuan-ze-biao', [item.sourceDb])
                });
              }
            }
            return null;
          });
          if (noEmptyDb && noSinkTable) {
            this.$refs.TableFilter.updateNextStep();
            if ((DataSourceGroup.noStruct.includes(this.taskInfo.sourceType) && DataSourceGroup.noStruct.includes(this.taskInfo.sinkType))) {
              this.handleNextStep(4);
            } else {
              this.handleNextStep(3);
            }
          }
          // }
        } else if (this.currentStep === 3) {
          const res = this.$refs.CleanData.updateNextStep();
          if (!res) {
            return;
          }

          if (!res.success && res.error === 'noEditData') {
            this.$Modal.confirm({
              title: this.$t('chuang-jian-ren-wu-ti-xing'),
              content: this.$t('wei-jin-hang-xiu-gai')
            });
          }
          if (!res.success && res.error === 'differentDataFilterType') {
            this.$Modal.confirm({
              title: this.$t('chuang-jian-ren-wu-ti-xing'),
              content: this.$t('zan-bu-zhi-chi-yi-ge-ren-wu-she-zhi-duo-zhong-shu-ju-guo-lv-mo-shi')
            });
          }

          if (res.success) {
            if (res.noPkList.length > 0 && (DataSourceGroup.mysql.includes(this.taskInfo.sourceType) || DataSourceGroup.polar.includes(this.taskInfo.sourceType))) {
              return;
            }

            if (res.noRedisKeyList.length > 0 && DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
              return;
            }
            if (isKafka(this.taskInfo.sourceType)) {
              this.taskInfo.dbMap.map((item) => {
                item.selectedTables.map((table) => {
                  if (table.selected) {
                    if (!table.cols) {
                      table.cols = [];
                      item.selectedColumns[table.sinkTable].forEach((column) => {
                        if (column.isPk) {
                          table.cols.push(column.sinkColumn);
                        }
                      });
                    }
                  }
                  return null;
                });
                return null;
              });
            }
            if (res.allHasColumn.length < 1) {
              if (this.taskInfo.sinkType === 'Hive') {
                let noPartition = false;

                this.taskInfo.dbMap.map((item) => {
                  item.selectedTables.map((table) => {
                    if (table.selected) {
                      if (isKafka(this.taskInfo.sourceType)) {
                        if (!table.cols) {
                          table.cols = [];
                          item.selectedColumns[table.sinkTable].forEach((column) => {
                            console.log('column2333', column);
                            if (column.isPk) {
                              table.cols.push(column.sinkColumn);
                            }
                          });
                        }
                      }
                      if (!table.partitionData || !table.partitionData.partition || table.partitionData.partition.length < 1) {
                        // console.log('table', table);
                        noPartition = true;
                        this.$Modal.confirm({
                          title: this.$t('fen-qu-wei-she-zhi-ti-shi'),
                          content: this.$t('p-biao-tablesourcetable-mei-you-she-zhi-fen-qu-jian-ke-neng-dao-zhi-shu-ju-zhong-fu-dao-ru-bing-fu-gai-zhi-qian-de-shu-ju-qing-dian-ji-pei-zhi-fen-qu-jian-jin-hang-pei-zhi-p', [table.sourceTable])
                        });
                      }
                    }
                    return null;
                  });
                  return null;
                });
                if (!noPartition) {
                  this.currentStep = 4;
                }
              } else {
                this.currentStep = 4;
              }
            } else {
              this.$Modal.warning({
                title: this.$t('ren-wu-chuang-jian-yi-chang'),
                content: this.$t('dang-qian-resallhascolumn0sourcetable-biao-mei-you-xuan-ze-zi-duan-qing-zhong-xin-xuan-ze-hou-zai-dian-ji-xia-yi-bu', [res.allHasColumn[0].sourceTable])
              });
            }
          }
        } else {
          this.currentStep = 5;
        }
      } else {
        this.taskInfo.firstTime = false;
        if (this.currentStep === 2) {
          this.$refs.TableFilter.updatePrevStep();
        } else if (this.currentStep === 3) {
          this.$refs.CleanData.updateNextStep('pre');
        }
        if (isRedis(this.taskInfo.sourceType) && isRedis(this.taskInfo.sinkType) && this.currentStep === 4) {
          this.currentStep = 1;
        } else if (DataSourceGroup.noStruct.includes(this.taskInfo.sourceType) && DataSourceGroup.noStruct.includes(this.taskInfo.sinkType) && this.currentStep === 4) {
          this.currentStep = 2;
        } else {
          this.currentStep--;
        }
      }
    },
    handleNextStep(n) {
      this.currentStep = n;
    },
    handleCreateTask() {
      if (this.taskInfo.processType !== 'create') {
        this.modifyLoading = true;
      }
      if (this.type === 'create') {
        store.commit('getJobDataForSimilarJob', null);
        if (this.makeSure) {
          this.showConfirm = true;
        } else {
          this.$Modal.warning({
            title: this.$t('chuang-jian-ren-wu-ti-xing'),
            content: this.$t('qing-que-ren-bing-gou-xuan-cloudcanal-fu-wu-deng-ji-xie-yi-zai-jin-hang-ren-wu-chuang-jian')
          });
        }
      } else {
        if (this.taskInfo.schemaWhiteListLevel === 'DB') {
          this.showConfirmEdit = true;
        } else {
          this.handleConfirm();
        }
      }
    },
    handleUpdateEdit(jobData) {
      this.taskInfo.taskInfoNewData.forEach((db) => {
        db.tableList = { ...db.selectedTables };
      });

      // const reduceConfig = [];
      const dbMapping = {};
      const tableTopicMapping = {};
      // const reduceMappingConfig = [];
      const tableMapping = {};
      const columnMapping = {};
      // const targetReduceConfigList = [];
      if (this.taskInfo.schemaWhiteListLevel === 'DB') {
        this.taskInfo.dbMap.forEach((db) => {
          let dbData = {};
          if (this.taskInfo.newData[db.sourceDb]) {
            dbData = { ...db };
            dbData.selectedTables = [];
            this.taskInfo.taskInfoNewData.push(dbData);
          }
        });
      }
      const configData = this.getConfigData(this.taskInfo.taskInfoNewData, this.taskInfo.sourceType, this.taskInfo.sinkType, 'newData');
      let reduceConfigData = null;
      if (Object.keys(this.taskInfo.reduceData).length > 0 && (isHana(this.taskInfo.sourceType) || this.taskInfo.sourceType === 'PostgreSQL')) {
        reduceConfigData = this.getConfigData(this.taskInfo.taskInfoReduceData, this.taskInfo.sourceType, this.taskInfo.sinkType, 'reduceData');
      }
      // console.log(configData);

      if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
        dbMapping.method = 'DB_SCHEMA';
      } else if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sourceType) > -1) {
        dbMapping.method = 'SCHEMA_DB';
      } else {
        dbMapping.method = 'DB_DB';
      }
      dbMapping.serializeMapping = {};
      dbMapping.serializeAutoGenRules = {};
      dbMapping.commonGenRule = 'MIRROR';

      tableTopicMapping.serializeMapping = {};
      tableTopicMapping.method = 'TABLE_TOPIC';
      tableTopicMapping.serializeAutoGenRules = {};
      tableTopicMapping.commonGenRule = 'MIRROR';

      tableMapping.serializeMapping = {};
      tableMapping.method = 'TABLE_TABLE';
      tableMapping.serializeAutoGenRules = {};
      tableMapping.commonGenRule = 'MIRROR';

      columnMapping.method = 'COLUMN_COLUMN';
      columnMapping.serializeMapping = {};
      columnMapping.serializeAutoGenRules = {};
      columnMapping.commonGenRule = 'MIRROR';

      const addProcessorConfs = []; const
        processorConfsWithoutAdd = [];
      const processorConfsWithoutAddVirtuals = []; const
        addProcessorConfsVirtuals = [];
      if (this.taskInfo.virtualColumns.length > 0) {
        this.taskInfo.virtualColumns.forEach((virtual) => {
          if (virtual.hasInJob) {
            delete virtual.hasInJob;
            processorConfsWithoutAddVirtuals.push(virtual);
          } else {
            addProcessorConfsVirtuals.push(virtual);
          }
        });
        addProcessorConfs.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(addProcessorConfsVirtuals)
        });
        processorConfsWithoutAdd.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(processorConfsWithoutAddVirtuals)
        });
        this.taskInfo.coreConfigWrapper.processorConfigList.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(this.taskInfo.virtualColumns)
        });
        this.taskInfo.coreConfigWrapper.processorConsoleConfigs.push({
          processorConfigType: 'FIELD_MAKER_PROCESSOR',
          allProcessorContextJson: JSON.stringify(this.taskInfo.customVirtualColumns)
        });
      }
      if (this.taskInfo.customPkgProcess) {
        processorConfsWithoutAdd.push(this.taskInfo.customPkgProcess);
      }

      const data = {
        dataJobId: this.taskInfo.jobData.dataJobId,
        structMigration: configData.structMigration,
        addMappingConfig: configData.mappingDef ? JSON.stringify(configData.mappingDef) : null,
        sourceAddConfig: configData.finalSourceSchema,
        targetAddConfig: configData.finalTargetSchema,
        initialSync: this.taskInfo.initialSync,
        mappingConfigWithoutAdd: jobData.mappingDef,
        srcSchemaWithoutAdd: jobData.sourceSchema,
        dstSchemaWithoutAdd: jobData.targetSchema,
        reduceConfig: reduceConfigData ? reduceConfigData.finalSourceSchema : null,
        addProcessorConfs,
        processorConfsWithoutAdd
      };
      if (this.editType === '1') {
        updateTransferObject(data)
          .then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('xiu-gai-ding-yue-cheng-gong'));
              this.$router.push({
                name: 'task/id',
                params: { id: this.taskInfo.jobData.dataJobId }
              });
            }
          }).finally(() => {
            this.modifyLoading = false;
          });
      } else {
        updateTransferObjectForFull(data)
          .then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('xiu-gai-ding-yue-cheng-gong'));
              this.$router.push({
                name: 'task/id',
                params: { id: this.taskInfo.jobData.dataJobId }
              });
            }
          }).finally(() => {
            this.modifyLoading = false;
          });
      }
    },
    handleHidePrecheck(event, ifKeep) {
      this.showPreCheck = false;
      if (ifKeep) {
        this.$Modal.confirm({
          title: this.$t('chuang-jian-ren-wu-jing-gao'),
          content: this.$t('dang-qian-ren-wu-cun-zai-wei-tong-guo-de-yu-jian-xiang-zhi-jie-hu-lve-ke-neng-hui-shi-ren-wu-yi-chang-que-ren-yao-hu-lve-bing-ji-xu-chuang-jian-ma'),
          onOk: () => {
            this.updateLoading(true);
            this.handleCreateJob();
          }
        });
      }
    },
    handleConfirm() {
      this.createData = '';
      this.showConfirm = false;
      this.showConfirmEdit = false;
      this.createLoading = true;
      const taskInfo = this.taskInfo;

      let hasLoop = false;

      if (DataSourceGroup.noDb.indexOf(this.taskInfo.sourceType) === -1) {
        taskInfo.dbMap.map((db) => {
          if (db.selectedTables) {
            db.selectedTables.map((table) => {
              if (table.selected) {
                if (db.selectedColumns && db.selectedColumns[table.sourceTable]) {
                  db.selectedColumns[table.sourceTable].map((column) => {
                    if (column.selected) {
                      if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sourceType) > -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
                        if (taskInfo.sourceInstanceId === taskInfo.sinkInstanceId
                          && db.sourceDb === db.sinkDb
                          && db.sourceSchema === db.targetSchema
                          && table.sourceTable === table.sinkTable) {
                          hasLoop = true;
                        }
                      } else if (DataSourceGroup.hasSchema.indexOf(this.taskInfo.sourceType) === -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) === -1) {
                        if (taskInfo.sourceInstanceId === taskInfo.sinkInstanceId && db.sourceDb === db.sinkDb && table.sourceTable === table.sinkTable) {
                          hasLoop = true;
                        }
                      }
                    }
                    return null;
                  });
                }
              }
              return null;
            });
          }
          return null;
        });
      }
      if (hasLoop) {
        this.$Modal.warning({
          title: this.$t('chuang-jian-ren-wu-yi-chang-ti-xing'),
          content: this.$t('dang-qian-ren-wu-cun-zai-tong-ku-biao-zhi-jian-de-qian-yi-ci-cao-zuo-bu-bei-yun-xu-qing-zhong-xin-xiu-gai')
        });
      } else {
        // eslint-disable-next-line one-var
        const data = this.getDataJobData();

        const stringData = JSON.stringify(data);
        const formData = new FormData();

        if (this.taskInfo.customPkgFile) {
          formData.append('customPkgFile', this.taskInfo.customPkgFile);
        }
        formData.append('dataJobData', new Blob([stringData], { type: 'application/json' }));

        this.createData = formData;// 第二次预检
        if (this.type === 'edit') {
          this.handleUpdateEdit(data);
        } else {
          const that = this;

          this.getPercantage = setInterval(() => {
            if (that.creatingStatus.state === 'CREATING') {
              if (that.currentPercentage < 90) {
                that.currentPercentage += Math.floor(Math.random() * 10);
              } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
                that.currentPercentage += Math.floor(Math.random() * 3);
              } else if (that.currentPercentage >= 97) {
                that.currentPercentage = 99;
              }
            } else if (that.creatingStatus.state === 'INIT') {
              that.currentPercentage = 100;
              clearInterval(that.getPercantage);
            }
          }, 200);

          this.showCreateStatus = true;
          this.creatingStatus.dataJobName = taskInfo.desc;
          this.creatingStatus.dataJobType = taskInfo.type;
          this.creatingStatus.state = 'PRECHECK';
          this.handleFinalCheck(data, formData);
        }
      }
    },
    handleFinalCheck(data, formData) {
      finalCheck(data)
        .then((res) => {
          let preCheckStatus = true;

          this.preCheckItemsErrorCount = 0;
          this.preCheckItemsSuccessCount = 0;
          this.preCheckItemsCheckedCount = 0;
          if (res.data.code === '1') {
            if (res.data.data) {
              res.data.data.forEach((item) => {
                if (item.checked) {
                  this.preCheckItemsCheckedCount++;
                  if (!item.success) {
                    preCheckStatus = false;
                    this.preCheckItemsErrorCount++;
                  } else {
                    this.preCheckItemsSuccessCount++;
                  }
                }
              });
            }
            this.preCheckItems = res.data.data || [];
          } else {
            preCheckStatus = false;
          }
          if (preCheckStatus) {
            this.handleCreateJob(formData);
          } else {
            this.showPreCheck = true;
            this.showCreateStatus = false;
            this.createLoading = false;
          }
        }).finally(() => {
          this.modifyLoading = false;
        });
    },
    handleCreateJob() {
      this.creatingStatus.state = 'CREATING';
      createJob(this.createData)
        .then((response) => {
          if (response.data.code === '1') {
            this.creatingStatus.state = 'INIT';
            this.createLoading = false;
            const that = this;

            setTimeout(() => {
              that.$router.push({ path: '/data/job/list' });
            }, 1000);
          } else {
            this.showCreateError = true;
            this.showCreateErrorMsg = response.data.msg;
            this.showCreateStatus = false;
            this.createLoading = false;
          }
        });
    },
    updateShowAddDataSource(type) {
      this.showAddDataSource = true;
      this.addDataSourceType = type;
    },
    updateAddDataSourceException() {
      this.showAddDataSourceException = true;
    },
    closeShowAddDataSource() {
      this.showAddDataSource = false;
      if (this.addDataSourceType === 'source') {
        this.$refs.OriginalConfig.getSourceDataSourceList();
      } else if (this.addDataSourceType === 'sink') {
        this.$refs.OriginalConfig.getSinkDataSourceList();
      }
    },
    handleGoDataSource() {
      this.$router.push({
        path: '/data/dataSource'
      });
    },
    updateLoading(loading) {
      this.loading = loading;
    },
    updateNextDisabled(nextDisabled) {
      this.nextDisabled = nextDisabled;
    },
    handleHideNotNull(notNull) {
      this.showNotNullTable = notNull;
    },
    handleReturn() {
      this.$router.push({ path: '/data/job/list' });
    },
    handleRefresh() {
      if (this.currentStep === 2) {
        this.$refs.TableFilter.handleRefresh();
      } else if (this.currentStep === 3) {
        this.$refs.CleanData.handleRefresh();
      }
    },
    handleCancel() {
      this.showConfirm = false;
      this.showConfirmEdit = false;
    }
  }
};
</script>

<style lang="less">
.create-task-process {
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid #DADADA;
  margin-bottom: 70px;
}

.create-task-step {
  padding: 30px 380px;
  border-bottom: 1px solid #DADADA;
}

.create-task-tools {
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  background: #ffffff;
  width: 100%;
  line-height: 60px;
  height: 60px;
  box-shadow: 6px 2px 23px 0 rgba(197, 197, 197, 0.5);

  button {
    margin: 0 10px;
  }
}

.add-datasource-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  background: #fff;
}

.ivu-drawer-body {
  padding: 0;
}

.ivu-drawer-content {
  padding-top: 54px;
}

.ivu-drawer-close {
  top: 62px;
}

.go-to-other-page-btn:hover .forward-btn {
  transform: translate(5px, 0);
}

.demo-carousel {
  text-align: center;
  font-size: 20px;
}

.show-list-point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #A2A9B6;
  margin-right: 8px;
}
</style>
