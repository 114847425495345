<template>
  <div>
    <div v-if="type==='sink'">
      <p class="show-manual-account" v-if="(targetConfig && targetConfig.length) || showSinkAdvancedSettings ">
        <Checkbox v-model="taskInfo.sinkExtraSetting"></Checkbox>
        <span>{{ $t('gao-ji-pei-zhi') }}</span>
      </p>
      <div class="advance-setting" v-if="taskInfo.sinkExtraSetting && targetConfig && targetConfig.length">
        <PosForm :form="targetConfig" :form-data="taskInfo.advancedSetting"></PosForm>
      </div>
      <div v-if="taskInfo.sinkExtraSetting && showSinkAdvancedSettings && !targetConfig" class="advance-setting">
        <div v-if="DataSourceGroup.starrocks.includes(taskInfo.sinkType)">
          {{ $t('jie-gou-qian-yi-dai-chuang-jian-biao-mo-ren-tong-bucket-shu-liang') }}
          <Input type="text" v-model="taskInfo.migrationBucketNumber" size="small"
                 style="margin-bottom: 10px;"/>
          {{ $t('jie-gou-qian-yi-dai-chuang-jian-biao-mo-ren-shu-xing-properties-pei-zhi') }}
          <Input v-if="taskInfo.sinkType !== 'StarRocks'" type="textarea" v-model="taskInfo.migrationPropertiesConfig" size="small"/>
          <Input v-if="taskInfo.sinkType === 'StarRocks'" type="textarea" v-model="taskInfo.migrationPropertiesConfigWithSr" size="small"/>
        </div>
<!--        <div v-if="(DataSourceGroup.pg.indexOf(this.taskInfo.sinkType)>-1 || DataSourceGroup.tidb.includes(this.taskInfo.sinkType))-->
<!--              &&DataSourceGroup.sqlServer.indexOf(this.taskInfo.sinkType) === -1">-->
<!--          <span style="margin-right: 10px">{{ $t('da-xiao-xie-min-gan') }}</span>-->
<!--          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"-->
<!--                      v-model="taskInfo.targetCaseSensitive">-->
<!--            <Radio label="true">{{ $t('shi') }}</Radio>-->
<!--            <Radio label="false">{{ $t('fou') }}</Radio>-->
<!--          </RadioGroup>-->
<!--        </div>-->
        <div v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">
          <span style="margin-right: 6px">{{ $t('shi-qu-xuan-ze') }}</span>
          <Tooltip :content="$t('xuan-ze-dui-ying-de-shi-qu-hou-shi-jian-lei-xing-zi-duan-hui-zai-yuan-shi-jian-zhi-shang-zeng-jia-dui-ying-shi-qu-de-pian-yi-liang')" placement="right">
            <Select filterable v-model="taskInfo.globalTimeZone" style="width: 100px">
              <Option v-for="(timezone,index) of timeZoneList" :value="timezone" :key="index">
                {{ timezone }}
              </Option>
            </Select>
          </Tooltip>
        </div>
        <div v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">{{ $t('xiao-xi-ge-shi') }}</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstSchemaLessFormat">
            <Radio v-for="schema in taskInfo.targetSchemaTypeList" :label="schema.format"
                   :key="schema.format">
              {{ schema.i18nName }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="isMQ(this.taskInfo.sinkType)">
          <span style="margin-right: 10px">DDL Topic</span>
          <Select style="width: 280px" filterable v-model="taskInfo.dstMqDdlTopic"
                  @on-change="handleChangeDdlTopic">
            <Option v-for="topic in sinkTableList" :key="topic.tableName" :value="topic.tableName">
              {{ topic.tableName }}
            </Option>
          </Select>
        </div>
<!--        <div v-if="DataSourceGroup.redis.indexOf(this.taskInfo.sinkType)>-1">-->
<!--          <span style="margin-right: 10px">{{ $t('huan-cun-ge-shi') }}</span>-->
<!--          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"-->
<!--                      v-model="taskInfo.dstSchemaLessFormat">-->
<!--            <Radio v-for="schema in taskInfo.schemaTypeList" :label="schema.format"-->
<!--                   :key="schema.format">-->
<!--              {{ schema.i18nName }}-->
<!--            </Radio>-->
<!--          </RadioGroup>-->
<!--        </div>-->
        <div v-if="DataSourceGroup.ck.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">{{ $t('biao-yin-qing') }}</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstCkTableEngine">
            <Radio v-for="schema in taskInfo.engineTypeList" :label="schema.ckTableEngine"
                   :key="schema.ckTableEngine">{{ schema.ckTableEngine }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.starrocks.indexOf(this.taskInfo.sinkType)>-1">
          <span style="margin-right: 10px">{{ $t('yin-qing') }}</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;"
                      v-model="taskInfo.dstSrOrDorisTableModel">
            <Radio v-for="schema in taskInfo.dstSrOrDorisTableModelList" :label="schema.tableModel"
                   :key="schema.tableModel">{{ schema.tableModel }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="DataSourceGroup.kudu.includes(taskInfo.sinkType)">
          <div>
            <Checkbox v-model="taskInfo.kuduNumReplicasSelected"/>
            <span style="margin-right: 10px">{{ $t('fu-ben-shu') }}</span>
            <Input style="width: 200px" v-model="taskInfo.kuduNumReplicas"
                   v-if="taskInfo.kuduNumReplicasSelected"/>
          </div>
          <div style="margin-top: 10px;">
            <Checkbox v-model="taskInfo.kuduNumBucketsSelected"/>
            <span style="margin-right: 10px">Buckets</span>
            <Input style="width: 200px" v-model="taskInfo.kuduNumBuckets"
                   v-if="taskInfo.kuduNumBucketsSelected"/>
          </div>
        </div>
        <div v-if="isMySQL(taskInfo.sinkType) || isPG(taskInfo.sinkType) || isPolar(taskInfo.sinkType)">
          <span style="margin-right: 10px">{{ $t('mo-ren-shi-jian-ce-lve') }}</span>
          <Select v-model="taskInfo.targetTimeDefaultStrategy" style="width: 280px">
            <Option v-for="strategy in targetTimeDefaultStrategyOptions" :value="strategy.value" :key="strategy.value">{{strategy.label}}</Option>
          </Select>
        </div>
        <div v-if="taskInfo.keyConflictStrategyList.length" style="margin-top: 10px;">
          <span>{{ $t('zeng-liang-xie-ru-chong-tu-ce-lve') }}</span>
          <Select v-model="taskInfo.keyConflictStrategy" style="width: 280px;">
            <Option v-for="strategy in taskInfo.keyConflictStrategyList" :value="strategy" :key="strategy">{{strategy}}</Option>
          </Select>
        </div>
      </div>
    </div>
    <div v-if="type==='source'">
      <p class="show-manual-account"
         v-if="(sourceConfig && sourceConfig.length) || showSourceAdvancedSettings">
        <Checkbox v-model="taskInfo.sourceExtraSetting"></Checkbox>
        <span>{{ $t('gao-ji-pei-zhi') }}</span>
      </p>
      <div class="advance-setting"  v-if="taskInfo.sourceExtraSetting && sourceConfig && sourceConfig.length">
        <PosForm :form="sourceConfig" :form-data="taskInfo.advancedSetting"></PosForm>
      </div>
      <div v-if="taskInfo.sourceExtraSetting&&showSourceAdvancedSettings && !sourceConfig"
        class="advance-setting">
        <div v-if="DataSourceGroup.mq.indexOf(this.taskInfo.sourceType)>-1">
          <span style="margin-right: 10px">{{ $t('xiao-xi-ge-shi') }}</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;" v-model="taskInfo.srcSchemaLessFormat" @on-change="handleMqMsgChange">
            <Radio v-for="schema in taskInfo.sourceSchemaTypeList" :label="schema.format"
                   :key="schema.format">
              {{ schema.i18nName }}
            </Radio>
          </RadioGroup>
          <div v-if="taskInfo.srcSchemaLessFormat === 'ORIGIN_MSG_FOR_MQ'">
            <span style="margin-right: 10px">{{ $t('jie-xi-xiao-xi-ti') }}</span>
            <Select
              placement="top-start"
              transfer
              clearable
              v-model="taskInfo.originDecodeMsgFormat"
              style="width: 200px;"
            >
              <Option v-for="decode in decodeList" :key="decode.format" :value="decode.format">
                {{decode.i18nName}}
              </Option>
            </Select>
          </div>
        </div>
<!--        <div-->
<!--          v-if="showMigrationModeSetting">-->
<!--          <span style="margin-right: 10px">{{ $t('qian-yi-mo-shi') }}</span>-->
<!--          <RadioGroup v-model="taskInfo.schemaWhiteListLevel">-->
<!--            <Radio label="">{{ $t('bai-ming-dan-mo-shi-mo-ren') }}</Radio>-->
<!--            <Radio label="DB">{{ $t('quan-ku-tong-bu') }}</Radio>-->
<!--          </RadioGroup>-->
<!--        </div>-->
        <div v-if="isOracle(this.taskInfo.sourceType)">
          <span style="margin-right: 10px">{{ $t('zeng-liang-mo-shi') }}</span>
          <RadioGroup style="position: relative;top:-2px;margin-right: 56px;" v-model="taskInfo.oraIncrMode">
            <Radio v-for="schema in taskInfo.oraIncrModeList" :label="schema.oraInceMode"
                   :key="schema.oraInceMode">
              {{ schema.oraInceMode }}
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="isOracle(this.taskInfo.sourceType) && taskInfo.oraIncrMode === 'redo'">
          <div>
            <span style="margin-right: 10px">{{ $t('chu-shi-hua-zi-dian') }}</span>
            <Checkbox v-model="taskInfo.oraBuildRedoDicWhenCreate"/>
          </div>
        </div>
        <div v-if="isOb(taskInfo.sourceType)">
          {{ $t('zu-hu') }}
          <Input style="width: 200px" v-model="taskInfo.obTenant" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  cacheSchemaLessValueFormats,
  ckTableEngine, getMqOriginalDecodeMsgFormat,
  mqSchemaLessValueFormats, srOrDorisTableModel, tunnelValueFormats
} from '@/services/cc/api/constant';
import {
  isCk,
  isES,
  isKudu,
  isMQ,
  isMySQL, isOb,
  isOracle,
  isPG,
  isPolar,
  isSQLServer,
  isStarRocks,
  isTiDB
} from '@/utils';
import { MYSQL_DEFAULT_STRATEGY, PG_DEFAULT_STRATEGY } from '@/const';
import DataSourceGroup from '@/views/dataSourceGroup';
import PosForm from '@/components/function/dataJob/jobDetail/PosForm';

export default {
  props: {
    taskInfo: Object,
    type: String,
    sinkTableList: Array,
    sourceConfig: Array,
    targetConfig: Array
  },
  components: { PosForm },
  computed: {
    showSinkAdvancedSettings() {
      const { sinkType } = this.taskInfo;
      return (isPG(sinkType) && !isSQLServer(sinkType))
        || isES(sinkType)
        || isMQ(sinkType)
        || isKudu(sinkType)
        || isCk(sinkType)
        || isStarRocks(sinkType)
        || isTiDB(sinkType)
        || isMySQL(sinkType)
        || isPolar(sinkType)
        || this.taskInfo.keyConflictStrategyList.length;
    },
    showSourceAdvancedSettings() {
      const { sourceType } = this.taskInfo;
      return DataSourceGroup.mq.indexOf(sourceType) > -1 || isOracle(sourceType) || isOb(sourceType);
    },
    showMigrationModeSetting() {
      const { sourceType, sinkType } = this.taskInfo;
      return ((isMQ(sinkType) || isMySQL(sinkType) || isPolar(sinkType) || isStarRocks(sinkType)) && (isMySQL(sourceType) || isPolar(sourceType))) || (isMySQL(sourceType) && isStarRocks(sinkType))
      || (isOracle(sourceType) && (isStarRocks(sinkType) || isMySQL(sinkType)));
    },
    targetTimeDefaultStrategyOptions() {
      const { sinkType } = this.taskInfo;
      return isMySQL(sinkType) || isPolar(sinkType) ? MYSQL_DEFAULT_STRATEGY : PG_DEFAULT_STRATEGY;
    }
  },
  data() {
    return {
      decodeList: [],
      DataSourceGroup,
      timeZoneList: ['-12:00', '-11:00', '-10:00', '-09:00', '-08:00', '-07:00', '-06:00',
        '-05:00', '-04:00', '-03:00', '-02:00', '-01:00', '+00:00', '+01:00', '+02:00',
        '+03:00', '+04:00', '+05:00', '+06:00', '+07:00', '+08:00', '+09:00', '+10:00', '+11:00', '+12:00'],
      schemaTypeList: [],
      engineTypeList: [],
      srOrDorisTableModelList: []
    };
  },
  mounted() {
    // if (!this.taskInfo.originalConfigHistory) {
    this.getAdvancedSettingList(this.taskInfo.sourceType);
    this.getAdvancedSettingList(this.taskInfo.sinkType);
    // }
  },
  methods: {
    isOriginalMsg() {
      return this.taskInfo.srcSchemaLessFormat === 'ORIGIN_MSG_FOR_MQ';
    },
    handleMqMsgChange() {
      if (this.isOriginalMsg()) {
        this.taskInfo.originDecodeMsgFormat = null;
        this.getMqOriginalDecodeMsgFormatList();
      }
    },
    async getMqOriginalDecodeMsgFormatList() {
      const res = await getMqOriginalDecodeMsgFormat({
        dataSourceType: this.taskInfo.sourceType,
        endPointType: 'SOURCE',
        mqSchemaLessValueFormat: 'ORIGIN_MSG_FOR_MQ'
      });

      if (res.data.success) {
        this.taskInfo.originDecodeMsgFormat = res.data.data.defaultDecode?.format;
        this.decodeList = res.data.data.allDecodes;
      }
    },
    isMQ,
    isMySQL,
    isPG,
    isOracle,
    isPolar,
    isOb,
    handleChangeDdlTopic(data) {
      this.sinkTableList.forEach((topic) => {
        if (topic.tableName === data) {
          this.taskInfo.dstMqDdlTopicPartitions = topic.mqTopicPartitions;
          this.taskInfo.dstMqDdlTopic = topic.tableName;
        }
      });
    },
    mqSchemaLessValueFormats(val) {
      mqSchemaLessValueFormats({
        dataSourceType: val,
        endPointType: this.type === 'source' ? 'SOURCE' : 'TARGET'
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'source') {
              this.taskInfo.sourceSchemaTypeList = res.data.data.result;
              if (!this.taskInfo.srcSchemaLessFormat) {
                this.taskInfo.srcSchemaLessFormat = res.data.data.defaultName;
              }
            } else {
              this.taskInfo.targetSchemaTypeList = res.data.data.result;
              if (!this.taskInfo.dstSchemaLessFormat) {
                this.taskInfo.dstSchemaLessFormat = res.data.data.defaultName;
              }
            }
          }
        });
    },
    cacheSchemaLessValueFormats() {
      cacheSchemaLessValueFormats()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.schemaTypeList = res.data.data;
            this.taskInfo.schemaTypeList.forEach(type => {
              if (type.defaultCheck) {
                this.taskInfo.dstSchemaLessFormat = type.format;
              }
            });
          }
        });
    },
    tunnelValueFormats(val) {
      tunnelValueFormats({
        dataSourceType: val,
        endPointType: this.type === 'source' ? 'SOURCE' : 'TARGET'
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'source') {
              this.taskInfo.sourceSchemaTypeList = res.data.data;
              this.taskInfo.srcSchemaLessFormat = this.taskInfo.sourceSchemaTypeList[0].format;
            } else {
              this.taskInfo.targetSchemaTypeList = res.data.data;
              this.taskInfo.dstSchemaLessFormat = this.taskInfo.targetSchemaTypeList[0].format;
            }
          }
        });
    },
    ckTableEngine(dataSourceId) {
      ckTableEngine({ dataSourceId })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.engineTypeList = res.data.data;
            if (!this.taskInfo.dstCkTableEngine) {
              this.taskInfo.engineTypeList.forEach((item) => {
                if (item.defaultCheck) {
                  this.taskInfo.dstCkTableEngine = item.ckTableEngine;
                }
              });
            }
          }
        });
    },
    srOrDorisTableModel(dataSourceType) {
      srOrDorisTableModel({
        dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.dstSrOrDorisTableModelList = res.data.data;
            this.taskInfo.dstSrOrDorisTableModelList.forEach((item) => {
              if (item.defaultCheck) {
                this.taskInfo.dstSrOrDorisTableModel = item.tableModel;
              }
            });
          }
        });
    },
    getAdvancedSettingList(val) {
      if (DataSourceGroup.redis.includes(val)) {
        this.cacheSchemaLessValueFormats();
      } else if (DataSourceGroup.mq.includes(val)) {
        this.mqSchemaLessValueFormats(val);
      } else if (DataSourceGroup.starrocks.includes(val)) {
        this.srOrDorisTableModel(val);
      } else if (DataSourceGroup.ck.includes(val)) {
        if (this.taskInfo.targetDataSourceId) {
          this.ckTableEngine(this.taskInfo.targetDataSourceId);
        }
      }
    }

  },
  watch: {
    'taskInfo.sinkType': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAdvancedSettingList(newVal);
        }
      }
    },
    'taskInfo.sourceType': {
      handler(val) {
        this.getAdvancedSettingList(val);
      }
    },
    'taskInfo.targetDataSourceId': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (isCk(this.taskInfo.sinkType)) {
            this.ckTableEngine(newVal);
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.advance-setting {
  border: 1px solid #EDEDED;
  background-color: #FAFAFA;
  line-height: 26px;
  padding: 8px 20px;
  vertical-align: middle;
  width: 470px;
}
</style>
