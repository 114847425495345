<template>
  <a-button ghost :disabled="counting" :size="size" :type="counting ? 'default' : 'primary'"
          @click="handleCountdownStart">
    <countdown v-if="counting" :time="60000" @end="handleCountdownEnd">
      <template slot-scope="props">{{ $t('propstotalseconds-miao-hou-chang-shi', [props.totalSeconds]) }}</template>
    </countdown>
    <span v-else>{{ $t('huo-qu-yan-zheng-ma') }}</span>
  </a-button>
</template>

<script>
export default {
  name: 'CCSmsButton',
  props: {
    sub: {
      type: Boolean,
      default: false
    },
    account: String,
    phoneNumber: String,
    verifyCodeType: String,
    verifyType: String,
    login: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      counting: false
    };
  },
  methods: {
    async handleCountdownStart() {
      const {
        phoneNumber,
        verifyCodeType,
        verifyType,
        login
      } = this;

      if (!phoneNumber && !login) {
        this.$Message.error('请输入手机号');
        return;
      }
      this.counting = true;
      const data = {
        verifyType,
        verifyCodeType
      };

      if (!login) {
        data.phoneNumber = phoneNumber;
        data.sub = this.sub;
        data.account = this.account;
      }
      const res = this.login ? await this.$services.sendCodeInLoginState({ data }) : await this.$services.sendCode({ data });
      if (res.success) {
        this.$Message.success('验证码发送成功');
      } else {
        this.counting = false;
      }
    },
    handleCountdownEnd() {
      this.counting = false;
    }
  }
};
</script>

<style scoped>

</style>
