import { instance } from '../auth';

export const signin = (param) => instance.post('signin', param);

export const login = (param) => instance.post('login', param);

export const logout = (param) => instance.post('logout', param);

export const resetdo = (param) => instance.post('resetpwd', param);
export const primaryUserDomains = (param) => instance.post('primary_user_domains', param);
