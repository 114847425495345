import Vue from 'vue';
import VueRouter from 'vue-router';
import Data from '@/router/data';
import UserCenter from '@/views/system/UserCenter';
import Preference from '@/views/system/Preference';
import StTokenSetting from '@/views/system/StTokenSetting';
import Resource from '@/views/worker/Cluster';
import Server from '@/views/worker/Worker';
import TicketTokenSetting from '@/views/system/TicketTokenSetting';
import store from '@/store/index';
import Sql from '@/views/sql/index';
import Monitor from './monitor/index';
import Alarm from './alarm/index';
import CCSystem from './ccsystem/index';
import System from './system';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */'@/views/login/index')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */'@/views/register/index')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "reset" */'@/views/resetPwd/index')
  },
  {
    path: '/',
    name: 'Home',
    // redirect: () => {
    //   console.log('redirect', store.state.globalSetting.defaultProduct);
    //   return 'data/job/list';
    // },
    component: () => import(/* webpackChunkName: "login" */'@/views/home/index'),
    children: [
      {
        path: 'sql',
        name: 'SQL',
        component: Sql
      },
      {
        path: 'ticket',
        name: 'Ticket',
        component: () => import(/* webpackChunkName: "ticket" */'@/views/ticket/index')
      },
      {
        path: '/ticket/:id',
        name: 'Ticket/id',
        component: () => import(/* webpackChunkName: "ticket" */'../views/ticket/ticketDetail')
      },
      {
        path: '/ticket_create',
        name: 'Ticket_create',
        component: () => import(/* webpackChunkName: "ticket" */'../views/ticket/ticket')
      },
      {
        path: 'ccdatasource',
        name: 'System_DataSource',
        component: () => import(/* webpackChunkName: "ccsystem-datasource" */'@/views/dataSource/DataSource')
      },
      {
        path: 'ccdatasource/params/:id',
        name: 'System_DataSource_Params/id',
        component: () => import(/* webpackChunkName: "ccsystem-datasource" */'@/views/system/datasource/index')
      },
      {
        path: 'ccdatasource/add',
        name: 'System_DataSource_Add',
        component: () => import(/* webpackChunkName: "ccsystem-datasource" */'@/views/dataSource/AddDataSource')
      },
      {
        path: 'dmdatasource',
        name: 'System_DataSource_list',
        component: () => import(/* webpackChunkName: "ccsystem-datasource" */'@/views/system/datasource/index2')
      },
      {
        path: 'dmdatasource/params/:id',
        name: 'System_DataSource_Params/id',
        component: () => import(/* webpackChunkName: "system-datasource" */'@/views/system/user/components/Params')
      },
      {
        path: 'dmmachine',
        name: 'System_Machine',
        component: () => import(/* webpackChunkName: "ccsystem-cluster" */'@/views/worker/Cluster')
      },
      {
        path: 'dmmachine/list/:clusterId',
        name: 'System_Machine_List',
        component: () => import(/* webpackChunkName: "ccsystem-cluster-list" */'@/views/system/cluster/workerList')
      },
      {
        path: '/ccsystem/resource',
        name: 'resource',
        component: Resource
      },
      {
        path: '/ccsystem/resource/:id',
        name: 'resource/id',
        component: Server
      },
      {
        path: '/userCenter',
        name: 'userCenter',
        component: UserCenter
      },
      ...Monitor,
      ...Alarm,
      ...CCSystem,
      {
        path: 'system',
        name: 'System',
        component: () => import(/* webpackChunkName: "ccsystem" */'@/views/system/index'),
        children: [
          {
            path: '/system/preference',
            name: '/system/preference',
            component: Preference
          },
          {
            path: '/ccsystem/stTokenSetting',
            name: '/ccsystem/stTokenSetting',
            component: StTokenSetting
          },
          {
            path: '/ccsystem/ticketTokenSetting',
            name: '/ccsystem/ticketTokenSetting',
            component: TicketTokenSetting
          }
        ].concat(System)
        // children: [
        //   {
        //     path: 'info_center',
        //     name: 'InfoCenter',
        //     component: () => import(/* webpackChunkName: "ticket" */'@/views/consoleJob/index')
        //   },
        //   {
        //     path: 'console_job/:id',
        //     name: 'ConsoleJob/id',
        //     component: () => import(/* webpackChunkName: "ticket" */'@/views/consoleJob/consoleJobDetail')
        //   },
        //   {
        //     path: 'async_job_list',
        //     name: 'ASYNC_JOB_LIST',
        //     component: () => import(/* webpackChunkName: "async-job-list" */'@/views/system/AsyncJobList/index')
        //   },
        //   {
        //     path: 'async_job/:id',
        //     name: 'ASYNC_JOB_DETAIL',
        //     component: () => import(/* webpackChunkName: "async-job-list" */'@/views/system/AsyncJobList/asyncJobDetail')
        //   },
        //   {
        //     path: '',
        //     name: 'System_Home',
        //     component: () => import(/* webpackChunkName: "system-home" */'@/views/system/home')
        //   },
        //   {
        //     path: 'user/config',
        //     name: 'User_Config',
        //     component: () => import(/* webpackChunkName: "system-home" */'@/views/system/user/userConfig')
        //   },
        //   {
        //     path: 'role',
        //     name: 'System_Role',
        //     component: () => import(/* webpackChunkName: "system-role" */'@/views/system/role/index')
        //   },
        //   {
        //     path: 'machine',
        //     name: 'System_Machine',
        //     component: () => import(/* webpackChunkName: "system-cluster" */'@/views/system/cluster/index')
        //   },
        //   {
        //     path: 'machine/list/:clusterId',
        //     name: 'System_Machine_List',
        //     component: () => import(/* webpackChunkName: "system-cluster-list" */'@/views/system/cluster/workerList')
        //   },
        //   {
        //     path: 'datasource',
        //     name: 'System_DataSource',
        //     component: () => import(/* webpackChunkName: "system-datasource" */'@/views/system/datasource/index')
        //   },
        //   {
        //     path: 'auth',
        //     name: 'System_Auth',
        //     component: () => import(/* webpackChunkName: "system-auth" */'@/views/system/auth/index')
        //   },
        //   {
        //     path: 'datasource/params/:id',
        //     name: 'System_DataSource_Params/id',
        //     component: () => import(/* webpackChunkName: "system-datasource" */'@/views/system/user/components/Params')
        //   },
        //   {
        //     path: 'datasource/add',
        //     name: 'System_DataSource_Add',
        //     component: () => import(/* webpackChunkName: "system-datasource" */'@/views/system/datasource/steps/Index')
        //   },
        //   {
        //     path: 'sub_account',
        //     name: 'System_Sub_Account',
        //     component: () => import(/* webpackChunkName: "system-subaccount" */'@/views/system/subaccount/index')
        //   },
        //   {
        //     path: 'sub_account/auth/:uid',
        //     name: 'System_Sub_Account_Auth',
        //     component: () => import(/* webpackChunkName: "system-subaccount-auth" */'@/views/system/subaccount/auth')
        //   },
        //   {
        //     path: 'env',
        //     name: 'System_Env',
        //     component: () => import(/* webpackChunkName: "system-env" */'@/views/system/env')
        //   },
        //   {
        //     path: 'rules',
        //     name: 'System_Rule',
        //     component: () => import(/* webpackChunkName: "system-env" */'@/views/system/rule/index')
        //   },
        //   {
        //     path: 'desensitization',
        //     name: 'System_Desensitization',
        //     component: () => import(/* webpackChunkName: "system-desensitization" */'@/views/system/desensitization/index')
        //   },
        //   {
        //     path: 'desensitization/add',
        //     name: 'System_Desensitization_Add',
        //     component: () => import(/* webpackChunkName: "system-desensitization" */'@/views/system/desensitization/addDesensitization')
        //   },
        //   {
        //     path: 'data_rules',
        //     name: 'System_Data_Rules',
        //     component: () => import(/* webpackChunkName: "system-data-rules" */'@/views/system/dataRule/index')
        //   },
        //   {
        //     path: 'data_rules/add',
        //     name: 'System_Data_Rules_Add',
        //     component: () => import(/* webpackChunkName: "system-data-rules-add" */'@/views/system/dataRule/addDataRule')
        //   },
        //   {
        //     path: 'data_code',
        //     name: 'System_Data_Code',
        //     component: () => import(/* webpackChunkName: "system-env" */'@/views/system/dataCode/index')
        //   }
        // ]
      }
    ].concat(Data)
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
