<template>
  <div class="job-detail-state-container">
    <div>
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>{{ $t('shu-ju-xiao-yan') }}
          <a @click="handleShowLog(task.dataTaskId)">{{ $t('cha-kan-ri-zhi') }}</a>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('CHECK')"
                    :percent="checkProgress.checkedPercent" :size="200">
            <span class="detail-Circle-inner"
                  style="font-size:50px">{{ checkProgress.checkedPercent }}%</span>
          </i-circle>
          <p style="margin-top: 32px">
            {{ $t('yi-xiao-yan-formatcountjsonparsetasklistchecktaskpositionchecked-hang', [formatData(JSON.parse(taskList['CHECK'].taskPosition).checked)]) }}</p>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <div class="job-detail-state-progress-title" style="display: flex;align-items: center;">
          <span class="status-point"></span>
          {{ $t('ge-ku-biao-xiao-yan-jin-du') }}
          <Input prefix="ios-search" v-model="tableSearchKey" :placeholder="$t('qing-shu-ru-biao-ming-shai-xuan')"
                 style="width: 150px;margin-left: 10px"
                 @on-change="handleTableSearch"/>
        </div>
        <Table stripe style="margin-top: 20px" :border="false" size="small"
               :columns="fullTransColumns" :data="showCheckData" max-height="300" @on-sort-change="sortChanged">
          <template slot-scope="{ row }" slot="progress">
            <div>
              <Progress :percent="row.checkPercent" :stroke-width="10"
                        :stroke-color="getProgressStatus(taskList['CHECK'].dataTaskStatus)==='normal'?['#ff9900', '#ff9900']:''"
                        :status="getProgressStatus(taskList['CHECK'].dataTaskStatus)">
                <span style="font-weight: 500">{{ row.checkPercent }}%</span>
              </Progress>
            </div>
          </template>
        </Table>
        <Page style="margin-top:20px;text-align: center" :total="checkTotal" size="small"
              show-elevator :page-size="checkSize"
              @on-change="handleCheckPageChange"/>
      </div>
    </div>
    <div class="job-detail-metric">
      <p class="job-detail-state-progress-title"><span class="status-point"></span>{{ $t('xiao-yan-su-shuai') }}
        <a @click="handleMoreMonitor(taskList['CHECK'].dataTaskId,'REVISE')">{{ $t('geng-duo-zhi-biao') }}</a></p>
      <div style="margin-top:20px">
        <div class="panel">
          <div class="panel-header">
            <div style="float: right">
            </div>
            {{ metric.title }}
          </div>
          <div class="panel-body">
            <div style="height: 200px">
              <Metric ref="metric3" :model="metric" :time="time" :resourceType="resourceType"
                      :dataJobId="jobId.toString()"
                      :filters="filters" :selected="metric.selected"
                      :dataTaskId="taskList['CHECK'].dataTaskId.toString()"
                      :filterOthers="filterOthers"></Metric>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCount, formatEnCount } from '../../../util';
import Metric from '../../monitor/metric/metric';

export default {
  components: { Metric },
  props: {
    getCurrentStateColor: Function,
    checkProgress: Object,
    showCheckData: Array,
    getProgressStatus: Function,
    checkTotal: Number,
    checkSize: Number,
    handleCheckPageChange: Function,
    handleCheckSortChange: Function,
    handleCheckSearch: Function,
    taskList: Object,
    resourceType: String,
    filters: Object,
    filterOthers: Object,
    metric: Object,
    time: Object,
    jobId: Number,
    handleShowLog: Function,
    task: Object,
    handleMoreMonitor: Function
  },
  data() {
    return {
      tableSearchKey: '',
      formatCount,
      fullTransColumns: [
        {
          title: this.$t('shu-ju-ku'),
          key: 'schema',
          minWidth: 150
        },
        {
          title: this.$t('biao-ming'),
          key: 'table',
          minWidth: 220
        },
        {
          title: ' ',
          slot: 'progress',
          width: 160
        },
        {
          title: this.$t('xiao-yan-hang-shu'),
          width: 200,
          render: (h, params) => h('div', {}, this.$t('yi-xiao-yan-formatcountjsonparsetasklistchecktaskpositionchecked-hang', [this.formatData(params.row.handledRecordCount)]))
        },
        {
          title: this.$t('diu-shi-hang-shu'),
          width: 120,
          sortable: 'custom',
          key: 'loss',
          render: (h, params) => h('div', {}, this.$t('diu-shi-formatcountparamsrowloss-hang', [this.formatData(params.row.loss)]))
        },
        {
          title: this.$t('bu-yi-zhi-hang-shu'),
          width: 120,
          sortable: 'custom',
          key: 'diff',
          render: (h, params) => h('div', {}, this.$t('bu-yi-zhi-formatcountparamsrowdiff-hang', [this.formatData(params.row.diff)]))
        }
      ]
    };
  },
  methods: {
    handleTableSearch() {
      this.handleCheckSearch(this.tableSearchKey, 'table');
    },
    sortChanged(e) {
      this.handleCheckSortChange(e);
    },
    formatData(data) {
      if (this.$i18n.locale === 'en-US') {
        return formatEnCount(data);
      }
      return formatCount(data);
    },
    handleRender() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric3) {
          that.$refs.metric3.$emit('render');
        }
      }, 100);
    }
  }
};
</script>
