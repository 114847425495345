<template>
  <Modal :title="title" v-model="visible" @on-ok="handleOk" @on-cancel="handleCancel">
    <slot />
    <Alert v-if="text">{{ $t('qing-shu-ru-dong-zuo-ming-cheng') }}<span class="warn-font">{{event}}</span>{{text}}</Alert>
    <Input v-model="inputEvent" @on-change="handleValidate" style="margin-bottom: 10px;"/>
    <Alert type="error" show-icon v-if="showError">{{ $t('qing-shu-ru-zheng-que-de-dong-zuo-ming-cheng') }}</Alert>
    <div slot="footer">
      <Button @click="handleOk" type="primary">{{ $t('que-ding') }}</Button>
      <Button @click="handleCancel">{{ $t('guan-bi') }}</Button>
    </div>
  </Modal>
</template>

<script>

export default {
  name: 'SecondConfirmModal',
  props: {
    visible: Boolean,
    title: String,
    handleConfirm: Function,
    handleClose: Function,
    event: String,
    text: String
  },
  data() {
    return {
      inputEvent: '',
      showError: false
    };
  },
  methods: {
    handleOk() {
      if (this.inputEvent === this.event) {
        this.showError = false;
        this.inputEvent = '';
        this.handleConfirm();
      } else {
        this.showError = true;
      }
    },
    handleCancel() {
      this.showError = false;
      this.inputEvent = '';
      this.handleClose();
    },
    handleValidate() {
      if (this.inputEvent !== this.event) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    }
  }
};

</script>

<style scoped lang="less">

</style>
