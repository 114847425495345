<template>
  <div>
    <second-confirm-modal
      :title="$t('shan-chu-ren-wu')"
      :event="SECOND_CONFIRM_EVENT_LIST.DELETE_JOB"
      :text="$t('yi-wan-cheng-shan-chu-selectedjobrowdatajobname-ren-wu-de-cao-zuo', [selectedJobRow.dataJobName])"
      :visible="showConfirm"
      ref="second-confirm-modal"
      :handle-confirm="handleConfirmDelete"
      :handle-close="handleCancelDelete"
    />
<!--    <verify-code-modal-->
<!--          :visible="showConfirm"-->
<!--          :title="$t('shan-chu-que-ren')"-->
<!--          :width="580"-->
<!--          :handle-close-modal="handleCancelDelete"-->
<!--          :handle-confirm-callback="handleConfirmDelete"-->
<!--          verify-code-type="DELETE_JOB"-->
<!--          ref="delete-job-modal"-->
<!--      >-->
<!--        <Alert type="warning" style="margin-bottom: 20px" slot="content">-->
<!--          <div style="color: #333;line-height: 22px">-->
<!--            <ul>-->
<!--              <li style="list-style:none;"><span class="warn-point"></span>{{ $t('qing-shu-ru-duan-xin-yan-zheng-ma-yi-que-ren-yao-shan-chu-shi-li-id-wei') }}<span-->
<!--                  class="warn-font">{{selectedJobRow.dataJobName}}</span>{{ $t('de-ren-wu') }}</li>-->
<!--              <li style="list-style:none;"><span class="warn-point"></span>{{ $t('duan-xin-yan-zheng-ma-mo-ren-fa-song-gei-ren-wu-chuang-jian-zhe') }}</li>-->
<!--              <li style="list-style:none;" v-if="store.getters.isProductTrail">-->
<!--                <span class="warn-point"></span>{{ $t('qing-xian-dian-ji') }}<span class="warn-font">{{ $t('huo-qu-duan-xin-yan-zheng-ma-an-niu') }}</span>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </Alert>-->
<!--      </verify-code-modal>-->
    <Modal
      v-model="showScheduleHistory"
      :title="$t('zhi-hang-li-shi')"
      width="1000" :closable="false"
      footer-hide
    >
      <div style="max-height: 500px;overflow: auto">
        <Table size="small" border :columns="scheduleHistoryColumn"
               :data="scheduleHistoryData"></Table>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCloseHistory">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showBatchConfirm"
      :title="$t('pi-liang-cao-zuo-que-ren')"
      footer-hide
    >
      <div>
        <p>
          {{ $t('nin-yi-xuan-ze-batchtype-stop-ting-zhi-batchtype-restart-zhong-qi-qi-dong-yi-xia-ren-wu-qing-que-ren-ren-wu', [batchType === 'stop' ? $t('ting-zhi') : batchType === 'restart' ? $t('zhong-qi') : $t('qi-dong')]) }}</p>
        <div class="modal-show-list-container">
          <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
            <Checkbox
              :value="checkAll"
              @click.prevent.native="handleCheckAll">{{ $t('quan-xuan') }}
            </Checkbox>
          </div>
          <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <Checkbox v-for="(job,index) of batchEditList" :label="job.dataJobName" :key="index">
              {{ job.dataJobName }}({{ job.dataJobDesc }})
            </Checkbox>
          </CheckboxGroup>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleBeginBatchEdit">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelDelete">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { deleteJob } from '@/services/cc/api/job';
import { sendCodeInLoginState } from '@/services/cc/api/rdpVerify';
import store from '@/store';
import SecondConfirmModal from '@/components/modal/SecondConfirmModal';
import { SECOND_CONFIRM_EVENT_LIST } from '@/const';

export default {
  components: { SecondConfirmModal },
  props: {
    type: String,
    dataJobInfo: Object,
    showConfirm: Boolean,
    showScheduleHistory: Boolean,
    showBatchConfirm: Boolean,
    scheduleHistoryColumn: Array,
    scheduleHistoryData: Array,
    batchEditList: Array,
    handleCancelDelete: Function,
    selectedJobRow: Object,
    batchType: String,
    checkAllGroup: Array,
    handleBeginBatchEdit: Function,
    jobId: Number,
    getJobList: Function,
    getJobData: Function,
    handleCloseHistory: Function
  },
  data() {
    return {
      SECOND_CONFIRM_EVENT_LIST,
      store,
      verifyCode: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      checkAll: true,
      sendCodeAgain: ''
    };
  },
  methods: {
    hideDeleteJobModal() {
      // console.log(this.$refs['delete-job-modal']);
      this.$refs['delete-job-modal'].handleEmptyVerifyCodeModalData();
    },
    handleVerify() {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: 'DELETE_JOB'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success(this.$t('fa-song-cheng-gong'));
          } else {
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            clearInterval(this.sendCodeAgain);
            this.$Modal.error({
              title: 'ERROR',
              content: `${res.data.msg}`
            });
          }
        })
        .catch((res) => {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          this.$Modal.error({
            title: 'ERROR',
            content: `${res.data.msg}`
          });
        });
    },
    handleConfirmDelete() {
      deleteJob({
        jobId: this.jobId
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            const that = this;
            this.$Message.success(this.$t('shan-chu-cheng-gong'));
            this.verifyCode = '';
            this.handleCancelDelete();

            if ((this.dataJobInfo && this.dataJobInfo.childJobs && this.dataJobInfo.childJobs.length) || this.type !== 'taskGroup') {
              setTimeout(() => {
                if (that.getJobList) {
                  that.getJobList();
                } else {
                  that.getJobData();
                }
              }, 2000);
            } else {
              this.$router.push({ path: '/data/job/list' });
            }
          }
        });
    },
    handleCheckAll() {
      if (!this.checkAll) {
        this.checkAll = true;
        this.batchEditList.forEach((item) => {
          this.checkAllGroup.push(item.dataJobName);
        });
      } else {
        this.checkAll = false;
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange() {
      this.checkAll = this.checkAllGroup.length === this.batchEditList.length;
    }
  }
};
</script>
