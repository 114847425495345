<template>
  <div>
    <p class="task-info-title">{{ $t('ren-wu-xin-xi') }}</p>
    <div>
      <p class="task-info-item-container">
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('quan-liang-0') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.type === 'MIGRATION' || taskInfo.mode.init ? $t('shi') : $t('fou') }} <span
                          style="margin-left: 5px"
                          v-if="taskInfo.type==='MIGRATION'&&taskInfo.mode.synchronize">{{ $t('kai-qi') }}<span
                          style="margin: 0 5px">{{ taskInfo.mode.shortTermNum }}</span>{{ $t('tian') }}
                        </span></span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('zeng-liang-0') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.type === 'SYNC' || taskInfo.mode.synchronize ? $t('shi') : $t('fou') }}
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('shu-ju-xiao-yan-0') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.type === 'Check' || taskInfo.checkMode!=='noCheck' ? $t('shi') : $t('fou') }}
                            <span
                              v-if="taskInfo.checkMode==='checkPeriod'&&taskInfo.checkPeriodDate"
                              style="margin-left: 10px">{{ getExpression('checkPeriodDate') }}</span>
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('shu-ju-ding-zheng-0')}}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.checkMode!=='noCheck' && taskInfo.dataReviseType!=='NONE' ? $t('shi') : $t('fou') }}
                        </span>
                    </span>
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('ddl-tong-bu-ce-lve') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.ddl === 'false' ? $t('quan-bu-tong-bu') : $t('guo-lv') }}
                        </span>
                    </span>
      </p>
      <p class="task-info-item-container">
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('shi-fou-zi-dong-qi-dong') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.autoStart?$t('shi'):$t('fou') }}
                        </span>
                    </span>
        <span class="task-info-item task-info-item-double">
                        <span class="task-info-item-title">
                            {{ $t('ren-wu-gui-ge') }}
                        </span>
                        <span class="task-info-item-value">
                          {{ $t('quan-liang-taskinfospecfullmemorymb-1024-g-zeng-liang-taskinfospecincrememorymb-1024-g-xiao-yan-taskinfospeccheckmemorymb-1024-g', [taskInfo.spec.fullMemoryMb / 1024, taskInfo.spec.increMemoryMb / 1024, taskInfo.spec.checkMemoryMb / 1024]) }}
                          <!--                            {{taskInfo.fullJVMHeapMB/1024}}G-->
                        </span>
                    </span>
        <span class="task-info-item task-info-item-all" style="border-top: 1px solid #DADADA" v-if="taskInfo.customPkgFile">
                        <span class="task-info-item-title">
                            {{ $t('dai-ma-bao-ming-cheng') }}
                        </span>
                        <span class="task-info-item-value">
                            {{taskInfo.customPkgFile.name}}
                        </span>
                    </span>
      </p>
      <p class="task-info-item-container" v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">
        <span class="task-info-item">
                        <span class="task-info-item-title">
                            {{ $t('shi-qu') }}
                        </span>
                        <span class="task-info-item-value">
                            {{ taskInfo.globalTimeZone }}
                        </span>
                    </span>
      </p>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    taskInfo: Object,
    structMigration: Boolean
  },
  data() {
    return {
      DataSourceGroup
    };
  },
  computed: {
    getTableCount() {
      return () => {
        let count = 0;

        this.taskInfo.dbMap.forEach((item) => {
          if (item.selectedTables) {
            item.selectedTables.forEach((table) => {
              if (table.selected) {
                count++;
              }
            });
          }
        });
        return count;
      };
    },
    getExpression() {
      return (type) => {
        const weekMap = {
          1: this.$t('ri'),
          2: this.$t('yi'),
          3: this.$t('er'),
          4: this.$t('san'),
          5: this.$t('si'),
          6: this.$t('wu'),
          7: this.$t('liu')
        };

        let expression = this.$t('mei');
        console.log(this.taskInfo[type].dayType, type);
        if (this.taskInfo[type].dayType === 'YEAR') {
          expression += this.$t('nian');
          expression += this.$t('di-thistaskinfotypemonth-yue', [this.taskInfo[type].month]);
          expression += this.$t('di-thistaskinfotypedate-tian', [this.taskInfo[type].date]);
          expression += this.taskInfo[type].time;
        } else if (this.taskInfo[type].dayType === 'MONTH') {
          expression += this.$t('yue');
          expression += this.$t('di-thistaskinfotypedate-tian', [this.taskInfo[type].date]);
          expression += this.taskInfo[type].time;
        } else if (this.taskInfo[type].dayType === 'DAY') {
          expression += this.$t('tian-0');
          expression += this.taskInfo[type].time;
        } else if (this.taskInfo[type].dayType === 'HOUR') {
          expression += this.$t('xiao-shi');
          expression += this.$t('di-thistaskinfotypehour-fen', [this.taskInfo[type].hour]);
        } else {
          expression += this.$t('zhou-weekmapthistaskinfotypeday', [weekMap[this.taskInfo[type].day]]);
          expression += this.taskInfo[type].time;
        }
        return expression;
      };
    }
  }
};
</script>
<style lang="less" scoped>
.task-info-title {
  background-color: #ECECEC;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Medium, serif;
  font-weight: 500;
  border-bottom: 1px solid #DADADA;
  padding-left: 20px;
}

.task-info-item-container {
  font-size: 0;
  border-bottom: 1px solid #DADADA;
}

.task-info-item {
  width: 20%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  overflow: hidden;

  .task-info-item-title {
    background-color: #F4F4F4;
    text-align: right;
    display: inline-block;
    font-weight: 400;
    width: 120px;
    border-right: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    padding-right: 10px;
  }

  .task-info-item-value {
    font-weight: 500;
    padding-left: 6px;
  }
}

.task-info-item-double {
  width: 40%;
}

.task-info-item-auto {
  width: 60%;
}
.task-info-item-all {
  width: 100%;
}
</style>
