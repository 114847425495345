<script>
import * as monaco from 'monaco-editor';

export default {
  name: 'ReadOnlyEditor',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      monacoEditor: null
    };
  },
  computed: {
    height() {
      const arr = this.text.split('\n');
      return arr.length > 25 ? 25 * 22 + 25 : arr.length < 5 ? 5 * 22 : arr.length * 22 + 25;
    }
  },
  mounted() {
    this.monacoEditor = monaco.editor.create(this.$refs.readOnlyEditor, {
      value: this.text, // 编辑器的值
      language: 'sql',
      fontSize: 14,
      fontWeight: 'bold',
      scrollBeyondLastLine: false,
      readOnly: true,
      theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
      minimap: {
        enabled: false
      },
      automaticLayout: true,
      autoIndent: true // 自动缩进
    });
  },
  beforeDestroy() {
    this.monacoEditor.dispose();
  }
};
</script>

<template>
  <div class="editor-wrapper">
    <div class="read-only-editor" ref="readOnlyEditor" :style="`height: ${height}px`">
    </div>
  </div>
</template>

<style scoped lang="less">
.editor-wrapper {

  .read-only-editor {
    width: 100%;
  }
}
</style>
