export const editorApi = {
  editorDef: '/clouddm/console/api/v1/editor/table/editorDef',
  initEditor: '/clouddm/console/api/v1/editor/table/initEditor',
  tableGenerateScript: '/clouddm/console/api/v1/editor/table/generateScript',
  scriptExecute: '/clouddm/console/api/v1/editor/table/scriptExecute',
  fetchData: '/clouddm/console/api/v1/editor/data/fetchData',
  fetchCount: '/clouddm/console/api/v1/editor/data/fetchCount',
  generateDml: '/clouddm/console/api/v1/editor/data/generateDml',
  saveData: '/clouddm/console/api/v1/editor/data/saveData'
};
