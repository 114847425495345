<script>
import CTree from '@wsfe/ctree';
import { mapGetters } from 'vuex';
import copyMixin from '@/mixins/copyMixin';
import datasourceMixin from '@/mixins/datasourceMixin';
import { ACTION_TYPE, TAB_TYPE } from '@/const';
import browseMixin from '@/mixins/browseMixin';
import { NODE_TYPE, DS_RIGHT_CLICK_MENU_ITEM } from '@/utils';
import utilMixin from '@/mixins/utilMixin';
import { clearAllPending } from '@/services/http/cencalRequest';

export default {
  name: 'DataSourceTree',
  mixins: [copyMixin, datasourceMixin, browseMixin, utilMixin],
  components: {
    CTree
  },
  props: {
    currentTab: Object,
    listLevels: Function,
    listLeaf: Function,
    detailLevels: Function,
    removeNode: Function,
    insertNode: Function,
    treeData: Array,
    setLoading: Function,
    getDataSourceList: Function,
    getNodeData: Function,
    getNodeByKey: Function,
    handleAddTab: Function,
    refreshTabSelectOptions: Function
  },
  data() {
    return {
      advancedSetting: [{
        value: 'delimited',
        label: this.$t('shi-yong-xian-ding-fu-bao-guo-shu-ju-ku-dui-xiang-ming')
      }, {
        value: 'usingExists',
        label: this.$t('shi-yong-if-exists-zi-ju')
      }, {
        value: 'cascade',
        label: this.$t('shi-yong-cascade-zi-ju-jin-hang-qiang-zhi-shan-chu')
      }, {
        value: 'restrict',
        label: this.$t('shi-yong-restrict-zi-ju-zai-xian-zhi-tiao-jian-xia-shan-chu')
      }, {
        value: 'purge',
        label: this.$t('shi-yong-purge-zi-ju-jin-hang-zi-yuan-hui-shou')
      }, {
        value: 'truncateUseDelete',
        label: this.$t('shi-yong-delete-yu-ju-ti-dai-truncate-yu-ju')
      }],
      scrollY: 0,
      top: 0,
      saveScroll: true,
      menuModal: {
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        },
        collapseKey: '',
        actionData: {},
        show: false,
        title: '',
        content: '',
        name: '',
        preName: '',
        showNameInput: false,
        sql: '',
        permission: false
      },
      doActionLoading: false,
      showEditDsDescModal: false,
      showDeleteInstanceModal: false,
      dsDesc: '',
      actionType: '',
      genActionData: null,
      TAB_TYPE,
      expandedKeys: [],
      selectedNode: null,
      dataSourceWidth: 250,
      preDataSourceWidth: 0,
      hide: false,
      searchKey: ''
    };
  },
  computed: {
    ...mapGetters([
      'isDesktop',
      'getMenus',
      'getBrowserMenus'
    ])
  },
  mounted() {
    const dataSourceTreeList = $('.datasource-tree');
    if (dataSourceTreeList && dataSourceTreeList.length) {
      dataSourceTreeList[0].addEventListener('scroll', this.handleSetScrollTop, true);
    }
  },
  beforeDestroy() {
    const dataSourceTreeList = $('.datasource-tree');
    if (dataSourceTreeList && dataSourceTreeList.length) {
      dataSourceTreeList[0].removeEventListener('scroll', this.handleSetScrollTop, true);
    }
  },
  methods: {
    handleSetScrollTop(e) {
      this.scrollY = e.target.scrollTop;
    },
    handleEleScroll(top) {
      const eleList = $('.datasource-tree .ctree-tree__scroll-area');
      if (eleList && eleList.length) {
        eleList[0].scrollTo({ top });
      }
    },
    async handleDeleteInstance() {
      const res = await this.$services.instanceDelete({
        data: {
          levels: this.browseGenLevelsData(this.selectedNode)
        }
      });

      if (res.success) {
        this.$message.success(this.$t('shan-chu-shu-ju-yuan-cheng-gong'));
        this.$refs.tree.remove(this.selectedNode.key);
        this.handleCloseModal();
      }
    },
    handleRefreshTree() {
      this.scrollY = 0;
      this.expandedKeys = [];
      this.getDataSourceList();
    },
    handleMenuNameChange(e) {
      if (e.target.value !== this.menuModal.name) {
        this.menuModal.sql = '';
        this.menuModal.permission = false;
        this.menuModal.danger = false;
        this.menuModal.actionData = {};
      }
      this.menuModal.name = e.target.value;
    },
    handleMenuOptionChange(key, e) {
      if (e.target.checked !== this.menuModal.options[key]) {
        this.menuModal.sql = '';
        this.menuModal.permission = false;
        this.menuModal.danger = false;
        this.menuModal.actionData = {};
      }
      this.menuModal.options[key] = e.target.checked;
    },
    async handleDoAction() {
      const callback = async () => {
        let currentNode = {};
        switch (this.actionType) {
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_RENAME:
            break;
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_DROP:
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_DROP:
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_DROP:
            this.$refs.tree.remove(this.selectedNode.key);
            this.removeNode(this.treeData, this.selectedNode._parent.key, this.selectedNode.key);
            this.refreshTabSelectOptions(this.selectedNode._parent.key);
            break;
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_CREATE:
            if (this.selectedNode.nodeType === NODE_TYPE.CATALOG) {
              const parentNode = this.selectedNode._parent;
              currentNode = {
                ...this.selectedNode,
                [NODE_TYPE.CATALOG]: {
                  id: this.menuModal.name,
                  name: this.menuModal.name,
                  attr: {}
                },
                isNew: true,
                isLeaf: false,
                title: this.menuModal.name,
                popTip: `${parentNode.popTip}.${this.menuModal.name}`,
                key: `${parentNode.key}.\`${this.menuModal.name}\``,
                children: [],
                icon: NODE_TYPE.CATALOG,
                nodeType: NODE_TYPE.CATALOG,
                levels: this.selectedNode.levels
              };
              this.$refs.tree.insertAfter(currentNode, this.selectedNode.key);
              this.$refs.tree.setSelected(currentNode.key, true);
              this.insertNode(parentNode.key, this.selectedNode.key, currentNode);
              await this.listLevels(currentNode);
            } else {
              if (this.selectedNode.children.length) {
                const parentNode = this.selectedNode;
                currentNode = {
                  ...this.selectedNode,
                  [NODE_TYPE.CATALOG]: {
                    id: this.menuModal.name,
                    name: this.menuModal.name,
                    attr: {}
                  },
                  isNew: true,
                  isLeaf: true,
                  title: this.menuModal.name,
                  popTip: `${parentNode.popTip}.${this.menuModal.name}`,
                  key: `${parentNode.key}.\`${this.menuModal.name}\``,
                  children: [],
                  icon: NODE_TYPE.CATALOG,
                  nodeType: NODE_TYPE.CATALOG,
                  levels: this.selectedNode.children[0].levels
                };
                this.$refs.tree.insertBefore(currentNode, this.selectedNode.children[0].key);
                this.$refs.tree.setSelected(currentNode.key, true);
                this.insertNode(parentNode.key, null, currentNode);
              } else {
                await this.getNodeData(this.selectedNode, { selected: `${this.selectedNode.key}.\`${this.menuModal.name}\`` });
              }
            }
            this.menuModal.name = '';
            this.menuModal.preName = '';
            break;
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_RENAME:
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_RENAME:
            const node = this.getNodeByKey(this.treeData, this.selectedNode.key);
            node.title = this.menuModal.name;
            node[node.nodeType].id = this.menuModal.name;
            node[node.nodeType].name = this.menuModal.name;
            node.key = `${node.parentKey}.\`${this.menuModal.name}\``;
            node.popTip = `${node.parentPoptip}.\`${this.menuModal.name}\``;
            if (node.children && node.children.length) {
              node.children.forEach((child) => {
                child.parentKey = node.key;
                child.parentPoptip = node.popTip;
                child.key = `${node.key}.\`${child.title}\``;
                child.popTip = `${node.popTip}.\`${child.title}\``;
              });
            }
            this.handleSetExpandedKeys(node);
            await this.handleSetData(this.treeData);
            this.handleSetSelected(node.key);
            if (this.actionType === DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_RENAME) {
              this.refreshTabSelectOptions(node.parentKey);
            }
            break;
          case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_CREATE:
            if (this.selectedNode.nodeType === NODE_TYPE.SCHEMA) {
              const parentNode = this.selectedNode._parent;
              currentNode = {
                ...this.selectedNode,
                [NODE_TYPE.SCHEMA]: {
                  id: this.menuModal.name,
                  name: this.menuModal.name,
                  attr: {}
                },
                isNew: true,
                isLeaf: true,
                title: this.menuModal.name,
                popTip: `${parentNode.popTip}.${this.menuModal.name}`,
                key: `${parentNode.key}.\`${this.menuModal.name}\``,
                children: [],
                icon: NODE_TYPE.SCHEMA,
                nodeType: NODE_TYPE.SCHEMA,
                levels: this.selectedNode.levels
              };
              this.$refs.tree.insertAfter(currentNode, this.selectedNode.key);
              this.$refs.tree.setSelected(currentNode.key, true);
              this.insertNode(parentNode.key, this.selectedNode.key, currentNode);
              this.refreshTabSelectOptions(this.selectedNode._parent.key);
            } else {
              if (this.selectedNode.children.length) {
                const parentNode = this.selectedNode;
                currentNode = {
                  ...this.selectedNode,
                  [NODE_TYPE.SCHEMA]: {
                    id: this.menuModal.name,
                    name: this.menuModal.name,
                    attr: {}
                  },
                  isNew: true,
                  isLeaf: true,
                  title: this.menuModal.name,
                  popTip: `${parentNode.popTip}.${this.menuModal.name}`,
                  key: `${parentNode.key}.\`${this.menuModal.name}\``,
                  children: [],
                  icon: NODE_TYPE.SCHEMA,
                  nodeType: NODE_TYPE.SCHEMA,
                  levels: this.selectedNode.children[0].levels
                };
                this.$refs.tree.insertBefore(currentNode, this.selectedNode.children[0].key);
                this.$refs.tree.setSelected(currentNode.key, true);
                this.insertNode(parentNode.key, null, currentNode);
              } else {
                await this.getNodeData(this.selectedNode, { selected: `${this.selectedNode.key}.\`${this.menuModal.name}\`` });
              }
            }
            this.menuModal.name = '';
            this.menuModal.preName = '';
            break;
          default:
            break;
        }

        this.handleCloseModal();
        this.doActionLoading = false;
        this.$message.success(this.$t('cao-zuo-cheng-gong'));
      };
      const callbackFail = () => {
        this.doActionLoading = false;
      };
      this.doActionLoading = true;
      await this.browseDoAction(this.menuModal.actionData, callback, callbackFail);
    },
    handleDblClick(node) {
      if (node.isLeaf) {
        this.handleAddTab(TAB_TYPE.QUERY, node);
      }
    },
    handleSetExpandedKeys(node) {
      const { key } = node;
      if (this.isExpandedKey(node)) {
        this.expandedKeys = this.expandedKeys.filter((k) => k !== key);
      } else {
        this.expandedKeys.push(key);
      }
    },
    isExpandedKey(node) {
      return this.expandedKeys.includes(node.key);
    },
    handleCloseModal() {
      this.menuModal = {
        options: {
          delimited: false,
          usingExists: false,
          cascade: false,
          restrict: false,
          purge: false,
          truncateUseDelete: false
        },
        actionData: {},
        show: false,
        title: '',
        content: '',
        name: '',
        preName: '',
        sql: '',
        permission: false
      };
      this.dsDesc = '';
      this.doActionLoading = false;
      this.showEditDsDescModal = false;
      this.showDeleteInstanceModal = false;
      clearAllPending();
    },
    async handleSetData(data) {
      this.top = this.scrollY;
      await this.$refs.tree.setData(data);
      setTimeout(() => {
        this.handleEleScroll(this.top);
      });
    },
    handleSetSelected(key, selected = true) {
      this.$refs.tree.setSelected(key, selected);
    },
    handleGetNode(key) {
      return this.$refs.tree.getNode(key);
    },
    handleGetTreeData() {
      return this.$refs.tree.getTreeData();
    },
    renderNode(h, node) {
      const { title, icon, children } = node;
      return (
        <div class="node">
          {icon && <cc-svg-icon name={icon}/>}
          <div style={{ marginLeft: '3px', color: `${node.isNew ? 'green' : '#000'}`, fontWeight: `${node.isNew ? 'bold' : 'default'}` }} domPropsInnerHTML={this.highlight(title, this.searchKey)}>
          </div>
          {children && children.length > 0 && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
        </div>
      );
    },
    handleNodeRightClick(node) {
      this.selectedNode = node;
      this.$refs.tree.setSelected(node.key, true);
    },
    async handleExpandLoadNode(node, resolve, reject) {
      await this.getNodeData(node, {}, resolve, reject);
    },
    async handleSearch() {
      await this.$refs.tree.filter(this.searchKey);
      this.$refs.tree.scrollTo(this.treeData[0].key);
    },
    handleFocus() {
      if (this.currentTab) {
        this.handleScrollTo(this.currentTab.node.key);
      }
    },
    handleScrollTo(key) {
      this.$refs.tree.scrollTo(key, 'center');
      this.handleSetSelected(key);
    },
    handleSwitchHide() {
      if (this.hide) {
        this.dataSourceWidth = this.preDataSourceWidth;
      } else {
        const ele = document.querySelector('.data-source-container');
        if (ele) {
          this.preDataSourceWidth = ele.getBoundingClientRect().width;
        }
        this.dataSourceWidth = 0;
      }
      this.hide = !this.hide;
    },
    handleAddNewDs() {
      this.$router.push({ name: 'System_DataSource_Add' });
    },
    //
    handleNodeClick(node) {
      this.selectedNode = node;
      this.$refs.tree.setSelected(node.key, true);
    },
    onContextmenu(event) {
      const menuList = this.getBrowserMenus(this.selectedNode && this.selectedNode.nodeType === NODE_TYPE.ENV ? null : this.selectedNode.INSTANCE.attr.dsType, this.selectedNode.nodeType);
      if (menuList) {
        const items = [];
        menuList.forEach((menu, menuIndex) => {
          if (menu.menuId !== 'MENU_SEPARATOR') {
            items.push({
              label: menu.i18n,
              icon: `iconfont icon-svg-${menu.menuId}`,
              divided: menuList[menuIndex + 1] && menuList[menuIndex + 1].menuId === 'MENU_SEPARATOR',
              onClick: () => this.handleRightClickMenu(menu.menuId)
            });
          }
        });

        this.$contextmenu({
          items,
          event,
          customClass: 'custom-class',
          zIndex: 3,
          minWidth: 100
        });
      }
    },
    async handleRightClickMenu(actionType) {
      this.actionType = actionType;
      const data = {
        actionType,
        callback: null,
        other: {
          targetType: '', targetName: '', targetNewName: '', options: {}
        }
      };

      switch (actionType) {
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CONSOLE:
          this.handleAddTab(TAB_TYPE.QUERY, this.selectedNode, { force: true });
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COPY_NAME:
          this.copyText(this.selectedNode.title);
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COPY_JDBC:
          this.copyText(this.selectedNode.INSTANCE.attr.dsHost);
          break;
        default:
          break;
      }

      switch (actionType) {
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_REFRESH:
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_REFRESH:
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_REFRESH:
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_REFRESH:
          const callback = async () => {
            if (this.selectedNode.isLeaf) {
              if (this.selectedNode.key === this.currentTab.node.key) {
                this.currentTab.expandedKeys = [];
                await this.listLeaf();
              }
            } else {
              this.expandedKeys = this.expandedKeys.filter((key) => !key.includes(this.selectedNode.key));
              await this.listLevels(this.selectedNode);
            }
          };
          await this.detailLevels(this.selectedNode, callback);
          break;
        default:
          break;
      }

      switch (actionType) {
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_CREATE:
          this.handleAddNewDs();
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_RENAME:
          if (!this.dsDesc) {
            this.dsDesc = this.selectedNode.title;
            this.showEditDsDescModal = true;
          } else {
            const res = await this.$services.instanceRemark({
              data: {
                levels: this.browseGenLevelsData(this.selectedNode),
                remark: this.dsDesc
              }
            });

            if (res.success) {
              this.showEditDsDescModal = false;
              this.dsDesc = '';
              this.$message.success(this.$t('xiu-gai-shi-li-bei-zhu-cheng-gong'));
              await this.detailLevels(this.selectedNode);
            }
          }
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INSTANCE_DROP:
          this.showDeleteInstanceModal = true;
          break;
        default:
          break;
      }

      switch (actionType) {
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_CREATE:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.showNameInput = true;
            this.menuModal.title = this.$t('xin-jian-shu-ju-ku');
            this.menuModal.content = this.selectedNode.nodeType === NODE_TYPE.CATALOG ? this.selectedNode._parent.popTip : this.selectedNode.popTip;
          } else {
            data.other.targetName = this.menuModal.name;
            data.other.targetType = NODE_TYPE.CATALOG;
            data.other.options = this.menuModal.options;
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(this.selectedNode, this.selectedNode.nodeType === NODE_TYPE.CATALOG ? this.selectedNode._parent.levels : this.selectedNode.levels), data.callback, data.other);
          }
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_DROP:
          data.other.targetName = this.selectedNode.title;
          data.other.targetType = NODE_TYPE.CATALOG;
          data.other.options = this.menuModal.options;
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.show = true;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.sql = sql;
            this.menuModal.actionData = genActionData;
            this.menuModal.title = this.$t('que-ren');
            this.menuModal.name = this.selectedNode.title;
            this.menuModal.preName = this.selectedNode.title;
            this.menuModal.content = this.$t('que-ding-yao-shan-chu-thisselectednodepoptip-ma', [this.selectedNode.popTip]);
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(this.selectedNode, this.selectedNode._parent.levels), data.callback, data.other);
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_REFRESH:
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_CATALOG_RENAME:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.name = this.selectedNode.title;
            this.menuModal.preName = this.selectedNode.title;
            this.menuModal.title = this.$t('zhong-ming-ming');
            this.menuModal.showNameInput = true;
            this.menuModal.content = this.$t('zhong-ming-ming-thisselectednodepoptip-wei', [this.selectedNode.popTip]);
          } else {
            data.other.targetName = this.selectedNode.title;
            data.other.targetNewName = this.menuModal.name;
            data.other.targetType = this.selectedNode.nodeType;
            data.other.options = this.menuModal.options;
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(this.selectedNode, this.selectedNode._parent.levels), data.callback, data.other);
          }
          break;
        default:
          break;
      }

      switch (actionType) {
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_CREATE:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.showNameInput = true;
            this.menuModal.title = this.$t('xin-jian-schema');
            this.menuModal.content = this.selectedNode.nodeType !== NODE_TYPE.SCHEMA ? this.selectedNode.popTip : this.selectedNode._parent.popTip;
          } else {
            data.other.targetName = this.menuModal.name;
            data.other.targetType = NODE_TYPE.SCHEMA;
            data.other.options = this.menuModal.options;
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType,
              this.browseGenLevelsData(this.selectedNode, this.selectedNode.nodeType !== NODE_TYPE.SCHEMA ? this.selectedNode.levels : this.selectedNode._parent.levels),
              data.callback, data.other);
          }
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_CREATE:
          this.handleAddTab(TAB_TYPE.STRUCT, this.selectedNode, { editorType: ACTION_TYPE.CREATE_TABLE });
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_RENAME:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.title = this.$t('zhong-ming-ming');
            this.menuModal.name = this.selectedNode.title;
            this.menuModal.preName = this.selectedNode.title;
            this.menuModal.showNameInput = true;
            this.menuModal.content = this.$t('zhong-ming-ming-thisselectednodepoptip-wei', [this.selectedNode.popTip]);
          } else {
            data.other.targetName = this.selectedNode.title;
            data.other.targetNewName = this.menuModal.name;
            data.other.targetType = this.selectedNode.nodeType;
            data.other.options = this.menuModal.options;
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(this.selectedNode, this.selectedNode._parent.levels), data.callback, data.other);
          }
          break;
        case DS_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_SCHEMA_DROP:
          data.other.targetName = this.selectedNode.title;
          data.other.targetType = this.selectedNode.nodeType;
          data.other.options = this.menuModal.options;
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.show = true;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.sql = sql;
            this.menuModal.actionData = genActionData;
            this.menuModal.title = this.$t('que-ren');
            this.menuModal.name = this.selectedNode.title;
            this.menuModal.preName = this.selectedNode.title;
            this.menuModal.content = this.$t('que-ding-yao-shan-chu-thisselectednodepoptip-ma', [this.selectedNode.popTip]);
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(this.selectedNode, this.selectedNode._parent.levels), data.callback, data.other);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<template>
  <div class="data-source-container" :style="`width: ${dataSourceWidth}px`"
       @contextmenu.prevent.stop="onContextmenu">
    <div class="tree-resize"/>
    <div class="data-source-filter" v-if="!hide">
      <a-input v-model="searchKey" class="filter-input" @change="handleSearch" size="small"
               allow-clear>
        <a-icon slot="prefix" type="search"/>
      </a-input>
      <cc-svg-icon name="focus" @click.native="handleFocus" style="cursor:pointer;"></cc-svg-icon>
      <cc-svg-icon style="margin-left: 6px" name="refresh" @click.native="handleRefreshTree" :size="15"></cc-svg-icon>
    </div>
    <cc-iconfont @click.native="handleSwitchHide" :size="14" class="hide-icon" color="#999999"
                 :name="hide?'zhankai': 'shouqi'"></cc-iconfont>
    <c-tree
      class="datasource-tree"
      ref="tree"
      key-field="key"
      :load="handleExpandLoadNode"
      :render="renderNode"
      :expanded-keys="expandedKeys"
      @expand="handleSetExpandedKeys"
      @node-right-click="handleNodeRightClick"
      @node-dblclick="handleDblClick"
      :nodeIndent="10"
      :renderNodeAmount="200"
      @click="handleNodeClick"
    >
    </c-tree>
    <a-modal :title="menuModal.title" v-model="menuModal.show" :mask-closable="false"
             :closable="false" :keyboard="false">
      <div style="margin-bottom: 5px;font-weight: bold;">
        {{ menuModal.content }}
      </div>
      <a-collapse :bordered="false" size="small" style="margin: 5px 0;" v-model="menuModal.collapseKey" :destroyInactivePanel="true">
        <a-collapse-panel :header="$t('gao-ji-pei-zhi')" key="options">
          <div v-for="setting in advancedSetting" :key="setting.value">
            <a-checkbox :value="menuModal.options[setting.value]" @change="handleMenuOptionChange(setting.value, $event)">
              {{setting.label}}
            </a-checkbox>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div style="display: flex;" v-if="menuModal.showNameInput">
        <a-input :value="menuModal.name" :placeholder="$t('qing-shu-ru-xin-de-ming-zi')"
                 @change="handleMenuNameChange" allow-clear/>
      </div>
      <div style="margin-top: 5px;font-weight: bold;" v-if="menuModal.sql">{{ $t('sql-yu-ju') }}:</div>
      <div style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;" v-if="menuModal.sql">
        <pre>{{ menuModal.sql }}</pre>
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleRightClickMenu(actionType)" v-if="!menuModal.sql" :disabled="!menuModal.name">
          {{ $t('sheng-cheng-sql-yu-ju') }}
        </a-button>
        <a-button v-if="menuModal.permission && menuModal.sql" :type="menuModal.danger ? 'danger' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading">{{ $t('li-ji-zhi-hang') }}
        </a-button>
        <a-button v-if="!menuModal.permission && menuModal.sql">{{ $t('ti-jiao-gong-dan') }}</a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model="showEditDsDescModal" :mask-closable="false" :closable="false"
             :keyboard="false"
             :title="$t('xiu-gai-shi-li-bei-zhu')" v-if="showEditDsDescModal">
      <div style="margin-bottom: 5px;font-weight: bold;">{{ $t('xiu-gai-selectednodepoptip-de-bei-zhu-selectednodetitle-wei', [selectedNode.popTip, selectedNode.title]) }}</div>
      <a-input v-model="dsDesc" :placeholder="$t('qing-shu-ru-xin-de-bei-zhu')" allow-clear/>
      <div class="footer">
        <a-button @click="handleRightClickMenu(actionType)">{{ $t('xiu-gai') }}
        </a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <a-modal v-model="showDeleteInstanceModal" :mask-closable="false" :closable="false"
             :keyboard="false"
             :title="$t('shan-chu-shi-li')" v-if="showDeleteInstanceModal">
      <div style="margin-bottom: 5px;font-weight: bold;">{{ $t('que-ding-yao-shan-chu-selectednodepoptip-ma', [selectedNode.popTip]) }}?</div>
      <div class="footer">
        <a-button @click="handleDeleteInstance(actionType)" type="danger">{{ $t('shan-chu') }}
        </a-button>
        <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="less">
/deep/ .ctree-tree-node__title {
  padding-left: 0;
  margin-left: 0;
}

.data-source-container {
  background: #FFFFFF;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid #ccc;

  .tree-resize {
    height: 100%;
    width: 6px;
    background: rgba(0, 0, 0, 0);
    //background: red;
    position: absolute;
    right: -3px;
    cursor: col-resize;
    z-index: 9;
  }

  .hide-icon {
    position: absolute;
    right: -26px;
    z-index: 9;
    top: 10px;
    background: #fff;
    padding: 2px 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .datasource-tree {
    flex: 1;
    min-height: 0;

    /deep/ .node {
      display: flex;
      align-items: center;
    }
  }
}

/deep/ .ant-collapse-header {
  padding: 5px 10px 5px 34px!important;
}

/deep/ .ant-collapse-item {
  border: none;
}

/deep/ .ant-collapse-content-box {
  padding: 8px 10px;
}

/deep/ .highlight {
  background: orange!important;
}
</style>
