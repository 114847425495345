<template>
  <a-input v-model="pwd" :placeholder="placeholder" :size="size" :type="eyeVisible ? 'text' : 'password'"
           autocomplete="new-password" @pressEnter="handleEnter">
    <a-icon slot="suffix" :type="eyeVisible ? 'eye' : 'eye-invisible'" size="large" theme="filled"
            @click="handleChangeEye"/>
  </a-input>
</template>

<script>
export default {
  name: 'CCPasswordInput',
  model: {
    prop: '__password__',
    event: '__handle_password__'
  },
  props: {
    placeholder: {
      type: String,
      default() {
        return this.$i18n.t('mi-ma');
      }
    },
    size: {
      type: String,
      default: 'default'
    },
    handleEnter: {
      type: Function,
      default: () => {

      }
    }
  },
  data() {
    return {
      pwd: '',
      eyeVisible: false
    };
  },
  methods: {
    handleChangeEye() {
      this.eyeVisible = !this.eyeVisible;
    }
  },
  watch: {
    pwd(value) {
      this.$emit('__handle_password__', value);
    }
  }
};
</script>

<style scoped>

</style>
