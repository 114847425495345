<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view v-if="showChild"/>
    </div>
  </a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';

export default {
  name: 'App',
  data() {
    return {
      showChild: false,
      locale: zhCN,
      position: {
        ele: null,
        canResize: false
      }
    };
  },
  async created() {
    if (window.location.hash === '#/login') {
      this.showChild = true;
    }
    this.locale = this.$i18n.locale === 'zh-CN' ? zhCN : enUS;
    await this.$store.dispatch('getUserInfo');
    this.showChild = true;
  },
  mounted() {
    document.addEventListener('mousedown', this.handleMousedown);
    document.addEventListener('mousemove', this.handleMousemove);
    document.addEventListener('mouseup', this.handleMouseup);
  },
  methods: {
    handleMousedown(event) {
      if (['editor-resize', 'tree-resize', 'table-list-resize', 'struct-resize'].includes(event.target.className)) {
        this.position.ele = event.target;
        event.target.style.background = '#ccc';
        this.position.canResize = true;
        this.position.type = event.target.className;
        this.position.clientX = event.clientX;
        this.position.clientY = event.clientY;
        document.body.style.setProperty('user-select', 'none');
      }
    },
    handleMousemove(event) {
      let needMouseUp = false;
      if (this.position.canResize) {
        const leftWidth = event.clientX - this.position.clientX;
        const leftHeight = event.clientY - this.position.clientY;
        this.position.clientX = event.clientX;
        this.position.clientY = event.clientY;

        if (this.position.type === 'editor-resize') {
          const ele = document.querySelector('.monaco-editor');
          if (ele) {
            const rect = ele.getBoundingClientRect();
            const sqlViewerEle = document.querySelector('.query-editor-container');
            const sqlViewerRect = sqlViewerEle.getBoundingClientRect();
            let calcHeight = rect.height;
            if (rect.height >= 20 && sqlViewerRect.height - rect.height >= 74) {
              calcHeight += leftHeight;
            }

            if (calcHeight < 20) {
              calcHeight = 20;
              needMouseUp = true;
            } else if (sqlViewerRect.height - calcHeight < 74) {
              calcHeight = sqlViewerRect.height - 74;
              needMouseUp = true;
            }

            ele.style.setProperty('height', `${calcHeight}px`, 'important');
            window.$bus.emit('setEditorHeight', calcHeight);
          }
        } else if (this.position.type === 'tree-resize') {
          const ele = document.querySelector('.data-source-container');
          if (ele) {
            const rect = ele.getBoundingClientRect();
            let calcWidth = rect.width;

            if (rect.width >= 60) {
              calcWidth += leftWidth;
            }
            if (calcWidth < 60) {
              calcWidth = 60;
              needMouseUp = true;
            }

            ele.style.setProperty('width', `${calcWidth}px`, 'important');
          }
        } else if (this.position.type === 'struct-resize') {
          const ele = document.querySelector('.struct-view .left');
          if (ele) {
            const rect = ele.getBoundingClientRect();
            let calcWidth = rect.width;

            if (rect.width >= 60) {
              calcWidth += leftWidth;
            }
            if (calcWidth < 60) {
              calcWidth = 60;
              needMouseUp = true;
            }

            ele.style.setProperty('width', `${calcWidth}px`, 'important');
          }
        } else if (this.position.type === 'table-list-resize') {
          const ele = document.querySelector('.table-list-container');
          if (ele) {
            const rect = ele.getBoundingClientRect();
            let calcWidth = rect.width;

            if (rect.width >= 60) {
              calcWidth += leftWidth;
            }
            if (calcWidth < 60) {
              calcWidth = 60;
              needMouseUp = true;
            }

            ele.style.setProperty('width', `${calcWidth}px`, 'important');
          }
        }

        if (needMouseUp) {
          this.handleMouseup(event);
        }
      }
    },
    handleMouseup() {
      document.body.style.setProperty('user-select', 'text');
      this.position.canResize = false;
      if (this.position.ele) {
        this.position.ele.style.background = 'rgba(0, 0, 0 ,0)';
        this.position.ele = null;
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.handleMousedown);
    document.removeEventListener('mousemove', this.handleMousemove);
    document.removeEventListener('mousemove', this.handleMouseup);
  }
};
</script>

<style lang="less">
#app {
  flex-direction: column;
  display: flex;
  height: 100%;
}
</style>
