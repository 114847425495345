import axios from 'axios';
import { Modal, Spin } from 'view-design';
import i18n from '@/i18n';
import store from '@/store';
import Cookies from 'js-cookie';

const baseURL = `${window.location.protocol}//${window.location.host}/cloudcanal/console/api/v1/inner`;
// const baseURL = '/' + '/api/v1/';
const timeout = 60000;
const instance = axios.create({
  baseURL,
  timeout,
  headers: {
    'Accept-Language': i18n.locale,
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Product-Code': store.state.selectCcProductCluster
    // 'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true,
  credentials: 'include'
});

export { instance };

instance.interceptors.request.use((request) => {
  if (request.headers['x-is-redirect']) {
    request.headers['X-Product-Code'] = null;
  } else {
    request.headers['X-Product-Code'] = store.state.selectCcProductCluster;
  }
  return request;
});

// 返回结果拦截器,处理默认的错误
// 返回结果拦截器,处理默认的错误
instance.interceptors.response.use((response) => {
  if (response.data.code !== '1') {
    if (response.data.code !== '6028' && response.data.code !== '2-11' && response.data.code !== '0014') {
      if (response.data.msg) {
        Modal.info({
          render: (h) => h('div', [
            h('div', {
              class: 'operation-error-title'
            }, [
              h('Icon', {
                props: {
                  type: 'md-close-circle'
                },
                style: {
                  fontSize: '24px',
                  float: 'left'
                }
              }),
              h('p', {
                class: 'preCheck-title-desc',
                style: {
                  marginLeft: '30px',
                  wordBreak: 'break-all'
                }
              }, i18n.t('cao-zuo-shi-bai')),
              h('p', {
                style: {
                  marginLeft: '30px'
                }
              }, response.data.msg)
            ])
          ]),
          width: 600,
          okText: i18n.t('zhi-dao-le'),
          closable: true
        });
      }
    }
  }
  // 正常的请求前拦截,在这里处理
  return response;
}, (error) => {
  // 非200请求时的错误处理'
  Spin.hide();
  if (error.response) {
    const res = error.response.data; // 请求data
    const status = error.response.status; // 请求状态吗

    if (status === 499) {
      window.location.href = res.url;
    } else if (status === 401) {
      window.location.href = `${window.location.protocol}//${window.location.host}/#/login`;
      // window.location.reload();
    } else if (status === 307) {
      console.log('response', error.response);
      if (error.response.headers['x-redirect-addr']) {
        const cookies = Cookies.get();
        const newUrl = `${error.response.headers['x-redirect-addr']}/cloudcanal/console/api/v1/inner/${error.response.config.url}`;
        const headers = {
          'x-is-redirect': true
        };
        if (cookies.jwt_token) {
          headers.jwt_token = cookies.jwt_token;
        }
        return instance.post(newUrl,
          error.response.config.data,
          {
            headers,
            withCredentials: true,
            credentials: 'include'
          });
      } else {
        // instance.post('').then((response) => response);
      }
    } else if (status === 406) {
      Modal.error({
        title: i18n.t('quan-xian-yi-chang'),
        content: res.message || i18n.t('nin-mei-you-gai-quan-xian-de-cao-zuo-qing-lian-xi-zhu-zhang-hao-huo-guan-li-yuan')
      });
    } else if (status === 500) {
      // if (window.location.href.split('#')[1] !== '/permission/denied' && window.location.href.split('#')[1] !== '/permission/exception') {
      //   localStorage.setItem('console_last_url', window.location.href.split('#')[1]);
      // }
      // window.location.href = `${window.location.protocol}//${window.location.host}/#/permission/exception`;
    } else if (res && !res.errors) {
      Modal.error({
        title: 'ERROR',
        content: `${res.message}`
      });
    } else {
      Modal.error({
        title: 'ERROR',
        content: `${res.errors[0].message}`
      });
    }
  } else {
    Modal.error({
      title: 'ERROR',
      content: i18n.t('yi-chao-shi')
    });
  }
  return Promise.reject(error);
});
