<template>
    <div style="padding: 16px;padding-bottom: 74px">
        <Breadcrumb style="margin-bottom: 14px;">
            <BreadcrumbItem>{{ $t('yi-chang-ri-zhi') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form label-position="right" :label-width="80" inline>
                <FormItem :label="$t('xuan-ze-shi-jian')">
                    <DatePicker :editable="false" v-model="searchInfo.dataRange" :options="dateRangeOptions" type="datetimerange"
                                format="yyyy-MM-dd HH:mm" :placeholder="$t('qing-xuan-ze-kai-shi-shi-jian-he-jie-shu-shi-jian')" style="width: 300px"></DatePicker>
                  <Input v-model="searchInfo.dataJobNameLike" :placeholder="$t('qing-shu-ru-ren-wu-ming-cheng')" style="width: 280px;margin-left: 20px" />
                  <pd-button type="primary" style="margin-left: 6px" @click="getExceptionList">{{ $t('cha-xun') }}</pd-button>
                </FormItem>
            </Form>
            <div class="page-header-function">
                <pd-button type="default" style="margin-right: 6px" @click="getExceptionList"
                           :loading="refreshLoading">
                    {{ $t('shua-xin') }}
                </pd-button>
            </div>
        </div>
        <Tabs style="margin-top: 20px" value="all" :animated = false @on-click="handleTabChange">
            <TabPane :label="$t('quan-bu')" name="all">
                <Table style="margin-top: 10px;" size="small" border :columns="exceptionColumns" :data="exceptionData">
                    <template slot-scope="{ row }" slot="dataJob">
                        <div><a @click="handleGoDataJob(row)" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.dataJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row }" slot="consoleJob">
                        <div><a @click="handleGoConsoleJob" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.consoleJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="rootCause">
                        <div style="cursor: pointer"><span
                            @click="handleShowMoreInfo('rootCause',index)"
                            :style="!showMore[index]||(showMore[index]&&!showMore[index].rootCause)?
                            `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;`:''">
                          {{row.rootCause}}</span></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="exceptionInfo">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('exceptionInfo',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].exceptionInfo)?
                                                           'display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;':''">
                          {{row.exceptionMsg}}</span></div>
                    </template>
                    <template slot-scope="{ row }" slot="stack">
                        <div>
                            <pd-button size="small" type="primary" @click="showMoreInfo(row)" style="margin-right: 5px">{{ $t('cha-kan-yi-chang-dui-zhan') }}</pd-button>
                            <pd-button size="small" type="primary" v-if="row.dataJobId" @click="handleJobDetail(row)">{{ $t('cha-kan-ren-wu') }}</pd-button>
                        </div>
                    </template>
                </Table>
                <div class="page-footer-container">
                    <div class="page-footer-paging">
                        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                          <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
                    </div>
                </div>
            </TabPane>
            <TabPane :label="$t('guan-kong-xi-tong-yi-chang')" name="CONSOLE_EXCEPTION_EVENT" v-if="role!=='ORG_ADMIN'">
                <Table style="margin-top: 10px;" size="small" border :columns="exceptionColumns" :data="exceptionData">
                    <template slot-scope="{ row }" slot="dataJob">
                        <div><a @click="handleGoDataJob(row)" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.dataJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row }" slot="consoleJob">
                        <div><a @click="handleGoConsoleJob" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.consoleJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="rootCause">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('rootCause',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].rootCause)?
                                                           `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;`:''">
                          {{row.rootCause}}</span></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="exceptionInfo">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('exceptionInfo',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].exceptionInfo)?
                                                           'display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;':''">
                          {{row.exceptionMsg}}</span></div>
                    </template>
                    <template slot-scope="{ row }" slot="stack">
                        <div><pd-button size="small" type="primary" @click="showMoreInfo(row)" style="margin-right: 5px">{{ $t('cha-kan-yi-chang-dui-zhan') }}</pd-button></div>
                    </template>
                </Table>
                <!--                        <Page :total="total" show-total style="margin-top: 16px" @on-change="handlePageChange" @on-page-size-change="handlePageSizeChange"/>-->
                <div class="page-footer-container">
                    <div class="page-footer-paging">
                        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                          <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
                    </div>
                </div>
            </TabPane>
            <TabPane :label="$t('ren-wu-yi-chang')" name="TASK_EXCEPTION_EVENT">
                <Table style="margin-top: 10px;" size="small" border :columns="exceptionColumns" :data="exceptionData">
                    <template slot-scope="{ row }" slot="dataJob">
                        <div><a @click="handleGoDataJob(row)" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.dataJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row }" slot="consoleJob">
                        <div><a @click="handleGoConsoleJob" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.consoleJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="rootCause">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('rootCause',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].rootCause)?
                                                           `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;`:''">
                          {{row.rootCause}}</span></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="exceptionInfo">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('exceptionInfo',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].exceptionInfo)?
                                                           'display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;':''">
                          {{row.exceptionMsg}}</span></div>
                    </template>
                    <template slot-scope="{ row }" slot="stack">
                        <div>
                            <pd-button size="small" type="primary" @click="showMoreInfo(row)" style="margin-right: 5px">{{ $t('cha-kan-yi-chang-dui-zhan') }}</pd-button>
                            <pd-button size="small" type="primary" v-if="row.dataJobId" @click="handleJobDetail(row)">{{ $t('cha-kan-ren-wu') }}</pd-button>
                        </div>
                    </template>
                </Table>
                <div class="page-footer-container">
                    <div class="page-footer-paging">
                        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                          <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
                    </div>
                </div>
            </TabPane>
            <TabPane :label="$t('sidecar-yi-chang')" name="SIDECAR_EXCEPTION_EVENT" v-if="role!=='ORG_ADMIN'">
                <Table style="margin-top: 10px;" size="small" border :columns="exceptionColumns" :data="exceptionData">
                    <template slot-scope="{ row }" slot="dataJob">
                        <div><a @click="handleGoDataJob(row)" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.dataJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row }" slot="consoleJob">
                        <div><a @click="handleGoConsoleJob" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.consoleJobId}}</a></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="rootCause">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('rootCause',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].rootCause)?
                                                           `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;`:''">
                          {{row.rootCause}}</span></div>
                    </template>
                    <template slot-scope="{ row,index }" slot="exceptionInfo">
                        <div style="cursor: pointer"><span @click="handleShowMoreInfo('exceptionInfo',index)"
                                                           :style="!showMore[index]||(showMore[index]&&!showMore[index].exceptionInfo)?
                                                           'display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;':''">
                          {{row.exceptionMsg}}</span></div>
                    </template>
                    <template slot-scope="{ row }" slot="stack">
                        <div><pd-button size="small" type="primary" @click="showMoreInfo(row)" style="margin-right: 5px">{{ $t('cha-kan-yi-chang-dui-zhan') }}</pd-button></div>
                    </template>
                </Table>
                <!--                        <Page :total="total" show-total style="margin-top: 16px" @on-change="handlePageChange" @on-page-size-change="handlePageSizeChange"/>-->
                <div class="page-footer-container">
                    <div class="page-footer-paging">
                        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px"
                                   @click="handleNext"><Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
                    </div>
                </div>
            </TabPane>
        </Tabs>
        <Modal
                v-model="showExceptionStack"
                :title="$t('yi-chang-zhan')"
                width="1200px"
                style="max-height: 450px"
        >
            <div style="max-height: 500px;overflow: auto">
                <pre>{{selectedRow.exceptionStack}}</pre>
            </div>
        </Modal>
    </div>
</template>
<script>
import fecha from 'fecha';
import { getExceptionList } from '@/services/cc/api/exception';
import { queryJobById } from '@/services/cc/api/noError';
import { mapState } from 'vuex';

export default {
  mounted() {
    if (localStorage.getItem('exception_ids')) {
      const dataJob = JSON.parse(localStorage.getItem('exception_ids'));

      this.searchInfo.dataJobNameLike = dataJob.dataJobName;

      localStorage.removeItem('exception_ids');
    }
    this.getExceptionList();
  },
  computed: {
    ...mapState({
      role: (state) => state.userRole
    })
  },
  data() {
    return {
      refreshLoading: false,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      startId: 0,
      noMoreData: false,
      showExceptionStack: false,
      selectedRow: {},
      page: 1,
      size: 20,
      total: 0,
      searchInfo: {
        dataJobNameLike: '',
        dataJobDescLike: '',
        eventType: '',
        taskName: 'all',
        dataJobId: null,
        dataRange: [fecha.format(new Date(new Date().getTime() - 3600 * 1000 * 24), 'YYYY-MM-DD HH:mm:ss'), fecha.format(new Date(), 'YYYY-MM-DD HH:mm:ss')]
      },
      dateRangeOptions: {
        shortcuts: [
          {
            text: '1 hour',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000);
              return [start, end];
            }
          },
          {
            text: '2 hour',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 2 * 3600 * 1000);
              return [start, end];
            }
          },
          {
            text: '3 hour',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3 * 3600 * 1000);
              return [start, end];
            }
          },
          {
            text: '1 day',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24);
              return [start, end];
            }
          },
          {
            text: '1 week',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          }
        ]
      },
      exceptionColumns: [
        {
          title: this.$t('lei-xing'),
          key: 'eventType',
          width: 260
        },
        {
          title: this.$t('ip-di-zhi'),
          key: 'ip',
          width: 150
        },
        {
          title: this.$t('fa-sheng-shi-jian'),
          key: 'gmtCreate',
          width: 190,
          sortable: true,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('guan-lian-ren-wu'),
          key: 'dataJobName',
          minWidth: 180
        },
        {
          title: this.$t('ren-wu-miao-shu'),
          key: 'dataJobDesc',
          minWidth: 150
        },
        {
          title: this.$t('yi-chang-lei'),
          key: 'exceptionClass',
          minWidth: 200
        },
        {
          title: this.$t('yi-chang-fang-fa'),
          key: 'exceptionMethod',
          minWidth: 200
        },
        {
          title: this.$t('yi-chang-deng-ji'),
          key: 'alarmLevel',
          width: 120,
          renderHeader: (h) => h('div', [
            h('span', {}, this.$t('yi-chang-deng-ji')),
            h('Tooltip', {
              props: {
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('a',
                {
                  style: {
                    color: '#333'
                  }
                }, [
                  h('icon', {
                    props: {
                      type: 'md-help-circle'
                    },
                    style: {
                      marginLeft: '5px'
                    }
                  })
                ]),
              h('div', {
                slot: 'content'
              }, [
                h('p', {}, this.$t('major-feng-xian-deng-ji-jiao-di-bu-ying-xiang-zhu-gong-neng-kong-zhi-tai-shang-tiao-yong-hou-tai-jie-kou-shi-bai-shi-hui-jin-hang-yi-chang-ji-lu-yi-bian-hou-xu-gai-jin-you-hua')),
                h('p', {}, this.$t('critical-feng-xian-deng-ji-jiao-gao-qing-ji-shi-que-ren-yi-chang-xin-xi-bing-jin-hang-chu-li'))
              ])
            ])
          ]),
          render: (h, params) => h('div', {
            style: {
              color: params.row.alarmLevel === 'Critical' ? '#ed4014' : ''
            }
          }, params.row.alarmLevel)
        },
        {
          title: this.$t('cao-zuo'),
          key: 'exceptionStack',
          width: 250,
          slot: 'stack'
        }
      ],
      exceptionData: [

      ],
      showMore: []
    };
  },
  methods: {
    handleDetail() {
      this.$router.push({
        path: '/monitor/exception/1'
      });
    },
    getExceptionList(type) {
      if (type !== 'next' && type !== 'prev') {
        this.page = 1;
        this.startId = 0;
        this.firstId = 0;
        this.lastId = 0;
        this.prevFirst = [];
      }
      this.refreshLoading = true;
      getExceptionList({
        startId: this.startId,
        eventType: this.searchInfo.eventType,
        pageSize: this.size,
        leftTimeMillis: new Date(this.searchInfo.dataRange[0]).getTime(),
        rightTimeMillis: new Date(this.searchInfo.dataRange[1]).getTime(),
        dataJobNameLike: this.searchInfo.dataJobNameLike,
        dataJobDescLike: this.searchInfo.dataJobDescLike,
        dataJobId: this.searchInfo.dataJobId
      }).then((res) => {
        this.refreshLoading = false;
        this.exceptionData = res.data.data.exceptionList;
        this.total = res.data.data.totalCount;
        if (type === 'next') {
          if (!this.prevFirst[this.page - 1]) {
            this.prevFirst.push(this.firstId);
          }
        }
        if (this.exceptionData.length > 0) {
          this.firstId = this.exceptionData[0].id;
          this.lastId = this.exceptionData[this.exceptionData.length - 1].id;
        }
        this.noMoreData = this.exceptionData.length < this.size;
      });
    },
    showMoreInfo(row) {
      this.selectedRow = row;
      this.showExceptionStack = true;
    },
    handleGoDataJob(row) {
      this.$router.push({
        path: `/data/job/${row.dataJobId}`
      });
    },
    handleGoConsoleJob() {
      this.$router.push({
        path: '/ccsystem/state/task'
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getExceptionList();
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.getExceptionList();
    },
    handleShowMoreInfo(type, index) {
      if (!this.showMore[index]) {
        this.showMore[index] = {
          rootCause: false,
          exceptionInfo: false
        };
      }
      this.showMore[index][type] = !this.showMore[index][type];
      this.showMore = { ...this.showMore };
    },
    handleTabChange(name) {
      if (name === 'all') {
        this.searchInfo.eventType = '';
      } else {
        this.searchInfo.eventType = name;
      }
      this.page = 1;
      this.getExceptionList();
    },
    handleJobDetail(row) {
      queryJobById({ jobId: row.dataJobId }).then((res) => {
        if (res.data.code === '1') {
          this.$router.push({ path: `/data/job/${row.dataJobId}` });
        } else {
          this.$Modal.info({
            title: this.$t('tiao-zhuan-shi-bai'),
            content: this.$t('gai-ren-wu-yi-bu-cun-zai')
          });
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.startId = startId;
      this.getExceptionList('prev');
    },
    handleNext() {
      this.startId = this.lastId;
      this.getExceptionList('next');
      this.page++;
    }
  }
};
</script>
