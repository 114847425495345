<template>
  <a-modal :title="title" v-model="visible" @onOk="onRun(asyncForm)" @cancel="handleCloseModal" >
    <a-input v-model="asyncForm.confirmInfo" type="textarea" :placeholder="$t('qing-shu-ru-cao-zuo-li-you')" v-if="showReasonInput"/>
    <div v-if="!isDesktop">
      <a-form-model :model="asyncForm" v-if="showGhost&&sqlExecType.length">
        <a-form-model-item :label="sql.i18nLabel" v-for="sql in sqlExecType" :key="sql.sqlType">
          <a-select v-model="asyncForm[sql.sqlType]">
            <a-select-option :value="op.execType" v-for="op in sql.options" :key="`${sql.sqlType}_${op.execType}`">{{op.i18nLabel}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div v-else>
        {{ $t('que-ren-yi-bu-zhi-hang-xuan-ze-de-cao-zuo') }}
      </div>
    </div>
    <div v-else>
      <input type="file" webkitdirectory directory @change="handleChooseFileSaveLocation"/>
      <a-button @click="handleChooseFileSaveLocation">{{ $t('xuan-ze-wen-jian-bao-cun-di-zhi') }}</a-button>
    </div>
    <div class="footer">
      <a-button type="primary" @click="handleSave">{{ $t('ti-jiao') }}</a-button>
      <a-button @click="handleCloseModal">{{ $t('qu-xiao') }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { isMySQL } from '@/const/dataSource';
import { mapGetters } from 'vuex';

export default {
  name: 'AsyncRunModal',
  props: {
    confirmType: String,
    visible: Boolean,
    onRun: Function,
    handleCloseModal: Function,
    title: String,
    type: String,
    dataSourceType: String
  },
  data() {
    return {
      asyncForm: {
        ddl: 'DIRECT',
        noneDdl: 'DIRECT',
        confirmInfo: ''
      },
      sqlExecType: {}
    };
  },
  computed: {
    ...mapGetters([
      'isDesktop'
    ]),
    showReasonInput() {
      return this.type === 'ticket';
    },
    showGhost() {
      return isMySQL(this.dataSourceType) && ((this.type === 'ticket' && this.confirmType === 'CONFIRM_AND_AUTO_EXEC') || this.type === 'console');
    }
  },
  // mounted() {
  //   this.getSQLExecType();
  // },
  methods: {
    async handleChooseFileSaveLocation(e) {
      console.log(e);
    },
    async getSQLExecType() {
      const res = await this.$services.listExportSqExecType({
        data: {
          dataSourceType: this.dataSourceType
        }
      });

      if (res.success) {
        this.sqlExecType = res.data;
        this.sqlExecType.forEach((sql) => {
          if (sql.sqlTypeForView === 'EXPORT_SQL_TYPE_FOR_VIEW_DDL_ALTER') {
            sql.sqlType = 'ddl';
          } else {
            sql.sqlType = 'noneDdl';
          }
        });
      }
    },
    handleSave() {
      this.onRun(this.asyncForm);
      this.handleCloseModal();
    }
  },
  watch: {
    dataSourceType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getSQLExecType();
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>

</style>
