<template>
  <div class="cluster-container" style="padding: 16px;">
    <Breadcrumb style="margin-bottom: 14px;">
      <BreadcrumbItem>{{ type === 'dm' ? $t('cha-xun-ji-qi') : $t('tong-bu-ji-qi') }}</BreadcrumbItem>
    </Breadcrumb>
    <ClusterHeader :handleSearch="handleRefresh"
                   :handleAddCluster="handleAddCluster" :params="searchData"></ClusterHeader>
    <Table border :columns="resourceColumns" :data="showData" size="small"
           style="margin-top: 12px;margin-bottom: 64px">
      <template slot-scope="{ row }" slot="cluster">
        <div><a @click="handleCluster(row)">{{ row.clusterName }}</a></div>
      </template>
      <template slot-scope="{ row }" slot="action">
        <a style="margin-right: 16px" @click="handleCluster(row)">{{ $t('ji-qi-lie-biao') }}
        </a>
        <a v-if="hasManageAuth" style="margin-right: 16px" @click="handleDeleteCluster(row)">{{ $t('shan-chu') }}
        </a>
      </template>
      <template slot="clusterDesc" slot-scope={row}>
        <span style="padding-right: 30px;display: block">{{ row.clusterDesc }}</span>
        <i v-if="hasManageAuth" style="position: absolute;right: 20px;top: 0" @click="handleEditDClusterDesc(row)"
           class="iconfont iconbianji"></i>
      </template>
    </Table>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
              :page-size="size" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal
      v-model="showConfirmDelete"
      :title="$t('shan-chu-ji-qun-que-ren')"
      footer-hide
    >
      <div>
        <p>{{ $t('que-ren-yao-shan-chu-ji-qun-ming-cheng-wei-selectedclusterclustername-de-ji-qun-ma', [selectedCluster.clusterName]) }}</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmDeleteCluster">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showAddCluster"
           :title="isEdit?$t('bian-ji-ji-qun'):$t('xin-zeng-ji-qun')"
           width="800px"
           footer-hide
    >
      <div style="padding: 10px">
        <Form :model="addCluster" label-position="right" :label-width="120">
          <FormItem :label="$t('ji-qun-miao-shu')">
            <Input style="width: 280px" v-model="addCluster.clusterDesc"/>
          </FormItem>
          <FormItem :label="$t('yun-huo-ji-fang-ming-cheng')">
            <RadioGroup v-model="addCluster.cloudOrIdcName" type="button"
                        @on-change="handleChangeCloudOrIdcName">
              <Radio v-for="(cloudOrIdcName) of cloudOrIdcNames"
                     :label="cloudOrIdcName.cloudOrIdcName"
                     :key="cloudOrIdcName.cloudOrIdcName">{{ cloudOrIdcName.i18nName }}
              </Radio>
            </RadioGroup>
          </FormItem>
          <FormItem :label="$t('di-yu-0')">
<!--            <RadioGroup v-model="addCluster.region" type="button">-->
<!--              <Radio v-for="(region) of regions" :label="region.region" :key="region.region"-->
<!--                     :disabled="supportedRegions.indexOf(region.region)===-1">-->
<!--                {{ region.i18nName }}-->
<!--              </Radio>-->
<!--            </RadioGroup>-->
            <Dropdown trigger="click" style="margin-left: 20px" placement="bottom-start" transfer>
              <a href="javascript:void(0)">
                <span class="selected-region">{{getRegionI18n(addCluster.region)}}</span>
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <template #list>
                <div class="region-container">
                  <div v-for="area in regionAreas" :key="area.regionArea">
                    <div class="region-group" v-if="getRegions(area.regionArea).length>0">
                      <h3>{{area.i18nName}}</h3>
                      <div>
                        <RadioGroup v-model="addCluster.region" type="button">
                          <Radio v-for="region in getRegions(area.regionArea)" :key="region.region" :disabled="supportedRegions.indexOf(region.region)===-1" :label="region.region">{{region.i18nName}}</Radio>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmAddCluster">{{ $t('bao-cun') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditDesc"
      :title="$t('xiu-gai-ji-qun-miao-shu')"
      footer-hide
      width="400px"
    >
      <div>
        <p>
          {{ $t('xiu-gai-ji-qun-ming-cheng-wei-selectedrowclustername-de-ji-qun-de-miao-shu-wei', [selectedRow.clusterName]) }}
        </p>
        <Input v-model="clusterDesc" style="width: 280px;margin-top: 20px"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditDesc">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import {
  createCluster,
  deleteCluster,
  listClusterByCondition,
  updateDesc
} from '@/services/cc/api/cluster';
import {
  listCloudOrIdcNames,
  listRegions,
  listSupportedRegions
} from '@/services/cc/api/constant';
import {
  listRegionAreas
} from '@/services/cc/api/rdpConstant';
import { mapState } from 'vuex';
import store from '@/store';
import ClusterHeader from '@/components/function/cluster/ClusterHeader';
import fecha from 'fecha';
import UtilMapping from '../util';

export default {
  components: {
    ClusterHeader
  },
  computed: {
    computedCloudOrIdcNames() {
      return this.cloudOrIdcNames.filter((cloudOrIdcName) => cloudOrIdcName === 'ALIBABA_CLOUD' || cloudOrIdcName === 'SELF_MAINTENANCE');
    },
    getRegions() {
      return (area) => {
        const regionsByArea = [];
        this.regions.forEach((region) => {
          if (region.regionArea === area) {
            regionsByArea.push(region);
          }
        });
        return regionsByArea;
      };
    },
    getRegionI18n() {
      return (theRegion) => {
        let i18nName = '';
        this.regions.forEach((region) => {
          if (region.region === theRegion) {
            i18nName = region.i18nName;
          }
        });
        return i18nName;
      };
    },
    ...mapState(['myAuth']),
    hasManageAuth() {
      if (this.$route.name === 'System_Machine') {
        return this.myAuth.includes('DM_WORKER_MANAGE');
      } else {
        return this.myAuth.includes('CC_WORKER_MANAGE');
      }
    }
  },
  created() {
    if (this.$route.name === 'System_Machine') {
      this.type = 'dm';
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      type: 'cc',
      page: 1,
      size: 20,
      total: 0,
      showEditDesc: false,
      selectedRow: '',
      clusterDesc: '',
      deployMode: '',
      supportedRegions: [],
      UtilMapping,
      store,
      refreshLoading: false,
      isEdit: true,
      showAddCluster: false,
      showConfirmDelete: false,
      selectedCluster: {},
      regions: [],
      regionAreas: [],
      cloudOrIdcNames: [],
      addCluster: {
        clusterName: '',
        region: 'customer',
        cloudOrIdcName: 'SELF_MAINTENANCE'
      },
      searchData: {
        cloudOrIdcName: '',
        clusterDesc: '',
        clusterName: ''
      },
      resourceColumns: [
        {
          title: this.$t('ji-qun-ming-cheng'),
          key: 'clusterName',
          slot: 'cluster',
          width: 300
        },
        {
          title: this.$t('ji-qun-miao-shu'),
          key: 'clusterDesc',
          slot: 'clusterDesc',
          minWidth: 200
        },
        {
          title: this.$t('lei-xing'),
          key: 'cloudOrIdcName',
          width: 240,
          render: (h, params) => h('div', {}, UtilMapping.cloudOrIdcName[params.row.cloudOrIdcName])
        },
        {
          title: this.$t('ji-qi-shu-liang'),
          key: 'workerCount',
          width: 120
        },
        {
          title: this.$t('ke-yong-shu-liang'),
          key: 'runningCount',
          width: 120
        },
        {
          title: this.$t('bu-ke-yong-shu-liang'),
          key: 'abnormalCount',
          width: 120
        },
        {
          title: this.$t('di-yu-0'),
          key: 'region',
          width: 200,
          render: (h, params) => h('div', {}, UtilMapping.region[params.row.region])
        },
        {
          title: this.$t('chuang-jian-ren'),
          key: 'ownerName',
          width: 120
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          key: 'ownerName',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          slot: 'action',
          width: 180,
          fixed: 'right'
        }
      ],
      resourceData: [],
      showData: [],
      pagingData: []
    };
  },
  methods: {
    init() {
      console.log('this.type', this.type);
      if (this.type === 'dm') {
        this.listDmRegions();
        this.listDmRegionAreas();
        this.listDmCloudOrIdcNames();
      } else {
        // queryDeployMode()
        //   .then((res) => {
        //     if (res.data.code === '1') {
        //       this.deployMode = res.data.data;
        //     }
        //   });
        this.listRegions();
        this.listRegionAreas();
        this.listCloudOrIdcNames();
      }
      this.getClusterList(this.searchData);
    },
    handleRefresh(data) {
      this.searchData = data;
      this.getClusterList(data);
    },
    handleCluster(row) {
      if (this.type === 'dm') {
        localStorage.setItem(`dmcluster-${row.id}`, JSON.stringify(row));
        this.$router.push({ path: `/dmmachine/list/${row.id}` });
      } else {
        localStorage.setItem(`cluster-${row.id}`, JSON.stringify(row));
        this.$router.push({ path: `/ccsystem/resource/${row.id}` });
      }
    },
    handleAddCluster() {
      this.isEdit = false;
      if (store.getters.isProductTrail) {
        this.cloudOrIdcNames = [{
          cloudOrIdcName: 'SELF_MAINTENANCE',
          defaultCheck: true,
          i18nName: 'Self Maintenance'
        }];
      }
      this.showAddCluster = true;
    },
    handleDeleteCluster(row) {
      this.selectedCluster = row;
      this.showConfirmDelete = true;
    },
    handleConfirmDeleteCluster() {
      this.showConfirmDelete = false;
      this.selectedCluster.clusterId = this.selectedCluster.id;
      if (this.type === 'dm') {
        this.$services.deleteCluster({ data: this.selectedCluster }).then((res) => {
          if (res.success) {
            this.getClusterList(this.searchData);
            this.$Message.success(this.$t('shan-chu-cheng-gong'));
          }
        });
      } else {
        deleteCluster(this.selectedCluster)
          .then((res) => {
            if (res.data.code === '1') {
              this.getClusterList(this.searchData);
              this.$Message.success(this.$t('shan-chu-cheng-gong'));
            }
          });
      }
    },
    handleEditCluster(row) {
      this.addCluster = _.cloneDeep(row);
      this.isEdit = true;
      this.showAddCluster = true;
    },
    handleConfirmAddCluster() {
      this.showAddCluster = false;
      if (this.type === 'dm') {
        this.$services.createCluster({ data: this.addCluster })
          .then((res) => {
            if (res.success) {
              this.getClusterList(this.searchData);
              this.$Message.success(this.$t('tian-jia-cheng-gong'));
              this.addCluster = {
                clusterName: '',
                region: 'hangzhou',
                cloudOrIdcName: 'ALIBABA_CLOUD'
              };
            }
          });
      } else {
        createCluster(this.addCluster)
          .then((res) => {
            if (res.data.code === '1') {
              this.getClusterList(this.searchData);
              this.$Message.success(this.$t('tian-jia-cheng-gong'));
              this.addCluster = {
                clusterName: '',
                region: 'hangzhou',
                cloudOrIdcName: 'ALIBABA_CLOUD'
              };
            }
          });
      }
    },
    getClusterList(data) {
      this.refreshLoading = true;
      if (this.type === 'dm') {
        this.$services.getClusterList({ data })
          .then((res) => {
            this.resourceData = res.data;
            this.total = this.resourceData.length;
            this.pagingData = _.cloneDeep(this.resourceData);
            this.showData = this.resourceData.slice((this.page - 1) * this.size, this.page * this.size);
            this.refreshLoading = false;
          })
          .catch(() => {
            this.refreshLoading = false;
          });
      } else {
        listClusterByCondition(data).then((res) => {
          this.resourceData = res.data.data;
          this.total = this.resourceData.length;
          this.pagingData = _.cloneDeep(this.resourceData);
          this.showData = this.resourceData.slice((this.page - 1) * this.size, this.page * this.size);
          this.refreshLoading = false;
        })
          .catch(() => {
            this.refreshLoading = false;
          });
      }
    },
    listRegions() {
      listRegions()
        .then((res) => {
          if (res.data.code === '1') {
            this.regions = res.data.data;
            listSupportedRegions({ cloudOrIdcName: this.addCluster.cloudOrIdcName })
              .then((response) => {
                if (res.data.code === '1') {
                  this.supportedRegions = response.data.data.map((region) => region.region);
                }
              });
          }
        });
    },
    listDmRegions() {
      this.$services.listRegions()
        .then((res) => {
          if (res.success) {
            this.regions = res.data;
            this.$services.listSupportedRegions({ data: { cloudOrIdcName: this.addCluster.cloudOrIdcName } })
              .then((response) => {
                if (res.success) {
                  this.supportedRegions = response.data.map((region) => region.region);
                }
              });
          }
        });
    },
    listRegionAreas() {
      listRegionAreas().then((res) => {
        if (res.data.code === '1') {
          this.regionAreas = res.data.data;
        }
      });
    },
    listDmRegionAreas() {
      this.$services.listRegionAreas().then((res) => {
        if (res.success) {
          this.regionAreas = res.data;
        }
      });
    },
    listCloudOrIdcNames() {
      listCloudOrIdcNames()
        .then((res) => {
          if (res.data.code === '1') {
            this.cloudOrIdcNames = res.data.data;
            // if (store.state.globalConfig.product_trial) {
            //   this.cloudOrIdcNames = ['SELF_MAINTENANCE'];
            // }
          }
        });
    },
    listDmCloudOrIdcNames() {
      this.$services.listCloudOrIdcNames()
        .then((res) => {
          if (res.success) {
            this.cloudOrIdcNames = res.data;
            // if (store.state.globalConfig.product_trial) {
            //   this.cloudOrIdcNames = ['SELF_MAINTENANCE'];
            // }
          }
        });
    },
    handleCancel() {
      this.showAddCluster = false;
      this.addCluster = {
        clusterName: '',
        region: 'hangzhou',
        cloudOrIdcName: 'ALIBABA_CLOUD'
      };
      this.showConfirmDelete = false;
    },
    handleChangeCloudOrIdcName(data) {
      this.listRegions();
      if (data === 'SELF_MAINTENANCE') {
        this.addCluster.region = 'customer';
      } else {
        this.addCluster.region = 'hangzhou';
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.resourceData.slice((this.page - 1) * this.size, this.page * this.size);
      this.showData.map((item) => {
        item.showEditDesc = false;
        return null;
      });
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    handleEditDClusterDesc(row) {
      this.clusterDesc = row.clusterDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handleConfirmEditDesc() {
      this.showEditDesc = false;
      updateDesc({
        clusterId: this.selectedRow.id,
        clusterName: this.selectedRow.clusterName,
        region: this.selectedRow.region,
        cloudOrIdcName: this.selectedRow.cloudOrIdcName,
        clusterDesc: this.clusterDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.getClusterList(this.searchData);
            this.$Message.success(this.$t('xiu-gai-cheng-gong'));
            this.addCluster = {
              clusterName: '',
              region: 'hangzhou',
              cloudOrIdcName: 'ALIBABA_CLOUD'
            };
          }
        });
    },
    handleCancelEdit() {
      this.showEditDesc = false;
    }
  },
  watch: {
    $route(val) {
      if (val.name === 'System_Machine') {
        this.type = 'dm';
      } else {
        this.type = 'cc';
      }
      this.init();
    }
  }
};
</script>
<style lang="less">
.cluster-container {
  .iconfont {
    font-size: 12px;
  }
}

.resource-manager-wrapper {
  padding: 24px;
  background: #ffffff;
  margin-top: 16px;
}

.iconbianji {
  color: #8D95A6;
  cursor: pointer;
}
.selected-region {
  color: #333333;
  padding-right: 16px;
}
.region-container {
  padding: 20px;
  max-height: 500px;
  width: 1000px;
  overflow: auto;

  .region-group {
    margin-bottom: 20px;
    h3 {
      margin-bottom: 6px;
    }

    .ivu-radio-group-item {
      width: 180px;
      text-align: center;
      margin: 4px;
      height: 36px;
      line-height: 34px;
    }
  }

  .region-btn {
    width: 100%;
  }
}
</style>
