<template>
  <div>
    <div v-if="showParent" style="position: relative">
      <Card
        :class="getStatusIcon==='icon-yichang'?'job-info-card job-info-card-error':'job-info-card'"
        style="margin-bottom: 5px">
        <span class="group-master">{{ $t('zhu-ren-wu') }}</span>
        <div>
          <div class="float-left">
            <div>
              <span class="status-point"
                    :style="`vertical-align: middle;background:${getStatusColor}`"></span>
              <a class="job-info-title"
                 :href="`/#/data/job/${dataJobInfo.dataJobId}`">{{ dataJobInfo.dataJobName }}</a>
              <!--                            <Tooltip placement="bottom" :content="getStatusName">-->
              <!--                                <svg class="icon job-info-status job-info-status-running" aria-hidden="true">-->
              <!--                                    <use :xlink:href="'#'+getStatusIcon"></use>-->
              <!--                                </svg>-->
              <!--                            </Tooltip>-->
              <Tooltip placement="bottom" class="freeze-icon" transfer :content="$t('zhang-hu-yuebu-zu-ren-wu-bei-dong-jie')"
                       v-if="dataJobInfo.lifeCycleState==='LOCKED'&&dataJobInfo.consoleTaskState!=='FAILED'">
                <span style="display: block" @click="handleGoUserCenter"><i
                  class="iconfont iconfreeze"></i></span>
              </Tooltip>
              <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                       v-if="dataJobInfo.consoleTaskState==='FAILED'">
                <span style="display: block" @click="handleGoConsoleJob"><i
                  class="iconfont iconyibuforce"></i></span>
              </Tooltip>
              <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('gai-ren-wu-cun-zai-yi-chang-dian-ji-cha-kan-xiang-qing')"
                       v-if="dataJobInfo.haveException">
                <span style="display: block" @click="handleGoExceptionLog"><i
                  class="iconfont iconyibuforce"></i></span>
              </Tooltip>
              <Tooltip class="job-info-datasource-group"
                       :content="Mapping.statusIcon[getStatusIcon]" placement="bottom" transfer>
                    <span class="job-info-source job-info-datasource-icon">
                        <DataSourceIcon
                          :type='dataJobInfo.sourceDsVO?dataJobInfo.sourceDsVO.dataSourceType:""'
                          :instanceType='dataJobInfo.sourceDsVO?dataJobInfo.sourceDsVO.deployType:""'></DataSourceIcon>
                    </span>
                <span class="job-info-sink job-info-datasource-icon">
                        <DataSourceIcon
                          :type='dataJobInfo.targetDsVO?dataJobInfo.targetDsVO.dataSourceType:""'
                          :instanceType='dataJobInfo.targetDsVO?dataJobInfo.targetDsVO.deployType:""'></DataSourceIcon>
                    </span>
                <div slot="content">
                  <span>{{
                      dataJobInfo.sourceDsVO ? dataJobInfo.sourceDsVO.dataSourceType : ''
                    }}</span>
                  <Icon type="md-arrow-forward"/>
                  <span>{{
                      dataJobInfo.targetDsVO ? dataJobInfo.targetDsVO.dataSourceType : ''
                    }}</span>
                </div>
              </Tooltip>
            </div>
            <div class="job-info-item-container">
              <p class="job-info-item">
                <span
                  class="job-header-name-desc">{{ Mapping.jobType[dataJobInfo.dataJobType] }}
                  <Tooltip transfer v-if="dataJobInfo.havePackage" :content="$t('cun-zai-zi-ding-yi-dai-ma-chu-li-de-ren-wu')" placement="right">
                    <svg style="font-size: 14px;margin-left: 6px" class="icon job-info-status job-info-status-running" aria-hidden="true">
                      <use xlink:href="#icon-zidingyishujuchuli"></use>
                    </svg>
                  </Tooltip>
                </span>
                <span
                  class="job-info-item-value">{{
                    dataJobInfo.dataJobDesc ? dataJobInfo.dataJobDesc : $t('zan-wu-miao-shu')
                  }}</span>
                <span class="job-info-desc-edit">
                        <Poptip :title="$t('xiu-gai-ren-wu-miao-shu')" placement="bottom" v-model="showEdit">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-bianji"></use>
                            </svg>
              <div slot="content">
                <Input v-model="dataJobDesc" maxlength="100" show-word-limit type="textarea"
                       :placeholder="$t('qing-shu-ru-ren-wu-miao-shu')" style="width: 250px"/>
                <div class="button-group">
                  <pd-button type="default" @click="handleEditCancel">{{ $t('qu-xiao') }}</pd-button>
                  <pd-button type="primary" @click="handleEditConfirm">{{ $t('bao-cun') }}</pd-button>
                </div>
              </div>
              </Poptip>
              </span>
              <span style="margin-left: 35px" class="job-info-item-key">{{ $t('chuang-jian-ren-shi-jian') }}</span>
              <span
                class="job-info-item-value">{{
                  dataJobInfo.username
                }}/{{
                  dataJobInfo.gmtCreated ? fecha.format(new Date(dataJobInfo.gmtCreated), 'YYYY-MM-DD HH:mm:ss') : ''
                }}</span>
              </p>
            </div>
          </div>
          <div class="job-info-progress-container job-info-progress-container-margin">
            <Row :gutter="10">
              <Col
                :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']">
                <Progress
                  :stroke-color="getStatusName!==$t('zheng-chang')&&getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus)"
                  :percent="parseInt(Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].taskPosition).builtPercent)*100, 10)"
                  :stroke-width="20" text-inside/>
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='wrong'?
                                'job-info-progress-error':'')}`">{{ $t('jie-gou-qian-yi') }}</p>
                <p class="job-info-progress-content">
                  {{
                    JSON.parse(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].taskPosition).built
                  }}
                  /
                  {{
                    JSON.parse(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].taskPosition).totalToBuild
                  }}</p>
              </Col>
              <Col
                :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['FULL']">
                <Progress
                  :stroke-color="getStatusName!==$t('zheng-chang')&&getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus)"
                  :percent="Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).transPercent)"
                  :stroke-width="20" text-inside/>
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ dataJobInfo.dataJobType === 'SYNC' ? $t('shu-ju-chu-shi-hua') : $t('quan-liang-qian-yi-0') }}</p>
                <p class="job-info-progress-content">
                  {{ $t('formatcountjsonparsegetdatataskdatajobinfodatatasksfulltaskpositiontrans-hang', [formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).trans)]) }}</p>
                <p class="cron-job-next"
                   v-if="dataJobInfo.dataTaskState!=='COMPLETE'&&getDataTask(dataJobInfo.dataTasks).FULL&&getDataTask(dataJobInfo.dataTasks).FULL.nextFireTime">
                  {{ $t('xia-ci-zhi-hang-getdatataskdatajobinfodatatasksfullnextfiretime', [getDataTask(dataJobInfo.dataTasks).FULL.nextFireTime]) }}</p>
              </Col>
                            <Col :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT']">
                <Progress
                  :stroke-color="getStatusName!==$t('zheng-chang')&&getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus)"
                  :percent="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'?0:99.99"
                  :stroke-width="20" hide-info/>
                <p class="job-info-incre-status">
                  {{
                    Mapping.taskStatus[getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus]
                  }}
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ dataJobInfo.dataJobType === 'SYNC' ? $t('shu-ju-tong-bu') : $t('zeng-liang-tong-bu-1') }}</p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus!=='INIT'">{{ $t('yan-chi') }}
                  <span :style="getDelayColor(JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis<=60000?
                                    getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs:JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis)"
                        v-text="formatSeconds(JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis <= 60000 ?
                        getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs === -1 ?
                          0.001 : getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs / 1000 :
                        JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis / 1000)"/>
                </p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'">
                  &nbsp;&nbsp;</p>
              </Col>
              <Col
                :span="24/dataJobInfo.dataTasks.length"
                v-if="getDataTask(dataJobInfo.dataTasks)['CHECK']">
                <Progress
                  :stroke-color="getStatusName!==$t('zheng-chang')&&getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus)"
                  :percent="Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).checkedPercent)"
                  :stroke-width="20" text-inside/>
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ $t('shu-ju-xiao-yan-0') }}</p>
                <p class="job-info-progress-content">
                  {{ $t('xiao-yan') }} <span
                  style="color:rgb(25, 190, 107)">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).checked)
                  }}</span>
                  {{ $t('diu-shi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).loss)
                  }}</span>
                  {{ $t('bu-yi-zhi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).diff)
                  }}</span>
                  <div>
                <a @click="handleShowHistory(dataJobInfo,'CHECK')"
                   v-if="JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).resultHistory">{{ $t('xiao-yan-li-shi') }}</a>
                <a @click="handleShowHistory(dataJobInfo,'FULL')"
                   v-if="getDataTask(row.dataTasks)['FULL']&&JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).resultHistory">{{ $t('cha-kan-li-shi') }}</a>
              </div>
                </p>
                <p class="cron-job-next"
                   v-if="dataJobInfo.dataTaskState!=='COMPLETE'&&getDataTask(dataJobInfo.dataTasks).CHECK&&getDataTask(dataJobInfo.dataTasks).CHECK.nextFireTime">
                  {{ $t('xia-ci-zhi-hang') }}{{ getDataTask(dataJobInfo.dataTasks).CHECK.nextFireTime }}</p>
              </Col>
              <Col
                :span="24/dataJobInfo.dataTasks.length"
                v-if="getDataTask(dataJobInfo.dataTasks)['REVISE']">
                <Progress :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                          :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus)"
                          :percent="Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).transPercent)" :stroke-width="20" text-inside />
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ $t('shu-ju-ding-zheng-0') }}</p>
                <p class="job-info-progress-content">{{ $t('formatcountjsonparsegetdatataskdatajobinfodatatasksfulltaskpositiontrans-hang', [formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).trans)]) }}
                  <div>
                <a @click="handleShowHistory(dataJobInfo,'REVISE')"
                   v-if="JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).resultHistory">{{ $t('ding-zheng-li-shi') }}</a>
              </div></p>
              </Col>
            </Row>
          </div>
        </div>
        <div class="job-info-btns">
            <span @click="handleDetail">
                <i class="iconfont iconbianji1"></i>
                <span>{{ $t('xiang-qing') }}</span>
            </span>
          <span @click="handleStartFsm(dataJobInfo)"
                v-if="getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']?(!dataJobInfo.fsmActive&&
                          dataJobInfo.dataTaskState==='INIT'&&
                          (getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']&&
                          getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE')):
                          (!dataJobInfo.fsmActive&&
                          dataJobInfo.dataTaskState==='INIT')">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('qi-dong') }}</span>
            </span>
          <span @click="handleStart(dataJobInfo)"
                v-if="(getDataTask(dataJobInfo.dataTasks)['FULL'] &&
                           getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus === 'STOP'||
                            getDataTask(dataJobInfo.dataTasks)['INCREMENT'] &&
                             getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus === 'STOP' ||
                             getDataTask(dataJobInfo.dataTasks)['CHECK'] &&
                              getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus === 'STOP')">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('qi-dong') }}</span>
            </span>
          <span @click="handleRestart(dataJobInfo)"
                v-if="(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont iconchongqi"></i>
                <span>{{ $t('zhong-qi') }}</span>
            </span>
          <span @click="handleStop(dataJobInfo)"
                v-if="(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('ting-zhi') }}</span>
            </span>
          <span @click="handleStartTimeScheduleJob(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('li-ji-zhi-hang') }}</span>
            </span>
          <span @click="handleStopSchedule(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('zan-ting-tiao-du') }}</span>
            </span>
          <span @click="handleResumSchedule(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE_PAUSE'">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('hui-fu-tiao-du') }}</span>
            </span>
          <span @click="handleDelete(dataJobInfo)"
                v-if="!(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont icondel"></i>
                <span>{{ $t('shan-chu') }}</span>
            </span>
        </div>
      </Card>
    </div>
    <div :class="dataJobInfo.children&&dataJobInfo.children.length>0?'job-info-card-parent':''"
         v-if="!showParent">
      <Card
        :class="getStatusIcon==='icon-yichang'?'job-info-card job-info-card-error':'job-info-card'">
        <div>
          <Button v-if="dataJobInfo.children&&dataJobInfo.children.length>0"
                  class="go-task-group-btn" @click="handleShowGroup(true,dataJobInfo)">{{ $t('cha-kan-guan-lian-ren-wu') }}
          </Button>
          <p>
            <span class="status-point"
                  :style="`vertical-align: middle;background:${getStatusColor}`"></span>
            <a class="job-info-title"
               :href="`/#/data/job/${dataJobInfo.dataJobId}`">{{ dataJobInfo.dataJobName }}</a>
            <!--                        <Tooltip placement="bottom" :content="getStatusName">-->
            <!--                            <svg class="icon job-info-status job-info-status-running" aria-hidden="true">-->
            <!--                                <use :xlink:href="'#'+getStatusIcon"></use>-->
            <!--                            </svg>-->
            <!--                        </Tooltip>-->
            <Tooltip placement="bottom" class="freeze-icon" transfer :content="$t('zhang-hu-yuebu-zu-ren-wu-bei-dong-jie')"
                     v-if="dataJobInfo.lifeCycleState==='LOCKED'&&dataJobInfo.consoleTaskState!=='FAILED'">
              <span style="display: block" @click="handleGoUserCenter"><i
                class="iconfont iconfreeze"></i></span>
            </Tooltip>
            <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                     v-if="dataJobInfo.consoleTaskState==='FAILED'">
              <span style="display: block" @click="handleGoConsoleJob"><i
                class="iconfont iconyibuforce"></i></span>
            </Tooltip>
            <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('gai-ren-wu-cun-zai-yi-chang-dian-ji-cha-kan-xiang-qing')"
                     v-if="dataJobInfo.haveException">
              <span style="display: block" @click="handleGoExceptionLog"><i
                class="iconfont iconyibuforce"></i></span>
            </Tooltip>
            <Tooltip class="job-info-datasource-icon-container"
                     :content="Mapping.statusIcon[getStatusIcon]" placement="bottom" transfer>
                    <span class="job-info-source job-info-datasource-icon" v-if="!subJob">
                        <DataSourceIcon :type='dataJobInfo.sourceDsVO.dataSourceType'
                                        :instanceType='dataJobInfo.sourceDsVO.deployType'></DataSourceIcon>
                    </span>
              <span class="job-info-sink job-info-datasource-icon" v-if="!subJob">
                        <DataSourceIcon :type='dataJobInfo.targetDsVO.dataSourceType'
                                        :instanceType='dataJobInfo.targetDsVO.deployType'></DataSourceIcon>
                    </span>
          <div slot="content">
            <span>{{ dataJobInfo.sourceDsVO.dataSourceType }}</span>
            <Icon type="md-arrow-forward"/>
            <span>{{ dataJobInfo.targetDsVO.dataSourceType }}</span>
          </div>
          </Tooltip>
          </p>
          <div class="job-info-item-container">
            <p class="job-info-item">
              <span class="job-info-item-key">{{ $t('lei-xing') }}</span>
              <span
                class="job-info-item-value">{{ Mapping.jobType[dataJobInfo.dataJobType] }}
                <Tooltip transfer v-if="dataJobInfo.havePackage" :content="$t('cun-zai-zi-ding-yi-dai-ma-chu-li-de-ren-wu')" placement="right">
                    <svg style="font-size: 14px;margin-left: 6px" class="icon job-info-status job-info-status-running" aria-hidden="true">
                      <use xlink:href="#icon-zidingyishujuchuli"></use>
                    </svg>
                  </Tooltip>
              </span>
            </p>
            <p class="job-info-item">
              <span class="job-info-item-key">{{ $t('ren-wu-miao-shu') }}</span>
              <Tooltip :content="dataJobInfo.dataJobDesc?dataJobInfo.dataJobDesc:$t('zan-wu-miao-shu')"
                       placement="bottom">
                <span
                  class="job-info-item-value">{{
                    dataJobInfo.dataJobDesc ? dataJobInfo.dataJobDesc : $t('zan-wu-miao-shu')
                  }}</span>
              </Tooltip>
              <span class="job-info-desc-edit">
                        <Poptip :title="$t('xiu-gai-ren-wu-miao-shu')" placement="bottom" v-model="showEdit" v-if="!subJob">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-bianji"></use>
                            </svg>
            <div slot="content">
              <Input v-model="dataJobDesc" maxlength="100" show-word-limit type="textarea"
                     :placeholder="$t('qing-shu-ru-ren-wu-miao-shu')" style="width: 250px"/>
              <div class="button-group">
                <pd-button type="default" @click="handleEditCancel">{{ $t('qu-xiao') }}</pd-button>
                <pd-button type="primary" @click="handleEditConfirm">{{ $t('bao-cun') }}</pd-button>
              </div>
            </div>
            </Poptip>
            </span>
            </p>
            <p class="job-info-item">
              <span class="job-info-item-key">{{ $t('chuang-jian-ren-shi-jian') }}</span>
              <span
                class="job-info-item-value">{{
                  dataJobInfo.username
                }}/{{
                  dataJobInfo.gmtCreated ? fecha.format(new Date(dataJobInfo.gmtCreated), 'YYYY-MM-DD HH:mm:ss') : ''
                }}</span>
            </p>
          </div>
          <div class="job-info-progress-container">
            <Row :gutter="10">
              <Col
                :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']">
                <Progress
                  :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus,getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].healthLevel)"
                  :percent="parseInt(Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].taskPosition).builtPercent)*100, 10)"
                  :stroke-width="20" text-inside/>
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='wrong'?
                                'job-info-progress-error':'')}`">{{ $t('jie-gou-qian-yi') }}</p>
                <p class="job-info-progress-content">
                  {{ $t('ben-ci-yi-qian-yi-jsonparsegetdatataskdatajobinfodatatasksbuildstructtaskpositionbuilt-zhang-biao', [JSON.parse(getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].taskPosition).built]) }}</p>
              </Col>
              <Col
                :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['FULL']">
                <Progress
                  :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus,getDataTask(dataJobInfo.dataTasks)['FULL'].healthLevel)"
                  :percent="Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).transPercent)"
                  :stroke-width="20" text-inside/>
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus==='wrong'?
                                'job-info-progress-error':'')}`">
                  {{ dataJobInfo.dataJobType === 'SYNC' ? $t('shu-ju-chu-shi-hua') : $t('quan-liang-qian-yi-0') }}
                  <Tooltip transfer placement="right"
                           :content="$t('xia-ci-zhi-hang-getdatataskdatajobinfodatatasksfullnextfiretime', [getDataTask(dataJobInfo.dataTasks).FULL.nextFireTime])"
                           v-if="dataJobInfo.dataTaskState!=='COMPLETE'&&getDataTask(dataJobInfo.dataTasks).FULL&&getDataTask(dataJobInfo.dataTasks).FULL.nextFireTime"
                  >
                    <Icon type="ios-clock-outline" />
                  </Tooltip>
                </p>
                <p class="job-info-progress-content">
                  {{ $t('formatcountjsonparsegetdatataskdatajobinfodatatasksfulltaskpositiontrans-hang', [formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).trans)]) }}</p>
              </Col>
                            <Col :span="getDataTask(dataJobInfo.dataTasks)['CHECK']?(24/(dataJobInfo.dataTasks.length-1)):(24/dataJobInfo.dataTasks.length)"
                v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT']">
                <Progress
                  :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus,getDataTask(dataJobInfo.dataTasks)['INCREMENT'].healthLevel)"
                  :percent="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'?0:99.99"
                  :stroke-width="20" hide-info/>
                <p class="job-info-incre-status">
                  {{
                    Mapping.taskStatus[getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus]
                  }}
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='wrong'?
                                'job-info-progress-error':'')}`">
                  {{ dataJobInfo.dataJobType === 'SYNC' ? $t('shu-ju-tong-bu') : $t('zeng-liang-tong-bu-1') }}</p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus!=='INIT'">{{ $t('yan-chi') }}
                  <span :style="getDelayColor(JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis<=60000?
                                    getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs:JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis)"
                        v-text="formatSeconds(JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis <= 60000 ?
                        getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs === -1 ?
                          0.001 : getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataDelayMs /
                          1000 : JSON.parse(getDataTask(dataJobInfo.dataTasks)['INCREMENT'].taskPosition).delayMillis / 1000)"
                  />
                </p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'">
                  &nbsp;&nbsp;</p>
              </Col>
              <Col
                :span="24/dataJobInfo.dataTasks.length"
                v-if="getDataTask(dataJobInfo.dataTasks)['CHECK']">
                <Progress
                  :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus,getDataTask(dataJobInfo.dataTasks)['CHECK'].healthLevel)"
                  :percent="Number(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).checkedPercent)"
                  :stroke-width="20" text-inside/>
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='wrong'?
                                'job-info-progress-error':'')}`">{{ $t('shu-ju-xiao-yan-0') }}
                  <Tooltip transfer placement="right"
                           :content="$t('xia-ci-zhi-hang-getdatataskdatajobinfodatatasksfullnextfiretime', [getDataTask(dataJobInfo.dataTasks).CHECK.nextFireTime])"
                           v-if="dataJobInfo.dataTaskState!=='COMPLETE'&&getDataTask(dataJobInfo.dataTasks).CHECK&&getDataTask(dataJobInfo.dataTasks).CHECK.nextFireTime"
                  >
                    <Icon type="ios-clock-outline" />
                  </Tooltip>
                </p>
                <p class="job-info-progress-content">
                  {{ $t('xiao-yan') }} <span
                  style="color:rgb(25, 190, 107)">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).checked)
                  }}</span>
                  {{ $t('diu-shi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).loss)
                  }}</span>
                  {{ $t('bu-yi-zhi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).diff)
                  }}</span>
                  <div>
                <a @click="handleShowHistory(dataJobInfo,'CHECK')"
                   v-if="JSON.parse(getDataTask(dataJobInfo.dataTasks)['CHECK'].taskPosition).resultHistory">{{ $t('xiao-yan-li-shi') }}</a>
                <a @click="handleShowHistory(dataJobInfo,'FULL')"
                   v-if="getDataTask(row.dataTasks)['FULL']&&JSON.parse(getDataTask(dataJobInfo.dataTasks)['FULL'].taskPosition).resultHistory">{{ $t('cha-kan-li-shi') }}</a>
              </div>
                </p>
              </Col>
              <Col
                :span="24/dataJobInfo.dataTasks.length"
                v-if="getDataTask(dataJobInfo.dataTasks)['REVISE']">
                <Progress :stroke-color="getStatusName!=='正常'&&getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                          :status="getProgressStatus(getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus)"
                          :percent="Number(JSON.parse(JSON.parse(getDataTask(dataJobInfo.dataTasks).REVISE.taskPosition).revisePosDetail).percentage)" :stroke-width="20" text-inside />
                <p :class="`job-info-progress-title ${getProgressStatus(getDataTask(dataJobInfo.dataTasks)['REVISE'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ $t('shu-ju-ding-zheng-0') }}</p>
                <p style="text-align: center;width: 100%;white-space:nowrap;">
                  {{ $t('ding-zheng-diu-shi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).revisePosDetail).revisedLossCount)
                  }}</span>
                  {{ $t('ding-zheng-bu-yi-zhi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).revisePosDetail).revisedDiffCount)
                  }}</span>
                  <div>
                <a @click="handleShowHistory(dataJobInfo,'REVISE')"
                   v-if="JSON.parse(getDataTask(dataJobInfo.dataTasks)['REVISE'].taskPosition).resultHistory">{{ $t('ding-zheng-li-shi') }}</a>
              </div></p>
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div class="job-info-footer">
            <span @click="handleDetail">
                <i class="iconfont iconbianji1"></i>
                <span>{{ $t('xiang-qing') }}</span>
            </span>
          <span @click="handleStartFsm(dataJobInfo)"
                v-if="getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']?
                          (!dataJobInfo.fsmActive&&dataJobInfo.dataTaskState==='INIT'&&
                          (getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT']&&
                          getDataTask(dataJobInfo.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE')):
                          (!dataJobInfo.fsmActive&&dataJobInfo.dataTaskState==='INIT')">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('qi-dong') }}</span>
            </span>
          <span @click="handleStart(dataJobInfo)"
                v-if="(getDataTask(dataJobInfo.dataTasks)['FULL'] &&
                           getDataTask(dataJobInfo.dataTasks)['FULL'].dataTaskStatus === 'STOP'||
                            getDataTask(dataJobInfo.dataTasks)['INCREMENT'] &&
                             getDataTask(dataJobInfo.dataTasks)['INCREMENT'].dataTaskStatus === 'STOP' ||
                             getDataTask(dataJobInfo.dataTasks)['CHECK'] &&
                              getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus === 'STOP')
                              &&getDataTask(dataJobInfo.dataTasks)['INCREMENT']&&getDataTask(dataJobInfo.dataTasks)['INCREMENT'].workerIp">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('qi-dong') }}</span>
            </span>
          <span @click="handleRestart(dataJobInfo)"
                v-if="(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont iconchongqi"></i>
                <span>{{ $t('zhong-qi') }}</span>
            </span>
          <span @click="handleStop(dataJobInfo)"
                v-if="(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('ting-zhi') }}</span>
            </span>
                  <span @click="handleReplay(dataJobInfo)" v-permission="'dataJob'"
                        v-if="((dataJobInfo.dataJobType === 'CHECK' || dataJobInfo.dataJobType === 'REVISE') && (dataJobInfo.currentStatus === 'STOP' || dataJobInfo.dataTaskState === 'COMPLETE') ||
                        ((getDataTask(dataJobInfo.dataTasks).FULL
                        &&(dataJobInfo.dataTaskState==='COMPLETE'
                        ||(dataJobInfo.dataTaskState === 'CATCH_UP'||dataJobInfo.dataTaskState === 'INCRE')&&dataJobInfo.currentStatus === 'STOP')))
                        &&getDataTask(dataJobInfo.dataTasks)['INCREMENT']&&getDataTask(dataJobInfo.dataTasks)['INCREMENT'].workerIp)">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('zhong-pao') }}</span>
            </span>
          <span @click="handleDetachIncreTask(dataJobInfo)" v-permission="'dataJob'"
                v-if="(dataJobInfo.dataTaskState === 'CATCH_UP'||dataJobInfo.dataTaskState === 'INCRE')&&dataJobInfo.currentStatus === 'STOP'
                      &&getDataTask(dataJobInfo.dataTasks)['INCREMENT']&&getDataTask(dataJobInfo.dataTasks)['INCREMENT'].workerIp">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('zhai-chu-ren-wu') }}</span>
            </span>
          <span @click="handleAttachIncreTask(dataJobInfo)" v-permission="'dataJob'"
                v-if="(dataJobInfo.dataTaskState === 'CATCH_UP'||dataJobInfo.dataTaskState === 'INCRE')&&dataJobInfo.currentStatus === 'STOP'
                      &&getDataTask(dataJobInfo.dataTasks)['INCREMENT']&&!getDataTask(dataJobInfo.dataTasks)['INCREMENT'].workerIp">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('gua-zai-ren-wu') }}</span>
            </span>
          <span @click="handleStartTimeScheduleJob(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('li-ji-zhi-hang') }}</span>
            </span>
          <span @click="handleStopSchedule(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'">
                    <i class="iconfont icontingzhi"></i>
                <span>{{ $t('zan-ting-tiao-du') }}</span>
            </span>
          <span @click="handleResumSchedule(dataJobInfo)"
                v-if="dataJobInfo.dataTaskState === 'TIMING_SCHEDULE_PAUSE'">
                    <i class="iconfont iconKAIS"></i>
                <span>{{ $t('hui-fu-tiao-du') }}</span>
            </span>
          <span @click="handleDelete(dataJobInfo)"
                v-if="!(dataJobInfo.currentStatus === 'ABNORMAL'||
                          dataJobInfo.currentStatus === 'RUNNING'||
                          dataJobInfo.currentStatus === 'WAIT_START' ||
                          dataJobInfo.dataTaskState === 'TIMING_SCHEDULE'||
                          (dataJobInfo.dataTaskState==='CHECK'&&
                          (getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                          getDataTask(dataJobInfo.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))">
                    <i class="iconfont icondel"></i>
                <span>{{ $t('shan-chu') }}</span>
            </span>
          <Checkbox class="job-info-check" v-model="selected" @on-change="handleCheck"></Checkbox>
        </div>
      </Card>
      <Card v-if="dataJobInfo.children&&dataJobInfo.children.length>0"
            class="job-info-parent-background job-info-parent-background1"></Card>
      <Card v-if="dataJobInfo.children&&dataJobInfo.children.length>0"
            class="job-info-parent-background job-info-parent-background2"></Card>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import Mapping from '@/views/util';
import DataSourceIcon from '../../DataSourceIcon';
import store from '../../../../store';
import { formatCount, formatSeconds, formatEnCount } from '../../../util';

export default {
  components: { DataSourceIcon },
  props: {
    loadingData: Object,
    dataJobInfo: Object,
    handleRestart: Function,
    handleStartFsm: Function,
    handleStop: Function,
    handleDelete: Function,
    handleStart: Function,
    handleResumSchedule: Function,
    handleStopSchedule: Function,
    handleStartTimeScheduleJob: Function,
    handleConfirmEdit: Function,
    handleBatchEdit: Function,
    getProgressStatus: Function,
    handleShowGroup: Function,
    showParent: Boolean,
    subJob: Boolean,
    handleShowHistory: Function,
    handleReplay: Function,
    handleDetachIncreTask: Function,
    handleAttachIncreTask: Function
  },
  computed: {
    getStatusIcon() {
      const type = Mapping.taskSateToTaskType[this.dataJobInfo.dataTaskState];

      if (type) {
        if (!this.getDataTask(this.dataJobInfo.dataTasks)[type]) {
          return 'icon-chongqi';
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].dataTaskStatus === 'STOP') {
          return 'icon-zanting';
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].healthLevel === 'Health') {
          return 'icon-zhengchang';
        }
        return 'icon-yichang';
      }
      if (this.dataJobInfo.dataTaskState === 'COMPLETE') {
        return 'icon-wancheng';
      }
      return 'icon-zhengchang';
    },
    getStatusName() {
      const type = Mapping.taskSateToTaskType[this.dataJobInfo.dataTaskState];

      if (type) {
        if (!this.getDataTask(this.dataJobInfo.dataTasks)[type]) {
          return this.$t('chu-shi-hua');
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].dataTaskStatus === 'STOP') {
          return this.$t('yi-ting-zhi');
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].healthLevel === 'Health') {
          return this.$t('zheng-chang');
        }
        return this.$t('yi-chang');
      }
      if (this.dataJobInfo.dataTaskState === 'COMPLETE') {
        return this.$t('yi-wan-cheng');
      }
      return this.$t('zheng-chang');
    },
    getDelayColor() {
      return (delay) => {
        // 位点延迟小于1分钟，正常，为绿色，展示数据延迟。1分钟<位点延迟<5分钟，黄色，展示位点延迟。位点延迟>5分钟，异常，为红色，展示位点延迟。
        if (delay <= 60000) {
          return 'color: #19be6b';
        }
        if (delay < 300000) {
          return 'color: #ff9900';
        }
        return 'color: #ed4014';
      };
    },
    getStatusColor() {
      const type = Mapping.taskSateToTaskType[this.dataJobInfo.dataTaskState];

      if (type) {
        if (!this.getDataTask(this.dataJobInfo.dataTasks)[type]) {
          return '#52C41A';
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].dataTaskStatus === 'STOP') {
          return '#FFA30E';
        }
        if (this.getDataTask(this.dataJobInfo.dataTasks)[type].healthLevel === 'Health') {
          return '#52C41A';
        }
        return '#FF1815';
      }
      if (this.dataJobInfo.dataTaskState === 'COMPLETE') {
        return '#999999';
      }
      return '#52C41A';
    }
  },
  mounted() {
    this.dataJobDesc = this.dataJobInfo.dataJobDesc;
  },
  data() {
    return {
      selected: false,
      Mapping,
      fecha,
      store,
      showEdit: false,
      dataJobDesc: '',
      formatCount,
      formatSeconds
    };
  },
  methods: {
    formatData(data) {
      if (this.$i18n.locale === 'en-US') {
        return formatEnCount(data);
      }
      return formatCount(data);
    },
    getDataTask(taskList) {
      const dataTasks = {};

      if (taskList) {
        taskList.forEach((item) => {
          dataTasks[item.dataTaskType] = item;
        });
      }
      return dataTasks;
    },
    handleDetail() {
      this.$router.push({ path: `/data/job/${this.dataJobInfo.dataJobId}` });
    },
    handleEditCancel() {
      this.showEdit = false;
    },
    handleEditConfirm() {
      this.showEdit = false;
      this.handleConfirmEdit(this.dataJobInfo, this.dataJobDesc);
    },
    handleCheck() {
      this.handleBatchEdit(this.dataJobInfo, this.selected);
    },
    handleGoConsoleJob() {
      this.$router.push({ path: `/ccsystem/state/task/${this.dataJobInfo.consoleJobId}` });
    },
    handleGoExceptionLog() {
      localStorage.setItem('exception_ids', JSON.stringify(this.dataJobInfo));

      this.$router.push({ path: '/monitor/exception' });
    },
    handleGoUserCenter() {
      this.$router.push({ path: '/userCenter' });
    }
  }
};
</script>
<style scoped lang="less">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.job-info-card-parent {
  cursor: pointer;
  position: relative;

  .go-task-group-btn {
    display: none;
  }

  &:hover .go-task-group-btn {
    display: block;
    position: absolute;
    right: 11px;
    top: 54px;
  }
}

.job-info-card {
  /*width:336px;*/
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 16px;

  .alarm-icon {
    position: absolute;
    left: 186px;
    top: 18px;
    width: 20px;
    height: 20px;
    display: block;
    /*border-radius: 50%;*/
    /*background-color: #FF6E0D;*/
    color: #FF6E0D;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    .iconyibuforce {
      font-size: 14px;
    }
  }

  .freeze-icon {
    position: absolute;
    left: 186px;
    top: 18px;
    width: 20px;
    height: 20px;
    display: block;
    /*border-radius: 50%;*/
    /*background-color: #FF6E0D;*/
    color: #FF6E0D;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    .iconfreeze {
      font-size: 19px;
    }
  }
}

.job-info-parent-background {
  position: absolute;
  height: 250px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 16px;
  z-index: -1;
}

.job-info-parent-background1 {
  right: -25px;
  top: -10px;
  opacity: 0.8;
}

.job-info-parent-background2 {
  right: -20px;
  top: -5px;
  opacity: 0.6;
}

.job-info-card-error {
  background: #FFF3EF;
}

.job-info-title {
  color: #333;
  font-size: 16px;
  vertical-align: middle;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.job-info-status {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 18px;
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}

.job-info-progress-error {
  color: #FC3F00;
}

.job-info-datasource-icon-container {
  position: absolute;
  right: 17px;
  top: 0;
  cursor: pointer;
}

.job-info-datasource-group {
  position: absolute;
  left: 275px;
  top: -4px;
  cursor: pointer;
}

.job-info-datasource-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #DADADA;
  font-size: 20px;
  /*text-align: center;*/
  background: #ffffff;
  position: absolute;
  top: 0;
  text-align: center;
}

.job-info-source {
  /*right: 37px;*/
  right: 24px;
}

.job-info-sink {
  right: 0;
}

.job-info-status-running {
  /*background: #52C41A;*/
  color: #52C41A;
}

.job-info-item-container {
  margin-top: 11px;
}

.job-info-item {
  margin-top: 8px;
  overflow: hidden;
  //text-overflow:ellipsis;
  white-space: nowrap;
}

.job-info-item-key {
  color: #808CA7;
}

.job-info-item-value {
  margin-left: 10px;
  color: #333;
  cursor: pointer;
}

.job-info-desc-edit {
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;

  .button-group {
    margin-top: 16px;
    text-align: right;

    button {
      margin-left: 8px;
    }
  }
}

.job-info-progress-container {
  margin-top: 16px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;

  .job-info-progress-title {
    margin-top: 10px;
    color: #333;
  }

  .job-info-progress-content {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ivu-col {
    position: relative;

    .job-info-incre-status {
      position: absolute;
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  .cron-job-next {
    position: absolute;
    bottom: -12px;
    font-size: 12px;
    left: 25%;
  }
}

.job-info-progress-container-margin {
  margin-left: 560px;
  margin-right: 430px;
  margin-top: 0;
  margin-bottom: 0;
}

.job-info-footer {
  /*width: 336px;*/
  width: 100%;
  height: 36px;
  background: rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 0;
  left: 0;
  vertical-align: middle;
  line-height: 36px;
  padding: 0 10px;
  /*&:hover span {*/
  /*    transform: translateX(-80px);*/
  /*}*/

  .iconfont {
    font-size: 14px;
    margin-right: 6px;
  }

  & > span {
    margin-right: 20px;
    color: #333;

    &:hover {
      cursor: pointer;
      /*-webkit-filter: drop-shadow(80px 0px 0px #4BB8EC);*/
      color: #0BB9F8;
    }

    .ivu-icon {
      vertical-align: middle;
      font-size: 16px;
      color: #A2A9B6;

      span {
        vertical-align: middle;
      }
    }
  }

  .job-info-check {
    position: absolute;
    right: 10px;
  }
}

.float-left {
  float: left;
}

.job-info-btns {
  position: absolute;
  right: 16px;
  top: 37px;

  & > span {
    margin-right: 20px;
    color: #333;

    &:hover {
      cursor: pointer;
      /*-webkit-filter: drop-shadow(80px 0px 0px #4BB8EC);*/
      color: #0BB9F8;
    }

    .ivu-icon {
      vertical-align: middle;
      font-size: 16px;
      color: #A2A9B6;

      span {
        vertical-align: middle;
      }
    }
  }
}

.group-master {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: #66C6F4;
  padding: 1px 11px;
  display: block;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 18px;
  font-size: 12px;
}

.job-header-name-desc {
  height: 24px;
  border-radius: 14px;
  background-color: rgba(151, 197, 255, 1);
  color: #ffffff;
  padding: 4px 12px;
  line-height: 24px;
  margin-top: 4px;
}
</style>
