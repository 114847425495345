<template>
    <div :style="`${type === 'cc'? 'padding: 16px;': ''}margin-bottom: 74px`">
        <Breadcrumb style="margin-bottom: 14px" v-if="type === 'cc'">
            <BreadcrumbItem>{{ $t('cao-zuo-shen-ji') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchData" inline label-position="right"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
                        <Option value="time" :label="$t('cao-zuo-shi-jian')">
                            <span>{{ $t('cao-zuo-shi-jian') }}</span>
                        </Option>
                        <Option value="user" :label="$t('cao-zuo-ren')" v-if="store.state.userRole!=='ORG_ADMIN'">
                            <span>{{ $t('cao-zuo-ren') }}</span>
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='time'">
                    <DatePicker :editable="false" v-model="timeRange" type="datetimerange" format="yyyy-MM-dd HH:mm" style="width: 300px"></DatePicker>
                </FormItem>
                <FormItem v-if="searchType==='user'">
                    <Input v-model="searchData.userName" style="width: 250px"/>
                </FormItem>
                <FormItem>
                    <a-button type="primary" @click="handleSearch" :loading="refreshLoading">{{ $t('cha-xun') }}</a-button>
                </FormItem>
            </Form>
            <div class="page-header-function">
                <pd-button type="default" style="margin-right: 6px" @click="handleRefresh"
                        :loading="refreshLoading">
                    {{ $t('shua-xin') }}
                </pd-button>
            </div>
        </div>
        <Table style="margin-top: 20px" size="small" border :columns="logColumn" :data="logData" :max-height="600"></Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                  <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
            </div>
        </div>
    </div>
</template>
<script>
import { queryAllLog, getLogByUserName, getLogByUid } from '@/services/cc/api/operationLog';
import { queryAllLog as rdpQueryAllLog, getLogByUserName as rdpGetLogByUserName, getLogByUid as rdpGetLogByUid } from '@/services/cc/api/rdpAudit';
import fecha from 'fecha';
import store from '../../store/index';

export default {
  components: {
  },
  data() {
    return {
      searchType: 'time',
      store,
      noMoreData: false,
      refreshLoading: false,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      timeRange: [new Date(new Date().getTime() - 24 * 3600 * 1000), new Date()],
      searchData: {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      logColumn: [
        {
          title: this.$t('cao-zuo-zhe'),
          key: 'userName',
          width: 150,
          render: (h, params) => {
            if (store.state.userRole !== 'ORG_ADMIN') {
              return h('a', {
                on: {
                  click: () => {
                    this.searchData.userName = params.row.userName;
                    this.searchData.uid = params.row.uid;
                    this.handleRefresh();
                  }
                }
              }, params.row.userName);
            }
            return h('div', {}, params.row.userName);
          }
        },
        {
          title: this.$t('cao-zuo-shi-jian'),
          key: 'operateDate',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.operateDate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('cao-zuo-di-zhi'),
          key: 'sourceIp',
          width: 150
        },
        {
          title: this.$t('zi-yuan-ming-cheng'),
          key: 'alias',
          width: 300
        },
        {
          title: this.$t('cao-zuo-zi-yuan'),
          key: 'resourceValue'
        },
        {
          title: this.$t('ri-zhi-wei-yi-xin-xi'),
          key: 'uuidKey'
        },
        {
          title: this.$t('an-quan-deng-ji'),
          key: 'securityLevel',
          width: 150,
          render: (h, params) => h('div', {
            style: {
              color: params.row.securityLevel === 'NORMAL' ? '#19be6b' : params.row.securityLevel === 'HIGH' ? '#ed4014' : ''
            }
          }, params.row.securityLevel === 'NORMAL' ? this.$t('pu-tong') : params.row.securityLevel === 'HIGH' ? this.$t('gao-feng-xian') : ''),
          filterMultiple: false,
          filters: [
            {
              label: this.$t('pu-tong'),
              value: 'NORMAL'
            },
            {
              label: this.$t('gao-feng-xian'),
              value: 'HIGH'
            }
          ],
          filterRemote(value) {
            this.searchData.securityLevel = value[0];
            this.handleSearch();
          }
        }
      ],
      logData: [],
      type: 'cc'
    };
  },
  created() {
    console.log('this.route', this.$route);
    if (this.$route.name === 'rdpOperationLog') {
      this.type = 'rdp';
    } else if (this.$route.name === 'operationLog') {
      this.type = 'cc';
    }
  },
  mounted() {
    this.handleSearch();
    this.searchData.pageData.pageSize = 20;
  },
  methods: {
    handleRefresh() {
      this.page = 1;
      this.firstId = 0;
      this.lastId = 0;
      this.searchData.pageData.startId = 0;
      this.handleSearch();
    },
    handleSearch(type) {
      this.refreshLoading = true;
      if (this.timeRange.length > 0) {
        this.searchData.opStart = fecha.format(new Date(new Date(this.timeRange[0]).getTime() - 8 * 3600 * 1000), 'YYYY-MM-DDTHH:mm:ss.SSS');
        this.searchData.opEnd = fecha.format(new Date(new Date(this.timeRange[1].getTime() - 8 * 3600 * 1000)), 'YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        this.searchData.opStart = '';
        this.searchData.opEnd = '';
      }
      this.searchData.pageData.pageSize = 20;
      if (this.searchData.uid) {
        let apiName = getLogByUid;
        if (this.type === 'rdp') {
          apiName = rdpGetLogByUid;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else if (this.searchData.userName) {
        let apiName = getLogByUserName;
        if (this.type === 'rdp') {
          apiName = rdpGetLogByUserName;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      } else {
        let apiName = queryAllLog;
        if (this.type === 'rdp') {
          apiName = rdpQueryAllLog;
        }
        apiName(this.searchData).then((res) => {
          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (type === 'next') {
              if (!this.prevFirst[this.page - 1]) {
                this.prevFirst.push(this.firstId);
              }
            }
            this.firstId = this.logData[0].id;
            this.lastId = this.logData[this.logData.length - 1].id;
          }
          this.refreshLoading = false;
          this.noMoreData = res.data.data.length < this.searchData.pageData.pageSize;
        }).catch(() => {
          this.refreshLoading = false;
        });
      }
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchData.pageData.startId = startId;
      this.handleSearch('prev');
    },
    handleNext() {
      this.searchData.pageData.startId = this.lastId;
      this.handleSearch('next');
      this.page++;
    },
    handleChangeSize() {
      this.handleSearch('next');
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        uid: '',
        userName: '',
        opStart: '',
        opEnd: '',
        // securityLevel:'',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      };
    }
  }
};
</script>
