const sqlMixin = {
  methods: {
    goTicketPage(sql) {
      const { instanceId, database, schema } = this.tab;
      localStorage.setItem('instanceToTicket', instanceId);
      localStorage.setItem('databaseToTicket', database);
      localStorage.setItem('schemaToTicket', schema);
      localStorage.setItem('sqlToTicket', sql);
      this.$router.push({
        path: 'ticket_create'
      });
    }
  }
};

export default sqlMixin;
