export const schemaApi = {
  listSchemaFirstLevel: '/clouddm/console/api/v1/datasource/schema/listfirstlevel',
  listDsSchema: '/clouddm/console/api/v1/datasource/schema/listschemas',
  listDbTables: '/clouddm/console/api/v1/datasource/schema/listtables',
  listTabColumns: '/clouddm/console/api/v1/datasource/schema/listcolumns',
  getDbTable: '/clouddm/console/api/v1/datasource/schema/getDbTable',
  listTableIndex: '/clouddm/console/api/v1/datasource/schema/listtableindex',
  listTableFk: '/clouddm/console/api/v1/datasource/schema/listtablefk',
  // requestScript: '/clouddm/console/api/v1/datasource/schema/requestscript',
  groupTables: '/clouddm/console/api/v1/datasource/schema/grouptables',
  schemaEditorExecute: '/datasource/schema/schemaeditorexecute',
  schemaEditorInit: '/clouddm/console/api/v1/datasource/schema/schemaeditorinit',
  schemaEditorApply: '/clouddm/console/api/v1/datasource/schema/schemaeditorapply',
  schemaEditorBuildDDL: '/clouddm/console/api/v1/datasource/schema/schemaeditorbuildddl',
  schemaEditorTypes: '/clouddm/console/api/v1/datasource/schema/schemaeditortypes',
  groupDsNodes: '/clouddm/console/api/v1/datasource/schema/groupdsnodes',
  rightClickSchema: '/clouddm/console/api/v1/datasource/schema/rightclickschema',
  schemaTableEditorDef: '/clouddm/console/api/v1/datasource/schema/schemaTableEditorDef',
  schemaEditorSave: '/clouddm/console/api/v1/datasource/schema/schemaeditorsave'
};
