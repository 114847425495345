<template>
    <div style="padding-bottom: 100px">
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem>{{ $t('yong-hu-guan-li') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchKey" inline label-position="right"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:100px" @on-change="handleChangeSearchType">
                        <Option value="username" :label="$t('yong-hu-ming')">
                            <span>{{ $t('yong-hu-ming') }}</span>
                        </Option>
                        <Option value="email" :label="$t('you-xiang')">
                            <span>{{ $t('you-xiang') }}</span>
                        </Option>
                        <Option value="company" :label="$t('gong-si-ming')">
                            <span>{{ $t('gong-si-ming') }}</span>
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='username'">
                    <Input v-model="searchKey.username" style="width: 280px"/>
                </FormItem>
                <FormItem v-if="searchType==='email'">
                    <Input v-model="searchKey.email" style="width: 280px"/>
                </FormItem>
                <FormItem v-if="searchType==='company'">
                    <Input v-model="searchKey.company" style="width: 280px"/>
                </FormItem>
                <FormItem>
                    <pd-button type="primary" @click="getUserList">{{ $t('cha-xun') }}</pd-button>
                </FormItem>
            </Form>
          <pd-button type="default" style="position: absolute;right: 100px;top: 12px" @click="handleAddUser">
            {{ $t('tian-jia-yong-hu') }}
          </pd-button>
            <pd-button type="default" style="position: absolute;right: 20px;top: 12px" @click="handleRefresh"
                    :loading="refreshLoading">
                {{ $t('shua-xin') }}
            </pd-button>
        </div>
        <Table style="margin-top: 20px" size="small" border :columns="userColumn" :data="showData">
          <template slot="action" slot-scope="{row}">
            <a :disabled="userInfo.uid===row.uid" @click="handleDeleteUser(row)">{{ $t('shan-chu') }}</a>
            <a style="margin-left: 16px" @click="handleTakeover(row)">{{ $t('yi-jiao-zi-yuan') }}</a>
          </template>
        </Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <Page :total="total" show-total @on-change="handlePageChange" :page-size="pageSize"/>
            </div>
        </div>
      <Modal v-model="showTakeOver"
             :title="$t('zi-yuan-yi-jiao-que-ren')"
             footer-hide
      >
        <div>
          {{ $t('qing-xuan-ze-xu-yao-jiang-yong-hu-selecteduserusername-de-zi-yuan-yi-jiao-gei', [selectedUser.username]) }}
          <Select v-model="dstUser" style="margin-top: 10px">
            <Option v-for="user in userData" :key="user.uid" :value="user.uid">{{user.username}}({{user.phone}})</Option>
          </Select>
          <p style="margin-top: 10px;color: red">{{ $t('yi-jiao-zi-yuan-hou-qing-li-ji-jiang-ren-wu-suo-zai-ji-qi-de-pei-zhi-gai-wei-bei-yi-jiao-ren-de-xin-xi-fou-ze-hui-dao-zhi-ren-wu-yi-chang') }}<a target="_blank" :href="getHandoverDoc">{{ $t('ru-he-xiu-gai') }}</a></p>
          <div slot="footer" class="modal-footer" style="margin-top: 20px">
            <pd-button type="primary" @click="handleConfirmTakeOver">{{ $t('que-ding') }}</pd-button>
            <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
          </div>
        </div>
      </Modal>
      <add-premise-modal :visible="showAddUserModal" :handle-close="handleCloseAddUserModal"></add-premise-modal>
    </div>
</template>
<script>
import { getUserList, deleteUser, takeoverUserRes } from '@/services/cc/api/user';
import { queryDeployMode } from '@/services/cc/api/constant';
import fecha from 'fecha';
import _ from 'lodash';
import AddPremiseModal from '@/components/modal/AddPremiseModal';
import store from '@/store';
import Mapping from '../util';

export default {
  components: {
    AddPremiseModal
  },
  computed: {
    getHandoverDoc() {
      if (store.state.deploySite === 'asia_pacific') {
        return `${store.state.docUrlPrefix}/cc-doc/operation/system_manage/user_manage#移交资源`;
      }
      return `${store.state.docUrlPrefix}/cc-doc/operation/system_manage/user_manage#transfer-resources`;
    }
  },
  mounted() {
    queryDeployMode().then((res) => {
      if (res.data.code === '1') {
        this.deployMode = res.data.data;
      }
    });
    this.getUserList();
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
  },
  data() {
    return {
      showAddUserModal: false,
      searchType: 'username',
      page: 1,
      pageSize: 20,
      total: 0,
      showData: [],
      deployMode: 'CLOUD_PUBLIC',
      refreshLoading: false,
      isAdmin: false,
      showDeleteUser: false,
      showTakeOver: false,
      ifEdit: false,
      selectedUser: {},
      dstUser: '',
      searchKey: {
        username: '',
        email: '',
        company: ''
      },
      userInfo: {
        role: 'DEFAULT'
      },
      userColumn: [
        {
          title: this.$t('yong-hu-ming'),
          key: 'username',
          width: 150
        },
        {
          title: this.$t('yong-hu-uid'),
          key: 'uid',
          width: 160
        },
        {
          title: this.$t('gong-si-ming-cheng'),
          key: 'company'
        },
        {
          title: this.$t('you-xiang'),
          key: 'email'
        },
        {
          title: this.$t('lian-xi-fang-shi'),
          key: 'phone'
        },
        {
          title: this.$t('jiao-se-0'),
          key: 'role',
          width: 160,
          render: (h, params) => h('div', {}, Mapping.role[params.row.role]),
          filters: [
            {
              label: this.$t('xi-tong-guan-li-yuan'),
              value: 'SYSTEM'
            },
            {
              label: this.$t('guan-li-yuan'),
              value: 'ADMIN'
            },
            {
              label: this.$t('zhi-du-guan-li-yuan'),
              value: 'ADMIN_READONLY'
            },
            {
              label: this.$t('pu-tong-yong-hu'),
              value: 'DEFAULT'
            }
          ],
          filterRemote(value) {
            let list = [];

            if (value) {
              if (this.deployMode === 'CLOUD_PUBLIC') {
                value.replace('DEFAULT', 'ORG_ADMIN');
              }
              this.userData.map((item) => {
                if (value.indexOf(item.role) > -1) {
                  list.push(item);
                }
                return null;
              });
            } else {
              list = _.cloneDeep(this.userData);
            }
            this.page = 1;
            this.total = list.length;
            this.pagingData = list;
            this.showData = list.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
          }
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          key: 'gmtCreate',
          width: 200,
          sortable: true,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          slot: 'action',
          width: 220
        }
      ],
      userColumnPrivate: [
        {
          title: this.$t('yong-hu-ming'),
          key: 'username',
          width: 150
        },
        {
          title: this.$t('you-xiang'),
          key: 'email'
        },
        {
          title: this.$t('lian-xi-fang-shi'),
          key: 'phone'
        },
        {
          title: this.$t('jiao-se-0'),
          key: 'role',
          width: 100,
          render: (h, params) => h('div', {}, Mapping.role[params.row.role]),
          filters: [
            {
              label: this.$t('xi-tong-guan-li-yuan'),
              value: 'SYSTEM'
            },
            {
              label: this.$t('guan-li-yuan'),
              value: 'ADMIN'
            },
            {
              label: this.$t('zhi-du-guan-li-yuan'),
              value: 'ADMIN_READONLY'
            },
            {
              label: this.$t('pu-tong-yong-hu'),
              value: 'DEFAULT'
            }
          ],
          filterRemote(value) {
            let list = [];

            if (value.length > 0) {
              if (this.deployMode === 'CLOUD_PUBLIC') {
                value.replace('DEFAULT', 'ORG_ADMIN');
              }
              this.userData.map((item) => {
                if (value.indexOf(item.role) > -1) {
                  list.push(item);
                }
                return null;
              });
            } else {
              list = _.cloneDeep(this.userData);
            }
            this.page = 1;
            this.total = list.length;
            this.pagingData = list;
            this.showData = list.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
          }
        }
        // {
        //     title: '操作',
        //     key: '',
        //     slot: 'action',
        //     width: 150
        // }
      ],
      userData: [],
      pagingData: [],
      ruleValidate: {
        role: [
          { required: true, message: 'The role cannot be empty', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    handleAddUser() {
      this.showAddUserModal = true;
    },
    handleCloseAddUserModal() {
      this.showAddUserModal = false;
      this.handleRefresh();
    },
    handleRefresh() {
      this.getUserList();
    },
    getUserList() {
      this.refreshLoading = true;
      getUserList(this.searchKey).then((res) => {
        if (res.data.code === '1') {
          this.userData = res.data.data;
          this.total = this.userData.length;
          this.pagingData = _.cloneDeep(this.userData);
          this.showData = this.userData.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
        }
        this.refreshLoading = false;
      }).catch(() => {
        this.refreshLoading = false;
      });
    },
    handleDeleteUser(row) {
      this.selectedUser = row;
      this.$Modal.confirm({
        title: this.$t('shan-chu-yong-hu-que-ren'),
        content: this.$t('p-que-ren-yao-shan-chu-yong-hu-rowusername-ma-p', [row.username]),
        onOk: () => {
          deleteUser({ deleteUid: this.selectedUser.uid }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('zheng-zai-shan-chu-yong-hu'));
              setTimeout(() => {
                this.getUserList();
              }, 1000);
            }
          });
        }
      });
    },
    handleTakeover(row) {
      this.showTakeOver = true;
      this.selectedUser = row;
    },
    handleConfirmTakeOver() {
      takeoverUserRes({ srcUid: this.selectedUser.uid, dstUid: this.dstUser }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('zheng-zai-yi-jiao-zhong'));
          this.showTakeOver = false;
          setTimeout(() => {
            this.getUserList();
          }, 1000);
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.pagingData.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        username: '',
        email: '',
        company: ''
      };
    },
    handleCancel() {
      this.showTakeOver = false;
    }
  }
};
</script>
<style lang="less">
    .user-container {
        background: #ffffff;
        margin-top: 16px;
        padding: 24px;
    }
</style>
