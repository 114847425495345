<template>
    <div>
        <div style="padding: 16px;">
            <Breadcrumb style="margin-bottom: 14px">
                <BreadcrumbItem>{{ $t('gao-jing-ri-zhi') }}</BreadcrumbItem>
            </Breadcrumb>
            <div class="page-header-container">
                <Form label-position="right" :label-width="80" inline>
                    <FormItem :label="$t('xuan-ze-shi-jian')">
                        <DatePicker :editable="false" v-model="searchInfo.dataRange" :options="dateRangeOptions"
                                    type="datetimerange" format="yyyy-MM-dd HH:mm" :placeholder="$t('qing-xuan-ze-kai-shi-shi-jian-he-jie-shu-shi-jian')"
                                    style="width: 300px"></DatePicker>
                        <pd-button style="margin-left: 6px" type="primary" @click="handleSearch">{{ $t('cha-xun') }}</pd-button>
                    </FormItem>
                </Form>
                <div class="page-header-function">
                    <pd-button type="default" style="margin-right: 6px" @click="handleSearch"
                               :loading="refreshLoading">
                        {{ $t('shua-xin') }}
                    </pd-button>
                </div>
            </div>
            <Table style="margin-top: 20px" size="small" :columns="alarmColumn" :data="alarmData" border>
                <template slot-scope="{ row }" slot="status">
                    <div>
                        <Tooltip transfer v-if="row.alertEventStatus==='ERROR'" :content="row.errMsg" placement="left">
                            <a style="color: #ed4014">{{ $t('shi-bai') }}</a>
                        </Tooltip>
                        <div style="color: #19be6b" v-if="row.alertEventStatus==='SUCCESS'">{{ $t('cheng-gong') }}</div>
                    </div>
                </template>
            </Table>
            <div class="page-footer-container">
                <div class="page-footer-paging">
                    <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre">
                        <Icon type="ios-arrow-back" style="font-size: 16px"/>
                        {{ $t('shang-yi-ye') }}
                    </pd-button>
                    <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                    <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px"
                            @click="handleNext">
                        <Icon type="ios-arrow-forward" style="font-size: 16px"/>
                        {{ $t('xia-yi-ye') }}
                    </pd-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import fecha from 'fecha';
import { listAlertEventLogs } from '@/services/cc/api/rdpAlert';

export default {
  components: {
  },
  mounted() {
    this.handleSearch();
  },
  data() {
    return {
      refreshLoading: false,
      dateRangeOptions: {
        shortcuts: [
          {
            text: '1 hour',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000);
              return [start, end];
            }
          },
          {
            text: '1 day',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24);
              return [start, end];
            }
          },
          {
            text: '1 week',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '1 month',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '3 months',
            value() {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      startId: 0,
      total: 0,
      page: 1,
      searchInfo: {
        pageSize: 20,
        dataRange: [fecha.format(new Date(new Date().getTime() - 3600 * 1000), 'YYYY-MM-DD HH:mm:ss'), fecha.format(new Date(), 'YYYY-MM-DD HH:mm:ss')],
        status: ''
      },
      noMoreData: false,
      alarmColumn: [
        {
          title: this.$t('gao-jing-ip'),
          key: 'ip',
          width: 200
        },
        {
          title: this.$t('gao-jing-nei-rong'),
          key: 'content'
        },
        {
          title: this.$t('gao-jing-shi-jian'),
          key: 'gmtCreate',
          width: 200,
          sortable: true,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreate), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('fa-song-jie-guo'),
          key: 'alertEventStatus',
          width: 150,
          slot: 'status',
          filters: [
            {
              label: this.$t('cheng-gong'),
              value: 'SUCCESS'
            },
            {
              label: this.$t('shi-bai'),
              value: 'ERROR'
            }
          ],
          filterMultiple: false,
          filterRemote(value) {
            this.searchInfo.status = value[0];
            this.handleSearch();
          }
        }
      ],
      alarmData: []
    };
  },
  methods: {
    handleSearch(type) {
      if (type !== 'next' && type !== 'prev') {
        this.page = 1;
        this.startId = 0;
        this.firstId = 0;
        this.lastId = 0;
        this.prevFirst = [];
      }
      this.refreshLoading = true;
      listAlertEventLogs({
        startId: this.startId,
        pageSize: this.searchInfo.pageSize,
        leftTimeMillis: new Date(this.searchInfo.dataRange[0]).getTime(),
        rightTimeMillis: new Date(this.searchInfo.dataRange[1]).getTime(),
        status: this.searchInfo.status
      }).then((res) => {
        this.refreshLoading = false;
        if (res.data.code === '1') {
          this.alarmData = res.data.data.alertEventLogVOList;
          this.total = res.data.data.totalCount;
          if (type === 'next') {
            if (!this.prevFirst[this.page - 1]) {
              this.prevFirst.push(this.firstId);
            }
          }
          if (this.alarmData.length > 0) {
            this.firstId = this.alarmData[0].id;
            this.lastId = this.alarmData[this.alarmData.length - 1].id;
          }
          this.noMoreData = this.alarmData.length < this.searchInfo.pageSize;
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.startId = startId;
      this.handleSearch('prev');
    },
    handleNext() {
      this.startId = this.lastId;
      this.handleSearch('next');
      this.page++;
    },
    handleChangeSize(size) {
      this.searchInfo.pageSize = size;
      this.handleSearch();
    },
    handlePageChange(page) {
      this.searchInfo.pageNo = page;
      this.handleSearch();
    }
  }
};
</script>
<style lang="less">
    .worker-monitor-count {
        text-align: center;

        .worker-monitor-count-number {
            font-size: 40px;
        }

        .worker-monitor-count-title {
            display: block;
        }

        .worker-monitor-count-error {
            color: #ed4014;
        }
    }
</style>
