export const workerApi = {
  createWorker: '/clouddm/console/api/v1/worker/createinitialworker',
  getWorkerList: '/clouddm/console/api/v1/worker/listworkers',
  getDownloadUrl: '/clouddm/console/api/v1/worker/downloadclienturl',
  deleteWorker: '/clouddm/console/api/v1/worker/deleteworker',
  startWorker: '/clouddm/console/api/v1/worker/waittoonline',
  stopWorker: '/clouddm/console/api/v1/worker/waittooffline',
  getWorkerConfig: '/clouddm/console/api/v1/worker/clientcoreconfig',
  updateWorkerDesc: '/clouddm/console/api/v1/worker/updateworkerdesc'
};
