import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('formatTime', (value, fmt) => dayjs(value)
  .format(fmt));
Vue.filter('capitalize', (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0)
    .toUpperCase() + value.slice(1);
});
