export const authApi = {
  fetchAuthTreeDef: '/rdp/console/api/v1/auth/fetchAuthTreeDef',
  listAuthDataByDataSource: '/rdp/console/api/v1/auth/listAuthDataByDataSource',
  listAuthDataByDataTask: '/rdp/console/api/v1/auth/listAuthDataByDataTask',
  authListLevels: '/rdp/console/api/v1/auth/listLevels',
  modifyAuthData: '/rdp/console/api/v1/auth/modifyAuthData',
  listCurrUserAuthResource: '/rdp/console/api/v1/auth/listCurrUserAuthResource',
  listElementsOfLevel: '/rdp/console/api/v1/auth/listElementsOfLevel',
  listElementsOfLevelForDM: '/clouddm/console/api/v1/auth/listElementsOfLevelFO',
  listUserAuthOfRes: '/rdp/console/api/v1/auth/listUserAuthOfRes',
  listUserAuthRes: '/rdp/console/api/v1/auth/listUserAuthRes',
  listMyAuthOfRes: '/rdp/console/api/v1/auth/listMyAuthOfRes',
  listMyAuthRes: '/rdp/console/api/v1/auth/listMyAuthRes',
  modifyUserAuth: '/rdp/console/api/v1/auth/modifyUserAuth',
  listElementsOfLevelForCC: '/cloudcanal/console/api/v1/inner/auth/listElementsOfLevel',
  listUserAuthOfResForCC: '/cloudcanal/console/api/v1/inner/auth/listUserAuthOfRes',
  listUserAuthResForCC: '/cloudcanal/console/api/v1/inner/auth/listUserAuthRes',
  listMyAuthOfResForCC: '/cloudcanal/console/api/v1/inner/auth/listMyAuthOfRes',
  listMyAuthResForCC: '/rdp/console/api/v1/auth/listMyAuthRes',
  modifyUserAuthForCC: '/cloudcanal/console/api/v1/inner/auth/modifyUserAuth'
};
