import { render, staticRenderFns } from "./DataSourceInfo.vue?vue&type=template&id=3938f954&scoped=true"
import script from "./DataSourceInfo.vue?vue&type=script&lang=js"
export * from "./DataSourceInfo.vue?vue&type=script&lang=js"
import style0 from "./DataSourceInfo.vue?vue&type=style&index=0&id=3938f954&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3938f954",
  null
  
)

export default component.exports