import {
  activeFsm,
  activeFsmBatch,
  pauseJobTimeSchedule,
  replayJob,
  restartJob,
  restartJobBatch,
  resumeJobTimeSchedule,
  startJob,
  startJobBatch,
  startTimeScheduleJob,
  stopJob,
  stopJobBatch,
  updateJob,
  detachIncreTask,
  attachIncreTask,
  manualMergeEditJob
} from '@/services/cc/api/job';
import _ from 'lodash';
import store from '@/store/index';

export default {
  data() {
    return {
      jobId: 0,
      loadingData: {},
      loading: false,
      selectedJobRow: {},
      showConfirm: false,
      sendCodeAgain: '',
      verifyCode: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      batchEditList: [],
      batchEdit: false,
      batchEditBtns: [],
      showScheduleHistory: false,
      showBatchConfirm: false,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      batchType: '',
      checkAllGroup: [],
      store
    };
  },
  computed: {
    getFitSpan() {
      let count = 0;

      const width = window.innerWidth;
      const countMapping = {
        3: 3,
        4: 4,
        5: 4,
        6: 6,
        7: 6,
        8: 8,
        9: 8
      };

      count = parseInt(width / 400, 10);
      return countMapping[parseInt(24 / count, 10)];
    }
  },
  methods: {
    handleCancelDelete() {
      this.verifyCode = '';
      this.showConfirm = false;
      this.showBatchConfirm = false;
    },
    handleBeginBatchEdit() {
      this.showBatchConfirm = false;
      if (this.batchType === 'stop') {
        this.handleStopBatch();
      } else if (this.batchType === 'restart') {
        this.handleRestartBatch();
      } else if (this.batchType === 'start') {
        this.handleStartBatch();
      } else if (this.batchType === 'fsmStart') {
        this.handleActiveFsmBatch();
      }
    },
    handleStartBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      startJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('qi-dong-cheng-gong'));
          }
        });
    },
    handleStopBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      stopJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success(this.$t('ting-zhi-cheng-gong'));
          }
        });
    },
    handleRestartBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      restartJobBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('zhong-qi-cheng-gong'));
          }
        });
    },
    handleActiveFsmBatch() {
      const jobIdList = [];

      this.batchEditList.forEach((item) => {
        jobIdList.push({
          jobId: item.dataJobId,
          dataJobName: item.dataJobName
        });
      });
      activeFsmBatch(jobIdList)
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('qi-dong-cheng-gong'));
          }
        });
    },
    handleRestart(row) {
      this.$Modal.confirm({
        title: this.$t('zhong-qi-ren-wu-que-ren'),
        content: this.$t('qing-que-ren-shi-fou-yao-zhong-qi-gai-ren-wu-zhong-qi-zhong-jiang-chu-xian-ren-wu-zhong-duan'),
        onOk: () => {
          restartJob({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (!this.loadingData[row.dataJobId]) {
                  this.loadingData[row.dataJobId] = {};
                }
                this.loadingData[row.dataJobId].restarting = true;
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success(this.$t('zhong-qi-cheng-gong'));
              }
            });
        }
      });
    },
    handleStop(row) {
      this.$Modal.confirm({
        title: this.$t('ting-zhi-ren-wu-que-ren'),
        content: this.$t('qing-que-ren-shi-fou-yao-ting-zhi-gai-ren-wu-ting-zhi-jiang-shi-ren-wu-zhong-duan'),
        onOk: () => {
          stopJob({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (!this.loadingData[row.dataJobId]) {
                  this.loadingData[row.dataJobId] = {};
                }
                this.loadingData[row.dataJobId].stopping = true;
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success(this.$t('ting-zhi-cheng-gong'));
              }
            });
        }
      });
    },
    handleStartFsm(row) {
      activeFsm({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('qi-dong-cheng-gong'));
          }
        });
    },
    handleReplay(row) {
      replayJob({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.$Message.success(this.$t('zhong-pao-cheng-gong'));

            const that = this;

            setTimeout(() => {
              if (this.type === 'group') {
                that.getJobData();
              } else {
                that.getJobList();
              }
            }, 500);
          }
        });
    },
    handleResumSchedule(row) {
      resumeJobTimeSchedule({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('hui-fu-tiao-du-cheng-gong'));
          }
        });
    },
    handleStopSchedule(row) {
      this.$Modal.confirm({
        title: this.$t('ting-zhi-tiao-du-que-ren'),
        content: this.$t('qing-que-ren-shi-fou-yao-ting-zhi-gai-ren-wu-tiao-du-ting-zhi-hou-ding-shi-ren-wu-jiang-zhong-zhi'),
        onOk: () => {
          pauseJobTimeSchedule({
            jobId: row.dataJobId,
            dataJobName: row.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  const that = this;

                  setTimeout(() => {
                    that.getJobList();
                  }, 2000);
                }
                this.$Message.success(this.$t('ting-zhi-tiao-du-cheng-gong'));
              }
            });
        }
      });
    },
    handleStart(row) {
      startJob({
        jobId: row.dataJobId,
        dataJobName: row.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (!this.loadingData[row.dataJobId]) {
              this.loadingData[row.dataJobId] = {};
            }
            this.loadingData[row.dataJobId].starting = true;
            if (this.type === 'group') {
              this.getJobData();
            } else {
              const that = this;

              setTimeout(() => {
                that.getJobList();
              }, 2000);
            }
            this.$Message.success(this.$t('qi-dong-cheng-gong'));
          }
        });
    },
    getBtns() {
      let allBtns = ['restart', 'stop', 'start', 'fsmStart'];

      this.batchEditList.forEach((item) => {
        const rowBtns = [];

        if (this.getDataTask(item.dataTasks).BUILD_STRUCT
          ? !item.fsmActive && item.dataTaskState === 'INIT'
          && (this.getDataTask(item.dataTasks).BUILD_STRUCT
            && this.getDataTask(item.dataTasks).BUILD_STRUCT.dataTaskStatus === 'COMPLETE')
          : !item.fsmActive && item.dataTaskState === 'INIT') {
          rowBtns.push('fsmStart');
        }
        if ((this.getDataTask(item.dataTasks).FULL
            && this.getDataTask(item.dataTasks).FULL.dataTaskStatus === 'STOP'
            || this.getDataTask(item.dataTasks).INCREMENT
            && this.getDataTask(item.dataTasks).INCREMENT.dataTaskStatus === 'STOP'
            || this.getDataTask(item.dataTasks).CHECK
            && this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'STOP')) {
          rowBtns.push('start');
        }
        if ((item.currentStatus === 'ABNORMAL'
            || item.currentStatus === 'RUNNING'
            || item.currentStatus === 'WAIT_START'
            || item.dataTaskState === 'CHECK'
            && (this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'WAIT_START'
              || this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'RUNNING'))) {
          rowBtns.push('restart');
        }
        if ((item.currentStatus === 'ABNORMAL'
            || item.currentStatus === 'RUNNING'
            || item.currentStatus === 'WAIT_START'
            || item.dataTaskState === 'CHECK'
            && (this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'WAIT_START'
              || this.getDataTask(item.dataTasks).CHECK.dataTaskStatus === 'RUNNING'))) {
          rowBtns.push('stop');
        }
        allBtns = allBtns.filter((v) => rowBtns.includes(v));
      });
      if (this.batchEditList.length === 0) {
        this.batchEditBtns = [];
      } else {
        this.batchEditBtns = allBtns;
      }
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.forEach((item) => {
        dataTasks[item.dataTaskType] = item;
      });
      return dataTasks;
    },
    handleShowGroup(bl, row) {
      if (bl) {
        // this.showGroup = true;
        this.groupData = row;
        this.$router.push({ path: `/data/job/group/${row.dataJobId}` });
      } else {
        this.showGroup = false;
      }
    },
    handleConfirmEdit(row, dataJobDesc) {
      updateJob({
        jobId: row.dataJobId,
        dataJobDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success(this.$t('xiu-gai-cheng-gong'));
          }
        });
    },
    handleBatchEdit(row, selected) {
      if (selected) {
        this.batchEditList.push(row);
      } else {
        this.batchEditList.forEach((item, index) => {
          if (item.dataJobId === row.dataJobId) {
            this.batchEditList.splice(index, 1);
          }
        });
      }
      this.batchEdit = this.batchEditList.length > 0;
      this.getBtns();
    },
    handleStartTimeScheduleJob(row) {
      startTimeScheduleJob({ jobId: row.dataJobId })
        .then((res) => {
          if (res.data.code === '1') {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
            this.$Message.success(this.$t('li-ji-zhi-hang-cao-zuo-cheng-gong-ren-wu-jiang-zai-liang-fen-zhong-hou-zi-dong-zhi-hang'));
          }
        });
    },
    handleDelete(row) {
      this.selectedJobRow = row;
      this.jobId = row.dataJobId;
      let hasRunningTask = false;

      if (row.childJobs) {
        row.childJobs.forEach((child) => {
          child.dataTasks.forEach((task) => {
            if (child.dataTaskState === 'INCRE' || child.dataTaskState === 'CATCH_UP') {
              if (task.dataTaskType === 'INCREMENT') {
                if (task.dataTaskStatus !== 'STOP' && task.dataTaskStatus !== 'COMPLETE') {
                  hasRunningTask = true;
                }
              }
            } else if (child.dataTaskState === task.dataTaskType) {
              if (task.dataTaskStatus !== 'STOP' && task.dataTaskStatus !== 'COMPLETE') {
                hasRunningTask = true;
              }
            }
          });
        });
      }

      if (!hasRunningTask) {
        this.showConfirm = true;
      } else {
        this.$Modal.warning({
          title: this.$t('shan-chu-ren-wu-ti-shi'),
          content: this.$t('dang-qian-ren-wu-cun-zai-yun-hang-zhong-de-guan-lian-ren-wu-qing-xian-ting-zhi-huo-shan-chu-guan-lian-ren-wu')
        });
      }
    },
    handleShowHistory(row, type) {
      if (type === 'CHECK') {
        this.scheduleHistoryColumn = [
          {
            title: this.$t('zhi-hang-shi-jian-0'),
            key: 'gmtModified'
          },
          {
            title: this.$t('jin-du-0'),
            key: 'checkedPercent'
          },
          {
            title: this.$t('yi-xiao-yan-hang'),
            key: 'checked',
            render: (h, params) => h('div', {}, `${params.row.checked}${this.$t('yu-gu-zhi')}`)
          },
          {
            title: this.$t('diu-shi-hang'),
            key: 'loss'
          },
          {
            title: this.$t('bu-yi-zhi-hang'),
            key: 'diff'
          }
        ];
      } else if (type === 'REVISE') {
        this.scheduleHistoryColumn = [
          {
            title: this.$t('zhi-hang-shi-jian-0'),
            key: 'gmtModified'
          },
          {
            title: this.$t('zong-ding-zheng-hang'),
            key: 'totalRevise'
          },
          {
            title: this.$t('ding-zheng-diu-shi-hang'),
            key: 'lossRevise'
          },
          {
            title: this.$t('ding-zheng-bu-yi-zhi-hang'),
            key: 'diffRevise'
          }
        ];
      } else {
        this.scheduleHistoryColumn = [
          {
            title: this.$t('zhi-hang-shi-jian-0'),
            key: 'gmtModified'
          },
          {
            title: this.$t('jin-du-0'),
            key: 'transPercent'
          },
          {
            title: this.$t('yi-qian-yi-hang'),
            key: 'trans',
            render: (h, params) => h('div', {}, `${params.row.trans}${this.$t('yu-gu-zhi')}`)
          }
        ];
      }
      const history = JSON.parse(this.getDataTask(row.dataTasks)[type].taskPosition).resultHistory;

      if (history) {
        this.scheduleHistoryData = JSON.parse(history)
          .reverse();
      }
      this.showScheduleHistory = true;
    },
    updateAllBatchList() {
      this.jobData.forEach((theData) => {
        theData._checked = true;
        if (theData.children) {
          theData.children.forEach((child) => {
            child._checked = true;
          });
        }
      });

      this.batchEditList = _.cloneDeep(this.jobData);
      this.getBtns();
    },
    handleCancelAllBatch() {
      this.jobData.forEach((theData) => {
        theData._checked = false;
        if (theData.children) {
          theData.children.forEach((child) => {
            child._checked = false;
          });
        }
      });

      this.batchEditList = [];
      this.getBtns();
    },
    handleSelectBatch(selection, row) {
      this.jobData.forEach((data) => {
        if (row.dataJobId === data.dataJobId) {
          data._checked = true;
          this.batchEditList.push(data);
          if (data.children) {
            data.children.forEach((child) => {
              child._checked = false;
            });
          }
        }
      });
      this.getBtns();
    },
    handleCancelBatch(selection, row) {
      this.jobData.forEach((data) => {
        if (row.dataJobId === data.dataJobId) {
          data._checked = false;
          if (data.children) {
            data.children.forEach((child) => {
              child._checked = false;
            });
          }
        }
      });
      this.batchEditList.forEach((edit, i) => {
        if (edit.dataJobId === row.dataJobId) {
          this.batchEditList.splice(i, 1);
          if (edit.children) {
            edit.children.forEach((child, index) => {
              if (child.dataJobId === edit.dataJobId) {
                this.batchEditList.splice(index, 1);
              }
            });
          }
        }
      });
      this.getBtns();
    },
    updateBatchInTable() {
      this.batchEdit = !this.batchEdit;
      this.batchEditList = [];
      this.batchEditBtns = [];
    },
    handleBatchEditConfirm(type) {
      this.batchType = type;
      this.showBatchConfirm = true;
      this.batchEditList.forEach((item) => {
        this.checkAllGroup.push(item.dataJobName);
      });
    },
    handleDetachIncreTask(row) {
      detachIncreTask({
        jobId: row.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('zheng-zai-zhai-chu-ren-wu-qing-shao-hou'));
          setTimeout(() => {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
          }, 2000);
        }
      });
    },
    handleAttachIncreTask(row) {
      attachIncreTask({
        jobId: row.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('zheng-zai-gua-zai-ren-wu-qing-shao-hou'));
          setTimeout(() => {
            if (this.type === 'group') {
              this.getJobData();
            } else {
              this.getJobList();
            }
          }, 2000);
        }
      });
    }
  }
};
