import './iview.config';
import './antd.config';

import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CCIconfont from '@/components/widgets/CCIconfont';
import CCLabel from '@/components/widgets/CCLabel';
import CCRegionSelect from '@/components/widgets/CCRegionSelect';
import CCClusterTypeSelect from '@/components/widgets/CCClusterTypeSelect';
import CCStatus from '@/components/widgets/CCStatus';
import CCDataSourceIcon from '@/components/widgets/CCDataSourceIcon';
import CCSmsButton from '@/components/widgets/CCSmsButton';
import CCPasswordInput from '@/components/widgets/CCPasswordInput';
import CCSchemaTreeSelect from '@/components/widgets/CCSchemaTreeSelect';
import CCAuthTreeSelect from '@/components/widgets/CCAuthTreeSelect';
import CCTableTreeSelect from '@/components/widgets/CCTableTreeSelect';
import CCFilterSelect from '@/components/widgets/CCFilterSelect';
import CCSvgIcon from '@/components/widgets/CCSvgIcon';
import DataSourceIcon from '@/components/function/DataSourceIcon';
import CCPagination from './widgets/CCPagination';

Vue.component(VueCountdown.name, VueCountdown);

Vue.component('cc-iconfont', CCIconfont);
Vue.component('cc-label', CCLabel);
Vue.component('cc-region-select', CCRegionSelect);
Vue.component('cc-cluster-type-select', CCClusterTypeSelect);
Vue.component('cc-status', CCStatus);
Vue.component('cc-data-source-icon', CCDataSourceIcon);
Vue.component('cc-sms-button', CCSmsButton);
Vue.component('cc-password-input', CCPasswordInput);
Vue.component('cc-schema-tree-select', CCSchemaTreeSelect);
Vue.component('cc-auth-tree-select', CCAuthTreeSelect);
Vue.component('cc-table-tree-select', CCTableTreeSelect);
Vue.component('cc-filter-select', CCFilterSelect);
Vue.component('cc-pagination', CCPagination);
Vue.component('cc-svg-icon', CCSvgIcon);
Vue.component('data-source-icon', DataSourceIcon);
