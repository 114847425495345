import { instance } from '../instance';

export const listConsoleJobLabels = (param) => instance.post('constant/consolejoblabel', param);

export const listCloudOrIdcNames = (param) => instance.post('constant/cloudoridcname', param);

export const listWorkerTypes = (param) => instance.post('constant/workertype', param);

export const listRegions = (param) => instance.post('constant/region', param);

export const listDataJobTypes = (param) => instance.post('constant/datajobtype', param);

export const listDataTaskStates = (param) => instance.post('constant/datataskstate', param);

export const listConsoleTaskStates = (param) => instance.post('constant/consoletaskstate', param);

export const supportedsourcedstype = (param) => instance.post('constant/supportedsourcedstype', param);

export const supportedtargetdstype = (param) => instance.post('constant/supportedtargetdstype', param);

export const listSupportedRegions = (param) => instance.post('constant/supportedregion', param);

export const queryDeployMode = (param) => instance.post('constant/deploymode', param);

export const listHdfsFileFormat = (param) => instance.post('constant/listhdfsfileformatandcompress', param);

export const getGlobalSystemConfig = (param) => instance.post('/constant/global/config', param);

export const listHivePartitionFunc = (param) => instance.post('constant/listhivepartitionfunc', param);

export const ccGlobalConfig = (param, headers) => instance.post('cc_global_settings', param, headers);

export const listDataSourceTypesByDeployType = (param) => instance.post('constant/dstypebydeploytype', param);

export const defaultLicenseTypeUrl = (param) => instance.post('constant/defaultlicensetypeurl', param);

export const defaultDisplayLicenseSet = (param) => instance.post('constant/defaultdisplaylicenseset', param);

export const transformJobType = (param) => instance.post('constant/transformjobtype', param);

export const listDsSupportedRegions = (param) => instance.post('constant/dssupportedregion', param);

export const drdsPartitionAlgs = (param) => instance.post('constant/dspartition/polardbxpartitionalgs', param);

export const dsSecurityOption = (param) => instance.post('constant/security/dssecurityoption', param);

export const columnMappingRule = (param) => instance.post('constant/ds/columnmappingrule', param);

export const tableMappingRule = (param) => instance.post('constant/ds/tablemappingrule', param);

export const mqSchemaFormat = (param) => instance.post('constant/mqschemaformat', param);

export const dsCharsetOptions = (param) => instance.post('constant/dscharset/dscharsetoptions', param);

export const mqSchemaLessValueFormats = (param) => instance.post('constant/mqschemalessvalueformats', param);

export const cacheSchemaLessValueFormats = (param) => instance.post('constant/cacheschemalessvalueformats', param);

export const schemaLessValueFormatTemplate = (param) => instance.post('constant/schemalessvalueformattemplate', param);

export const listFilterDsTypes = (param) => instance.post('constant/listfilterdstypes', param);

export const ckTableEngine = (param) => instance.post('constant/cktableengine', param);

export const srOrDorisTableModel = (param) => instance.post('constant/srordoristablemodel', param);

export const getEsAnalyzerTypes = (param) => instance.post('constant/getesanalyzertypes', param);

export const dsPosTypes = (param) => instance.post('constant/dspostypes', param);

export const validExprFilter = (param) => instance.post('datarule/validexprfilter', param);

export const listLoopRunTypes = (param) => instance.post('constant/listloopruntypes', param);

export const editJobInitialSyncSwitch = (param) => instance.post('constant/editjobinitialsyncswitch', param);

export const noPkUkSyncUpdateDelete = (param) => instance.post('constant/nopkuksyncupdatedelete', param);

export const needTestBeforeAddDsTypes = (param) => instance.post('constant/needtestbeforeadddstypes', param);

export const showPositionConf = (param) => instance.post('constant/show_position_conf', param);

export const dsMainVersions = (param) => instance.post('constant/dsmainversions', param);

export const dsDrivers = (param) => instance.post('constant/dsdrivers', param);

export const listBlackUri = (param) => instance.post('constant/listblackuri', param);

export const oraInCrMode = (param) => instance.post('constant/ds/oraincrmode', param);

export const oraConnectType = (param) => instance.post('constant/ds/oraconnecttype', param);

export const haveDsKvConfig = (param) => instance.post('constant/ds/havedskvconfig', param);

export const tunnelValueFormats = (param) => instance.post('constant/tunnelvalueformats', param);

export const rebuildIndex = (param) => instance.post('schema/rebuildindex', param);

export const supportedDsType = (param) => instance.post('constant/supportedstype', param);

export const rdbKeyConflictStrategy = (param) => instance.post('constant/rdbtargetkeyconflictstrategy', param);

export const dsCheckReviseOpts = (param) => instance.post('constant/ds/dscheckreviseopts', param);

export const schemaQueryTypes = (param) => instance.post('constant/schemaquerytypes', param);

export const schemaFilterTypes = (param) => instance.post('constant/schemafiltertypes', param);

export const deploySite = (param) => instance.post('constant/global/deploysite', param);

export const listCountry = (param) => instance.post('constant/listcountry', param);

export const getMqOriginalDecodeMsgFormat = (param) => instance.post('constant/mqorigindecodemsgformats', param);

export const supportSrcDeployTypes = (param) => instance.post('constant/supportsrcdeploytypes', param);
export const supportDstDeployTypes = (param) => instance.post('constant/supportdstdeploytypes', param);
export const cleanTargetBeforeFullDsType = (param) => instance.post('constant/cleantargetbeforefulldstype', param);
export const schemaMappingRule = (param) => instance.post('constant/ds/schemamappingrule', param);
export const listDataJobKvTemplates = (param) => instance.post('constant/listdatajobkvtemplates', param);
export const listVirtualColGenRule = (param) => instance.post('constant/ds/listvirtualcolgenrule', param);
export const virtualColGenSupport = (param) => instance.post('constant/ds/virtualcolgensupport', param);
export const listVirtualColType = (param) => instance.post('constant/ds/listvirtualcoltype', param);
export const specifiedKeysSupport = (param) => instance.post('constant/ds/specifiedkeyssupport', param);
