<template>
  <div class="sql-viewer">
    <operators :onRun="onRun" :tab="tab"
               :handleCommit="handleCommit"
               :handleRollback="handleRollback" :handleStop="handleStop"
               :stopping="stopping" :store-query-tabs="storeQueryTabs" :format-sql="formatSql"
    />
    <Editor :set-editor-instance="setEditorInstance" :current-tab="tab" ref="editor"
            style="border-bottom: 1px solid #ccc" :completion-data="completionData" :store-query-tabs="storeQueryTabs"
            :rdb-table-detail="rdbTableDetail" :on-run="onRun"/>
    <div class="editor-resize"/>
    <div class="sql-error" v-if="errorMessage&&showError">
      <a-icon type="close" class="sql-error-close-btn" @click="handleCloseError"/>
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor';
import { Modal } from 'ant-design-vue';
import { mapGetters, mapState } from 'vuex';
import Operators from '@/views/sql/components/Operators';
import { MYSQL_DATA_TYPE } from '@/const';
import { chunk } from 'xe-utils';
import Editor from '@/components/editor';
import { isRedis } from '@/const/dataSource';
import Cookies from 'js-cookie';
import { hasSchema } from '../../../utils/index';
import sqlMixin from '../../../mixins/sqlMixin';

export default {
  name: 'SqlViewer',
  components: {
    Editor,
    Operators
  },
  props: {
    rdbTableDetail: Function,
    completionData: Object,
    handleRunAsync: Function,
    tab: Object,
    storeQueryTabs: Function,
    createSession: Function,
    updateResultSpinning: Function
  },
  computed: {
    ...mapGetters([
      'isDesktop'
    ]),
    ...mapState(['productClusterList']),
    getDmProductClusterList() {
      const dmList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudDM') {
          dmList.push(cluster);
        }
      });
      return dmList;
    }
  },
  mixins: [sqlMixin],
  data() {
    return {
      canRun: true,
      stopping: false,
      editorOptions: {
        mode: 'text/javascript'
      },
      monacoEditor: null,
      newCode: '',
      showSql: '',
      resultArray: [],
      code: '',
      columnNameList: [],
      tables: [],
      errorMessage: '',
      ws: null,
      showError: false
    };
  },
  methods: {
    formatSql() {
      this.$refs.editor.formatSql();
    },
    setEditorInstance(editor) {
      this.monacoEditor = editor;
    },
    setSql(sql) {
      const position = this.monacoEditor.getPosition();
      let text = sql;
      if (position.column !== 1) {
        text = `\n${sql}`;
      } else {
        text = `${sql}\n`;
      }

      const range = new monaco.Range(position.lineNumber,
        position.column,
        position.lineNumber,
        position.column);
      this.monacoEditor.executeEdits('', [
        {
          range,
          text
        }
      ]);
      this.monacoEditor.focus();

      let selectRange;
      if (position.column !== 1) {
        selectRange = new monaco.Range(position.lineNumber + 1,
          position.column,
          position.lineNumber + 1,
          position.column + sql.length);
      } else {
        selectRange = new monaco.Range(position.lineNumber,
          position.column,
          position.lineNumber,
          position.column + sql.length);
      }

      this.monacoEditor.setSelection(selectRange);
    },
    getColumnsList(res) {
      // const list = [];
      const list = [{
        type: 'seq',
        width: 50,
        title: this.$t('xu-hao'),
        fixed: 'left',
        className: 'seq-content',
        headerClassName: 'seq-header'
      }];
      // 这边根据列和值来算width，值取一批的最长值，另需要设置最小宽度
      if (res.columnList) {
        res.columnList.forEach((item, index) => {
          const minWidth = 100;
          const maxWidth = 200;
          let width = 0;
          if (res.resultSet && res.resultSet[0]) {
            const value = res.resultSet[0][index].value;
            if (value && value.length > item.length) {
              width = value.length * 5 + 80;
            }
          }
          if (width === 0) {
            width = item.length * 5 + 80;
          }

          if (width < minWidth) {
            width = minWidth;
          }

          if (width > maxWidth) {
            width = maxWidth;
          }

          let cellRenderName = 'input';
          if (MYSQL_DATA_TYPE.TIME.includes(item)) {
            cellRenderName = 'time';
          }
          list.push({
            field: item,
            title: item,
            width,
            // type: res.columnType[index],
            cellRender: { name: `vxe-${cellRenderName}-tpl` }
          });
        });
      }
      return list;
    },
    async handleRun(selectedSql) {
      this.tab.running = true;
      this.updateResultSpinning(true);
      this.errorMessage = '';
      this.showError = false;
      if (!this.tab.sessionId) {
        await this.createSession(this.tab);
      }
      const data = {
        type: 'WS_EXEC',
        object: {
          sessionId: this.tab.sessionId,
          queryString: selectedSql,
          dataSourceId: this.tab.node.INSTANCE.id,
          currentDb: hasSchema(this.tab.dsType) ? this.tab.node.CATALOG.id : null,
          currentSchema: isRedis(this.tab.dsType) ? null : this.tab.node.SCHEMA.id,
          rdbAutoCommit: this.tab.autoCommit
        }
      };
      this.createWebSocket();
      if (!this.tab.autoCommit) {
        this.tab.readyToCommit = true;
        this.storeQueryTabs();
      }
      this.ws.onclose = () => {
        console.log(`llws连接关闭!${new Date().toLocaleString()}`);
        if (this.tab.result.list && this.tab.result.list.length) {
          this.tab.result.active = this.tab.result.list[this.tab.result.list.length - 1].startTimestamp;
        }
      };
      this.ws.onerror = (e) => {
        console.log('llws连接错误!', e);
        this.tab.running = false;
        this.updateResultSpinning(false);
      };
      this.ws.onopen = () => {
        console.log(`llws连接成功!${new Date().toLocaleString()}`, data);
        this.tab.result.active = 'message';
        this.ws.send(JSON.stringify(data));
      };
      this.ws.onmessage = (event) => { // 如果获取到消息，心跳检测重置
        const queryData = JSON.parse(event.data);
        if (queryData.type === 'WS_RESULT' || queryData.type === 'WS_FAILED') {
          if (queryData.object.success) {
            const { resultSet, columnList } = queryData.object;
            queryData.object.columnListSeq = this.getColumnsList(queryData.object);
            const list = [];
            if (resultSet) {
              resultSet.forEach((item) => {
                const currentRow = {};
                for (let i = 0; i < columnList.length; i++) {
                  currentRow[columnList[i]] = item[i].value;
                }
                list.push(currentRow);
              });
            }

            const dataArr = chunk(list, 50);
            Object.assign(queryData.object, {
              page: 1,
              size: 50,
              total: resultSet ? resultSet.length : 0,
              data: resultSet,
              dataArr,
              showData: resultSet ? dataArr[0] : []
            });
            const len = this.tab.result.list.length;
            queryData.object.showIndex = len ? this.tab.result.list[len - 1].showIndex + 1 : 1;
            this.tab.result.list.push(queryData.object);
          }
          if (!this.tab.executeInfo) {
            this.tab.executeInfo = [];
          }
          this.tab.executeInfo.unshift({
            database: this.tab.node[this.tab.node.levels[this.tab.node.levels.length - 1]].name,
            queryBody: queryData.object.queryBody,
            message: queryData.object.message,
            success: queryData.object.success,
            time: queryData.time
          });
        } else if (queryData.type === 'WS_ERROR') {
          if (queryData.object.code === 507) {
            const next = () => {
              this.onRun();
            };
            this.createSession(this.tab, next);
          } else {
            this.errorMessage = `【error】${queryData.object.object}`;
          }
          this.showError = true;
        } else if (queryData.type === 'WS_RULES') {
          this.errorMessage = queryData.object.ruleNames.join('；');
          this.showError = true;
        } else if (queryData.type === 'WS_DONE') {
          this.ws.close();
          this.tab.running = false;
          this.updateResultSpinning(false);
        }
      };
    },
    handleRunTicket(selectedSql) {
      this.goTicketPage(selectedSql);
    },
    async onRun(type = 'run', asyncForm) {
      this.storeQueryTabs();
      if (window._hmt && this.isDesktop) {
        window._hmt.push(['_trackEvent', 'execute sql', 'uid', 'personal']);
      }
      let selectedSql = '';
      if (type !== 'asyncList') {
        selectedSql = this.monacoEditor.getModel().getValueInRange(this.monacoEditor.getSelection());
        if (!selectedSql) {
          console.log(this.$refs.editor.getCurrentSql());
          selectedSql = this.$refs.editor.getCurrentSql();
        }
        if (!selectedSql) {
          Modal.warning({
            title: this.$t('zhi-hang-yi-chang-ti-shi'),
            content: this.$t('nin-dang-qian-mei-you-xuan-zhong-ren-he-sql-yu-ju-qing-xuan-zhong-hou-zai-zhi-hang'),
            okText: this.$t('zhi-dao-le')
          });
          return;
        }
      }

      switch (type) {
        case 'run':
          await this.handleRun(selectedSql);
          break;
        case 'runAsync':
          this.tab.showAsyncListTab = false;
          await this.handleRunAsync(selectedSql, asyncForm);
          await this.onRun('asyncList');
          break;
        case 'ticket':
          this.handleRunTicket(selectedSql);
          break;
        case 'asyncList':
          this.tab.showAsyncListTab = false;
          if (!this.tab.showAsyncListTab) {
            this.tab.showAsyncListTab = true;
          } else {
            window.$bus.emit('asyncJobListRefresh');
            window.$bus.emit('asyncTaskListBack');
          }
          break;
        default:
          break;
      }
      // this.analysisSplit(selectedSql);
    },
    async commit() {
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.commit({ data });
      this.tab.running = false;
      if (res.success) {
        this.tab.readyToCommit = false;
        this.$message.success(this.$t('ti-jiao-cheng-gong'));
      } else if (res.code === '7002') {
        this.tab.readyToCommit = false;
      }
      this.storeQueryTabs();
    },
    handleCommit() {
      this.commit();
    },
    async handleRollback() {
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.rollBack({ data });
      this.tab.running = false;
      if (res.success) {
        this.tab.readyToCommit = false;
        this.$message.success(this.$t('hui-gun-cheng-gong'));
      } else if (res.code === '7002') {
        this.tab.readyToCommit = false;
      }
      this.storeQueryTabs();
    },
    async cancelQuery() {
      this.stopping = true;
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.cancelQuery({ data });
      if (res.success) {
        this.tab.running = false;
        this.updateResultSpinning(false);
      }
      this.stopping = false;
    },
    handleStop() {
      this.cancelQuery();
      this.storeQueryTabs();
    },
    createWebSocket() {
      try {
        if ('WebSocket' in window) {
          const protocal = document.location.protocol;
          let wsPrex = 'ws';
          if (protocal === 'https:') {
            wsPrex = 'wss';
          }
          // const host = document.location.host;
          let host = '';
          if (process.env.NODE_ENV === 'development') {
            host = 'localhost:8222';
          } else {
            if (this.getDmProductClusterList.length > 0) {
              if (this.getDmProductClusterList[0].apiAddr) {
                host = this.getDmProductClusterList[0].apiAddr.split('//')[1];
              }
            } else {
              host = document.location.host;
            }
          }
          // if (this.getDmProductClusterList.length > 0) {
          //   if (this.getDmProductClusterList[0].apiAddr) {
          //     host = this.getDmProductClusterList[0].apiAddr.split('//')[1];
          //   }
          // } else {
          //   host = document.location.host;
          // }
          const cookies = Cookies.get();
          this.ws = new WebSocket(`${wsPrex}://${host}/clouddm/console/api/v1/query_socket?jwt_token=${cookies.jwt_token}`);
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = this.$t('chuang-jian-websocket-lian-jie-shi-bai-qing-zhong-shi');
      }
    },
    handleCloseError() {
      this.showError = false;
    }
  }
};
</script>
<style lang="less">
.CodeMirror {
  height: 250px !important;
  border-bottom: 1px solid #ddd;
  font-family: monospace;
}

.codemirror-wrapper {
  position: relative;
}

.sql-viewer {
  position: relative;

  .editor-resize {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: -3px;
    width: 100%;
    background: #fff;
    cursor: row-resize;
    //border-bottom: 1px solid #ddd;
    height: 6px;
    background: rgba(0, 0, 0, 0);
  }

  .sql-error {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #FFDCDC;
    padding: 10px 12px;
    z-index: 4;
    max-height: 200px;
    overflow: auto;

    .sql-error-close-btn {
      position: absolute;
      right: 20px;
      top: 12px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
