<template>
  <div class="function-config-container">
    <Form ref="taskInfo" :model="taskInfo" label-position="right" :label-width="120"
          :rules="ruleValidate">
      <FormItem :label="$t('ren-wu-lei-xing')" key="type" prop="type">
        <RadioGroup v-model="taskInfo.type" type="button" @on-change="handleChangeType">
          <Radio label="MIGRATION" :disabled="!taskInfo.jobTypeList.MIGRATION || isCreateFromCheck">
            {{ Mapping.jobType.MIGRATION }}
          </Radio>
          <Radio label="SYNC" :disabled="!taskInfo.jobTypeList.SYNC || isCreateFromCheck">
            {{ Mapping.jobType.SYNC }}
          </Radio>
          <Radio label="CHECK" :disabled="!taskInfo.jobTypeList.CHECK || isCreateFromCheck">
            {{ Mapping.jobType.CHECK }}
          </Radio>
          <Radio label="STRUCT_MIGRATION" :disabled="!taskInfo.jobTypeList.STRUCT_MIGRATION || isCreateFromCheck">
            {{ Mapping.jobType.STRUCT_MIGRATION }}
          </Radio>
        </RadioGroup>
        <Poptip trigger="hover" placement="right" class="create-job-tip" style="margin-left: 12px;">
          <i class="iconfont icontips"></i>
          <div slot="content">
            <div style="margin-bottom: 20px">
              <p class="tip-title">{{ $t('quan-liang-qian-yi') }}</p>
              <div class="tip-content">{{ $t('yi-shu-ju-qian-yi-wei-zhu-shi-he-shu-ju-de-quan-liang-ban-qian-ji-duan-qi-de-zeng-liang-tong-bu-ren-wu') }}</div>
              <p class="tip-title">{{ $t('zeng-liang-tong-bu') }}</p>
              <div class="tip-content">{{ $t('yi-shu-ju-tong-bu-wei-zhu-shi-he-chang-qi-de-zeng-liang-tong-bu-ren-wu') }}</div>
              <p class="tip-title">{{ $t('xiao-yan-yu-ding-zheng') }}</p>
              <div class="tip-content">{{ $t('dui-bi-yuan-duan-yu-mu-biao-duan-de-shu-ju-yi-ci-xing-huo-ding-shi-duo-ci-xiao-yan-shu-ju-qian-yi-de-zhun-que-xing') }}</div>
              <p class="tip-title">{{ $t('jie-gou-qian-yi') }}</p>
              <div class="tip-content">{{ $t('gen-ju-suo-xuan-shu-ju-ku-biao-zi-dong-chuang-jian-dui-ying-de-shu-ju-ku-biao') }}</div>
            </div>
          </div>
        </Poptip>
        <div style="display: flex">
          <div class="function-extra" style="margin-right: 20px;"
               v-if="taskInfo.jobTypeList.MIGRATION&&taskInfo.jobTypeList.MIGRATION.Incremental&&taskInfo.type==='MIGRATION'">
            <Checkbox v-model="taskInfo.mode.synchronize" style="margin-right: 0"
                      @on-change="handleSynchronize">{{ $t('zeng-liang-tong-bu-1') }}
            </Checkbox>
            <Select v-model="taskInfo.mode.shortTermNum" :disabled="!taskInfo.mode.synchronize"
                    style="width: 100px;margin-left: 10px">
              <Option :value="1">{{ $t('1-tian') }}</Option>
              <Option :value="7">{{ $t('7-tian') }}</Option>
              <Option :value="15">{{ $t('15-tian') }}</Option>
              <Option :value="30">{{ $t('30-tian') }}</Option>
            </Select>
          </div>
          <div class="function-extra" v-if="taskInfo.jobTypeList[taskInfo.type]&&taskInfo.jobTypeList[taskInfo.type].Scheduling">
            <Checkbox v-model="taskInfo.fullPeriod" style="margin-right: 0"
                      @on-change="handleChangeFullPeriod">{{ $t('ding-shi-qian-yi') }}
            </Checkbox>
            <div v-if="taskInfo.fullPeriod" style="display: inline-block;margin-left: 10px">
              <span style="margin-right: 5px">{{ $t('mei') }}</span>
              <Select v-model="taskInfo.fullPeriodDate.dayType" style="width: 100px;margin-right: 10px">
                <Option v-for="type in taskInfo.loopRunTypes" :value="type.loopRunType" :key="type.loopRunType">{{type.i18nName}}</Option>
              </Select>
              <Select v-model="taskInfo.fullPeriodDate.month" placeholder="select month"
                      v-if="taskInfo.fullPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(month,index) of monthList" :key="index" :value="month">
                  {{month}}
                </Option>
              </Select>
              <Select v-model="taskInfo.fullPeriodDate.date" placeholder="select date"
                      v-if="taskInfo.fullPeriodDate.dayType==='MONTH'||taskInfo.fullPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(date,index) of dateList" :key="index" :value="date">
                  {{date}}
                </Option>
              </Select>
              <Select v-model="taskInfo.fullPeriodDate.week"
                      v-if="taskInfo.fullPeriodDate.dayType==='WEEK'" style="width: 100px;margin-right: 10px">
                <Option key="1" value="1">
                  {{ $t('ri-0') }}
                </Option>
                <Option key="2" value="2">
                  {{ $t('yi-1') }}
                </Option>
                <Option key="3" value="3">
                  {{ $t('er-0') }}
                </Option>
                <Option key="4" value="4">
                  {{ $t('san-0') }}
                </Option>
                <Option key="5" value="5">
                  {{ $t('si-0') }}
                </Option>
                <Option key="6" value="6">
                  {{ $t('wu-1') }}
                </Option>
                <Option key="7" value="7">
                  {{ $t('liu-0') }}
                </Option>
              </Select>
              <TimePicker v-if="taskInfo.fullPeriodDate.dayType!=='HOUR'"
                          v-model="taskInfo.fullPeriodDate.time" format="HH:mm"
                          placeholder="Select time" style="width: 112px"></TimePicker>
              <Select v-model="taskInfo.fullPeriodDate.hour"
                      v-if="taskInfo.fullPeriodDate.dayType==='HOUR'" style="width: 100px">
                <Option v-for="(minute,index) of minutes" :key="index" :value="minute">
                  {{ minute &lt; 10 ? '0' + minute : minute }}
                </Option>
              </Select>
              <span v-if="taskInfo.fullPeriodDate.dayType==='HOUR'" style="margin-left: 4px">{{ $t('fen') }}</span>
            </div>
          </div>
          <div class="function-extra" v-if="taskInfo.type==='REVISE'">
            {{ $t('guan-lian-de-xiao-yan-ren-wu') }}
            <Select v-model="taskInfo.reviseBindCheckTaskId" style="width: 200px;" :disabled="isCreateFromCheck">
              <Option v-for="checkJob in checkTaskList" :value="checkJob.reviseTaskId" :key="checkJob.reviseTaskId">{{checkJob.reviseTaskId}}({{checkJob.reviseJobDesc || checkJob.reviseTaskName }})</Option>
            </Select>
          </div>
        </div>
        <Checkbox class="function-extra"
                  v-if="taskInfo.jobTypeList.SYNC&&taskInfo.jobTypeList.SYNC.FullAmount&&taskInfo.type==='SYNC'"
                  v-model="taskInfo.mode.init">{{ $t('quan-liang-chu-shi-hua') }}
        </Checkbox>
      </FormItem>
      <FormItem :label="$t('ren-wu-gui-ge')" key="fullJVMHeapMB" prop="fullJVMHeapMB" v-if="taskInfo.type!=='STRUCT_MIGRATION'">
        <RadioGroup v-model="taskInfo.specKind" type="button" @on-change="handleChangeSpecKind">
          <Radio v-if="taskInfo.specsMap.IncreEnhance" label="IncreEnhance">{{ $t('zeng-liang-zeng-qiang-xing') }}</Radio>
          <Radio v-if="taskInfo.specsMap.FullEnhance" label="FullEnhance">{{ $t('quan-liang-zeng-qiang-xing') }}</Radio>
          <Radio v-if="taskInfo.specsMap.Balance" label="Balance">{{ $t('ping-heng-xing') }}</Radio>
        </RadioGroup>
        <Table style="width: 760px;margin-top: 10px" size="small" stripe border
               :columns="standardColumns" :data="taskInfo.specsMap[taskInfo.specKind]"
               @on-row-click="handleSelectStandard">
          <template slot-scope="{row,index}" slot="selectRadio">
            <div>
              <Radio @on-change="handleSelectStandard(row)"
                     v-model="taskInfo.specsMap[taskInfo.specKind][index].selected"></Radio>
            </div>
          </template>
        </Table>
        <p class="memory-tip">{{ $t('ren-wu-yun-hang-gui-ge') }}</p>
        <p v-if="taskInfo.spec && taskInfo.type!=='STRUCT_MIGRATION'" style="font-weight: 500">
          {{ $t('quan-liang-nei-cun-taskinfospecfullmemorymb-1024-g-zeng-liang-nei-cun-taskinfospecincrememorymb-1024-gxiao-yan-nei-cun-taskinfospeccheckmemorymb-1024-g-miao-shu-taskinfospecdescription', [taskInfo.spec.fullMemoryMb / 1024, taskInfo.spec.increMemoryMb / 1024, taskInfo.spec.checkMemoryMb / 1024, taskInfo.spec.description]) }}
        </p>
      </FormItem>
      <FormItem :label="$t('ren-wu-miao-shu')" key="desc2" prop="desc">
        <Input v-model="taskInfo.desc" type="textarea" maxlength="300" style="width: 300px"/>
      </FormItem>
      <FormItem
        v-if="(taskInfo.mode.synchronize||taskInfo.type==='SYNC')&&taskInfo.type!=='CHECK'&&taskInfo.type!=='REVISE'&&taskInfo.type!=='STRUCT_MIGRATION'"
        :label="$t('shi-fou-tong-bu-ddl')">
        <RadioGroup v-model="taskInfo.ddl">
          <Radio v-if="!(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.DisableDdlSync && JSON.parse(this.taskInfo.jobTypeList.SYNC.DisableDdlSync))" label="false">{{ $t('quan-bu-tong-bu') }}
          </Radio>
          <Radio label="true">{{ $t('bu-tong-bu') }}
          </Radio>
        </RadioGroup>
      </FormItem>
      <!--            <FormItem label="迁移白名单">-->
      <!--                <CheckboxGroup v-model="taskInfo.whiteList" style="display: inline-block">-->
      <!--                    <Checkbox label="db">新增库</Checkbox>-->
      <!--                    <Checkbox label="table">新增表</Checkbox>-->
      <!--                    <Checkbox label="column">新增列</Checkbox>-->
      <!--                </CheckboxGroup>-->
      <!--                <Tooltip placement="right-start" style="display: inline-block">-->
      <!--                    <Icon type="ios-help-circle-outline" style="font-size: 14px;"/>-->
      <!--                    <div slot="content">-->
      <!--                        <P>选择迁移白名单后，迁移或同步过程中有新增的库表列系统将自动为其进行迁移</P>-->
      <!--                    </div>-->
      <!--                </Tooltip>-->
      <!--            </FormItem>-->
      <FormItem :label="$t('shi-fou-kai-qi-xiao-yan')" key="mode" prop="mode" v-if="taskInfo.jobTypeList.CHECK">
        <RadioGroup v-model="taskInfo.checkMode"
                    style="display: inline-block;vertical-align: middle">
          <Radio label="noCheck" v-if="taskInfo.type!=='CHECK'">{{ $t('bu-kai-qi-xiao-yan') }}</Radio>
          <Radio label="checkOnce"
                 v-if="taskInfo.type !== 'STRUCT_MIGRATION'&&taskInfo.type !== 'REVISE'">{{ $t('kai-qi-yi-ci-xing-xiao-yan') }}
          </Radio>
          <Radio label="checkPeriod"
                 v-if="taskInfo.type!=='MIGRATION'&&taskInfo.type !== 'STRUCT_MIGRATION'&&taskInfo.type !== 'REVISE'">
            {{ $t('kai-qi-zhou-qi-xing-xiao-yan') }}
          </Radio>
        </RadioGroup>
        <div v-if="taskInfo.checkMode==='checkPeriod'" style="display: inline-block;margin-left: 10px;vertical-align:middle">
          <span style="margin-right: 5px">{{ $t('mei') }}</span>
          <Select v-model="taskInfo.checkPeriodDate.dayType" style="width: 100px;margin-right: 10px">
            <Option v-for="type in taskInfo.loopRunTypes" :value="type.loopRunType" :key="type.loopRunType">{{type.i18nName}}</Option>
          </Select>
          <Select v-model="taskInfo.checkPeriodDate.month" placeholder="select month"
                  v-if="taskInfo.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
            <Option v-for="(month,index) of monthList" :key="index" :value="month">
              {{month}}
            </Option>
          </Select>
          <Select v-model="taskInfo.checkPeriodDate.date" placeholder="select date"
                  v-if="taskInfo.checkPeriodDate.dayType==='MONTH'||taskInfo.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
            <Option v-for="(date,index) of dateList" :key="index" :value="date">
              {{date}}
            </Option>
          </Select>
          <Select v-model="taskInfo.checkPeriodDate.week"
                  v-if="taskInfo.checkPeriodDate.dayType==='WEEK'" style="width: 100px;margin-right: 10px">
            <Option key="1" value="1">
              {{ $t('ri-0') }}
            </Option>
            <Option key="2" value="2">
              {{ $t('yi-1') }}
            </Option>
            <Option key="3" value="3">
              {{ $t('er-0') }}
            </Option>
            <Option key="4" value="4">
              {{ $t('san-0') }}
            </Option>
            <Option key="5" value="5">
              {{ $t('si-0') }}
            </Option>
            <Option key="6" value="6">
              {{ $t('wu-1') }}
            </Option>
            <Option key="7" value="7">
              {{ $t('liu-0') }}
            </Option>
          </Select>
          <TimePicker v-if="taskInfo.checkPeriodDate.dayType!=='HOUR'"
                      v-model="taskInfo.checkPeriodDate.time" format="HH:mm"
                      placeholder="Select time" style="width: 112px"></TimePicker>
          <Select v-model="taskInfo.checkPeriodDate.hour"
                  v-if="taskInfo.checkPeriodDate.dayType==='HOUR'" style="width: 100px">
            <Option v-for="(minute,index) of minutes" :key="index" :value="minute">
              {{ minute &lt; 10 ? '0' + minute : minute }}
            </Option>
          </Select>
          <span v-if="taskInfo.checkPeriodDate.dayType==='HOUR'" style="margin-right: 4px">{{ $t('fen') }}</span>
        </div>
      </FormItem>
      <div v-if="taskInfo.checkMode !== 'noCheck'">
        <FormItem :label="$t('shu-ju-xiao-yan-mo-shi')" key="dataCheckType" prop="mode">
          <RadioGroup v-model="taskInfo.dataCheckType"
                      style="display: inline-block;vertical-align: middle">
            <Radio v-for="opt of checkReviseOpts" :label="opt.dataCheckType" :key="opt.dataCheckType">{{opt.i18nName}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem :label="$t('xiao-yan-ding-zheng-mo-shi')" key="dataReviseType" prop="mode">
          <RadioGroup v-model="taskInfo.dataReviseType"
                      style="display: inline-block;vertical-align: middle">
            <Radio v-for="type of selectedCheckType.reviseTypeVos" :label="type.dataReviseType" :key="type.dataReviseType">{{type.i18nName}}</Radio>
          </RadioGroup>
        </FormItem>
      </div>
      <FormItem
        v-if="cleanTargetBeforeFullDsTypeList.includes(taskInfo.sinkType)&&(taskInfo.type==='MIGRATION'||(taskInfo.type==='SYNC'&&taskInfo.initialSync))"
        :label="$t('quan-liang-qian-shi-fou-qing-kong-mu-biao-duan-shu-ju')">
        <i-switch true-color="#52C41A" v-model="taskInfo.cleanTargetBeforeFull"></i-switch>
      </FormItem>
      <FormItem
        v-if="cleanTargetBeforeFullDsTypeList.includes(taskInfo.sinkType)&&(taskInfo.type==='MIGRATION'||(taskInfo.type==='SYNC'&&taskInfo.initialSync))"
        :label="$t('quan-liang-qian-zhong-jian-yi-cun-zai-de-mu-biao-biao')">
        <i-switch true-color="#52C41A" v-model="taskInfo.reSchemaMigration"></i-switch>
      </FormItem>
      <FormItem :label="$t('zi-dong-qi-dong-ren-wu')">
        <i-switch true-color="#52C41A" v-model="taskInfo.autoStart"></i-switch>
      </FormItem>
    </Form>
    <div>
      <Modal
        v-model="showPreCheck"
        :closable="false"
        :footer-hide="true"
        :mask-closable="false"
        width="1000px"
        :title="$t('yu-jian-shi-bai')">
        <div>
          <div class="preCheck-title">
            <span class="preCheck-title-desc">{{ preCheckStatusDesc[preCheckStatus].desc }}</span>
            {{ $t('gong-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItems.length }}</span>
            {{ $t('xiang-yi-jian-cha') }}<span class="preCheck-title-count">{{ preCheckItemsCheckedCount }}</span>{{ $t('xiang-shi-bai') }}
            <span class="preCheck-title-count">{{ preCheckItemsErrorCount }}</span>{{ $t('xiang') }}
          </div>
          <div style="max-height: 400px;overflow: auto">
            <Table stripe border size="small" :columns="preCheckColumns" :data="preCheckItems">
              <template slot-scope="{ row }" slot="preCheckResult">
                <div style="font-size: 18px">
                  <Icon type="md-checkmark" style="color: #19be6b" v-if="row.success&&row.checked"/>
                  <Icon type="md-close" style="color: #ed4014" v-if="!row.success&&row.checked"/>
                  <span v-if="!row.checked" style="font-size: 12px;color: #808695;">{{ $t('dai-jian-cha') }}</span>
                </div>
              </template>
            </Table>
          </div>
          <p style="margin: 20px 0;text-align: center">
            <pd-button style="width: 120px;height: 32px;line-height: 30px" type="default"
                       @click="handleHidePrecheck(false)">{{ $t('guan-bi') }}
            </pd-button>
          </p>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import { listCheckTasks, listSpecifications, preCheckStepOne } from '@/services/cc/api/job';
import {
  transformJobType,
  listLoopRunTypes,
  dsCheckReviseOpts,
  cleanTargetBeforeFullDsType
} from '@/services/cc/api/constant';
import Mapping from '@/views/util';
import { isRedis } from '@/utils';
import DataSourceGroup from '@/views/dataSourceGroup';
import store from '@/store/index';
import CreateJobMixin from '../../mixin/createJobMixin';

export default {
  name: 'functionConfig',
  mixins: [CreateJobMixin],
  props: {
    updateStep: Function,
    taskInfo: Object,
    updateShowAddDataSource: Function,
    updateShowAddCluster: Function,
    deployType: String,
    updateLoading: Function,
    updateNextDisabled: Function,
    handleCreateTask: Function
  },
  data() {
    return {
      checkTaskList: [],
      store,
      DataSourceGroup,
      jobTypeList: '',
      specsList: [],
      resourceData: [],
      loopRunTypes: [],
      dateList: [],
      monthList: [],
      checkReviseOpts: [],
      selectedCheckType: {},
      standardColumns: [
        {
          title: ' ',
          key: '',
          width: 60,
          slot: 'selectRadio'
        },
        {
          title: this.$t('quan-liang-nei-cun'),
          key: 'fullMemoryMb',
          minWidth: 120,
          render: (h, params) => h('div', {}, `${params.row.fullMemoryMb / 1024}G`)
        },
        {
          title: this.$t('zeng-liang-nei-cun'),
          key: 'increMemoryMb',
          minWidth: 120,
          render: (h, params) => h('div', {}, `${params.row.increMemoryMb / 1024}G`)
        },
        {
          title: this.$t('xiao-yan-nei-cun'),
          key: 'checkMemoryMb',
          minWidth: 120,
          render: (h, params) => h('div', {}, `${params.row.checkMemoryMb / 1024}G`)
        },
        {
          title: this.$t('miao-shu'),
          key: 'description',
          minWidth: 400
        }
      ],
      showPreCheck: false,
      preCheckStatusDesc: {
        processing: {
          desc: this.$t('yu-jian-zhong'),
          color: ''
        },
        success: {
          desc: this.$t('yu-jian-cheng-gong'),
          color: '#19be6b'
        },
        error: {
          desc: this.$t('yu-jian-shi-bai'),
          color: '#ed4014'
        }
      },
      preCheckItems: [],
      preCheckItemsMap: {},
      preCheckItemsSuccessCount: 0,
      preCheckItemsErrorCount: 0,
      preCheckItemsCheckedCount: 0,
      preCheckStatus: 'error',
      preCheckColumns: [
        {
          title: this.$t('lei-xing'),
          key: 'preCheckType',
          width: 220
        },
        {
          title: this.$t('biao-ti'),
          key: 'title'
        },
        {
          title: this.$t('cuo-shi'),
          key: 'passRequirement'
        },
        {
          title: this.$t('shi-bai-mu-biao'),
          render: (h, params) => {
            let value = '';

            if (params.row.contextDbName) {
              if (params.row.contextTableName) {
                if (params.row.contextColumnName) {
                  if (params.row.varActualValue) {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}:${params.row.varActualValue}`;
                  } else {
                    value = `${params.row.contextDbName}.${params.row.contextTableName}.${params.row.contextColumnName}`;
                  }
                } else {
                  value = `${params.row.contextDbName}.${params.row.contextTableName}`;
                }
              } else {
                value = `${params.row.contextDbName}`;
              }
            }
            return h('div', {}, params.row.checked && !params.row.success ? value : '');
          }
        },
        {
          title: this.$t('jian-cha-jie-guo'),
          slot: 'preCheckResult',
          width: 120
        }
      ],
      minutes: [],
      Mapping,
      ruleValidate: {
        type: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-ren-wu-lei-xing'),
            trigger: 'change'
          }
        ],
        fullJVMHeapMB: [
          {
            required: true,
            type: 'number',
            message: this.$t('qing-xuan-ze-ren-wu-gui-ge'),
            trigger: 'change'
          }
        ]
      },
      cleanTargetBeforeFullDsTypeList: []
    };
  },
  computed: {
    isCreateFromCheck() {
      return store.state.jobData && store.state.jobData.createReviseTask;
    }
  },
  mounted() {
    this.updateNextDisabled(false);
    if (!this.taskInfo.functionalConfigHistory) {
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) > -1) {
        this.taskInfo.type = 'SYNC';
        this.taskInfo.mode.init = false;
      }
      transformJobType({
        sourceType: this.taskInfo.sourceType,
        targetType: this.taskInfo.sinkType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.jobTypeList = res.data.data.optionType;
            if (!store.state.jobData) {
              Object.keys(this.taskInfo.jobTypeList)
                .map((jobType) => {
                  if (this.taskInfo.jobTypeList[jobType].defaultCheck) {
                    this.taskInfo.type = jobType;
                  }
                  return null;
                });
            }
            if (this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.DisableDdlSync) {
              this.taskInfo.ddl = (this.taskInfo.jobTypeList.SYNC.DisableDdlSync).toString();
            } else {
              this.taskInfo.ddl = 'false';
            }

            this.taskInfo.mode.init = Boolean(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.FullAmount);
            this.taskInfo.mode.synchronize = false;
            this.listSpecifications();
          }
        });
      listLoopRunTypes().then((res) => {
        if (res.data.code === '1') {
          this.taskInfo.loopRunTypes = res.data.data;
        }
      });
    }

    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
    for (let i = 1; i < 32; i++) {
      this.dateList.push(i);
    }
    for (let i = 1; i < 13; i++) {
      this.monthList.push(i);
    }

    this.getDsCheckReviseopts();
    this.cleanTargetBeforeFullDsType();
  },
  methods: {
    async getDsCheckReviseopts() {
      const res = await dsCheckReviseOpts({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      });
      if (res.data.success) {
        this.checkReviseOpts = res.data.data;
        this.checkReviseOpts.forEach((opt) => {
          if (opt.defaultCheck) {
            this.selectedCheckType = opt;
            if (!this.taskInfo.dataCheckType) {
              this.taskInfo.dataCheckType = opt.dataCheckType;
            }
            opt.reviseTypeVos.forEach((type) => {
              if (type.defaultCheck) {
                if (!this.taskInfo.dataReviseType) {
                  this.taskInfo.dataReviseType = type.dataReviseType;
                }
              }
            });
          }
        });
      }
    },
    async cleanTargetBeforeFullDsType() {
      const res = await cleanTargetBeforeFullDsType();
      if (res.data.success) {
        this.cleanTargetBeforeFullDsTypeList = res.data.data;
      }
    },
    handleChangeType(value) {
      this.taskInfo.firstToTableFilter = false;
      this.taskInfo.firstToCleanData = false;
      if (value === 'CHECK') {
        this.taskInfo.checkMode = 'checkOnce';
      } else {
        this.taskInfo.checkMode = 'noCheck';
      }
      this.taskInfo.fullPeriod = false;
      this.listSpecifications();
      if (value === 'MIGRATION') {
        this.taskInfo.mode.synchronize = false;
      } else if (value === 'SYNC') {
        this.taskInfo.mode.init = Boolean(this.taskInfo.jobTypeList.SYNC && this.taskInfo.jobTypeList.SYNC.FullAmount);
      } else if (value === 'REVISE') {
        this.getCheckTaskList();
      }
    },
    async getCheckTaskList() {
      const res = await listCheckTasks();
      if (res.data.success) {
        this.checkTaskList = res.data.data;
      }
    },
    handleSynchronize(checked) {
      if (checked) {
        this.taskInfo.fullPeriod = false;
      }
      this.listSpecifications();
    },
    handleChangeFullPeriod(checked) {
      if (checked && this.taskInfo.type === 'MIGRATION') {
        this.taskInfo.mode.synchronize = false;
      }
      this.listSpecifications();
    },
    handleHidePrecheck() {
      this.showPreCheck = false;
    },
    handleShowPreCheck() {
      if (this.taskInfo.type === 'REVISE' && !this.taskInfo.reviseBindCheckTaskId) {
        this.$Modal.warning({
          title: this.$t('cao-zuo-shi-bai'),
          content: this.$t('shu-ju-ding-zheng-ren-wu-bi-xu-xuan-ze-guan-lian-de-xiao-yan-ren-wu')
        });
        return;
      }
      this.updateLoading(true);
      // 第一次预检
      const data = this.getDataJobData();

      preCheckStepOne(data)
        .then((res) => {
          let preCheckStatus = true;

          this.preCheckItemsErrorCount = 0;
          this.preCheckItemsSuccessCount = 0;
          this.preCheckItemsCheckedCount = 0;
          if (res.data.code === '1') {
            if (res.data.data) {
              res.data.data.map((item) => {
                if (item.checked) {
                  this.preCheckItemsCheckedCount++;
                  if (!item.success) {
                    preCheckStatus = false;
                    this.preCheckItemsErrorCount++;
                  } else {
                    this.preCheckItemsSuccessCount++;
                  }
                }
                return null;
              });
            }
            this.preCheckItems = res.data.data || [];
            if (preCheckStatus) {
              if (isRedis(this.taskInfo.sourceType) && isRedis(this.taskInfo.sinkType)) {
                this.updateStep(4);
              } else {
                this.updateStep(2);
              }
            } else {
              this.showPreCheck = true;
            }
          } else {
            preCheckStatus = false;
          }
          this.updateLoading(false);
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },
    listSpecifications() {
      if (Object.keys(this.taskInfo.specsMap).length < 1) {
        listSpecifications({
          dataJobType: this.taskInfo.type,
          initialSync: this.taskInfo.mode.init,
          shortTermSync: this.taskInfo.mode.synchronize
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.specsMap = {};
              res.data.data.map((item) => {
                if (this.taskInfo.specsMap[item.specKind]) {
                  item.selected = false;
                  this.taskInfo.specsMap[item.specKind].push(item);
                } else {
                  this.taskInfo.specsMap[item.specKind] = [];
                  item.selected = false;
                  this.taskInfo.specsMap[item.specKind].push(item);
                }
                return null;
              });
              this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
              if (!this.taskInfo.specKind) {
                this.taskInfo.specKind = 'Balance';
                this.handleChangeSpecKind('Balance');
              } else {
                this.handleChangeSpecKind(this.taskInfo.specKind, 'useOld');
              }
            }
          });
      }
    },
    handleSelectStandard(row) {
      this.taskInfo.specsMap[row.specKind].map((item) => {
        item.selected = item.id === row.id;
        return null;
      });
      this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
      this.taskInfo.spec = row;
    },
    handleChangeSpecKind(kind) {
      if (this.taskInfo.specsMap[kind]) {
        this.taskInfo.specsMap[kind].map((item) => {
          item.selected = false;
          return null;
        });
        this.taskInfo.specsMap[kind][1].selected = true;
        this.taskInfo.specsMap = { ...this.taskInfo.specsMap };
        this.taskInfo.spec = this.taskInfo.specsMap[kind][1];
      }
    },
    handleUserCenter() {
      this.$router.push({ path: '/userCenter' });
    },
    handleGoTB() {
      this.handleUserCenter();
    }
  }
};
</script>
<style lang="less">
.function-extra {
  margin-top: 20px;
  display: block!important;
}

.function-config-container {
  padding: 20px;

  .ivu-table-header thead tr th {
    padding: 3px 0;
  }
}

.create-job-tip {
  cursor: pointer;
  color: #999999;

  &:hover {
    color: #555555;
  }
}

.tip-title {
  font-family: PingFangSC-Semibold, serif;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.tip-content {
  color: #555555;
  width: 480px;
  word-break: break-all;
  white-space: pre-wrap;
}

.memory-tip {
  color: #aaaaaa;
}
</style>
