<template>
  <div>
    <Form label-position="right" :label-width="labelWidth">
      <div v-for="op in form" :key="op.targetParameter" >
        <FormItem :label="op.label" v-if="(op.targetParameter!== 'oraBuildRedoDicWhenCreate'||(op.targetParameter=== 'oraBuildRedoDicWhenCreate') && formData.oraIncrMode==='redo')
         && (op.targetParameter!== 'originDecodeMsgFormat'||(op.targetParameter=== 'originDecodeMsgFormat') && formData.srcSchemaLessFormat==='ORIGIN_MSG_FOR_MQ') && !(op.targetParameter === 'sysTriggerDataTable' && formData.incrTableMode === 'TABLE_LEVEL_CDC_TABLE')">
          <Input v-model="formData[op.targetParameter]" v-if="op.type === 'TEXTAREA'" type="textarea" :rows="textAreaRows"/>
          <Input style="width: 280px" v-model="formData[op.targetParameter]" v-if="op.type === 'TEXT'"/>
          <CheckboxGroup v-model="formData[op.targetParameter]" v-if="op.type === 'CHECKBOX'">
            <Checkbox v-for="option in op.options" :key="option.value" :label="option.value">{{option.label}}</Checkbox>
          </CheckboxGroup>
          <Select v-model="formData[op.targetParameter]" v-if="op.type === 'SELECT'">
            <Option v-for="option in op.options" :key="option.value" :value="option.value">{{option.label}}</Option>
          </Select>
          <RadioGroup v-model="formData[op.targetParameter]" v-if="op.type === 'RADIO'" @on-change="handleRadioGroupChange">
            <Radio v-for="option in op.options" :key="option.value" :label="option.value">{{option.label}}</Radio>
          </RadioGroup>
          <i-switch v-model="formData[op.targetParameter]" v-if="op.type === 'SWITCH'"/>
          <Tooltip v-if="op.comment" :content="op.comment" placement="top" transfer>
            <Icon style="cursor: pointer;font-size: 16px;margin-left: 10px" type="ios-help-circle-outline" />
          </Tooltip>
        </FormItem>
      </div>
    </Form>
  </div>
</template>
<script>
export default {
  props: {
    textAreaRows: {
      type: Number,
      default: 4
    },
    labelWidth: {
      type: Number,
      default: 120
    },
    form: Array,
    formData: Object
  },
  methods: {
    handleRadioGroupChange() {
      this.$forceUpdate();
    }
  }
};
</script>
