export const REDIS_KEYWORDS = [
    undefined,
    'WS',
    'COMMENT1',
    'COMMENT2',
    'EOL',
    'COPY',
    'DEL',
    'DUMP',
    'EXISTS',
    'EXPIRE',
    'EXPIREAT',
    'EXPIRETIME',
    'KEYS',
    'MOVE',
    'OBJECT',
    'PERSIST',
    'PEXPIRE',
    'PEXPIREAT',
    'PEXPIRETIME',
    'TTL',
    'PTTL',
    'RANDOMKEY',
    'RENAME',
    'RENAMENX',
    'RESTORE',
    'SCAN',
    'SORT',
    'SORT_RO',
    'TOUCH',
    'TYPE',
    'UNLINK',
    'WAIT',
    'APPEND',
    'DECR',
    'DECRBY',
    'GET',
    'GETDEL',
    'GETEX',
    'GETRANGE',
    'GETSET',
    'GETBIT',
    'INCR',
    'INCRBY',
    'INCRBYFLOAT',
    'LCS',
    'MGET',
    'MSET',
    'MSETNX',
    'SETEX',
    'PSETEX',
    'SET',
    'SETNX',
    'SETRANGE',
    'SETBIT',
    'STRLEN',
    'SUBSTR',
    'HDEL',
    'HEXISTS',
    'HGET',
    'HGETALL',
    'HINCRBY',
    'HINCRBYFLOAT',
    'HKEYS',
    'HLEN',
    'HMGET',
    'HMSET',
    'HRANDFIELD',
    'HSCAN',
    'HSET',
    'HSETNX',
    'HSTRLEN',
    'HVALS',
    'BLMOVE',
    'BLMPOP',
    'BLPOP',
    'BRPOP',
    'BRPOPLPUSH',
    'LINDEX',
    'LINSERT',
    'LLEN',
    'LMOVE',
    'LMPOP',
    'LPOP',
    'LPOS',
    'LPUSH',
    'LPUSHX',
    'LRANGE',
    'LREM',
    'LSET',
    'LTRIM',
    'RPOP',
    'RPOPLPUSH',
    'RPUSH',
    'RPUSHX',
    'SADD',
    'SCARD',
    'SDIFF',
    'SDIFFSTORE',
    'SINTER',
    'SINTERCARD',
    'SINTERSTORE',
    'SISMEMBER',
    'SMEMBERS',
    'SMISMEMBER',
    'SMOVE',
    'SPOP',
    'SRANDMEMBER',
    'SREM',
    'SSCAN',
    'SUNION',
    'SUNIONSTORE',
    'BZMPOP',
    'BZPOPMAX',
    'BZPOPMIN',
    'ZADD',
    'ZCARD',
    'ZCOUNT',
    'ZDIFF',
    'ZDIFFSTORE',
    'ZINCRBY',
    'ZINTER',
    'ZINTERCARD',
    'ZINTERSTORE',
    'ZLEXCOUNT',
    'ZMPOP',
    'ZMSCORE',
    'ZPOPMAX',
    'ZPOPMIN',
    'ZRANDMEMBER',
    'ZRANGE',
    'ZRANGEBYLEX',
    'ZRANGEBYSCORE',
    'ZRANGESTORE',
    'ZRANK',
    'ZREM',
    'ZREMRANGEBYLEX',
    'ZREMRANGEBYRANK',
    'ZREMRANGEBYSCORE',
    'ZREVRANGE',
    'ZREVRANGEBYLEX',
    'ZREVRANGEBYSCORE',
    'ZREVRANK',
    'ZSCAN',
    'ZSCORE',
    'ZUNION',
    'ZUNIONSTORE',
    'PSUBSCRIBE',
    'PUBSUB',
    'PUBLISH',
    'CHANNELS',
    'NUMSUB',
    'NUMPAT',
    'SHARDCHANNELS',
    'SHARDNUMSUB',
    'PUNSUBSCRIBE',
    'SPUBLISH',
    'SUBSCRIBE',
    'UNSUBSCRIBE',
    'SSUBSCRIBE',
    'SUNSUBSCRIBE',
    'SERVER',
    'CLIENTS',
    'MEMORY',
    'PERSISTENCE',
    'STATS',
    'REPLICATION',
    'CPU',
    'COMMANDSTATS',
    'LATENCYSTATS',
    'SENTINEL',
    'MODULES',
    'KEYSPACE',
    'ERRORSTATS',
    'ALL',
    'DEFAULT',
    'EVERYTHING',
    'ASKING',
    'CLUSTER',
    'ADDSLOTS',
    'DELSLOTS',
    'ADDSLOTSRANGE',
    'BUMPEPOCH',
    'COUNTKEYSINSLOT',
    'DELSLOTSRANGE',
    'FAILOVER',
    'FLUSHSLOTS',
    'FORGET',
    'GETKEYSINSLOT',
    'KEYSLOT',
    'LINKS',
    'MEET',
    'MYID',
    'MYSHARDID',
    'FORCE',
    'TAKEOVER',
    'NODE',
    'NODES',
    'REPLICAS',
    'REPLICATE',
    'RESET',
    'HARD',
    'SOFT',
    'SAVECONFIG',
    'SETSLOT',
    'IMPORTING',
    'MIGRATING',
    'STABLE',
    'SHARDS',
    'SLAVES',
    'READONLY',
    'READWRITE',
    'FLUSHDB',
    'SCRIPT',
    'EVAL',
    'EVALSHA',
    'EXEC',
    'WATCH',
    'DISCARD',
    'UNWATCH',
    'MULTI',
    'PFMERGE',
    'PFADD',
    'PFCOUNT',
    'ECHO',
    'PING',
    'SAVE',
    'SLOWLOG',
    'LASTSAVE',
    'CONFIG',
    'CLIENT',
    'SHUTDOWN',
    'SYNC',
    'ROLE',
    'MONITOR',
    'SLAVEOF',
    'FLUSHALL',
    'SELECT',
    'QUIT',
    'AUTH',
    'DBSIZE',
    'BGREWRITEAOF',
    'TIME',
    'INFO',
    'BGSAVE',
    'COMMAND',
    'DEBUG',
    'DB',
    'NX',
    'XX',
    'GT',
    'LT',
    'ENCODING',
    'FREQ',
    'IDLETIME',
    'REFCOUNT',
    'ABSTTL',
    'BY',
    'ASC',
    'DESC',
    'ALPHA',
    'STORE',
    'REPLACE',
    'EX',
    'PX',
    'EXAT',
    'PXAT',
    'LEN',
    'IDX',
    'MINMATCHLEN',
    'WITHMATCHLEN',
    'KEEPTTL',
    'WITHSCORES',
    'LIMIT',
    'BEFORE',
    'AFTER',
    'FLUSH',
    'RESETSTAT',
    'REWRITE',
    'PAUSE',
    'SETNAME',
    'GETNAME',
    'LIST',
    'NOSAVE',
    'SLOTS',
    'GETKEYS',
    'COUNT',
    'SEGFAULT',
    'KILL',
    'LOAD',
    'WITHVALUES',
    'MATCH',
    'MIN',
    'MAX',
    'CH',
    'WEIGHTS',
    'AGGREGATE',
    'SUM',
    'BYSCORE',
    'BYLEX',
    'REV',
    'LEFT',
    'RIGHT',
    'RANK',
    'MAXLEN',
    'ASYNC',
    'HEX_NUM',
    'OCT_NUM',
    'BIT_NUM',
    'INTEGER_NUM',
    'DECIMAL_NUM',
    'STRING',
    'HOST',
    'HOST_PORT',
    'NAME_TOKEN',
];
