import VXETable from 'vxe-table';
import XEClipboard from 'xe-clipboard';
import { message } from 'ant-design-vue';

VXETable.renderer.add('vxe-input-tpl', {
  renderDefault(h, renderOpts, {
    row,
    column
  }) {
    const copy = (e) => {
      e.stopPropagation();
      if (row && column) {
        if (XEClipboard.copy(row[column.property])) {
          message.success('复制成功');
        }
      }
    };

    const detail = (e) => {
      window.$bus.emit('showCellDetailModal', { row, column });
      e.stopPropagation();
      // VXETable.modal.open({
      //   'min-height': 300,
      //   title: column.property,
      //   showFooter: true,
      //   'cancel-button-text': '复制',
      //   'confirm-button-text': '关闭',
      //   content: row[column.property]
      // });
    };

    return [
      <div class="vxe-input-tpl">
        {row[column.property] === null ? <i style={{ color: '#ccc' }}>NULL</i> : <div domPropsInnerHTML={row[column.property]}/>}
        <div class='op'>
          <div onClick={copy} style={{ marginRight: '3px' }}>
            <cc-iconfont name="copy" size={12}/>
          </div>
          <div onClick={detail}>
            <cc-iconfont name="eye" size={12}/>
          </div>
        </div>
      </div>
    ];
  }
});

VXETable.renderer.add('vxe-time-tpl', {
  autofocus: '.vxe-input--inner',
  renderEdit(h, renderOpts, {
    row,
    column
  }) {
    return [
      <vxe-input class="vxe-input-tpl" v-model={row[column.property]} type="datetime" transfer/>
    ];
  },

  renderCell(h, renderOpts, {
    row,
    column
  }) {
    return [
      <span>
        {row[column.property]}
        {/* <cc-iconfont size={12} name="copy" /> */}
      </span>
    ];
  }
});
