u<template>
  <div>
    <Table border size="small" :columns="paramsColumn" :data="dsKvConfigs"
               max-height="600" stripe>
          <template slot-scope="{ row }" slot="configValue">
            <p style="position: relative;padding-right: 15px;">
                                    <span v-if="isJSON(row.configValue)"
                                          style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block">{{
                                        row.configValue
                                      }}
                                        <a style="position: absolute;right: 0;font-size: 14px"
                                           @click="handleShowJson(row)"><Icon
                                          type="md-arrow-dropdown"/></a>
                                    </span>
              <span v-if="!isJSON(row.configValue)">{{
                  (row.configValue) ? (row.configValue) : '-'
                }}</span>
            </p>
          </template>
          <template slot-scope="{ row }" slot="paramName">
            <p style="position: relative">
                                    <span
                                      :style="`font-style: italic;font-size: 13px;font-weight: 500;color: ${row.needCreated ? '#ff6e0d' : ''}`">
                                      {{row.configName}}
                                      {{row.needCreated ? $t('dai-chuang-jian') : ''}}
                                    <Tooltip transfer :content="row.description" placement="right"
                                             style="position:absolute;right: 0;top: 0">
                                        <a><Icon style="font-size: 14px"
                                                 type="ios-information-circle"/></a>
                                    </Tooltip>
                                </span>
            </p>
          </template>
      <template slot-scope="{ row }" slot="currentValue">
        <div>
          <div v-if="!row.readOnly">
                      <span
                        style="margin-right: 16px;display: inline-block;width: 210px;">{{
                          row.currentCount
                        }}</span><span>
                                    <Poptip v-model="row.visible" transfer
                                            @on-popper-show="handlePopShow(row)"
                                            placement="right" width="250"><i
                                      class="iconfont iconbianji"></i>
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">{{ $t('xiu-gai-can-shu-wei') }}:</p>
                                    <p style="margin-bottom: 10px"><Input size="small"
                                                                          style="width: 200px"
                                                                          v-model="currentValue"/></p>
                                    <p><pd-button style="margin-right: 5px" type="primary"
                                                  size="small"
                                                  @click="handleEditCurrent(row)">{{ $t('que-ding') }}</pd-button></p>
                                </div>
                            </Poptip>
                                <Tooltip transfer style="margin-left: 5px" :content="$t('che-xiao')"
                                         placement="right"
                                         v-if="row.currentCount"><a style="font-size: 16px;"
                                                                    @click="handleCancelEdit(row)"><Icon
                                  type="md-undo"/></a></Tooltip></span>
          </div>
          <div v-if="row.readOnly">
            {{ $t('zhi-du-can-shu') }}
          </div>
        </div>
      </template>
        </Table>
    <Modal v-model="showJson"
           :title="$t('wan-zheng-can-shu-zhan-shi')"
           width="700px"
           footer-hide
    >
      <div style="position: relative">
                <span style="position: absolute; right: 20px;top: 20px">
                    <Icon class="copy-icon" type="ios-photos-outline"
                          @click="handleCopyJson(JSON.stringify(selectedJson))"/>
                </span>
        <div style="max-height: 500px;overflow: auto;">
          <pre>{{ selectedJson }}</pre>
          <!--                    <vue-json-editor v-model="selectedJson" :mode="currentMode" :show-btns="false" :expandedOnStart="true" @json-change="onJsonChange"></vue-json-editor>-->
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleEditJson">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelEditParams">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
// import {
//   getConfigTagList
// } from '@/services/cc/api/job';
import { pick } from '@/components/function/monitor/utils/colors';
import { handleCopy } from '@/components/function/dataJob/createJob/util';
import UtilJson from '../util';

export default {
  name: 'ConfigParamsEdit',
  props: {
    dsKvConfigs: Array
  },
  data() {
    return {
      tagList: [],
      selectedJson: {},
      editJson: {},
      selectedRow: '',
      showJson: false,
      showParamsEdit: false,
      currentValue: 0,
      selectedData: '',
      selectedParam: {},
      showParamsEditConfirm: false,
      showTagList: [],
      paramsColumn: [
        {
          title: this.$t('can-shu-ming-cheng'),
          key: 'configName',
          slot: 'paramName',
          minWidth: 230,
          sortable: true
        },
        {
          title: this.$t('can-shu-dang-qian-yun-hang-zhi'),
          key: 'configValue',
          minWidth: 200,
          slot: 'configValue'
        },
        {
          title: this.$t('mo-ren-zhi'),
          minWidth: 200,
          key: 'defaultValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-can-shu-zhi'),
          key: 'currentCount',
          slot: 'currentValue',
          minWidth: 300,
          filters: [
            {
              label: this.$t('ke-xiu-gai-can-shu'),
              value: 1
            },
            {
              label: this.$t('zhi-du-can-shu'),
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return !row.readOnly;
            }
            if (value === 2) {
              return row.readOnly;
            }
          }
        },
        {
          title: this.$t('ke-xiu-gai-fan-wei'),
          key: 'valueAdvance',
          minWidth: 300
        },
        {
          title: this.$t('biao-qian'),
          minWidth: 150,
          render: (h, params) => h('div', [
            h('Tooltip', {
              props: {
                content: UtilJson.tagInfo[params.row.configGroup],
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('pd-button', {
                props: {
                  type: 'warning',
                  size: 'small',
                  ghost: true
                },
                style: {
                  color: pick(this.showTagList.indexOf(params.row.configGroup)),
                  borderColor: pick(this.showTagList.indexOf(params.row.configGroup))
                }
              }, params.row.configGroup)
            ])
          ])
        }
      ],
      editedParams: []
    };
  },
  mounted() {
    // getConfigTagList()
    //   .then((res) => {
    //     if (res.data.code === '1') {
    //       this.tagList = res.data.data;
    //     }
    //   });
  },
  methods: {
    handleCopyJson(data) {
      handleCopy(data);
      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    },
    handleEditJson() {
      this.showJson = false;
      if (this.currentMode === 'tree') {
        this.currentValue = JSON.stringify(this.editJson);
        this.handleEditCurrent('businessParamsData', this.businessParamsData, this.selectedRow);
        this.editJson = {};
      } else {
        this.$Modal.warning({
          title: this.$t('xiu-gai-can-shu-ti-shi'),
          content: this.$t('qing-dian-ji-xiu-gai-tu-biao-jin-hang-xiu-gai')
        });
      }
    },
    handleCancelEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = false;
      this.showJson = false;
      this.showParamsEdit = false;
    },
    showUserConfigModal() {
      this.editedParams = [];
      this.userConfigList.forEach((item) => {
        if (((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue) || item.needCreated) {
          this.editedParams.push(item);
        }
      });

      if (this.editedParams.length === 0) {
        this.$Modal.warning({
          title: this.$t('cao-zuo-yi-chang'),
          content: this.$t('qing-xiu-gai-xu-yao-sheng-xiao-de-can-shu')
        });
      } else {
        this.showParamsEditConfirm = true;
      }
    },
    hideUserConfigModal() {
      this.showParamsEditConfirm = false;
    },
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str);

          return Boolean(typeof obj === 'object' && obj);
        } catch (e) {
          return false;
        }
      }
    },
    handleShowJson(row, type, key) {
      if (type === 'edit') {
        this.currentMode = 'tree';
      } else {
        this.currentMode = 'view';
      }
      this.selectedRow = row;
      if (key) {
        if (!row[key]) {
          row[key] = row.configValue;
        }
        this.selectedJson = JSON.parse(row[key]);
        this.editJson = JSON.parse(row[key]);
      } else {
        this.selectedJson = JSON.parse(row.configValue);
        this.editJson = JSON.parse(row.configValue);
      }
      this.showJson = true;
    },
    handleShowEditParams(row, data) {
      row.currentCount = row.count;
      this.selectedParam = row;
      this.selectedData = data;
      this.showParamsEdit = true;
    },
    handleEditCurrent(row) {
      row.currentCount = this.currentValue;
      this.dsKvConfigs.forEach((item) => {
        if (item.configName === row.configName) {
          item.currentCount = this.currentValue;
        }
      });
      this.currentValue = '';
      row.visible = false;
    },
    handlePopShow(row) {
      this.currentValue = row.count;
    },
    handleCancelEdit(row) {
      this.dsKvConfigs.forEach((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
      });
      this.dsKvConfigs = [...this.dsKvConfigs];
    }
  }
};
</script>
<style lang="less" scoped>
.page-header {
  /*margin-top: 24px;*/
  /*margin-left: -16px;*/
  /*margin-right: -16px;*/

  .page-header-container {
    background: #fff;
    border-bottom: 1px solid #e8eaec;
  }

  .page-header-detail {
    display: flex;
  }

  .page-header-main {
    width: 100%;
    flex: 0 1 auto;

    .ivu-page-header-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .ivu-page-header-row {
    width: 100%;
  }

  .ivu-page-header-title {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inline-block;
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
  }

  .ivu-page-header-content {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    font-size: 14px;
  }
}

.params-edit-wrapper {
  /*padding: 24px;*/
  /*background: #ffffff;*/
  margin-top: 10px;
  /*border: 1px solid #DADADA;*/
  /*overflow: auto;*/

  .ivu-tabs-nav-scroll {
    background-color: #ffffff;
    border-top: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    border-right: 1px solid #DADADA;
  }

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
  }
}
</style>
