import Vue from 'vue';
import 'ant-design-vue/dist/antd.css';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  FormModel,
  Icon,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Tree,
  Pagination,
  message,
  Upload,
  Spin,
  LocaleProvider,
  Collapse,
  Tag,
  TimePicker
} from 'ant-design-vue';

Vue.component(TimePicker.name, TimePicker);
Vue.component(Tree.name, Tree);
Vue.component(Table.name, Table);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Button.name, Button);
Vue.component(Alert.name, Alert);
Vue.component(Input.name, Input);
Vue.component(Input.Search.name, Input.Search);
Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Dropdown.name, Dropdown);
Vue.component(Menu.name, Menu);
Vue.component(Popover.name, Popover);
Vue.component(Icon.name, Icon);
Vue.component(Tabs.name, Tabs);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Radio.name, Radio);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Breadcrumb.name, Breadcrumb);
Vue.component(Card.name, Card);
Vue.component(Switch.name, Switch);
Vue.component(Pagination.name, Pagination);
Vue.component(Upload.name, Upload);
Vue.component(Spin.name, Spin);
Vue.component(LocaleProvider.name, LocaleProvider);
Vue.component(Collapse.name, Collapse);
Vue.component(Tag.name, Tag);

Vue.use(Modal);
Vue.use(FormModel);
Vue.use(Form);
Vue.use(Menu);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(Tooltip);
Vue.use(Table);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Breadcrumb);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(message);
Vue.use(Upload);
Vue.use(Spin);
Vue.use(LocaleProvider);
Vue.use(Collapse);
Vue.use(Tag);

Vue.prototype.$message = message;
