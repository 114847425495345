<template>
  <div>
    <h4 style="margin-top: 30px">{{ $t('shu-ju-ku-ying-she') }}
      <RadioGroup v-if="!DataSourceGroup.pg.includes(taskInfo.sourceType)&&!DataSourceGroup.oracle.includes(taskInfo.sourceType)&&
          !DataSourceGroup.pg.includes(taskInfo.sinkType)&&!DataSourceGroup.oracle.includes(taskInfo.sinkType)"
                  v-model="taskInfo.mappingMode"
                  @on-change="handleChangeMode"
                  style="margin-left: 10px;font-weight: 400;font-size: 12px">
        <Radio label="advanced">{{ $t('mo-ren-mo-shi') }}</Radio>
        <Radio label="easy" v-if="!DataSourceGroup.mongo.includes(taskInfo.sourceType)">{{ $t('shu-xing-mo-shi') }}</Radio>
      </RadioGroup>
    </h4>
    <div v-if="taskInfo.mappingMode === 'advanced'">
      <div v-for="(db,index) in taskInfo.dbMap" :key="index" style="margin-bottom: 20px">
        <FormItem prop="hasDb">
          <Select filterable :disabled="!taskInfo.sourceConnection" v-model="db.sourceDb"
                  :placeholder="$t('xuan-ze-yuan-shu-ju-ku')"
                  :transfer="true" v-if="schemaMapping.srcSchemaLevel!=='NONE'"
                  style="width: 280px" @on-change="handleDbChange('source',index,$event)">
            <Option :disabled="(hasTheDb(item) || hasSelectedTheDb(item.dbName, 'source')) && !(isMySQL(taskInfo.sourceType) && isGP(taskInfo.sinkType))"
                    v-for="(item,i) in taskInfo.sourceDbList" :value="item.dbName"
                    :key="i">{{ item.dbName }}
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sourceConnection"
                  v-if="schemaMapping.srcSchemaLevel==='DB_SCHEMA'"
                  v-model="db.sourceSchema"
                  :placeholder="$t('xuan-ze-yuan-duan-schema')"
                  ref="source-schema-select"
                  @on-change="handleSchemaChange(db, index, $event, 'source')"
                  :transfer="true" style="width: 220px;margin: 0 5px">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sourceDbList,db.sourceDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
          </Select>
          <Icon type="md-arrow-forward"/>
          <Select filterable :disabled="!taskInfo.sinkConnection" v-model="db.sinkDb"
                  :class="!containsDb(taskInfo.sinkDbList,db.sinkDb)?'db-to-create':''"
                  :placeholder="$t('xuan-ze-mu-biao-shu-ju-ku')"
                  :transfer="true" v-if="schemaMapping.dstSchemaLevel!=='NONE'"
                  style="width: 280px" ref="sink-db-select"
                  @on-open-change="handleEmptySinkQuery(index, $event)">
            <Option v-for="(item) in taskInfo.sinkDbList" :value="item.dbName"
                    :key="item.dbName">{{ item.dbName }}
            </Option>
            <Option v-if="schemaMapping.targetCreateDb && schemaMapping.mappingMethod === 'DB_DB'&&db.sourceDb&&!containsDb(taskInfo.sinkDbList, valueWithCase(db.sourceDb))"
                    :value="valueWithCase(db.sourceDb)"
                    :key="999">
              {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [valueWithCase(db.sourceDb)]) }}
            </Option>
            <Option v-if="schemaMapping.targetCreateDb && schemaMapping.mappingMethod === 'SCHEMA_DB'&&db.sourceDb&&
              !containsDb(taskInfo.sinkDbList,valueWithCase(db.sourceSchema))"
                    :value="valueWithCase(db.sourceSchema)" :key="9999">{{ $t('dbsourceschema-dai-chuang-jian', [valueWithCase(db.sourceSchema)]) }}
            </Option>
          </Select>
          <Select filterable :disabled="!taskInfo.sinkConnection"
                  v-if="schemaMapping.dstSchemaLevel==='DB_SCHEMA'"
                  :placeholder="$t('xuan-ze-dui-duan-schema')"
                  :class="getSchemaList(taskInfo.sinkDbList,db.sinkDb)&&getSchemaList(taskInfo.sinkDbList,db.sinkDb).indexOf(db.targetSchema)===-1?'db-to-create':''"
                  :transfer="true" style="width: 220px;margin: 0 5px" v-model="db.targetSchema"
                  ref="sink-schema-select"
                  @on-change="handleSchemaChange(db, index, $event, 'target')"
                  @on-open-change="handleEmptySinkSchemaQuery(index, $event)">
            <Option v-for="(item,i) in getSchemaList(taskInfo.sinkDbList,db.sinkDb)"
                    :value="item" :key="i">{{ item }}
            </Option>
            <Option :value="valueWithCase(db.sourceDb)"
                    v-if="schemaMapping.targetCreateSchema && schemaMapping.mappingMethod === 'DB_SCHEMA'&&db.sourceDb&&
                      !containsSchema(taskInfo.sinkDbList,db.sinkDb,valueWithCase(db.sourceDb))">
              {{ $t('targetschemadb-dai-chuang-jian', [valueWithCase(db.sourceDb)]) }}
            </Option>
            <Option :value="valueWithCase(db.sourceSchema)"
                    v-if="schemaMapping.targetCreateSchema && schemaMapping.mappingMethod === 'SCHEMA_SCHEMA'&&db.sourceDb&&
                      !containsSchema(taskInfo.sinkDbList,db.sinkDb,valueWithCase(db.sourceSchema))">
              {{ $t('targetschemadb-dai-chuang-jian', [valueWithCase(db.sourceSchema)]) }}
            </Option>
          </Select>
          <Icon class="add-item-btn add-item-btn-remove" @click="handleRemoveMap(index)"
                v-if="taskInfo.dbMap.length>1" type="ios-remove-circle-outline"/>
          <Icon class="add-item-btn add-item-btn-add" @click="handleAddMap"
                v-if="index===taskInfo.dbMap.length-1&&schemaMapping.multiSchema"
                type="ios-add-circle-outline"/>
        </FormItem>
      </div>
    </div>
    <div v-if="taskInfo.mappingMode === 'easy'">
      <div>
        <Row>
          <Col :span="8">
            <Input v-model="filterkey" @on-change="handleFilterDb" suffix="ios-search"
                   :placeholder="$t('sou-suo-ku-ming')" style="width: 100%"/>
            <div class="db-tree-container">
              <div class="select-all">
                <Checkbox @on-change="handleSelectAll">{{ $t('quan-xuan') }}</Checkbox>
                <a style="float: right" @click="handleEditMapping">{{ $t('pi-liang-xiu-gai-mu-biao-ying-she') }}</a>
              </div>
              <div class="db-tree-body">
                <Tree :data="filteredData" show-checkbox
                      @on-check-change="handleChangeCheck"></Tree>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <Modal v-model="showEditMapping"
           :title="batchlyEdit?$t('pi-liang-xiu-gai-mu-biao-ying-she'):$t('xiu-gai-mu-biao-ying-she')"
           footer-hide
    >
      <div>
        <Select filterable v-model="currentSinkDb">
          <Option v-for="sink in taskInfo.sinkDbList" :value="sink.dbName" :key="sink.dbName">
            {{ sink.dbName }}
          </Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleChangeMapping">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import {
  isGP, isHana, isHudi,
  isMongoDB, isMySQL, isOracle, isPG, isSchemaMapping, isSQLServer, isTunnel
} from '@/utils';
import DataSourceGroup from '@/views/dataSourceGroup';

export default {
  props: {
    dbMap: Array,
    handleDbChange: Function,
    hasTheDb: Function,
    hasSelectedTheDb: Function,
    taskInfo: Object,
    getSchemaList: Function,
    store: Object,
    handleRemoveMap: Function,
    handleAddMap: Function,
    containsDb: Function,
    handleSchemaChange: Function,
    containsSchema: Function,
    showEditMapping: Boolean,
    updateShowEditMapping: Function,
    currentSourceDb: String,
    schemaMapping: Object
  },
  data() {
    return {
      DataSourceGroup,
      hasChecked: false,
      needCancelList: [],
      treeData: [],
      filteredData: [],
      filterkey: '',

      currentSinkDb: '',

      batchlyEdit: false
    };
  },
  computed: {
    valueWithCase() {
      return (value) => (this.schemaMapping.mappingRule === 'TO_LOWER_CASE' && value ? value.toLowerCase() : this.schemaMapping.mappingRule === 'TO_UPPER_CASE' && value ? value.toUpperCase() : value);
    }
  },
  created() {
    this.filteredData = _.cloneDeep(this.taskInfo.treeData);
    this.taskInfo.mappingMode = 'advanced';
  },
  methods: {
    isHana,
    isPG,
    isMySQL,
    isTunnel,
    isOracle,
    isSQLServer,
    isMongoDB,
    isGP,
    isHudi,
    handleEmptySinkQuery(index, type) {
      if (!type) {
        const sinkDb = this.taskInfo.dbMap[index].sinkDb;
        this.$refs['sink-db-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].sinkDb = sinkDb;
          this.handleDbChange('sink', index, sinkDb);
        });
      }
    },
    handleEmptySinkSchemaQuery(index, type) {
      if (!type) {
        const targetSchema = this.taskInfo.dbMap[index].targetSchema;
        this.$refs['sink-schema-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].targetSchema = targetSchema;
          this.handleSchemaChange(this.taskInfo.dbMap[index], index, targetSchema);
        });
      }
    },
    handleEmptySourceSchemaQuery(index, type) {
      if (!type) {
        const sourceSchema = this.taskInfo.dbMap[index].sourceSchema;
        this.$refs['source-schema-select'][index].setQuery(null);
        this.$nextTick(() => {
          this.taskInfo.dbMap[index].sourceSchema = sourceSchema;
        });
      }
    },
    handleFilterDb() {
      if (this.filterkey) {
        this.filteredData = [];
        this.taskInfo.treeData.forEach((db) => {
          if (db.title.indexOf(this.filterkey) > -1) {
            this.filteredData.push(db);
          }
        });
      } else {
        this.getTreeData(this.taskInfo.treeData);
      }
    },
    handleSelectAll(data) {
      this.filteredData.forEach((db) => {
        db.checked = data;
        this.taskInfo.treeData.forEach((treedata) => {
          if (treedata.title === db.title) {
            treedata.checked = data;
          }
        });

        if (data) {
          let needAuto = false;

          if (this.taskInfo.sinkDbList) {
            needAuto = true;
            this.taskInfo.sinkDbList.forEach((theDb) => {
              if (theDb.dbName === db.title) {
                needAuto = false;
              }
            });
          }

          if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
            this.taskInfo.dbMap.push({
              sourceDb: db.title,
              sinkDb: db.title,
              targetSchema: 'dbo',
              sourceSchema: 'dbo',
              needAutoCreated: needAuto
            });
          } else {
            this.taskInfo.dbMap.push({
              sourceDb: db.title,
              sinkDb: db.title,
              targetSchema: 'public',
              sourceSchema: 'public',
              needAutoCreated: needAuto
            });
          }
        }
      });
      if (!data) {
        this.taskInfo.dbMap.splice(0, this.taskInfo.dbMap.length);
        this.taskInfo.treeData.forEach((db) => {
          db.sinkDb = '';
        });
        this.taskInfo = { ...this.taskInfo };
      }
    },
    handleChangeCheck(selection, current) {
      this.filteredData.forEach((db) => {
        if (current.title === db.title) {
          db.checked = current.checked;
          this.taskInfo.treeData.forEach((treedata) => {
            if (treedata.title === db.title) {
              treedata.checked = current.checked;

              if (!treedata.checked) {
                treedata.sinkDb = '';
              }
            }
          });
          let hasSame = false;

          this.taskInfo.sinkDbList.forEach((sink) => {
            if (sink.dbName === current.title) {
              hasSame = true;
            }
          });
          if (current.checked) {
            if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
              this.taskInfo.dbMap.push({
                sourceDb: current.title,
                sinkDb: current.title,
                targetSchema: 'dbo',
                sourceSchema: 'dbo',
                needAutoCreated: !hasSame
              });
            } else {
              this.taskInfo.dbMap.push({
                sourceDb: current.title,
                sinkDb: current.title,
                targetSchema: 'public',
                sourceSchema: 'public',
                needAutoCreated: !hasSame
              });
            }
          } else {
            db.sinkDb = '';

            this.taskInfo.dbMap.forEach((item, i) => {
              if (item.sourceDb === current.title) {
                this.taskInfo.dbMap.splice(i, 1);
              }
            });
          }
        }
      });
    },
    getTreeData(data) {
      this.filteredData = [];
      data.forEach((db) => {
        this.filteredData.push(db);
      });
    },
    handleChangeMode(data) {
      if (data === 'easy') {
        this.taskInfo.treeData.forEach((treeData) => {
          treeData.checked = false;
        });

        this.taskInfo.dbMap.forEach((item, i) => {
          if (!item.sourceDb) {
            this.taskInfo.dbMap.splice(i, 1);
          } else {
            this.taskInfo.treeData.forEach((treeData) => {
              if (treeData.title === item.sourceDb) {
                treeData.checked = true;
                if (item.sourceDb !== item.sinkDb) {
                  treeData.sinkDb = item.sinkDb;
                }
              }
            });
            this.filteredData = _.cloneDeep(this.taskInfo.treeData);
            this.filterkey = '';
          }
        });
      } else {
        if (this.taskInfo.dbMap.length < 1) {
          if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType)) {
            this.taskInfo.dbMap.push({
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'dbo',
              sourceSchema: 'dbo'
            });
          } else {
            this.taskInfo.dbMap.push({
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'public',
              sourceSchema: 'public'
            });
          }
        }
      }
    },
    handleChangeMapping() {
      this.updateShowEditMapping(false);

      if (this.batchlyEdit) {
        this.taskInfo.treeData.forEach((db) => {
          if (db.checked) {
            db.sinkDb = this.currentSinkDb;
          }
        });

        this.filteredData.forEach((db) => {
          if (db.checked) {
            db.sinkDb = this.currentSinkDb;
          }
        });
        this.taskInfo.dbMap.forEach((db) => {
          db.needAutoCreated = false;
          db.sinkDb = this.currentSinkDb;
        });
      } else {
        this.taskInfo.treeData.forEach((db) => {
          if (db.title === this.currentSourceDb) {
            db.sinkDb = this.currentSinkDb;
          }
        });

        this.filteredData.forEach((db) => {
          console.log('db', db, this.currentSourceDb, this.currentSourceDb);
          if (db.title === this.currentSourceDb) {
            db.sinkDb = this.currentSinkDb;
          }
        });
        this.taskInfo.dbMap.forEach((db) => {
          if (db.sourceDb === this.currentSourceDb) {
            db.needAutoCreated = false;
            db.sinkDb = this.currentSinkDb;
          }
        });
      }
      this.filteredData.push('');
      this.filteredData.pop();
    },
    handleCancel() {
      this.updateShowEditMapping(false);
    },
    handleEditMapping() {
      this.updateShowEditMapping(true);
      this.batchlyEdit = true;
    }
  },
  watch: {
    'taskInfo.treeData': {
      handler() {
        this.handleFilterDb();
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.db-tree-container {
  width: 100%;
  height: 300px;
  border-left: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  border-bottom: 1px solid #DADADA;

  .select-all {
    background: #F4F4F4;
    padding: 6px 20px;
  }

  .db-tree-body {
    height: 270px;
    overflow: auto;

    .ivu-tree ul {
      font-size: 12px;
    }
  }

  .db-mapping-item:hover {
    .edit-mapping-icon {
      display: inline-block;
    }
  }
}
</style>
