<template>
  <div>
    <Modal
      v-model="showFilter"
      footer-hide
      :closable="false">
      <div slot="header">
        {{ $t('shu-ju-guo-lv-tiao-jian') }}
        <Tooltip placement="right">
          <Icon type="ios-help-circle-outline" />
          <div slot="content">
            {{sourceWhereTip}}
          </div>
        </Tooltip>
      </div>
      <div style="word-break: break-all">
        <div style="margin-bottom: 16px">
          <span>{{ $t('guo-lv-mo-shi') }}</span>
          <Select v-model="selectedTable.dataFilterType" style="width: 180px">
            <Option value="SQL_WHERE_ADV">{{ $t('gao-ji-mo-shi-tui-jian') }}</Option>
            <Option value="SQL_WHERE">{{ $t('pu-tong-mo-shi') }}</Option>
          </Select>
        </div>
        select {{ getColumns(selectedTable.sourceDb, selectedTable.sourceTable) }} from
        {{ selectedTable.sourceTable }}
        where
        <div style="margin-top: 10px">
          <Input v-model="currentData" :placeholder="$t('tiao-jian-zhong-zi-duan-qing-yan-ge-an-ming-cheng-pi-pei-ru-da-xiao-xie')" type="textarea" :rows="4" @on-change="handleUpdateWhereCondition"/>
        </div>
        <a style="margin-top: 10px;display: block;width: 160px"
           @click="clearWhereCondition(selectedTable)">{{ $t('qing-chu-shu-ju-guo-lv-tiao-jian') }}</a>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddWhereCondition(selectedTable)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showTargetFilter"
      footer-hide
      :closable="false"
    >
      <div slot="header">

        {{ $t('dui-duan-geng-xin-tiao-jian') }} <Tooltip placement="right">
          <Icon type="ios-help-circle-outline" />
          <div slot="content">
            {{targetWhereTip}}
          </div>
        </Tooltip>
      </div>
      <div>
        <div style="word-break: break-all">
          update {{ selectedTable.sourceTable }} {{"set ... where pk = xxx and"}}
          <div style="margin-top: 10px">
            <Input v-model="currentTargetData" :placeholder="$t('tiao-jian-zhong-zi-duan-qing-yan-ge-an-ming-cheng-pi-pei-ru-da-xiao-xie')" type="textarea" :rows="4" @on-change="handleUpdateTargetWhereCondition"/>
          </div>
          <a style="margin-top: 10px;display: block;width: 100px"
             @click="clearTargetWhereCondition(selectedTable)">{{ $t('qing-chu-dui-duan-geng-xin-tiao-jian') }}</a>
          <div slot="footer" class="modal-footer" style="margin-top: 20px">
            <pd-button type="primary" @click="handleAddTargetWhereCondition(selectedTable)">{{ $t('que-ding') }}</pd-button>
            <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showVirtualColumn"
      footer-hide
      width="1120px"
      :closable="false"
      :title="$t('tian-jia-xu-ni-lie')">
      <div>
        <Table size="small" border :columns="virtualColumns" :data="virtualData" max-height="400"
               :key="selectedTable.sinkTable">
          <template slot="targetVirtualName" slot-scope="{row,index}">
            <Input :placeholder="$t('qing-shu-ru-lie-ming-yi-cun-zai-lie-zi-dong-pi-pei')" v-model="row.customName"
                   @on-change="handleChangeColumnRow(row,index,'customName',$event)"/>
          </template>
          <template slot="targetVirtualType" slot-scope="{row,index}">
            <span v-if="!row.isCustom">{{ row.targetVirtualType }}</span>
            <Select v-if="row.isCustom" @on-change="handleChangeTargetType(row,index,$event)" v-model="row.typeData"
                    transfer filterable>
              <Option v-for="type in typeMeta" :key="type.typeName" :value="JSON.stringify(type)">
                {{ type.typeName }}
              </Option>
            </Select>
          </template>
          <template slot="targetVirtualLength" slot-scope="{row,index}">
            <span v-if="!row.isCustom">{{
                row.targetVirtualLength && row.targetVirtualLength.match(/\((.+)\)/g) &&
                row.targetVirtualLength.match(/\((.+)\)/g)[0] &&
                row.targetVirtualLength.match(/\((.+)\)/g)[0].substr(1, row.targetVirtualLength.match(/\((.+)\)/g)[0].length - 2)
              }}</span>
            <Input v-if="row.isCustom" v-model="row.columnLength"
                   @on-change="handleChangeInputRow(row,index,'columnLength',$event)"/>
          </template>
          <template slot="columnValue" slot-scope="{row,index}">
            <Select v-model="row.genRule" transfer class="virtual-column-rule" @on-change="handleChangeGenRule(row,index,$event)">
              <Option v-for="rule in virtualColGenRule" :value="rule.virtualColGenRule" :key="rule.virtualColGenRule">{{rule.i18nName}}</Option>
            </Select>
            <Input v-if="row.genRule === 'SPECIFIED_VALUE'" v-model="row.columnValue"
                   @on-change="handleChangeInputRow(row,index,'columnValue',$event)" class="virtual-column-value"/>
          </template>
          <template slot="action" slot-scope="{index}">
            <i style="color: #51D300;cursor: pointer" @click="handleAddVirtualColumn"
               v-if="index===0"
               class="iconfont iconadd-g"></i>
            <i style="color: #FF1815;cursor: pointer" v-if="index!==0" class="iconfont iconDEL-R"
               @click="handleDeleteVirtual(index)"></i>
          </template>
        </Table>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleVirtualColumn(selectedTable,virtualData)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showBatchVirtualColumn"
      width="1120px"
      footer-hide
      :closable="false"
      :title="$t('pi-liang-she-zhi-xu-ni-lie')">
      <div style="word-break: break-all">
        <div style="margin-top: 10px">
          <Table size="small" border :columns="virtualColumns" :data="virtualData" max-height="400">
            <template slot="targetVirtualName" slot-scope="{row, index}">
              <Input :placeholder="$t('qing-shu-ru-lie-ming-yi-cun-zai-lie-zi-dong-pi-pei')" v-model="row.customName"
                     @on-change="handleChangeColumnRow(row,index,'customName',$event)"/>
            </template>
            <template slot="targetVirtualType" slot-scope="{row,index}">
              <Select @on-change="handleChangeTargetType(row,index,$event)" v-model="row.typeData"
                      transfer filterable>
                <Option v-for="type in typeMeta" :key="type.typeName" :value="JSON.stringify(type)">
                  {{ type.typeName }}
                </Option>
              </Select>
            </template>
            <template slot="targetVirtualLength" slot-scope="{row,index}">
              <Input v-model="row.columnLength"
                     @on-change="handleChangeInputRow(row,index,'columnLength',$event)"/>
            </template>
            <template slot="columnValue" slot-scope="{row,index}">
              <Select v-model="row.genRule" transfer class="virtual-column-rule" @on-change="handleChangeGenRule(row,index,$event)">
                <Option v-for="rule in virtualColGenRule" :value="rule.virtualColGenRule" :key="rule.virtualColGenRule">{{rule.i18nName}}</Option>
              </Select>
              <Input v-if="row.genRule === 'SPECIFIED_VALUE'" v-model="row.columnValue"
                     @on-change="handleChangeInputRow(row,index,'columnValue',$event)" class="virtual-column-value"/>
            </template>
            <template slot="action" slot-scope="{index}">
              <i style="color: #51D300;cursor: pointer" @click="handleAddVirtualColumn"
                 v-if="index===0"
                 class="iconfont iconadd-g"></i>
              <i style="color: #FF1815;cursor: pointer" v-if="index!==0" class="iconfont iconDEL-R"
                 @click="handleDeleteVirtual(index)"></i>
            </template>
          </Table>
        </div>
        <BatchSet :checkAll="checkAll" :handleCheckAll="handleCheckAll" :checkAllGroup="checkAllGroup"
                  :checkAllGroupChange="checkAllGroupChange" :sourceType="taskInfo.sourceType"
                  :hasColumnTableList="hasColumnTableList" :showHasColumnTableListSize="showHasColumnTableListSize"></BatchSet>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleBatchSetVirtualColumn(virtualData, checkAllGroup)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showPartitionAll"
      footer-hide
      width="600"
      :closable="false"
      :title="$t('pei-zhi-fen-qu-jian')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80">
          <FormItem :label="$t('fen-qu-jian')">
            <Input style="width: 300px" v-model="partitionData.partition[0].key"/>
          </FormItem>
          <FormItem :label="$t('fen-qu-lei-xing')">
            <RadioGroup v-model="partitionData.partition[0].func" type="button" transfer @on-change="handleChangePartitionFunc">
              <Tooltip v-for="(format) of HivePartitionFuncs" :content="format.funcDesc" :key="format.function"
                       placement="bottom">
                <Radio :label="format.function">{{ format.i18nName }}</Radio>
              </Tooltip>
            </RadioGroup>
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            <Select style="width:300px" v-model="partitionData.fileFormat">
              <Option v-for="(format,key) of HdfsFileFormats" :value="key" :key="key">{{
                  key
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            <Select style="width:300px" v-model="partitionData.compressType">
              <Option v-for="(compress) of HdfsFileFormats[partitionData.fileFormat]"
                      :value="compress" :key="compress">
                {{ compress }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddPartitionAll">{{ $t('que-ding') }}</pd-button>
          <pd-button type="default" @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showMqPartition"
      width="740"
      footer-hide
      :closable="false"
      :title="$t('she-zhi-fen-qu-shu')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80">
          <!--          <FormItem label="分区键">-->
          <!--            <Select v-model="mqPartition.key" style="width: 600px" filterable multiple>-->
          <!--              <Option v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"-->
          <!--                      :value="column" :key="i">{{ column }}-->
          <!--              </Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <FormItem :label="$t('fen-qu-shu')">
            <Input style="width: 132px" type="number"
                   v-model="mqPartition.count"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddMqPartition(selectedTable)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showRedisKey"
      width="740"
      footer-hide
      :closable="false"
      :title="$t('she-zhi-redis-key')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80">
          <FormItem label="Redis KEY">
            <Select v-model="suffixFields" style="width: 600px" filterable multiple>
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetRedisKey(selectedTable, suffixFields)">
            {{ $t('que-ding') }}
          </pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showSrPartition"
      width="500px"
      footer-hide
      :closable="false"
      :title="$t('she-zhi-shu-ju-fen-qu')"
    >
      <div style="word-break: break-all">
        <p style="margin-bottom: 10px">{{ $t('qing-tian-ru-fen-qu-biao-da-shi') }}</p>
        <Input v-model="srData.partitionExpr" type="textarea" :rows="10"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddSrPartitions(selectedTable)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showDrdsPartition"
      width="500px"
      footer-hide
      :closable="false"
      :title="$t('she-zhi-fen-qu-jian')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="120" v-if="drdsPartitionSetting.dbPartition">
          <FormItem :label="$t('chai-fen-fang-shi')">
            <Select v-model="partitionType" style="width: 280px">
              <Option v-for="type in drdsPartitionSetting.types" :value="type" :key="type">{{
                  type
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('fen-ku-suan-fa')" v-if="partitionType!=='NONE'">
            {{ drdsPartitionSetting.dbPartition.function }}
          </FormItem>
          <FormItem :label="$t('fen-ku-jian')"
                    v-if="partitionType!=='NONE'&&drdsPartitionSetting.dbPartition.needCols">
            <Select v-model="drdsPartition.dbpartition" filterable style="width: 280px"
                    :multiple="drdsPartitionSetting.dbPartition.multiCols">
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('fen-biao-suan-fa')" v-if="partitionType==='DB_TAB'" @on-change="handleChangeTbFuncs">
            <Select v-model="drdsPartition.tbFuncs" style="width: 280px">
              <Option v-for="func in drdsPartitionSetting.tbPartition" :value="func.function"
                      :key="func.function">
                {{ func.function }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('fen-biao-jian')" v-if="partitionType==='DB_TAB'&&getTbPartitionData().needCols">
            <Select v-model="drdsPartition.tbpartition" filterable style="width: 280px"
                    :multiple="getTbPartitionData().multiCols">
              <Option
                v-for="(column,i) in getColumnList(selectedTable.sourceDb,selectedTable.sourceTable)"
                :value="column" :key="i">{{ column }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('chai-fen-shu')" v-if="partitionType==='DB_TAB'" prop="tbpartitions">
            <Input style="width: 132px" v-model="drdsPartition.tbpartitions"
                   @on-change="formatPartionSize(getTbPartitionData())"
                   :placeholder="partitionRange"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleAddDrdsPartitions(selectedTable)">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import BatchSet from '@/components/function/dataJob/createJob/cleanData/BatchSet';
import _ from 'lodash';
import store from '../../../../../store/index';

export default {
  components: { BatchSet },
  props: {
    showTargetFilter: Boolean,
    showFilter: Boolean,
    whereConditionFilter: Object,
    selectedTable: Object,
    clearWhereCondition: Function,
    clearTargetWhereCondition: Function,
    handleAddWhereCondition: Function,
    handleAddTargetWhereCondition: Function,
    handleCancel: Function,
    showFilterAll: Boolean,
    showVirtualColumn: Boolean,
    handleColumnChange: Function,
    handleFindTable: Function,
    checkAll: Boolean,
    handleCheckAll: Function,
    checkAllGroup: Array,
    checkAllGroupChange: Function,
    hasColumnTableList: Array,
    showHasColumnTableListSize: Number,
    handleAddAllWhereCondition: Function,
    showPartition: Boolean,
    taskInfo: Object,
    HivePartitionFuncs: Array,
    HdfsFileFormats: Object,
    showPartitionAll: Boolean,
    showPartitionExprAll: Boolean,
    partitionCheckAll: Boolean,
    partitionCheckAllGroup: Array,
    partitionCheckAllGroupChange: Function,
    handleAddPartitionAll: Function,
    showMqPartition: Boolean,
    mqPartition: Object,
    handleAddMqPartition: Function,
    selectedColumns: Object,
    tableList: Array,
    showDrdsPartition: Boolean,
    drdsPartition: Object,
    handleAddDrdsPartitions: Function,
    handleAddSrPartitions: Function,
    drdsPartitionSetting: Object,
    handleVirtualColumn: Function,
    hasTheSameColumn: Function,
    virtualColumnData: Array,
    typeMeta: Array,
    showOperateColumns: Boolean,
    handleOperateColumns: Function,
    showRedisKey: Boolean,
    handleConfirmSetRedisKey: Function,
    showBatchSetRedisKey: Boolean,
    handleConfirmBatchSetRedisKey: Function,
    whereCondition: String,
    targetWhereCondition: String,
    updateWhereCondition: Function,
    updateTargetWhereCondition: Function,
    partitionData: Object,
    showSrPartition: Boolean,
    srData: Object,
    handleFindCropTable: Function,
    handleConfirmBatchSetPartitionExpr: Function,
    virtualColGenRule: Array,
    showBatchVirtualColumn: Boolean,
    handleBatchSetVirtualColumn: Function
  },
  data() {
    return {
      sourceWhereTip: this.$t('dang-qian-zhi-chi-sql-92-shu-ju-shan-xuan-yu-ju-wu-zi-cha-xun-wu-join-zhi-chi-andorin-deng-luo-ji-he-suan-shu-biao-da-shi'),
      targetWhereTip: this.$t('dang-qian-zhi-chi-xxx-lei-si-sql-92-shu-ju-shan-xuan-yu-ju-andor'),
      store,
      partitionType: 'NONE',
      partitionRange: '1',
      searchColumnKey: '',
      operateType: 'cancel',
      targetFilterColumns: [
        {
          title: this.$t('lie-ming'),
          slot: 'columnName'
        },
        {
          title: this.$t('biao-da-shi'),
          slot: 'expression'
        },
        {
          title: this.$t('zhi'),
          slot: 'value'
        }
      ],
      targetFilterList: [{
        columnName: '',
        expression: '',
        value: ''
      }],
      virtualColumns: [
        {
          title: this.$t('xu-ni-lie-ming-cheng-0'),
          slot: 'targetVirtualName',
          width: 240
        },
        {
          title: this.$t('lie-zhi-0'),
          slot: 'columnValue',
          width: 380
        },
        {
          title: this.$t('xu-ni-lie-lei-xing-0'),
          slot: 'targetVirtualType'
        },
        {
          title: this.$t('xu-ni-lie-chang-du'),
          slot: 'targetVirtualLength'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action',
          width: 100
        }
      ],
      virtualData: [{
        targetVirtualName: '',
        targetVirtualType: '',
        targetVirtualLength: 0,
        columnValue: '',
        sqlType: 0,
        columnLength: '',
        genRule: ''
      }],
      suffixFields: [],
      currentData: '',
      currentTargetData: '',
      currentColumns: ''
    };
  },
  computed: {
    getColumns() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].forEach((item) => {
            if (item.selected) {
              columns.push(item.sourceColumn);
            }
          });
        }
        return columns.join(',');
      };
    },
    getColumnList() {
      return (db, table) => {
        const columns = [];

        if (this.selectedColumns[db] && this.selectedColumns[db][table]) {
          this.selectedColumns[db][table].forEach((item) => {
            columns.push(item.sourceColumn);
          });
        }
        return columns;
      };
    },
    getWhereFilterFormat() {
      return (value, type) => {
        if (type !== 'in') {
          if (Number.isNaN(value)) {
            return value.substr(0, 1) !== '\'' || value.substr(value.length - 1, 1) !== '\'';
          }
          return false;
        }
        let isIllegal = false;

        value.split(',')
          .forEach((item) => {
            if (Number.isNaN(item)) {
              if (item.substr(0, 1) !== '\'' || item.substr(item.length - 1, 1) !== '\'') {
                isIllegal = true;
              }
            }
          });
        return isIllegal;
      };
    }
  },
  mounted() {
    this.currentData = this.whereCondition;
    this.currentTargetData = this.targetWhereCondition;
  },
  methods: {
    handleAddTargetFilter() {
      this.targetFilterList.push({
        columnName: '',
        expression: '',
        value: ''
      });
    },
    formatPartionSize(data) {
      if (this.drdsPartition.tbpartitions > data.maxPartitionSize) {
        this.$set(this.drdsPartition, 'tbpartitions', data.maxPartitionSize);
      }
      if (this.drdsPartition.tbpartitions < data.minPartitionSize) {
        this.$set(this.drdsPartition, 'tbpartitions', data.minPartitionSize);
      }
    },
    getTbPartitionData() {
      let data = {};

      this.drdsPartitionSetting.tbPartition.forEach((item) => {
        if (this.drdsPartition.tbFuncs === item.function) {
          data = item;
        }
      });
      return data;
    },
    handleChangeTbFuncs() {
      this.drdsPartition.tbpartitions = this.getTbPartitionData().minPartitionSize;
      if (this.getTbPartitionData().maxPartitionSize > this.getTbPartitionData().minPartitionSize) {
        this.taskInfo.partitionRange = `${this.getTbPartitionData().minPartitionSize}-${this.getTbPartitionData().maxPartitionSize}`;
      } else {
        this.taskInfo.partitionRange = this.getTbPartitionData()
          .minPartitionSize
          .toString();
      }
    },
    handleAddVirtualColumn() {
      this.virtualData.push({
        targetVirtualName: '',
        targetVirtualType: '',
        targetVirtualLength: 0,
        columnValue: '',
        sqlType: 0,
        columnLength: '',
        genRule: ''
      });
    },
    handleDeleteVirtual(index) {
      this.virtualData.splice(index, 1);
    },
    handleChangeRow(row, index, key, data) {
      if (data === 'cc-custom') {
        row.isCustom = true;
        this.virtualData[index].isCustom = true;
        this.virtualData[index].targetVirtualName = data;
      } else {
        row.isCustom = false;
        this.virtualData[index].isCustom = false;
        const columnList = this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb]
          ? this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb][this.selectedTable.sinkTable
            ? this.selectedTable.sinkTable : this.selectedTable.targetTable] : [];

        columnList.forEach((item) => {
          if (item.columnName === data) {
            this.virtualData[index].targetVirtualName = item.columnName;
            this.virtualData[index].targetVirtualType = item.dataType;
            this.virtualData[index].targetVirtualLength = item.dataTypeWithLength;
            this.virtualData[index].sqlType = item.sqlTypeIntVal;
          }
        });
      }
    },
    handleChangeColumnRow(row, index, key) {
      // 判断是是否已存在
      const sinkColumns = this.taskInfo.sinkColumns[this.selectedTable.sinkDb ? this.selectedTable.sinkDb : this.selectedTable.targetDb];

      let hasSame = false;

      if (sinkColumns && sinkColumns[this.selectedTable.sinkTable]) {
        sinkColumns[this.selectedTable.sinkTable].forEach((item) => {
          if (item.columnName === row.customName) {
            hasSame = true;
            row.isCustom = false;
            this.virtualData[index].isCustom = false;
            this.virtualData[index].customName = item.columnName;
            this.virtualData[index].targetVirtualName = item.columnName;
            this.virtualData[index].targetVirtualType = item.dataType;
            this.virtualData[index].targetVirtualLength = item.dataTypeWithLength;
            this.virtualData[index].sqlType = item.sqlTypeIntValue;
            this.virtualData[index].genRule = item.genRule;
          }
        });
      }
      if (!hasSame) {
        row.isCustom = true;
        this.virtualData[index].isCustom = true;
        this.virtualData[index][key] = row[key];
      }
    },
    handleChangeInputRow(row, index, key) {
      this.virtualData[index][key] = row[key];
    },
    handleChangeTargetType(row, index, data) {
      const theData = data ? JSON.parse(data) : '';

      this.virtualData[index].targetVirtualType = theData.typeName;
      this.virtualData[index].sqlType = theData.sqlTypeIntVal;
      this.virtualData[index].typeData = data;
    },
    handleChangeGenRule(row, index, data) {
      this.virtualData[index].genRule = data;
      this.virtualColGenRule.forEach(((rule) => {
        if (rule.virtualColGenRule === data) {
          if (rule.type) {
            this.typeMeta.forEach(type => {
              if (type.typeName === rule.type) {
                console.log('type', type);
                this.virtualData[index].targetVirtualType = type.typeName;
                this.virtualData[index].typeData = JSON.stringify(type);
                this.virtualData[index].sqlType = type.sqlTypeIntVal;
                console.log('this.virtualData[index].typeData', this.virtualData[index].typeData);
              }
            });
          }
          if (rule.length) {
            this.virtualData[index].columnLength = rule.length;
          }
        }
      }));
    },
    handleUpdateWhereCondition() {
      this.updateWhereCondition(this.currentData);
    },
    handleUpdateTargetWhereCondition() {
      this.updateTargetWhereCondition(this.currentTargetData);
    },
    handleChangePartitionFunc(data) {
      this.HivePartitionFuncs.forEach((item) => {
        if (item.function === data) {
          this.partitionData.partition[0].key = item.partitionKey;
        }
      });
    }
  },
  watch: {
    virtualColumnData(val) {
      if (!this.showBatchVirtualColumn) {
        if (val.length > 0 && this.taskInfo.processType === 'create') {
          this.virtualData = _.cloneDeep(val);
        } else {
          this.virtualData = [];
          this.virtualData.push({
            targetVirtualName: '',
            targetVirtualType: '',
            targetVirtualLength: 0,
            columnValue: '',
            sqlType: 0,
            typeData: '',
            columnLength: '',
            genRule: ''
          });
        }
      }
    },
    showOperateColumns(val) {
      if (val) {
        this.searchColumnKey = '';
      }
    },
    showRedisKey(val) {
      if (val) {
        this.suffixFields = this.selectedTable.suffixFields || [];
      }
    },
    whereCondition(val) {
      this.currentData = val;
    },
    targetWhereCondition(val) {
      this.currentTargetData = val;
    }
  }
};
</script>
<style lang="less" scoped>
.virtual-column-rule {
  width: 230px;
  display: inline-block;
  margin-right: 10px;
}
.virtual-column-value {
  display: inline-block;
  width: 100px;
}
</style>
