<template>
  <div class="page-header-container">
    <Form ref="formInline" :model="searchData" inline label-position="right"
          style="padding-right: 300px">
      <FormItem>
        <Select v-model="searchType" style="width:120px" @on-change="handleChangeSearchType">
          <Option value="dataJobDesc" :label="$t('ren-wu-miao-shu')">
            <span>{{ $t('ren-wu-miao-shu') }}</span>
          </Option>
          <Option value="instanceId" :label="$t('ren-wu-ming-cheng')">
            <span>{{ $t('ren-wu-ming-cheng') }}</span>
          </Option>
          <Option value="dataJobType" :label="$t('ren-wu-lei-xing')">
            <span>{{ $t('ren-wu-lei-xing') }}</span>
          </Option>
          <Option value="sourceInstance" :label="$t('yuan-shu-ju-yuan-id')">
            <span>{{ $t('yuan-shu-ju-yuan-id') }}</span>
          </Option>
          <Option value="sinkInstance" :label="$t('mu-biao-shu-ju-yuan-id')">
            <span>{{ $t('mu-biao-shu-ju-yuan-id') }}</span>
          </Option>
          <Option value="workerIp" :label="$t('ji-qi-ip')">
             <span>{{ $t('ji-qi-ip') }}</span>
          </Option>
          <Option value="transferObjName" :label="$t('yuan-duan-biao-ming')">
            <span>{{ $t('yuan-duan-biao-ming') }}</span>
          </Option>
          <Option value="dataJobId" :label="$t('ren-wu-shu-zi-id-0')">
                      <span>{{ $t('ren-wu-shu-zi-id-0') }}</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='instanceId'">
        <Input v-model="searchData.dataJobName" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='transferObjName'">
        <Input v-model="searchData.transferObjName" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='dataJobId'">
        <Input v-model="searchData.dataJobId" style="width: 280px" @on-enter="_handleSearch" type="number"/>
      </FormItem>
      <FormItem v-if="searchType==='dataJobType'">
        <Select class="data-job-type-select" v-model="searchData.dataJobType" style="width:250px">
          <Option value="MIGRATION" :label="$t('shu-ju-qian-yi')">
            <span>{{ $t('shu-ju-qian-yi') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.MIGRATION}`"></i>
          </Option>
          <Option value="SYNC" :label="$t('shu-ju-tong-bu')">
            <span>{{ $t('shu-ju-tong-bu') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.SYNC}`"></i>
          </Option>
          <Option value="CHECK" :label="$t('shu-ju-xiao-yan')">
            <span>{{ $t('shu-ju-xiao-yan') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.CHECK}`"></i>
          </Option>
          <Option value="STRUCT_MIGRATION" :label="$t('jie-gou-qian-yi')">
            <span>{{ $t('jie-gou-qian-yi') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.STRUCT_MIGRATION}`"></i>
          </Option>
          <!--                            <Option value="SUBSCRIBE">数据订阅</Option>-->
          <Option value="all" :label="$t('quan-bu')">{{ $t('quan-bu') }}</Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='dataJobDesc'">
        <Input v-model="searchData.desc" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='sourceInstance'">
        <Select filterable style="width: 250px" v-model="searchData.sourceInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='sinkInstance'">
        <Select filterable style="width: 250px" v-model="searchData.targetInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='workerIp'">
         <Input v-model="searchData.workerIp" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem>
        <pd-button type="primary" @click="_handleSearch" :loading="loading">
          {{ $t('cha-xun') }}
        </pd-button>
<!--        <Checkbox style="margin-left: 20px" v-model="ifClassfy" @on-change="handleChooseClassify">-->
<!--          {{ $t('fen-lei') }}-->
<!--        </Checkbox>-->
<!--        <Select v-model="classfyType" style="width:98px" :disabled="!ifClassfy"-->
<!--                @on-change="handleClassify">-->
<!--          <Option value="status">{{ $t('ren-wu-zhuang-tai') }}</Option>-->
<!--          <Option value="state">{{ $t('ren-wu-jie-duan') }}</Option>-->
<!--          <Option value="type">{{ $t('ren-wu-zhong-lei') }}</Option>-->
<!--        </Select>-->
      </FormItem>
    </Form>
    <div class="page-header-function">
      <!--            <a href="/home/document" target="_blank">查看任务创建流程</a>-->
      <pd-button type="primary" ghost @click="handleCreateJob" v-if="myAuth.includes('CC_DATAJOB_MANAGE')">
        <Icon type="md-add"/>
        {{ $t('chuang-jian-ren-wu') }}
      </pd-button>
      <pd-button v-if="myAuth.includes('CC_DATAJOB_MANAGE') && mode==='table'" @click="updateBatchInTable">
        {{ batchEditInTable ? $t('qu-xiao-pi-liang-chu-li-ren-wu') : $t('pi-liang-chu-li-ren-wu') }}
      </pd-button>
      <pd-button @click="handleGoMetaCenter">
        {{ $t('yuan-shu-ju-jian-suo') }}
      </pd-button>
<!--      <Tooltip transfer placement="bottom-end" :content="`点击切换${mode==='table'?'卡片':'列表'}模式`">-->
<!--                <span class="data-job-mode-switch"-->
<!--                      @click="handleChangeMode(mode==='table'?'card':'table')">-->
<!--                <svg class="icon" aria-hidden="true" v-if="mode==='table'">-->
<!--                    <use xlink:href="#icon-card"></use>-->
<!--                </svg>-->
<!--                <svg class="icon" aria-hidden="true" v-if="mode==='card'">-->
<!--                    <use xlink:href="#icon-list"></use>-->
<!--                </svg>-->
<!--            </span>-->
<!--      </Tooltip>-->
      <Checkbox style="margin-left: 8px" v-model="hasIntervalNow" @on-change="handleInterval">
        {{ $t('20-miao-ding-shi-shua-xin') }}
      </Checkbox>
      <pd-button style="margin-left: 0" type="default" :loading="loading"
                 @click="_handleSearch">{{ $t('shua-xin') }}
      </pd-button>
    </div>
  </div>
</template>
<script>
import { preFirstCheck } from '@/services/cc/api/job';
import Mapping from '@/views/util';
import { mapState } from 'vuex';

export default {
  props: ['handleSearch', 'handleChangeMode', 'mode', 'batchEditInTable', 'updateBatchInTable', 'handleClassify', 'loading', 'handleInterval', 'hasInterval', 'instanceList'],
  data() {
    return {
      ifClassfy: false,
      classfyType: 'status',
      hasIntervalNow: this.hasInterval,
      searchType: 'dataJobDesc',
      Mapping,
      searchData: {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        transferObjName: '',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        dataJobId: null,
        sourceInstanceId: 0,
        targetInstanceId: 0
      }
    };
  },
  created() {
    const params = JSON.parse(sessionStorage.getItem('job_search_params'));
    if (params) {
      this.searchData = params;
      this.searchType = params.searchType;
      this.ifClassfy = params.ifClassfy;
      this.classfyType = params.classfyType;
      this.handleSearch(params);
    } else {
      this.handleSearch(this.searchData);
    }
  },
  computed: {
    ...mapState(['myAuth'])
  },
  methods: {
    _handleSearch() {
      const {
        ifClassfy, classfyType, searchType, searchData
      } = this;
      sessionStorage.setItem('job_search_params', JSON.stringify({
        searchType, ifClassfy, classfyType, ...searchData
      }));
      this.handleSearch(this.searchData);
    },
    handleCreateJob() {
      preFirstCheck()
        .then((res2) => {
          if (res2.data.code === '1') {
            this.$router.push({ path: '/data/job/create/process' });
          }
        });
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0,
        transferObjName: '',
        dataJobId: null
      };
    },
    handleChooseClassify(data) {
      this.handleChangeMode('card');
      if (!data) {
        this.classfyType = 'status';
        this.handleClassify('');
      } else {
        this.classfyType = 'status';
        this.handleClassify('status');
      }
    },
    handleGoMetaCenter() {
      this.$router.push({
        path: '/data/job/meta/center'
      });
    }
  }
};
</script>
<style lang="less" scoped>
.page-header-container {
  background: #ffffff;
  border: 1px solid #EDEDED;
  height: 60px;
  line-height: 54px;
  padding: 0 20px;
  position: relative;

  .ivu-form-inline .ivu-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .ivu-form-item {
    margin-bottom: 0;
  }

  .page-header-function {
    position: absolute;
    right: 20px;
    top: 2px;

    a {
      color: #333;
      margin-right: 10px;
    }

    button {
      margin-left: 8px;
    }

    .ivu-tooltip {
      margin-left: 8px;
    }
  }
}

.data-job-mode-switch {
  width: 32px;
  height: 32px;
  border: 1px solid #BABDC5;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
.data-job-type-select i {
  margin-left: 6px;
}
</style>
