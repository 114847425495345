<template>
  <div>
    <p style="margin-top: 20px;margin-bottom: 10px;font-size: 14px;">{{ $t('ying-yong-dao-yi-xia-biao') }}</p>
    <div style="background-color: #FAFAFA;border: 1px solid #EDEDED;padding: 22px 12px">
      <Input class="check-all-filter" placeholder="筛选表" v-model="filterKey" @on-change="handleFilterTable"/>
      <Checkbox style="margin-bottom: 10px"
                :value="checkAll"
                @click.prevent.native="handleCheckAll(filteredData)">{{ $t('quan-xuan') }}
      </Checkbox>
      <CheckboxGroup style="max-height: 200px;overflow: auto;" :value="checkAllGroup"
                     @on-change="checkAllGroupChange">
        <Checkbox style="margin-bottom: 10px" v-for="table of showHasColumnTableList"
                  :label="getTableName(table)" :key="getTableName(table)"></Checkbox>
      </CheckboxGroup>
      <Page size="small" style="margin-top: 20px" :total="filteredData.length" show-total
            :page-size="showHasColumnTableListSize" @on-change="handleShowHasColumnPageChange"/>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { isHasSchema } from '@/utils';

export default {
  props: {
    handleCheckAll: Function,
    checkAllGroupChange: Function,
    // handleShowHasColumnPageChange: Function,
    checkAll: Boolean,
    checkAllGroup: Array,
    // showHasColumnTableList: Array,
    hasColumnTableList: Array,
    showHasColumnTableListSize: Number,
    sourceType: String
  },
  data() {
    return {
      checkAllGroupData: [],
      filterKey: '',
      showHasColumnTableList: [],
      filteredData: []
    };
  },
  computed: {
    getTableName() {
      return (table) => {
        if (isHasSchema(this.sourceType)) {
          // 等下次一起优化
          // return `${table.db || table.dbName}.${table.sourceSchema}.${table.sourceTable || table.tableName}`;
          return `${table.db || table.dbName}.${table.sourceTable || table.tableName}`;
        }
        return `${table.db || table.dbName}.${table.sourceTable || table.tableName}`;
      };
    }
  },
  methods: {
    handleFilterTable() {
      this.filteredData = [];
      this.hasColumnTableList.forEach((table) => {
        const path = `${table.dbName}.${table.tableName}`;
        if (path.includes(this.filterKey)) {
          this.filteredData.push(table);
        }
      });
      this.showHasColumnTableListPage = 1;
      this.handleShowHasColumnPageChange(1);
    },
    handleShowHasColumnPageChange(page) {
      this.showHasColumnTableListPage = page;
      this.showHasColumnTableList = this.filteredData.slice(this.showHasColumnTableListSize * (this.showHasColumnTableListPage - 1),
        this.showHasColumnTableListPage * this.showHasColumnTableListSize);
    }
  },
  watch: {
    hasColumnTableList(val) {
      this.filteredData = _.cloneDeep(val);
      this.showHasColumnTableListPage = 1;
      this.handleShowHasColumnPageChange(1);
    }
  }
};
</script>
<style scoped lang="less">
.check-all-filter {
  width: 280px;
  margin-bottom: 10px;
  display: block;
}
</style>
