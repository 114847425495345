import Vue from 'vue';
import request from './request';
import { api } from './api';

const services = {};

Object.entries(api)
  .forEach((item) => {
    services[item[0]] = (options = {}) => request({ url: item[1], ...options });
  });

Object.defineProperty(Vue.prototype, '$services', {
  value: services
});

export default services;
