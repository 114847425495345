export const desensitizationApi = {
  addDesensitizeRule: '/clouddm/console/api/v1/datadesensitizerule/adddesensitizerule',
  deleteDesensitizeRule: '/clouddm/console/api/v1/datadesensitizerule/deletedesensitizerule',
  listDesensitizeRules: '/clouddm/console/api/v1/datadesensitizerule/listdesensitizerules',
  modifyDesensitizeRule: '/clouddm/console/api/v1/datadesensitizerule/modifydesensitizerule',
  updateDesensitizeRule: '/clouddm/console/api/v1/datadesensitizerule/updatedesensitizerule',
  updateDesensitizeRuleAbility: '/clouddm/console/api/v1/datadesensitizerule/updatedesensitizeruleability',
  desensitizeRuleSample: '/clouddm/console/api/v1/datadesensitizerule/desensitizerulesample',
  istTableDesensitizeRules: '/clouddm/console/api/v1/datadesensitizerule/listtabledesensitizerules',
  desensitizeRuleTest: '/clouddm/console/api/v1/datadesensitizerule/desensitizeruletest'
};
