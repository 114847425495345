<template>
    <div class="content-wrapper">
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem to="/ccsystem/state/task">{{ $t('yi-bu-ren-wu') }}</BreadcrumbItem>
            <BreadcrumbItem>{{ $t('xiang-qing') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="console-job-detail-container">
            <div class="console-job-detail-title">
                <span class="console-job-detail-label">{{ $t('yi-bu-ren-wu-ming-cheng') }}:</span>
                <span class="console-job-detail-value">{{Mapping.consoleJob[consoleJobInfo.label]}}</span>
                <span class="console-job-detail-label">{{ $t('lei-xing-0') }}</span>
                <span class="console-job-detail-value">{{Mapping.resourceType[consoleJobInfo.resourceType]}}</span>
                <span class="console-job-detail-label">{{ $t('zi-yuan-id-0') }}</span>
                <span class="console-job-detail-value">
                    <span v-if="consoleJobInfo.dataJobName"><a @click="getJob(consoleJobInfo)">{{consoleJobInfo.dataJobName}}</a></span>
                    <span v-if="consoleJobInfo.workerName">{{consoleJobInfo.workerName}}</span>
                    <span v-if="consoleJobInfo.dsInstanceId">{{consoleJobInfo.dsInstanceId}}</span>
                </span>
                <span class="console-job-detail-label">{{ $t('zi-yuan-miao-shu-0') }}</span>
                <span class="console-job-detail-value">
                    <span v-if="consoleJobInfo.dataJobDesc">{{consoleJobInfo.dataJobDesc}}</span>
                    <span v-if="consoleJobInfo.workerDesc">{{consoleJobInfo.workerDesc}}</span>
                    <span v-if="consoleJobInfo.datasourceDesc">{{consoleJobInfo.datasourceDesc}}</span>
                </span>
<!--                <pd-button v-if="consoleJobInfo.taskState!=='SUCCESS'&&consoleJobInfo.taskState!=='CANCELED'" class="cancel-btn" @click="handleCancelJob">取消</pd-button>-->
                <pd-button class="refresh-btn" @click="getConsoleJobInfo" :loading="loading">{{ $t('shua-xin') }}</pd-button>
            </div>
            <div class="console-job-detail-body">
                <div class="console-job-detail-wrapper">
                    <div class="step-line">

                    </div>
                    <div v-for="task in consoleJobInfo.taskVOList" :key="task.id" class="console-job-step">
                        <span :class="`task-status-point ${task.taskState==='SUCCESS'?'task-status-success':task.taskState==='FAILED'?'task-status-error':''}`"></span>
                        <span class="console-job-detail-label">ID：</span>
                        <span class="console-job-detail-value">{{task.id}}</span>
                        <span class="console-job-detail-label">{{ $t('bu-zhou-ming-cheng') }}</span>
                        <span class="console-job-detail-value">{{task.stepName}}</span>
                        <span class="console-job-detail-label">{{ $t('zhu-ji-di-zhi') }}</span>
                        <span class="console-job-detail-value">{{task.host}}</span>
                        <span class="console-job-detail-label">{{ $t('zhuang-tai-0') }}</span>
                        <span class="console-job-detail-value" :style="`color:${task.taskState==='SUCCESS'?
                        '#52C41A':task.taskState==='FAILED'?'#FF1815':'#FFA30E'}`">{{Mapping.consoleJobStatus[task.taskState]}}</span>
                        <span class="console-job-detail-label">{{ $t('zhi-hang-shi-jian-1') }}</span>
                        <span class="console-job-detail-value">{{task.taskState==='SUCCESS'||task.taskState==='FAILED'?fecha.format(new Date(task.executeTime), 'YYYY-MM-DD HH:mm:ss'):''}}</span>
                        <span v-if="myAuth.includes('CC_CONSOLEJOB_MANAGE') && task.taskState==='FAILED'" class="console-task-action">
                            <a style="margin-right: 16px" @click="handleRetryTask(task)">{{ $t('zhong-shi') }}</a>
                            <a v-if="task.cancelable" @click="handleSkipTask(task)">{{ $t('hu-lve-bing-ji-xu') }}</a>
                        </span>
                        <div v-if="task.taskState==='FAILED'" class="error-msg-container">
                            <pre style="overflow: auto">{{task.message}}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
  queryConsoleJob, retryConsoleTask, skipConsoleTask, cancelConsoleJob
} from '@/services/cc/api/consoleJob';
import { queryJobById } from '@/services/cc/api/noError';
import fecha from 'fecha';
import { mapState } from 'vuex';
import Mapping from '../util';

export default {
  mounted() {
    this.consoleJobId = this.$route.params.id;
    this.getConsoleJobInfo();
  },
  data() {
    return {
      consoleJobId: 0,
      consoleJobInfo: {},
      Mapping,
      fecha,
      loading: false
    };
  },
  computed: {
    ...mapState(['myAuth'])
  },
  methods: {
    handleRetryTask(task) {
      retryConsoleTask({
        consoleJobId: this.consoleJobId,
        consoleTaskId: task.id
      }).then((res) => {
        if (res.data.code === '1') {
          this.getConsoleJobInfo();
        }
      });
    },
    handleSkipTask(task) {
      this.$Modal.confirm({
        title: this.$t('hu-lve-bing-ji-xu-que-ren'),
        content: this.$t('p-hu-lve-zhi-hou-ben-bu-zhou-jiang-bu-zhi-hang-bing-ji-xu-zhi-hang-xia-yi-bu-zhou-que-ren-yao-hu-lve-bing-gai-yi-bu-ren-wu-ma-p'),
        onOk: () => {
          skipConsoleTask({
            consoleJobId: this.consoleJobId,
            consoleTaskId: task.id
          }).then((res) => {
            if (res.data.code === '1') {
              this.getConsoleJobInfo();
              this.$Message.success(this.$t('hu-lve-bing-ji-xu-ren-wu-cheng-gong'));
            }
          });
        }
      });
    },
    getConsoleJobInfo() {
      this.loading = true;
      queryConsoleJob({ consoleJobId: this.consoleJobId }).then((res) => {
        this.loading = false;
        if (res.data.code === '1') {
          this.consoleJobInfo = res.data.data;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getJob(row) {
      queryJobById({ jobId: row.resourceId }).then((res) => {
        if (res.data.code === '1') {
          this.$router.push({ path: `/data/job/${row.resourceId}` });
        } else {
          this.$Modal.info({
            title: this.$t('tiao-zhuan-shi-bai'),
            content: this.$t('gai-ren-wu-yi-bu-cun-zai')
          });
        }
      });
    },
    handleCancelJob() {
      this.$Modal.confirm({
        title: this.$t('qu-xiao-yi-bu-ren-wu-que-ren'),
        content: this.$t('p-qu-xiao-zhi-hou-zheng-ge-ren-wu-jiang-shi-bai-bing-jie-shu-que-ren-yao-qu-xiao-gai-yi-bu-ren-wu-ma-p'),
        onOk: () => {
          cancelConsoleJob({
            consoleJobId: this.consoleJobInfo.id,
            consoleTaskId: ''
          }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('qu-xiao-yi-bu-ren-wu-cheng-gong'));
              this.getConsoleJobInfo();
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
    .console-job-detail-container{
        width: 100%;
        border: 1px solid #dadada;
        .console-job-detail-title{
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #dadada;
            background-color: #DEEFFF;
            padding-left: 15px;
            position: relative;
            .refresh-btn{
                position: absolute;
                right: 20px;
                top: 8px;
            }
          .cancel-btn{
            position: absolute;
            right: 80px;
            top: 8px;
          }
        }
        .console-job-detail-body{
            background-color: #ffffff;
            padding: 18px 16px 18px 30px;
            .console-job-detail-wrapper{
                position: relative;
                .step-line{
                    width: 1px;
                    height: 100%-18px;
                    background-color: #dadada;
                    position: absolute;
                    left: 6px;
                    top: 12px;
                    z-index:0;
                }
            }
        }
        .console-job-detail-value{
            font-family: PingFangSC-Semibold,serif;
            font-weight: 500;
            margin-right: 60px;
        }
        .console-job-step{
            margin-bottom: 20px;
            position: relative;
            .error-msg-container{
                background-color: #FAFAFA;
                border: 1px solid #EDEDED;
                margin: 10px 0 10px 22px;
                padding: 10px;
            }
            .console-task-action{
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .task-status-point{
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #999999;
            margin-right: 8px;
            z-index:1;
            position: relative;
        }
        .task-status-success{
            background-color: #52C41A;
        }
        .task-status-error{
            background-color: #FF1815;
        }
    }
</style>
