<template>
  <div>
    <Steps :current="currentStep" v-if="showStep===1" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('jie-gou-qian-yi')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jsobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType===`MIGRATION`?$t('quan-liang-qian-yi'):$t('shu-ju-chu-shi-hua')"
            :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="$t('jobdatadatajobtypemigration-zeng-liang-tong-bu-jobdatadatajobtypesync-shu-ju-tong-bu-shu-ju-ding-yue', [jobData.dataJobType===`MIGRATION`?$t('zeng-liang-tong-bu'):jobData.dataJobType===`SYNC`?$t('shu-ju-tong-bu'):$t('shu-ju-ding-yue')])"
        :icon="currentStep===3&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===2" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('jie-gou-qian-yi')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType===`MIGRATION`?$t('quan-liang-qian-yi'):$t('shu-ju-chu-shi-hua')"
            :icon="currentStep===2?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===3" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType===`MIGRATION`?$t('quan-liang-qian-yi'):$t('shu-ju-chu-shi-hua')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===4" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="jobData.dataJobType===`MIGRATION`?$t('quan-liang-qian-yi'):$t('shu-ju-chu-shi-hua')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType===`MIGRATION`?$t('zeng-liang-tong-bu'):jobData.dataJobType===`SYNC`?$t('shu-ju-tong-bu'):$t('shu-ju-ding-yue')"
        :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===5" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType===`MIGRATION`?$t('zeng-liang-tong-bu'):jobData.dataJobType===`SYNC`?$t('shu-ju-tong-bu'):$t('shu-ju-ding-yue')"
        :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===6" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('jie-gou-qian-yi')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step
        :title="jobData.dataJobType===`MIGRATION`?$t('zeng-liang-tong-bu'):jobData.dataJobType===`SYNC`?$t('shu-ju-tong-bu'):$t('shu-ju-ding-yue')"
        :icon="currentStep===2&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===7" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :key="$t('xiao-yan-yu-ding-zheng')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
    <Steps :current="currentStep" v-if="showStep===8" :status="currentStatus">
      <Step :title="$t('ren-wu-chu-shi-hua')"
            :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('jie-gou-qian-yi')"
            :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
      <Step :title="$t('wan-cheng')"></Step>
    </Steps>
  <Steps :current="currentStep" v-if="showStep===9" :status="currentStatus">
        <Step :title="$t('ren-wu-chu-shi-hua')"
              :icon="currentStep===0&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
        <Step :title="$t('shu-ju-ding-zheng')"
              :icon="currentStep===1&&jobData.currentStatus!=='STOP'&&jobData.currentStatus!=='ABNORMAL'?'':''"></Step>
        <Step :title="$t('wan-cheng')"></Step>
      </Steps>
    </div>
</template>
<script>
export default {
  props: {
    currentStep: Number,
    showStep: Number,
    jobData: Object,
    currentStatus: String
  }
};
</script>
<style lang="less">
.job-detail-container .job-header .task-detail-step .ivu-steps .ivu-steps-title {
  margin-top: 4px;
}
</style>
