<template>
  <div class="console-list" style="padding: 16px;">
    <Breadcrumb style="margin-bottom: 14px">
      <BreadcrumbItem>{{ $t('kong-zhi-tai-jian-kong') }}</BreadcrumbItem>
    </Breadcrumb>
    <Table :data="consoleList" :columns="columns" size="small" @on-row-click="jumpGraph" border>
      <template slot="cpu" slot-scope="{row}">
        {{ (row.cpuUseRatio * 100).toFixed(2) }}
      </template>
      <template slot="action" slot-scope="{row}">
        <a type="text" @click="jumpGraph(row)" size="small" style="color: #0BB9F8">{{ $t('xiang-qing') }}</a>
      </template>
    </Table>
  </div>
</template>
<script>

import { listConsole } from '@/services/cc/api/console';

export default {
  created() {
    this.getConsoleList();
  },
  data() {
    return {
      consoleList: [],
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: 'IP',
          key: 'consoleIp'
        },
        {
          title: this.$t('cpu-shi-yong-shuai'),
          slot: 'cpu'
        },
        {
          title: this.$t('nei-cun-shi-yong-shuai'),
          key: 'memUseRatio'
        },
        {
          title: this.$t('ban-ben'),
          key: 'version'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action'
        }
      ]
    };
  },
  methods: {
    jumpGraph(row) {
      this.$router.push({ path: `/monitor/console/graph?ip=${row.consoleIp}&id=${row.id}` });
    },
    async getConsoleList() {
      const res = await listConsole({});

      if (res.data.success) {
        this.consoleList = res.data.data;
      }
    }
  }
};

</script>
<style scoped type="less">

</style>
