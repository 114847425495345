














import Vue, { VueConstructor } from 'vue'

export default Vue.extend({
  name: 'CLoadingIcon',
})
