export const browseApi = {
  listLevels: '/clouddm/console/api/v1/browse/listLevels',
  detailLevels: '/clouddm/console/api/v1/browse/detailLevels',
  listLeaf: '/clouddm/console/api/v1/browse/listLeaf',
  genAction: '/clouddm/console/api/v1/browse/actions/genAction',
  doAction: '/clouddm/console/api/v1/browse/actions/doAction',
  instanceRemark: '/clouddm/console/api/v1/browse/actions/instanceRemark',
  instanceDelete: '/clouddm/console/api/v1/browse/actions/instanceDelete',
  requestScript: '/clouddm/console/api/v1/browse/actions/requestScript',
  generateScript: '/clouddm/console/api/v1/browse/actions/generateScript',
  convertDDL: '/clouddm/console/api/v1/browse/actions/convertDDL',
  rdbTableDetail: '/clouddm/console/api/v1/browse/rdbObjectDetail',
  getCreateObjectScript: '/clouddm/console/api/v1/browse/object/getCreateObjectScript',
  loadObject: '/clouddm/console/api/v1/browse/actions/loadObject'
};
