<template>
  <div class="async-job-list">
    <div class="header">
      <div>{{ $t('yi-bu-zhi-hang-guan-li-lie-biao') }}</div>
      <a-button style="margin-bottom: 10px;" @click="handleRefresh" class="refresh" :loading="refreshLoading">{{ $t('shua-xin') }}</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="asyncJobList"
      :pagination="false"
      bordered
      size="small"
    >
      <template #createTime="record">
        {{ record.gmtCreate | formatTime('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template #status="record">
        <div style="display: flex">
          <cc-status :type="EXPORT_HEALTH_LEVEL_COLOR[record.status]"/>
          {{ EXPORT_STATUS_I18N[record.status] }}
        </div>
      </template>
      <template #jobName="record">
        <a-button type="link" @click="goAsyncDetail(record)">{{ record.jobName }}</a-button>
      </template>
      <template #action="record">
        {{ '' }}
        <a-button type="link" v-if="record.bizType===BIZ_TYPE.TICKETS_WORKFLOW"
                  @click="handleGoTicket(record)">{{ $t('tiao-zhuan-gong-dan') }}
        </a-button>
        <a-button type="link" @click="cancelJob(record)" class="cancel">{{ $t('qu-xiao') }}</a-button>
      </template>
      <template #source="record">
        {{ BIZ_TYPE_I18N[record.bizType] }}
      </template>
    </a-table>
    <cc-pagination @onChange="handlePageChange" style="float: right" :page="page"
                   :hasNext="hasNext"/>
  </div>
</template>

<script>
import {
  BIZ_TYPE, BIZ_TYPE_I18N, EXPORT_HEALTH_LEVEL_COLOR, EXPORT_STATUS, EXPORT_STATUS_I18N
} from '@/const';

export default {
  name: 'AsyncJobList',
  props: {
    bizType: String,
    bizId: String,
    setAsyncJobDetail: Function
  },
  data() {
    return {
      EXPORT_STATUS_I18N,
      EXPORT_STATUS,
      EXPORT_HEALTH_LEVEL_COLOR,
      BIZ_TYPE_I18N,
      BIZ_TYPE,
      preStartIds: [],
      refreshLoading: false,
      startId: 0,
      pageSize: 10,
      page: 1,
      hasNext: true,
      asyncJobList: [],
      columns: [
        {
          title: this.$t('ren-wu-ming-cheng-0'),
          scopedSlots: { customRender: 'jobName' }
        },
        {
          title: this.$t('yong-hu'),
          dataIndex: 'userName'
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: this.$t('zhuang-tai'),
          scopedSlots: { customRender: 'status' }
        },
        {
          title: this.$t('lai-yuan'),
          scopedSlots: { customRender: 'source' }
        },
        {
          title: this.$t('cao-zuo'),
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },
  created() {
    this.getAsyncJobList();
    window.$bus.on('asyncJobListRefresh', this.getAsyncJobList);
  },
  methods: {
    handleGoTicket(record) {
      this.$router.push({ path: `/ticket/${record.bizId}` });
    },
    handlePageChange(type = '') {
      this.getAsyncJobList(type);
    },
    goAsyncDetail(record) {
      if (this.bizType === 'QUERY_CONSOLE') {
        this.setAsyncJobDetail(record.id);
      } else {
        this.$router.push({
          path: `async_job/${record.id}`
        });
      }
    },
    async getAsyncJobList(type) {
      this.refreshLoading = true;
      let startId = 0;
      if (type) {
        if (type === 'left') {
          this.page--;
          startId = this.preStartIds[this.page - 1];
        }

        if (this.page < 0) {
          this.page = 1;
          startId = 0;
        }

        if (type === 'right') {
          startId = this.asyncJobList[this.pageSize - 1].id;
          this.page++;
        }
      }

      const queryUrl = this.bizType === 'QUERY_CONSOLE' ? 'listExportJoBbyBiz' : 'listExportJobs';
      const data = this.bizType === 'QUERY_CONSOLE' ? {
        bizType: this.bizType,
        exportBizId: this.bizId,
        startId,
        pageSize: this.pageSize
      } : {
        startId,
        pageSize: this.pageSize
      };
      const res = await this.$services[queryUrl]({
        data
      });

      if (res.success) {
        if (res.data.length) {
          if (type === 'right') {
            if (!this.preStartIds[this.page - 1]) {
              this.preStartIds.push(this.asyncJobList[0].id);
            }
          }
          this.asyncJobList = res.data;
          if (this.asyncJobList.length >= this.pageSize) {
            this.hasNext = true;
          } else {
            this.hasNext = false;
          }
        } else {
          this.page--;
        }
      }

      this.refreshLoading = false;
    },
    async cancelJob(record) {
      const res = await this.$services.cancelExportJob({
        data: {
          exportJobId: record.id
        }
      });

      if (res.success) {
        await this.handleRefresh();
        this.$message.success(this.$t('qu-xiao-cheng-gong'));
      }
    },
    handleRefresh() {
      this.page = 1;
      this.startId = 0;
      this.getAsyncJobList();
    }
  },
  destroyed() {
    window.$bus.off('asyncJobListRefresh');
  }
};
</script>

<style scoped lang="less">
.async-job-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .refresh {
      float: right;
    }
  }
}
</style>
