export default [
  {
    path: 'info_center',
    name: 'InfoCenter',
    component: () => import(/* webpackChunkName: "ticket" */'@/views/consoleJob/index')
  },
  {
    path: 'console_job/:id',
    name: 'ConsoleJob/id',
    component: () => import(/* webpackChunkName: "ticket" */'@/views/consoleJob/consoleJobDetail')
  },
  {
    path: 'async_job_list',
    name: 'ASYNC_JOB_LIST',
    component: () => import(/* webpackChunkName: "async-job-list" */'@/views/system/AsyncJobList/index')
  },
  {
    path: 'async_job/:id',
    name: 'ASYNC_JOB_DETAIL',
    component: () => import(/* webpackChunkName: "async-job-list" */'@/views/system/AsyncJobList/asyncJobDetail')
  },
  {
    path: '',
    name: 'System_Home',
    component: () => import(/* webpackChunkName: "ccsystem-home" */'@/views/system/home')
  },
  {
    path: 'user/config',
    name: 'User_Config',
    component: () => import(/* webpackChunkName: "ccsystem-home" */'@/views/system/user/userConfig')
  },
  {
    path: 'role',
    name: 'System_Role',
    component: () => import(/* webpackChunkName: "ccsystem-role" */'@/views/system/role/index')
  },
  {
    path: 'auth',
    name: 'System_Auth',
    component: () => import(/* webpackChunkName: "ccsystem-auth" */'@/views/system/auth/index')
  },
  {
    path: 'sub_account',
    name: 'System_Sub_Account',
    component: () => import(/* webpackChunkName: "ccsystem-subaccount" */'@/views/system/subaccount/index')
  },
  {
    path: 'sub_account/auth/:uid',
    name: 'System_Sub_Account_Auth',
    component: () => import(/* webpackChunkName: "ccsystem-subaccount-auth" */'@/views/system/auth/index')
  },
  {
    path: 'env',
    name: 'System_Env',
    component: () => import(/* webpackChunkName: "ccsystem-env" */'@/views/system/env')
  },
  {
    path: 'rules',
    name: 'System_Rule',
    component: () => import(/* webpackChunkName: "ccsystem-env" */'@/views/system/rule/index')
  },
  {
    path: 'desensitization',
    name: 'System_Desensitization',
    component: () => import(/* webpackChunkName: "ccsystem-desensitization" */'@/views/system/desensitization/index')
  },
  {
    path: 'desensitization/add',
    name: 'System_Desensitization_Add',
    component: () => import(/* webpackChunkName: "ccsystem-desensitization" */'@/views/system/desensitization/addDesensitization')
  },
  {
    path: 'data_rules',
    name: 'System_Data_Rules',
    component: () => import(/* webpackChunkName: "ccsystem-data-rules" */'@/views/system/dataRule/index')
  },
  {
    path: 'data_rules/add',
    name: 'System_Data_Rules_Add',
    component: () => import(/* webpackChunkName: "ccsystem-data-rules-add" */'@/views/system/dataRule/addDataRule')
  },
  {
    path: 'data_code',
    name: 'System_Data_Code',
    component: () => import(/* webpackChunkName: "ccsystem-env" */'@/views/system/dataCode/index')
  },
  {
    path: 'product_list',
    name: 'Product_List',
    component: () => import(/* webpackChunkName: "ccsystem-env" */'@/views/system/productList/index')
  },
  {
    path: 'operation_log',
    name: 'rdpOperationLog',
    component: () => import(/* webpackChunkName: "ccsystem-env" */'@/views/system/OperationLog')
  }
];
