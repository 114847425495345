import { instance } from '../rdpInstance';

export const listDatasourceDeployType = (param) => instance.post('constant/datasourcedeploytype', param);
export const listDataSourceTypesByDeployType = (param) => instance.post('constant/dstypebydeploytype', param);
export const dsSecurityOption = (param) => instance.post('constant/ds/dssecurityoption', param);
export const listRegionAreas = (param) => instance.post('constant/listregionareas', param);
export const dsMainVersions = (param) => instance.post('constant/ds/dsmainversions', param);
export const dsDrivers = (param) => instance.post('constant/ds/dsdrivers', param);

export const listDsSupportedRegions = (param) => instance.post('constant/dssupportedregion', param);
export const dsConnectType = (param) => instance.post('constant/ds/dsconnecttype', param);
export const listFilterDsTypes = (param) => instance.post('constant/listfilterdstypes', param);
