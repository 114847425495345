export const exportApi = {
  submitExportJob: '/clouddm/console/api/v1/export/submitexportjob',
  listExportJobs: '/clouddm/console/api/v1/export/listexportjobs',
  queryExportJob: '/clouddm/console/api/v1/export/queryexportjob',
  retryExportTask: '/clouddm/console/api/v1/export/retryexporttask',
  skipExportTask: '/clouddm/console/api/v1/export/skipexporttask',
  cancelExportJob: '/clouddm/console/api/v1/export/cancelexportjob',
  listExportJoBbyBiz: '/clouddm/console/api/v1/export/listexportjobbybiz',
  genDownloadInfo: '/clouddm/console/api/v1/export/gendownloadinfo',
  updateExportTaskLimit: '/clouddm/console/api/v1/export/updateexporttasklimit',
  tailExportTaskLog: '/clouddm/console/api/v1/export/tailexporttasklog',
  pauseExportTask: '/clouddm/console/api/v1/export/pauseexporttask',
  resumeExportTask: '/clouddm/console/api/v1/export/resumeexporttask',
  queryExportTask: '/clouddm/console/api/v1/export/queryexporttask'
};
