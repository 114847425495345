import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Icon,
  Input,
  Menu,
  MenuItem,
  Message,
  Modal,
  Option,
  Page,
  Poptip,
  Radio,
  RadioGroup,
  Select,
  Table,
  TabPane,
  Tabs,
  Tooltip,
  Tree,
  Divider,
  Switch,
  Row,
  Col,
  Steps,
  Step,
  Collapse,
  Panel,
  TimePicker,
  Circle,
  Progress,
  Spin
} from 'view-design';
import Vue from 'vue';

const components = {
  Menu,
  Button,
  MenuItem,
  Table,
  Select,
  Option,
  Input,
  Icon,
  Modal,
  Message,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Page,
  Tooltip,
  Poptip,
  Tree,
  DropdownItem,
  RadioGroup,
  Radio,
  DatePicker,
  Card,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  CheckboxGroup,
  Checkbox,
  Divider,
  TimePicker,
  'i-switch': Switch,
  'i-button': Button,
  'i-input': Input,
  'i-alert': Alert,
  'i-form': Form,
  'i-form-item': FormItem,
  'i-checkbox': Checkbox,
  Row,
  Steps,
  Step,
  Collapse,
  Panel,
  'i-circle': Circle,
  Progress,
  Spin,
  Col
};

Object.keys(components)
  .forEach((key) => {
    Vue.component(key, components[key]);
  });

Vue.prototype.$Modal = Modal;
Vue.prototype.$Message = Message;
Vue.prototype.$Spin = Spin;
