<template>
  <div>
    <p><span class="job-header-name-desc" style="margin-right: 10px">{{ $t('ren-wu-id') }}</span><span
      class="basic-info-value">{{ dataJobDetail.dataJobName}}</span></p>
    <Row style="margin-top: 8px" :gutter="80">
      <Col :span="12">
        <p>
          <span>{{ $t('miao-shu-0') }}</span>
          <span
            class="basic-info-value">{{
              `${dataJobDetail.dataJobDesc?dataJobDetail.dataJobDesc:$t('zan-wu-miao-shu')}`
            }}</span>
        </p>
      </Col>
      <Col :span="12">
        <p>
          <span>{{ $t('ren-wu-shu-zi-id') }}</span>
          <span class="basic-info-value">{{ dataJobDetail.dataJobId }}</span>
        </p>
      </Col>
    </Row>
    <div class="datasource-info-block">
      <Row :gutter="80">
        <Col :span="12">
          <div>
            <h3 class="datasource-info-block-title">{{ $t('yuan-duan') }}</h3>
            <div class="basic-info-content">
              <p><span>{{ $t('shi-li-bei-zhu') }}</span><span
                class="basic-info-value">{{ dataJobDetail.sourceDsVO.instanceDesc }}</span></p>
              <p><span>{{ $t('di-yu') }}</span><span class="basic-info-value">{{ dataJobDetail.sourceDsVO.region }}</span></p>
              <p><span>{{ $t('lei-xing-0') }}</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${dataJobDetail.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[dataJobDetail.sourceDsVO.dataSourceType]:dataJobDetail.sourceDsVO.dataSourceType}`">
                                    <DataSourceIcon :type="dataJobDetail.sourceDsVO.dataSourceType"
                                                    :instanceType="dataJobDetail.sourceDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
              <div><span>{{ $t('shi-li-id-0') }}</span><span
                class="basic-info-value">{{ dataJobDetail.sourceDsVO.instanceId }}</span>
              </div>
              <div><span>{{ $t('shu-ju-yuan-shu-zi-id-0') }}</span><span
                class="basic-info-value">{{ dataJobDetail.sourceDsVO.id }}</span>
              </div>
              <div><span>{{ $t('ban-ben-hao-0') }}</span><div
                class="basic-info-value">
                <Tooltip transfer placement="top" :content="dataJobDetail.sourceDsVO.version">
                <p class="show-host-p">{{ dataJobDetail.sourceDsVO.version }}</p>
              </Tooltip>
              </div>
              </div>
              <p class="basic-info-item">
                <span>{{ $t('lian-jie-chuan') }}</span><div class="basic-info-value">
              <Tooltip transfer placement="top" :content="dataJobDetail.sourceDsVO.host">
                <p class="show-host-p">{{ dataJobDetail.sourceDsVO.host }}</p>
              </Tooltip>
            </div>
                <Icon class="copy-icon"  @click="handleCopyHost(dataJobDetail.sourceDsVO.host)"  type="ios-photos-outline"/>
              </p>
              <p><span>{{ $t('bu-shu-lei-xing-0') }}</span><span
                class="basic-info-value">{{
                  DATASOURCE_DEPLOY_TYPE_I18N[dataJobDetail.sourceDsVO.deployType]
                }}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col :span="12">
          <div>
            <h3 class="datasource-info-block-title">{{ $t('mu-biao-duan') }}</h3>
            <div class="basic-info-content">
              <p><span>{{ $t('shi-li-bei-zhu') }}</span><span
                class="basic-info-value">{{ dataJobDetail.targetDsVO.instanceDesc }}</span></p>
              <p><span>{{ $t('di-yu') }}</span><span class="basic-info-value">{{ dataJobDetail.targetDsVO.region }}</span></p>
              <p><span>{{ $t('lei-xing-0') }}</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${dataJobDetail.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[dataJobDetail.targetDsVO.dataSourceType]:dataJobDetail.targetDsVO.dataSourceType}`">
                                    <DataSourceIcon :type="dataJobDetail.targetDsVO.dataSourceType"
                                                    :instanceType="dataJobDetail.targetDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
              <div><span>{{ $t('shi-li-id-0') }}</span><span
                class="basic-info-value">{{ dataJobDetail.targetDsVO.instanceId }}</span>
              </div>
              <div><span>{{ $t('shu-ju-yuan-shu-zi-id-0') }}</span><span
                class="basic-info-value">{{ dataJobDetail.targetDsVO.id }}</span>
              </div>
              <div><span>{{ $t('ban-ben-hao-0') }}</span><div
                class="basic-info-value">
                <Tooltip transfer placement="top" :content="dataJobDetail.targetDsVO.version">
                <p class="show-host-p">{{ dataJobDetail.targetDsVO.version }}</p>
              </Tooltip>
              </div>
              </div>
              <p class="basic-info-item">
                <span>{{ $t('lian-jie-chuan') }}</span><div class="basic-info-value">
              <Tooltip transfer placement="top" :content="dataJobDetail.targetDsVO.host">
                <p class="show-host-p">{{ dataJobDetail.targetDsVO.host }}</p>
              </Tooltip>
              </div>
                <Icon class="copy-icon" @click="handleCopyHost(dataJobDetail.targetDsVO.host)" type="ios-photos-outline"/>
              </p>
              <p><span>{{ $t('bu-shu-lei-xing-0') }}</span><span
                class="basic-info-value">{{
                  DATASOURCE_DEPLOY_TYPE_I18N[dataJobDetail.targetDsVO.deployType]
                }}</span>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@/views/util';
import { handleCopy } from '@/components/function/dataJob/createJob/util';
import { DATASOURCE_DEPLOY_TYPE_I18N } from '@/const/ccIndex';
import DataSourceIcon from '../../DataSourceIcon';

export default {
  props: {
    dataJobDetail: Object
  },
  components: { DataSourceIcon },
  data() {
    return {
      DATASOURCE_DEPLOY_TYPE_I18N,
      fecha,
      MappingUtil,
      desc: ''
    };
  },
  methods: {
    handleCopyHost(data) {
      handleCopy(data);
      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    }
  }
};
</script>
<style lang="less" scoped>
  .datasource-info-block-title{
    margin: 10px 0;
  }
  .show-host-p{
    overflow: hidden;
    max-width: 180px;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
</style>
