<template>
  <div>
    <div class="table-filter-nav-item-db" v-if="type==='target'">
      <p><i class="iconfont iconshujuku"></i>{{ db.sourceDb }}<span v-if="db.sourceSchema && hasSchema.includes(taskInfo.sourceType)">.{{db.sourceSchema}}</span></p>
      <p class="chosed-count">{{ $t('yi-xuan-zhong') }}
        <span
          :class="getSelectedInfo(db.sourceDb).selectedCount<(selectedTables[db.sourceDb]?selectedTables[db.sourceDb].length:0)?'warn-count':''"
          style="text-align: center;display: inline-block;">{{ getSelectedInfo(db.sourceDb).selectedCount }}</span>
        {{ $t('selectedtablesdbsourcedb-selectedtablesdbsourcedblength-0-zhang-biao', [selectedTables[db.sourceDb] ? selectedTables[db.sourceDb].length : 0]) }}</p>
      <a v-if="DataSourceGroup.ddlFilter.includes(taskInfo.sourceType) && !JSON.parse(taskInfo.ddl) && ((this.taskInfo.type === 'MIGRATION'
        && this.taskInfo.mode.synchronize)||this.taskInfo.type === 'SYNC')" @click="handleShowFilterDDL" style="position: absolute; right: 10px;top: 40px">{{ $t('action-guo-lv') }}</a>
    </div>
    <div class="table-filter-nav-item-db" v-if="type==='source'">
      <p><i class="iconfont iconshujuku"></i>{{ db.sinkDb }}</p>
      <p class="chosed-count">{{ $t('yi-xuan-zhong') }}
        <span
          :class="getSelectedInfo(db.sinkDb).selectedCount<(selectedTables[db.sinkDb]?selectedTables[db.sinkDb].length:0)?'warn-count':''"
          style="text-align: center;display: inline-block;">{{ getSelectedInfo(db.sinkDb).selectedCount }}</span>{{ $t('selectedtablesdbsourcedb-selectedtablesdbsourcedblength-0-zhang-biao', [selectedTables[db.sinkDb] ? selectedTables[db.sinkDb].length : 0]) }}
      </p>
    </div>
    <Modal
      v-model="showDdlFilter"
      :title="$t('action-guo-lv')"
      :closable="false"
      footer-hide
    >
      <div>
        <p style="margin-bottom: 10px">{{ $t('gai-ku-xu-yao-tong-bu-yi-xia-action') }}</p>
        <CheckboxGroup  v-model="ddlAction">
          <Checkbox label="INSERT">
            INSERT
          </Checkbox>
          <Checkbox label="UPDATE">
            UPDATE
          </Checkbox>
          <Checkbox label="DELETE">
            DELETE
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="CREATE">
            CREATE
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="ALTER">
            ALTER
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="RENAME">
            RENAME
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="TRUNCATE">
            TRUNCATE
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType) && isMySQL(taskInfo.sourceType) && isMySQL(taskInfo.sinkType)" label="DROP">
            DROP
          </Checkbox>
        </CheckboxGroup>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmFilterDDL">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { isMySQL } from '@/utils';
import DataSourceGroup from '@/views/dataSourceGroup';

export default {
  props: {
    taskInfo: Object,
    db: Object,
    getSelectedInfo: Function,
    selectedTables: Object,
    type: {
      type: String,
      default: 'target'
    }
  },
  data() {
    return {
      DataSourceGroup,
      hasSchema: [
        'Oracle',
        'PostgreSQL',
        'Greenplum',
        'Db2'
      ],
      showDdlFilter: false,
      ddlAction: []
    };
  },
  methods: {
    isMySQL,
    handleShowFilterDDL() {
      this.showDdlFilter = true;
      this.ddlAction = this.db.actions;
    },
    handleCancel() {
      this.showDdlFilter = false;
    },
    handleConfirmFilterDDL() {
      this.showDdlFilter = false;
      this.handleDbActions(this.db, this.ddlAction);
    },
    handleDbActions(db, actions) {
      db.actions = actions;
      db.selectedTables.forEach((table) => {
        if (table.selected) {
          table.action = actions;
        }
      });
    }
  }
};
</script>
