<template>
  <div>
    <Table :row-class-name="rowClassName" stripe size="small" border :columns="virtualColumns" :key="index"
           :data="virtualList" height="120">
      <template slot="targetVirtualName" slot-scope="{row}">
        {{ row.isCustom ? row.customName : row.targetVirtualName }}
        <Icon type="ios-key" v-if="row.isPk" style="color: #ff9900;margin-left: 5px;"/>
      </template>
      <template slot="targetVirtualLength" slot-scope="{row}">
        {{
          row.columnLength ? row.columnLength : row.targetVirtualLength && row.targetVirtualLength.match(/\((.+)\)/g)
            && row.targetVirtualLength.match(/\((.+)\)/g)[0]
            && row.targetVirtualLength.match(/\((.+)\)/g)[0].substr(1, row.targetVirtualLength.match(/\((.+)\)/g)[0].length - 2)
        }}
      </template>
      <template slot="columnValue" slot-scope="{row}">
        {{ getColumnValue(row) }}
      </template>
      <template slot="targetName" slot-scope="{row}">
        {{ getTargetName(row.customName || row.targetVirtualName) }}
      </template>
      <template slot="action" slot-scope="{index}">
        <i style="color: #FF1815;cursor: pointer" class="iconfont iconDEL-R" v-if="!selectedTable.hasInJob"
           @click="handleDeleteVirtual(index,selectedTable)"></i>
      </template>
    </Table>
  </div>
</template>
<script>
import store from '../../../../../store/index';

export default {
  props: {
    selectedTable: Object,
    virtualList: Array,
    handleDeleteVirtual: Function,
    virtualColGenRule: Array,
    taskInfo: Object,
    showMappingRule: Boolean
  },
  data() {
    return {
      store,
      index: 0,
      selectedColumnMappingRule: '',
      virtualColumns: [
        {
          title: this.$t('xu-ni-lie-ming-cheng'),
          slot: 'targetVirtualName'
        },
        {
          title: this.$t('xu-ni-lie-lei-xing'),
          key: 'targetVirtualType'
        },
        {
          title: this.$t('xu-ni-lie-chang-du'),
          slot: 'targetVirtualLength'
        },
        {
          title: this.$t('lie-zhi'),
          slot: 'columnValue'
        },
        {
          title: this.$t('mu-biao-lie-ming-cheng'),
          slot: 'targetName'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action'
        }
      ]
    };
  },
  computed: {
    getColumnValue() {
      return (row) => {
        let columnValue = '';
        this.virtualColGenRule.forEach((rule) => {
          if (row.genRule === rule.virtualColGenRule) {
            if (row.genRule === 'SPECIFIED_VALUE') {
              columnValue = `${rule.i18nName}(${row.columnValue})`;
            } else {
              columnValue = rule.i18nName;
            }
          }
        });
        return columnValue;
      };
    },
    getTargetName() {
      return (target) => {
        switch (this.selectedColumnMappingRule) {
          case 'TO_LOWER_CASE':
            return target.toLowerCase();
          case 'TO_UPPER_CASE':
            return target.toUpperCase();
          case 'MIRROR':
            return target;
          default:
            return target;
        }
      };
    }
  },
  mounted() {
    this.selectedColumnMappingRule = this.taskInfo.selectedColumnMappingRule;
  },
  methods: {
    handleChangeRow(row, index, key, data) {
      this.virtualColumnData[index][key] = data;
    },
    handleChangeInputRow(row, index, key) {
      this.virtualColumnData[index][key] = row[key];
    },
    handleAddVirtualColumn() {
      this.virtualColumnData.push({
        targetVirtualName: '',
        targetVirtualType: '',
        columnValue: ''
      });
    },
    rowClassName(row) {
      if (row.isCustom) {
        return 'db-to-create';
      }
      return '';
    }
  },
  watch: {
    showMappingRule: {
      handler() {
        this.selectedColumnMappingRule = this.taskInfo.selectedColumnMappingRule;
      },
      deep: true
    }
  }
};
</script>
