export const ticketApi = {
  requestUserDsAuth: '/clouddm/console/api/v1/ticket/requestuserdsauth',
  listTickets: '/clouddm/console/api/v1/ticket/listbasic',
  createTicket: '/clouddm/console/api/v1/ticket/create',
  deleteTicket: '/clouddm/console/api/v1/ticket/delete',
  updateApproKey: '/clouddm/console/api/v1/ticket/appro/updatekey',
  listApproTemplates: '/clouddm/console/api/v1/ticket/appro/listtemplates',
  refreshApproTemplates: '/clouddm/console/api/v1/ticket/appro/refreshtemplates',
  queryTicketsDetail: '/clouddm/console/api/v1/ticket/querydetail',
  cancelTicket: '/clouddm/console/api/v1/ticket/cancel',
  confirmTicket: '/clouddm/console/api/v1/ticket/confirm',
  retryTicket: '/clouddm/console/api/v1/ticket/retry',
  closeTicket: '/clouddm/console/api/v1/ticket/close',
  checkPermissions: '/clouddm/console/api/v1/ticket/check_permissions',
  ticketType: '/clouddm/console/api/v1/ticket/appro/ticketype'
};
