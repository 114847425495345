export const STARROCKS_KEYWORDS = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'ACCESS',
    'ACTIVE',
    'ADD',
    'ADMIN',
    'AFTER',
    'AGGREGATE',
    'ALL',
    'ALTER',
    'ANALYZE',
    'AND',
    'ANTI',
    'APPLY',
    'ARRAY',
    'ARRAY_AGG',
    'AS',
    'ASC',
    'ASYNC',
    'AUTHORS',
    'AUTHENTICATION',
    'AUTO_INCREMENT',
    'AVG',
    'BACKEND',
    'BACKENDS',
    'BACKUP',
    'BASE',
    'BEGIN',
    'BETWEEN',
    'BIGINT',
    'BINARY',
    'BITMAP',
    'BITMAP_UNION',
    'BLACKLIST',
    'BODY',
    'BOOLEAN',
    'BOTH',
    'BROKER',
    'BUCKETS',
    'BUILTIN',
    'BY',
    'CANCEL',
    'CASE',
    'CAST',
    'CATALOG',
    'CATALOGS',
    'CEIL',
    'CHAIN',
    'CHAR',
    'CHARACTER',
    'CHARSET',
    'CHECK',
    'CLEAN',
    'CLEAR',
    'CLUSTER',
    'CLUSTERS',
    'COLLATE',
    'COLLATION',
    'COLUMN',
    'COLUMNS',
    'COMMENT',
    'COMMIT',
    'COMMITTED',
    'COMPACT',
    'COMPACTION',
    'COMPUTE',
    'CONFIG',
    'CONNECTION',
    'CONSISTENT',
    'CONVERT',
    'COSTS',
    'COUNT',
    'CREATE',
    'CROSS',
    'CUBE',
    'CUME_DIST',
    'CUMULATIVE',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_ROLE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'DATA',
    'DATACACHE',
    'DATABASE',
    'DATABASES',
    'DATE',
    'DATETIME',
    'DAY',
    'DEALLOCATE',
    'DECIMAL',
    'DECIMALV2',
    'DECIMAL32',
    'DECIMAL64',
    'DECIMAL128',
    'DECOMMISSION',
    'DEFAULT',
    'DELETE',
    'DENSE_RANK',
    'DEFERRED',
    'NTILE',
    'DESC',
    'DESCRIBE',
    'DISABLE',
    'DISTINCT',
    'DISTRIBUTED',
    'DISTRIBUTION',
    'DOUBLE',
    'DROP',
    'DUAL',
    'DUPLICATE',
    'DYNAMIC',
    'ELSE',
    'ENABLE',
    'ENCLOSE',
    'END',
    'ENGINE',
    'ENGINES',
    'ERRORS',
    'ESCAPE',
    'EVENTS',
    'EXCEPT',
    'EXECUTE',
    'EXISTS',
    'EXPLAIN',
    'EXPORT',
    'EXTERNAL',
    'EXTRACT',
    'EVERY',
    'FAILPOINT',
    'FAILPOINTS',
    'FALSE',
    'FIELDS',
    'FILE',
    'FILES',
    'FILTER',
    'FIRST',
    'FIRST_VALUE',
    'FLOAT',
    'FLOOR',
    'FN',
    'FOLLOWING',
    'FOLLOWER',
    'FOR',
    'FORCE',
    'FORMAT',
    'FREE',
    'FROM',
    'FRONTEND',
    'FRONTENDS',
    'FULL',
    'FUNCTION',
    'FUNCTIONS',
    'GLOBAL',
    'GRANT',
    'GRANTS',
    'GROUP',
    'GROUPS',
    'GROUPING',
    'GROUPING_ID',
    'GROUP_CONCAT',
    'HASH',
    'HAVING',
    'HELP',
    'HISTOGRAM',
    'HLL',
    'HLL_UNION',
    'HOST',
    'HOUR',
    'HUB',
    'IDENTIFIED',
    'IF',
    'IMPERSONATE',
    'IMMEDIATE',
    'IGNORE',
    'IMAGE',
    'IN',
    'INACTIVE',
    'INCREMENTAL',
    'INDEX',
    'INDEXES',
    'INFILE',
    'INNER',
    'INSTALL',
    'INSERT',
    'INT',
    'INTEGER',
    'INTEGRATION',
    'INTEGRATIONS',
    'INTERMEDIATE',
    'INTERSECT',
    'INTERVAL',
    'INTO',
    'GIN',
    'OVERWRITE',
    'IS',
    'ISOLATION',
    'JOB',
    'JOIN',
    'JSON',
    'KEY',
    'KEYS',
    'KILL',
    'LABEL',
    'LAG',
    'LARGEINT',
    'LAST',
    'LAST_VALUE',
    'LATERAL',
    'LEAD',
    'LEFT',
    'LESS',
    'LEVEL',
    'LIKE',
    'LIMIT',
    'LIST',
    'LOAD',
    'LOCAL',
    'LOCALTIME',
    'LOCALTIMESTAMP',
    'LOCATION',
    'LOCATIONS',
    'LOGS',
    'LOGICAL',
    'MANUAL',
    'MAP',
    'MAPPING',
    'MAPPINGS',
    'MASKING',
    'MATERIALIZED',
    'MAX',
    'MAXVALUE',
    'MERGE',
    'MICROSECOND',
    'MILLISECOND',
    'MIN',
    'MINUTE',
    'MINUS',
    'META',
    'MOD',
    'MODE',
    'MODIFY',
    'MONTH',
    'NAME',
    'NAMES',
    'NEGATIVE',
    'NO',
    'NODE',
    'NODES',
    'NONE',
    'NOT',
    'NULL',
    'NULLS',
    'NUMBER',
    'NUMERIC',
    'OBSERVER',
    'OF',
    'OFFSET',
    'ON',
    'ONLY',
    'OPEN',
    'OPERATE',
    'OPTIMIZE',
    'OPTIMIZER',
    'OPTION',
    'OR',
    'ORDER',
    'OUTER',
    'OUTFILE',
    'OVER',
    'PARAMETER',
    'PARTITION',
    'PARTITIONS',
    'PASSWORD',
    'PATH',
    'PAUSE',
    'PENDING',
    'PERCENT_RANK',
    'PERCENTILE',
    'PERCENTILE_UNION',
    'PLUGIN',
    'PLUGINS',
    'PIPE',
    'PIPES',
    'POLICY',
    'POLICIES',
    'PRECEDING',
    'PREPARE',
    'PRIMARY',
    'PRIORITY',
    'PRIVILEGES',
    'PROBABILITY',
    'PROC',
    'PROCEDURE',
    'PROCESSLIST',
    'PROFILE',
    'PROFILELIST',
    'PROPERTIES',
    'PROPERTY',
    'QUALIFY',
    'QUARTER',
    'QUERY',
    'QUERIES',
    'QUEUE',
    'QUOTA',
    'RANDOM',
    'RANGE',
    'RANK',
    'READ',
    'RECOVER',
    'REFRESH',
    'REWRITE',
    'REGEXP',
    'RELEASE',
    'REMOVE',
    'RENAME',
    'REPAIR',
    'REPEATABLE',
    'REPLACE',
    'REPLACE_IF_NOT_NULL',
    'REPLICA',
    'REPOSITORY',
    'REPOSITORIES',
    'RESOURCE',
    'RESOURCES',
    'RESTORE',
    'RESUME',
    'RETURNS',
    'RETRY',
    'REVOKE',
    'REVERT',
    'RIGHT',
    'RLIKE',
    'ROLE',
    'ROLES',
    'ROLLBACK',
    'ROLLUP',
    'ROUTINE',
    'ROW',
    'ROWS',
    'ROW_NUMBER',
    'RULE',
    'RULES',
    'RUNNING',
    'SAMPLE',
    'SCHEDULER',
    'SCHEMA',
    'SCHEMAS',
    'SECOND',
    'SECURITY',
    'SELECT',
    'SEMI',
    'SEPARATOR',
    'SERIALIZABLE',
    'SESSION',
    'SET',
    'SETS',
    'SET_VAR',
    'SIGNED',
    'SKIP_HEADER',
    'SHOW',
    'SMALLINT',
    'SNAPSHOT',
    'SQLBLACKLIST',
    'START',
    'STATS',
    'STATUS',
    'STOP',
    'STORAGE',
    'STREAM',
    'STRING',
    'TEXT',
    'SUBMIT',
    'SUM',
    'SUSPEND',
    'SYNC',
    'SYSTEM',
    'SYSTEM_TIME',
    'SWAP',
    'STRUCT',
    'TABLE',
    'TABLES',
    'TABLET',
    'TASK',
    'TEMPORARY',
    'TERMINATED',
    'THAN',
    'THEN',
    'TIME',
    'TIMES',
    'TIMESTAMP',
    'TIMESTAMPADD',
    'TIMESTAMPDIFF',
    'TINYINT',
    'TRANSACTION',
    'TO',
    'TRACE',
    'TRIGGERS',
    'TRIM_SPACE',
    'TRUE',
    'TRUNCATE',
    'TYPE',
    'TYPES',
    'UNBOUNDED',
    'UNCOMMITTED',
    'UNION',
    'UNIQUE',
    'UNINSTALL',
    'UNSET',
    'UNSIGNED',
    'UPDATE',
    'USAGE',
    'USE',
    'USER',
    'USERS',
    'USING',
    'VALUE',
    'VALUES',
    'VARBINARY',
    'VARCHAR',
    'VARIABLES',
    'VERBOSE',
    'VIEW',
    'VIEWS',
    'VOLUME',
    'VOLUMES',
    'WAREHOUSE',
    'WAREHOUSES',
    'WARNINGS',
    'WEEK',
    'WHEN',
    'WHERE',
    'WHITELIST',
    'WITH',
    'WORK',
    'WRITE',
    'YEAR',
    'LOCK',
    'UNLOCK',
    'LOW_PRIORITY',
    'EQ',
    'NEQ',
    'LT',
    'LTE',
    'GT',
    'GTE',
    'EQ_FOR_NULL',
    'PLUS_SYMBOL',
    'MINUS_SYMBOL',
    'ASTERISK_SYMBOL',
    'SLASH_SYMBOL',
    'PERCENT_SYMBOL',
    'LOGICAL_OR',
    'LOGICAL_AND',
    'LOGICAL_NOT',
    'INT_DIV',
    'BITAND',
    'BITOR',
    'BITXOR',
    'BITNOT',
    'BIT_SHIFT_LEFT',
    'BIT_SHIFT_RIGHT',
    'BIT_SHIFT_RIGHT_LOGICAL',
    'ARROW',
    'AT',
    'INTEGER_VALUE',
    'DECIMAL_VALUE',
    'DOUBLE_VALUE',
    'SINGLE_QUOTED_TEXT',
    'DOUBLE_QUOTED_TEXT',
    'BINARY_SINGLE_QUOTED_TEXT',
    'BINARY_DOUBLE_QUOTED_TEXT',
    'LETTER_IDENTIFIER',
    'DIGIT_IDENTIFIER',
    'BACKQUOTED_IDENTIFIER',
    'DOT_IDENTIFIER',
    'SIMPLE_COMMENT',
    'BRACKETED_COMMENT',
    'SEMICOLON',
    'DOTDOTDOT',
    'WS',
    'CONCAT',
];
