import Vue from 'vue';
// import ViewUI from 'view-design';
import eventBus from '@/utils/eventBus';
import VXETable from 'vxe-table';
import vSelect from 'vue-select';
import VueHotkey from 'v-hotkey';
import 'vue-select/dist/vue-select.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import PdButton from '@/components/ui/pdButton';
import Contextmenu from 'vue-contextmenujs';
import App from './App';
import router from './router';
import store from './store';
import '@/components';
import '@/directives';
import '@/services';
import '@/filters';

import '@/assets/iconfont/iconfont';
import './styles/global.less';

import './styles/reset.css';
// 旧的，逐渐替换
import './styles/iconfont.css';
// 新的
import './assets/iconfont/iconfont.css';
import 'view-design/dist/styles/iview.css';
import './styles/iconfont';
import './styles/app.less';
import './styles/cloudCanal.less';
import './iconfontJs';
import './styles/iconfontCss.css';
import './components/ctree/ctree.css';

import 'xe-utils';
import 'vxe-table/lib/style.css';

import './utils/vxeTableTemplates';
import i18n from './i18n';

Vue.use(VueHotkey);

Vue.use(VueHotkey);

Vue.use(Contextmenu);

Vue.use(VXETable);

Vue.config.productionTip = false;

Vue.use(eventBus);

// console.log('ViewUI', ViewUI);
//
// Vue.use(ViewUI, {
//   capture: false,
//   modal: {
//     maskClosable: false
//   },
//   // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
//   i18n: (key, value) => i18n.t(key, value)
// });

Vue.component('pd-button', PdButton);

Vue.component('v-select', vSelect);

window.vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
