<template>
  <Modal v-model="showPosModal" :title="pos.label" footer-hide :closable="false" :width="width">
    <div style="padding: 20px 0">
      <Alert v-if="pos.formTips" type="warning" style="margin-bottom: 20px">{{pos.formTips}}</Alert>
      <slot></slot>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
        <pd-button type="primary" @click="handleConfirmPos">{{ $t('que-ding') }}</pd-button>
        <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
      </div>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 600
    },
    pos: Object,
    handleCancel: Function,
    handleConfirmPos: Function,
    showPosModal: Boolean
  }
};
</script>
