import dayjs from 'dayjs';
import moment from 'moment';
import DataSourceGroup from '../views/dataSourceGroup';

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const randomString = (length) => {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const arrayToObj = (arr, name) => {
  const temp = {};

  if (!arr.length) {
    return temp;
  }

  for (const role of arr) {
    if (!role[name]) {
      return {};
    }
    temp[role[name]] = role;
  }
  return temp;
};

export const formatTime = (value, fmt = 'YYYY/MM/DD') => dayjs(value)
  .format(fmt);

export const generateData = (list) => list;

export const dsGroup = {
  hasSchema: ['PostgreSQL', 'Greenplum', 'SQLServer'],
  noStruct: ['Redis'],
  supportTransaction: ['MySQL', 'PostgreSQL', 'Greenplum']
};

export const hasSchema = (type) => dsGroup.hasSchema.includes(type);

export const noStruct = (type) => dsGroup.noStruct.includes(type);

export const supportTransaction = (type) => dsGroup.supportTransaction.includes(type);

export const dateRange = {
  一天: [moment(), moment().add(1, 'd')],
  一周: [moment(), moment().add(1, 'w')],
  一月: [moment(), moment().add(1, 'M')]
};

export const isDb2 = (type) => DataSourceGroup.db2.includes(type);
export const isOb = (type) => DataSourceGroup.ob.includes(type);
export const isHudi = (type) => DataSourceGroup.hudi.includes(type);
export const isTunnel = (type) => DataSourceGroup.tunnel.includes(type);
export const isMQ = (type) => DataSourceGroup.mq.includes(type);
export const isPG = (type) => DataSourceGroup.pg.includes(type);

export const isGP = (type) => DataSourceGroup.gp.includes(type);
export const isPolar = (type) => DataSourceGroup.polar.includes(type);
export const isPolarDbX = (type) => DataSourceGroup.polarDbX.includes(type);
export const isMySQL = (type) => DataSourceGroup.mysql.includes(type);
export const isMariaDB = (type) => DataSourceGroup.mariaDb.includes(type);
export const isOracle = (type) => DataSourceGroup.oracle.includes(type);
export const isHana = (type) => DataSourceGroup.hana.includes(type);
export const isHasSchema = (type) => DataSourceGroup.hasSchema.includes(type);
export const isMongoDB = (type) => DataSourceGroup.mongo.includes(type);
export const isStarRocks = (type) => DataSourceGroup.starrocks.includes(type);
export const isTiDB = (type) => DataSourceGroup.tidb.includes(type);
export const isSQLServer = (type) => DataSourceGroup.sqlServer.includes(type);
export const isES = (type) => DataSourceGroup.es.includes(type);
export const isKudu = (type) => DataSourceGroup.kudu.includes(type);
export const isRedis = (type) => DataSourceGroup.redis.includes(type);
export const isCk = (type) => DataSourceGroup.ck.includes(type);
export const isKafka = (type) => DataSourceGroup.kafka.includes(type);

export const isRocketMQ = (type) => DataSourceGroup.rocketMq.includes(type);
export const separatePort = (type) => !['Kudu', 'Redis', 'ClickHouse', 'ElasticSearch', 'RabbitMQ', 'RocketMQ', 'Kafka', 'MongoDB', 'PolarDbX', 'StarRocks', 'Doris', 'SelectDB'].includes(type);

export const showActiveLicense = (status) => [550, 555, 556, 557, 558, 559, 560, 561, 562, 563, 564, 565, 566].includes(status);

export const isSchemaMapping = (type) => DataSourceGroup.schemaMapping.includes(type);

export const DATA_SOURCE = {
  MYSQL: 'MySQL',
  TIDB: 'TiDB',
  STARROCKS: 'StarRocks',
  DORIS: 'Doris',
  POLARDBX: 'PolarDBX',
  OCEANBASE: 'OceanBase',
  POSTGRESQL: 'PostgreSQL',
  GREENPLUM: 'Greenplum',
  ORACLE: 'Oracle',
  DB2: 'Db2',
  DAMENG: 'Dameng',
  SQLSERVER: 'SQLServer',
  CLICKHOUSE: 'ClickHouse',
  REDIS: 'Redis'
};

export const TAB_TYPE = {
  QUERY: 'query',
  STRUCT: 'struct',
  DATA: 'data'
};

export const getNodeType = (dsType, deep) => (deep > -1 ? getDataSourceLevel(dsType)[deep] : 'env');

export function getDataSourceLevel(type) {
  switch (type) {
    case DATA_SOURCE.CLICKHOUSE:
    case DATA_SOURCE.MYSQL:
    case DATA_SOURCE.TIDB:
    case DATA_SOURCE.STARROCKS:
    case DATA_SOURCE.DORIS:
    case DATA_SOURCE.POLARDBX:
    case DATA_SOURCE.OCEANBASE:
    case DATA_SOURCE.DAMENG:
      return ['instance', 'schema'];
    case DATA_SOURCE.GREENPLUM:
    case DATA_SOURCE.POSTGRESQL:
    case DATA_SOURCE.SQLSERVER:
    case DATA_SOURCE.ORACLE:
    case DATA_SOURCE.DB2:
      return ['instance', 'catalog', 'schema'];
    case DATA_SOURCE.REDIS:
      return ['instance'];
    default:
      break;
  }
}

export const KEY_SETTING = {
  label: '键', value: 'key', showListKey: 'showKeyList', listKey: 'keyList', filteredListKey: 'filteredKeyList'
};

export const TABLE_SETTING = {
  label: '表', value: 'table', showListKey: 'showTableList', listKey: 'tableList', filteredListKey: 'filteredTableList'
};

export const VIEW_SETTING = {
  label: '视图', value: 'view', showListKey: 'showViewList', listKey: 'viewList', filteredListKey: 'filteredViewList'
};

export const NODE_TYPE = {
  ENV: 'ENV',
  INSTANCE: 'INSTANCE',
  CATALOG: 'CATALOG',
  SCHEMA: 'SCHEMA'
};

export const MENU_OPTION_ITEM = {
  DELIMITED: 'delimited',
  USING_EXISTS: 'usingExists',
  CASCADE: 'cascade',
  RESTRICT: 'restrict',
  PURGE: 'purge',
  TRUNCATE_USE_DELETE: 'truncateUseDelete'
};

export const MENU_OPTION_ITEM_I18N = {
  delimited: '使用 限定符 包裹数据库对象名',
  usingExists: '使用  if exists 子句',
  cascade: '使用 cascade 子句进行强制删除',
  restrict: '使用 restrict 子句在限制条件下删除',
  purge: '使用 purge 子句进行资源回收',
  truncateUseDelete: '使用 delete 语句替代 truncate 语句'
};

const {
  DELIMITED, USING_EXISTS, CASCADE, RESTRICT, PURGE, TRUNCATE_USE_DELETE
} = MENU_OPTION_ITEM;
export const MENU_OPTIONS = {
  MENU_BROWSE_CATALOG_CREATE: [DELIMITED],
  MENU_BROWSE_CATALOG_DROP: [DELIMITED, USING_EXISTS],
  MENU_BROWSE_CATALOG_RENAME: [DELIMITED],
  MENU_BROWSE_SCHEMA_CREATE: [DELIMITED, USING_EXISTS],
  MENU_BROWSE_SCHEMA_DROP: [DELIMITED, USING_EXISTS, CASCADE, RESTRICT],
  MENU_BROWSE_SCHEMA_RENAME: [DELIMITED],
  MENU_BROWSE_TABLE_DROP: [DELIMITED, USING_EXISTS, CASCADE, RESTRICT, PURGE],
  MENU_BROWSE_TABLE_RENAME: [DELIMITED],
  MENU_BROWSE_TABLE_TRUNCATE: [DELIMITED, USING_EXISTS, CASCADE, TRUNCATE_USE_DELETE],
  MENU_BROWSE_VIEW_DROP: [DELIMITED, USING_EXISTS, CASCADE, RESTRICT],
  MENU_BROWSE_VIEW_RENAME: [DELIMITED],
  MENU_BROWSE_TABLE_REQUEST: [DELIMITED, USING_EXISTS],
  MENU_BROWSE_TABLE_GENERATE: [DELIMITED, USING_EXISTS],
  MENU_BROWSE_VIEW_REQUEST: [DELIMITED, USING_EXISTS]
};

export const DATASOURCE_ICON = [
  'MySQL', 'TiDB', 'StarRocks', 'Doris', 'PolarDBX', 'OceanBase', 'PostgreSQL', 'Greenplum', 'Oracle', 'Db2',
  'Dameng', 'SQLServer', 'ClickHouse', 'Redis'
];

export const DS_RIGHT_CLICK_MENU_ITEM = {
  MENU_BROWSE_CONSOLE: 'MENU_BROWSE_CONSOLE',
  MENU_BROWSE_COPY_NAME: 'MENU_BROWSE_COPY_NAME',
  MENU_BROWSE_COPY_JDBC: 'MENU_BROWSE_COPY_JDBC',
  // ***** 复制 //
  MENU_BROWSE_REFRESH: 'MENU_BROWSE_REFRESH',
  MENU_BROWSE_INSTANCE_REFRESH: 'MENU_BROWSE_INSTANCE_REFRESH',
  MENU_BROWSE_CATALOG_REFRESH: 'MENU_BROWSE_CATALOG_REFRESH',
  MENU_BROWSE_SCHEMA_REFRESH: 'MENU_BROWSE_SCHEMA_REFRESH',
  // ***** //
  MENU_BROWSE_INSTANCE_CREATE: 'MENU_BROWSE_INSTANCE_CREATE',
  MENU_BROWSE_INSTANCE_RENAME: 'MENU_BROWSE_INSTANCE_RENAME',
  MENU_BROWSE_INSTANCE_DROP: 'MENU_BROWSE_INSTANCE_DROP',
  // ***** //
  MENU_BROWSE_CATALOG_CREATE: 'MENU_BROWSE_CATALOG_CREATE',
  MENU_BROWSE_CATALOG_RENAME: 'MENU_BROWSE_CATALOG_RENAME',
  MENU_BROWSE_CATALOG_DROP: 'MENU_BROWSE_CATALOG_DROP',
  // ***** //
  MENU_BROWSE_SCHEMA_CREATE: 'MENU_BROWSE_SCHEMA_CREATE',
  MENU_BROWSE_SCHEMA_DROP: 'MENU_BROWSE_SCHEMA_DROP',
  MENU_BROWSE_SCHEMA_RENAME: 'MENU_BROWSE_SCHEMA_RENAME',
  // ***** //
  MENU_BROWSE_TABLE_CREATE: 'MENU_BROWSE_TABLE_CREATE'
};

export const TABLE_RIGHT_CLICK_MENU_ITEM = {
  MENU_BROWSE_COPY_NAME: 'MENU_BROWSE_COPY_NAME',
  // ***** //
  MENU_BROWSE_KEY_REFRESH: 'MENU_BROWSE_KEY_REFRESH',
  MENU_BROWSE_VIEW_REFRESH: 'MENU_BROWSE_VIEW_REFRESH',
  MENU_BROWSE_TABLE_REFRESH: 'MENU_BROWSE_TABLE_REFRESH',
  MENU_BROWSE_COLUMN_REFRESH: 'MENU_BROWSE_COLUMN_REFRESH',
  MENU_BROWSE_INDEX_REFRESH: 'MENU_BROWSE_INDEX_REFRESH',
  MENU_BROWSE_PARTITION_REFRESH: 'MENU_BROWSE_PARTITION_REFRESH',
  MENU_BROWSE_PRIMARY_REFRESH: 'MENU_BROWSE_PRIMARY_REFRESH',
  // ***** //
  MENU_BROWSE_TABLE_CREATE: 'MENU_BROWSE_TABLE_CREATE',
  MENU_BROWSE_VIEW_RENAME: 'MENU_BROWSE_VIEW_RENAME',
  MENU_BROWSE_TABLE_RENAME: 'MENU_BROWSE_TABLE_RENAME',
  MENU_BROWSE_TABLE_ALTER: 'MENU_BROWSE_TABLE_ALTER',
  MENU_BROWSE_VIEW_DATA: 'MENU_BROWSE_VIEW_DATA',
  MENU_BROWSE_TABLE_DATA: 'MENU_BROWSE_TABLE_DATA',
  MENU_BROWSE_TABLE_TRUNCATE: 'MENU_BROWSE_TABLE_TRUNCATE',
  MENU_BROWSE_VIEW_DROP: 'MENU_BROWSE_VIEW_DROP',
  MENU_BROWSE_TABLE_DROP: 'MENU_BROWSE_TABLE_DROP',
  MENU_BROWSE_TABLE_IMPORT: 'MENU_BROWSE_TABLE_IMPORT',
  MENU_BROWSE_TABLE_EXPORT: 'MENU_BROWSE_TABLE_EXPORT',
  MENU_BROWSE_VIEW_CREATE: 'MENU_BROWSE_VIEW_CREATE',
  MENU_BROWSE_VIEW_REQUEST: 'MENU_BROWSE_VIEW_REQUEST',
  MENU_BROWSE_TABLE_REQUEST: 'MENU_BROWSE_TABLE_REQUEST',
  MENU_BROWSE_TABLE_GENERATE: 'MENU_BROWSE_TABLE_GENERATE',
  MENU_BROWSE_TABLE_GET_DDL: 'MENU_BROWSE_TABLE_GET_DDL',
  MENU_BROWSE_TRIGGER_CREATE: 'MENU_BROWSE_TRIGGER_CREATE',
  MENU_BROWSE_TRIGGER_DROP: 'MENU_BROWSE_TRIGGER_DROP',
  MENU_BROWSE_TRIGGER_ALTER: 'MENU_BROWSE_TRIGGER_ALTER',
  MENU_BROWSE_TABLE_FAKER: 'MENU_BROWSE_TABLE_FAKER',
  MENU_BROWSE_TABLE_FAKER_INCREMENT: 'MENU_BROWSE_TABLE_FAKER_INCREMENT'
};

export const LEAF_API_TYPE = {
  GENERATE: 'GENERATE',
  LIST: 'LIST'
};
