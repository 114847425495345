<template>
  <div>
    <p><span class="job-header-name-desc" style="margin-right: 10px">HOST</span><span
      class="basic-info-value">{{ dataSourceDetail.host || dataSourceDetail.privateHost || dataSourceDetail.publicHost }}</span></p>
    <Row style="margin-top: 8px" :gutter="80">
      <Col :span="12">
        <p>
          <span>{{ $t('bu-shu-qing-kuang') }}</span>
          <span
            class="basic-info-value">{{
              DATASOURCE_DEPLOY_TYPE_I18N[dataSourceDetail.deployType]
            }}</span>
        </p>
        <p>
          <span>{{ $t('suo-you-zhe') }}</span>
          <span class="basic-info-value">{{ dataSourceDetail.owner }}</span>
        </p>
        <p>
          <span>{{ $t('shi-li-id-0') }}</span>
          <span class="basic-info-value">{{ dataSourceDetail.instanceId }}</span>
        </p>
        <p>
          <span>{{ $t('qu-yu') }}</span>
          <span class="basic-info-value">{{ MappingUtil.region[dataSourceDetail.region] }}</span>
        </p>
        <p>
          <span>{{ $t('ren-zheng-fang-shi-0') }}</span>
          <span class="basic-info-value">{{ MappingUtil.securityType[dataSourceDetail.securityType] }}</span>
        </p>
      </Col>
      <Col :span="12">
        <p>
          <span>{{ $t('miao-shu-0') }}</span>
          <span class="basic-info-value">{{ dataSourceDetail.instanceDesc }}</span>
        </p>
        <p>
          <span>{{ $t('ban-ben-hao-0') }}</span>
          <span class="basic-info-value">{{ dataSourceDetail.version }}</span>
        </p>
        <p>
          <span>{{ $t('shu-ju-yuan-shu-zi-id-0') }}</span>
          <span class="basic-info-value">{{ dataSourceDetail.id }}</span>
        </p>
        <p>
          <span>{{ $t('lei-xing-0') }}</span>
          <span class="basic-info-value"><DataSourceIcon :type="dataSourceDetail.dataSourceType"
                                                         :instanceType="dataSourceDetail.deployType"></DataSourceIcon></span>
        </p>
        <p>
          <span>{{ $t('tian-jia-shi-jian') }}</span>
          <span
            class="basic-info-value">{{
              dataSourceDetail.gmtCreate ? fecha.format(new Date(dataSourceDetail.gmtCreate), 'YYYY-MM-DD HH:mm:ss') : ''
            }}</span>
        </p>
      </Col>
    </Row>
    <div class="datasource-info-block"
         v-if="dataSourceDetail.extraVO&&dataSourceDetail.dataSourceType==='Hive'">
      <p style="margin-top: 8px">
        <span>{{ $t('hdfs-host-0') }}</span>
        <span
          class="basic-info-value">{{
            dataSourceDetail.extraVO.hdfsIp + ':' + dataSourceDetail.extraVO.hdfsPort
          }}</span>
      </p>
      <p>
        <span>{{ $t('shu-cang-lu-jing') }}</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hdfsDwDir }}</span>
      </p>
      <p>
        <span>{{ $t('hdfs-principal-0') }}</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hdfsPrincipal }}</span>
      </p>
      <p>
        <span>{{ $t('hadoop-user') }}</span>
        <span class="basic-info-value">{{ dataSourceDetail.extraVO.hadoopUser }}</span>
      </p>
    </div>
    <div class="datasource-info-block">
      <!--            <Divider class="datasource-info-block-title" orientation="left">账号信息</Divider>-->
      <p style="margin-top: 8px">
        <span>{{ $t('zhang-hao-0') }}</span>
        <span
          class="basic-info-value">{{
            dataSourceDetail.accountName ? dataSourceDetail.accountName : $t('zan-wu')
          }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@/views/util';
import { DATASOURCE_DEPLOY_TYPE_I18N } from '@/const';
import DataSourceIcon from '../DataSourceIcon';

export default {
  computed: {
    DATASOURCE_DEPLOY_TYPE_I18N() {
      return DATASOURCE_DEPLOY_TYPE_I18N;
    }
  },
  components: { DataSourceIcon },
  props: {
    dataSourceDetail: Object
  },
  data() {
    return {
      fecha,
      MappingUtil,
      desc: ''
    };
  }
};
</script>
