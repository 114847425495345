import { instance } from '../rdpInstance';

export const queryDsDefaultKVConfig = (param) => instance.post('datasource/dsKvConfigDef', param);
export const listbycondition = (param) => instance.post('datasource/listbycondition', param);
export const deleteDataSource = (param) => instance.post('datasource/delete', param);
export const updateDataSourceDesc = (param) => instance.post('datasource/updatedatasourcedesc', param);
export const updateAliyunRdsAkSk = (param) => instance.post('datasource/updatealiyunrdsaksk', param);
export const deleteAccount = (param) => instance.post('datasource/deleteaccount', param);
export const updatePublicHost = (param) => instance.post('datasource/updatepublichost', param);
export const updateDataSourceHost = (param) => instance.post('datasource/updateprivatehost', param);
export const queryById = (param) => instance.post('datasource/queryds', param);
export const queryDsConfig = (param) => instance.post('datasource/querydsconfig', param);
export const upsertDsConfig = (param) => instance.post('datasource/upsertdsconfig', param);
export const listDataSource = (param) => instance.post('aliyun/datasource/listdatasource', param);

export const queryInstancesNetInfo = (param) => instance.post('aliyun/datasource/querynetinfo', param);
