import { mapState } from 'vuex';

const datasourceMixin = {
  data() {
    return {
      testConnectMsg: '',
      testConnectMsgDetail: ''
    };
  },
  computed: {
    ...mapState(['globalDsSetting'])
  },
  methods: {
    async testConnection(data) {
      const res = await this.$services.connectDs({ data });
      this.testConnectMsg = res.success ? this.$t('lian-jie-cheng-gong') : this.$t('lian-jie-shi-bai-qing-jian-cha-shu-ju-yuan-deng-ru-xin-xi');
      if (res.fail) {
        this.testConnectMsgDetail = res.msg;
      }
    },
    showDropdownItem(dataSourceType, key) {
      return this.globalDsSetting[dataSourceType] && this.globalDsSetting[dataSourceType].features && this.globalDsSetting[dataSourceType].features[key];
    }
  }
};

export default datasourceMixin;
