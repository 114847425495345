<template>
  <Modal
    :title="$t('zhong-qi-li-shi-ji-lu')"
    :width="1200"
    v-model="visible"
  >
    <Table :columns="columns" :data="showRestartList"/>
    <Page @on-change="handlePageChange" :total="restartList.length" show-total :current="page"
          :page-size="pageSize" style="margin-top: 10px;text-align: right"/>
    <div slot="footer" class="modal-footer" style="margin-top: 20px">
      <pd-button @click="handleCloseModal">{{ $t('guan-bi') }}</pd-button>
    </div>
  </Modal>
</template>

<script>
import dayjs from 'dayjs';
import { formatSeconds } from '@/components/util';

export default {
  name: 'RestartListModal',
  props: {
    visible: Boolean,
    handleCloseModal: Function,
    restartList: Array
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      showRestartList: [],
      dataTaskType: {
        FULL: this.$t('quan-liang-qian-yi'),
        INCREMENT: this.$t('zeng-liang-tong-bu'),
        CHECK: this.$t('shu-ju-xiao-yan'),
        REVISE: this.$t('shu-ju-ding-zheng')
      },
      columns: [
        {
          title: this.$t('ren-wu-lei-xing'),
          render: (h, params) => h('div', this.dataTaskType[params.row.dataTaskType])
        },
        {
          title: this.$t('ji-qi-ip'),
          key: 'scheduleWorkerIp'
        },
        {
          title: this.$t('shou-ci-bang-ding-shi-jian'),
          render: (h, params) => h('div', dayjs(params.row.firstBindTime).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('shang-ci-qi-dong-shi-jian'),
          render: (h, params) => h('div', params.row.lastBindTime ? dayjs(params.row.lastBindTime).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss') : '-')
        },
        {
          title: this.$t('ben-ci-qi-dong-shi-jian'),
          render: (h, params) => h('div', dayjs(params.row.currentBindTime).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('xiao-shi-zhong-qi-lei-ji-ci-shu'),
          key: 'hourStartCount'
        },
        {
          title: this.$t('tian-lei-ji-zhong-qi-ci-shu-24-xiao-shi'),
          key: 'dayStartCount'
        }
      ]
    };
  },
  mounted() {
    // console.log('restart');
    this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
  },
  methods: {
    handlePageChange(page) {
      // console.log(page);
      this.page = page;
      this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    }
  },
  watch: {
    restartList() {
      // console.log('restartList');
      this.showRestartList = this.restartList.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
    }
  }
};
</script>

<style scoped>

</style>
