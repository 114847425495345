export const commonApi = {
  getDeployEnvList: '/clouddm/console/api/v1/constant/listdeployenv',
  getRegionList: '/clouddm/console/api/v1/constant/listregionbydeploy',
  getDsTypeList: '/clouddm/console/api/v1/constant/listdstypes',
  getDsTypeLisrWithoutDeployEnv: '/clouddm/console/api/v1/constant/listdstypeswithoutenv',
  getDesensitizeRuleTypeList: '/clouddm/console/api/v1/constant/listdesensitizeruletypes',
  getDsDesensitizePathElements: '/clouddm/console/api/v1/constant/listdsdesensitizepathelements',
  listPageElementsLevel: '/clouddm/console/api/v1/constant/listPageElementsLevel',
  listDsSecurityOption: '/clouddm/console/api/v1/constant/listdssecurityoption',
  listExportSqExecType: '/clouddm/console/api/v1/constant/listexportsqexectype'
};
