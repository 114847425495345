<template>
  <a-modal :visible="visible" :title="$t('zhi-hang-xiang-qing')" :width="800" @cancel="handleCloseModal">
    <div class="execute-detail">
      <div class="header">
        <div class="left">
          <a-button @click="pauseTask" style="margin-right: 10px;" :disabled="disablePauseBtn">{{ $t('zan-ting') }}
          </a-button>
          <a-button @click="resumeTask" :disabled="disableResumeBtn">{{ $t('hui-fu') }}</a-button>
        </div>
        <div class="right">
          <a-button @click="taskLog">{{ $t('shua-xin') }}</a-button>
        </div>
      </div>
      <div class="body">
        <div class="section">
          <div class="title">{{ $t('ming-ling') }} <span @click="copyText(log.cmd)" v-if="log.cmd"
                                      class="copy">{{ $t('fu-zhi') }}</span></div>
          <div class="content">{{ log.cmd || $t('wu-0') }}</div>
        </div>
        <div class="section">
          <div class="title">{{ $t('ri-zhi-di-zhi') }} <span @click="copyText(log.logPath)" v-if="log.logPath"
                                        class="copy">{{ $t('fu-zhi') }}</span></div>
          <div class="content">{{ log.logPath || '无' }}</div>
        </div>
        <div class="section">
          <div class="title">{{ $t('ri-zhi-nei-rong') }} <span @click="copyText(log.logContent)" v-if="log.logContent"
                                        class="copy">{{ $t('fu-zhi') }}</span></div>
          <pre class="content log">
            {{ log.logContent || $t('wu-0') }}
          </pre>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button @click="handleCloseModal">{{ $t('guan-bi') }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { EXPORT_STATUS } from '@/const';
import copyMixin from '@/mixins/copyMixin';

export default {
  name: 'ExecuteDetailModal',
  props: {
    visible: Boolean,
    jobId: [Number, String],
    selectedTaskId: [Number, String],
    handleCloseModal: Function
  },
  mixins: [copyMixin],
  computed: {
    disablePauseBtn() {
      return this.selectedTask.status !== EXPORT_STATUS.RUNNING;
    },
    disableResumeBtn() {
      return this.selectedTask.status !== EXPORT_STATUS.STOP;
    }
  },
  data() {
    return {
      log: {},
      selectedTask: {}
    };
  },
  async mounted() {
    await this.taskLog();
  },
  methods: {
    async getTaskDetail() {
      const res = await this.$services.queryExportTask({
        data: {
          exportJobId: this.jobId,
          exportTaskId: this.selectedTaskId
        }
      });

      if (res.success) {
        this.selectedTask = res.data;
      }
    },
    async pauseTask() {
      const res = await this.$services.pauseExportTask({
        data: {
          exportJobId: this.jobId,
          exportTaskId: this.selectedTaskId
        }
      });

      if (res.success) {
        this.$Message.success(this.$t('zan-ting-cheng-gong'));
        await this.getTaskDetail();
      } else {
        this.$Message.success(this.$t('zan-ting-shi-bai'));
      }
    },
    async resumeTask() {
      const res = await this.$services.resumeExportTask({
        data: {
          exportJobId: this.jobId,
          exportTaskId: this.selectedTaskId
        }
      });

      if (res.success) {
        this.$Message.success(this.$t('zan-ting-cheng-gong'));
        await this.getTaskDetail();
      } else {
        this.$Message.success(this.$t('zan-ting-shi-bai'));
      }
    },
    async taskLog() {
      await this.getTaskDetail();
      const res = await this.$services.tailExportTaskLog({
        data: {
          exportJobId: this.jobId,
          exportTaskId: this.selectedTaskId
        }
      });

      if (res.success) {
        this.log = res.data;
      }
    }
  }
};
</script>

<style scoped lang="less">
.execute-detail {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .body {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;

    .section {
      .title {
        font-weight: bold;
        margin-top: 5px;

        .copy {
          cursor: pointer;
          color: #0BB9F8;
        }
      }

      .content {
        border: 1px solid #ccc;
        margin-top: 5px;
        padding: 5px;
        background: #eee;

        &.log {
          max-height: 300px;
          min-height: 300px;
          overflow: scroll;
        }
      }
    }
  }
}
</style>
