<template>
  <div style="padding: 16px;">
    <Breadcrumb>
      <BreadcrumbItem to="/monitor/consoleList">{{ $t('kong-zhi-tai-jian-kong-da-pan') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('kong-zhi-tai-jian-kong') }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="console-info-container">
      <icon class="iconfont iconmachine"></icon><span style="margin-left: 10px;font-weight: 500;font-family: PingFangSC-Semibold,serif">{{consoleInfo.consoleIp}}</span>
      <span style="margin-left: 20px;color: #555555">{{ $t('ban-ben') }}<span style="color: #333333">{{consoleInfo.version}}</span></span>
      <div style="position: absolute;right: 20px;top: 16px">
        <!--                    <pd-button @click="handleInterval">{{hasInterval?"取消10s定时刷新":"10s定时刷新"}}</pd-button>-->
        <Timepicker style="display: inline-block;margin-right: 10px" ref="timepicker" :time="time"></Timepicker>
        <pd-button :loading="refreshLoading" @click="handleRefresh">
          <Icon type="md-refresh"/>
          {{ $t('shua-xin') }}
        </pd-button>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="clearfix" style="margin-bottom:10px;position: relative" >
        <div>
          <div ref="grid"></div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import '../../components/function/monitor/core/core';
import _ from 'lodash';
import $ from 'jquery';
import { render } from 'react-dom';
import React from 'react';
import { EventEmitter } from 'eventemitter3';
import { Config, PanelConfig } from '@/components/function/monitor/config';
import { queryConsoleMonitorConfig } from '@/services/cc/api/monitor';
import Timepicker from '@/components/function/monitor/metric/timepicker';
import Time from '@/components/function/monitor/utils/time';
// eslint-disable-next-line import/no-cycle
import Grid from '@/components/function/monitor/metric/grid';
import '@/components/function/monitor/monitor.less';
import { queryConsoleById } from '@/services/cc/api/console';
import Mapping from '../util';

export default {
  name: 'ConsoleMonitorGraph',
  components: {
    Timepicker
  },
  created() {
    const that = this;

    setTimeout(() => {
      that.init();
    }, 500);

    queryConsoleMonitorConfig().then((res) => {
      this.metricConfig = res.data.data[0];
    });
  },
  mounted() {
    if (this.$route.query.ip) {
      this.filters.instance = this.$route.query.ip;
    }
    if (this.$route.query.id) {
      this.consoleId = this.$route.query.id;
      queryConsoleById({ consoleId: this.consoleId }).then((res) => {
        if (res.data.code === '1') {
          this.consoleInfo = res.data.data;
        }
      });
    }
    this.emitter = new EventEmitter();
    this.emitter.on('layout', (layout) => {
      this.panel.options = this.panel.options || {};
      this.panel.options.layout = layout;

      const model = _.cloneDeep(this.panel);

      model.options = JSON.stringify(model.options);

      // this.$axios.put('metric/panel/' + model.id, model).then(response => {
      // })
    });
    this.emitter.on('refresh', () => {
      this.updateConfigs();
    });
    this.$refs.timepicker.$on('refresh', () => {
      this.handleRender();
    });

    this.time.events.on('changed', () => {
      this.$refs.timepicker.$emit('to_refresh');
    });
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
  },
  data() {
    return {
      Mapping,
      metricConfig: '',
      consoleId: 0,
      consoleInfo: '',
      resourceType: 'CONSOLE',
      refreshLoading: false,
      hasInterval: false,
      myInterval: '',
      inited: false,
      time: new Time(),
      metrics: [],
      metric: null,
      configs: [],
      servers: [],
      dcs: {},
      filters: {
        instance: 'all',
        role: 'master',
        job: 'all'
      },
      configFile: Config.mysql,
      panelType: 'basic',
      panel: {},
      filterInstances: [],
      filterOthers: {
        database: '',
        filterInstances: [{
          ip: '127.0.0.1'
        }],
        panelType: 'basic',
        dbOnInstance: {}
      }
    };
  },
  methods: {
    init() {
      this.inited = false;

      const model = PanelConfig.mysql.panel;

      if (model.options) {
        try {
          model.options = JSON.parse(model.options);
        } catch (e) {
          model.options = _.cloneDeep(model);
        }
      }

      model.options = model.options || {};
      model.options.layout = model.options.layout || [];

      this.panel = model;

      this.updateConfigs();
      // })
    },
    updateConfigs() {
      const configs = this.metricConfig.config;

      _.forEach(configs, (c) => {
        try {
          c.options = JSON.parse(c.options);
          // eslint-disable-next-line no-empty
        } catch (e) {
        }

        if (this.filters.instance && !this.filters.jobId) {
          c.options.queries.map((query) => {
            if (query.metricNamePattern.indexOf('sum(') > -1) {
              let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
              const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

              a = a.replace('\'', '');
              query.metricNamePattern = `${a}'{'console_id="{0}"'}') by${b}`;
            } else if (query.metricNamePattern.indexOf('increase') > -1) {
              let a = query.metricNamePattern.split('[')[0].split('{')[0];

              a = a.replace('\'', '');
              query.metricNamePattern = `${a}'{'console_id="{0}"'}'[${query.metricNamePattern.split('[')[1]}`;
            } else {
              let a = query.metricNamePattern.split('{')[0];

              a = a.replace('\'', '');
              query.metricNamePattern = a;
              query.metricNamePattern += '\'{\'console_id="{0}"\'}\'';
            }
            return null;
          });
        }
      });
      render(
        React.createElement(Grid, {
          emitter: this.emitter,
          time: this.time,
          panels: configs,
          width: $(this.$refs.grid).width(),
          panel: this.panel,
          filters: this.filters,
          filterOthers: this.filterOthers,
          resourceType: this.resourceType,
          consoleId: this.consoleId
        }),
        this.$refs.grid
      );

      this.configs = configs;
      this.inited = true;
      // })
    },
    handleRender() {
      if (this.emitter) {
        this.emitter.emit('render');
      }
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRender();
        }, 10000);
      }
    },
    handleRefresh() {
      this.handleRender();
    }
  }
};
</script>
<style lang="less" scoped>
.console-info-container{
  background-color: #DEEFFF;
  padding: 22px 20px;
  border: 1px solid #DADADA;
  margin-top: 16px;
  border-bottom: none;
  position: relative;
}
.container-wrapper{
  margin-top: 0;
}
</style>
