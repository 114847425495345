<template>
  <div>
    <FormItem :label="$t('bu-shu-lei-xing')" prop="sinkInstanceType">
      <div v-if="type === 'source'">
        <RadioGroup v-model="getInstanceType" type="button"
                    @on-change="handleDeployTypeChange(type)">
          <Radio v-for="(type) of taskInfo.srcDeployTypes" :label="type.deployEnvType"
                 :key="type.deployEnvType">
            {{ type.i18nName }}
          </Radio>
        </RadioGroup>
        <Tooltip placement="right-start" v-if="type==='source'">
          <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
          <div slot="content">
            <P><span>{{
                $t('zi-jian-shu-ju-ku')
              }}</span>{{ $t('shi-yong-hu-zi-ji-bu-shu-de-shu-ju-ku-shi-li') }}</P>
            <p><span>{{
                $t('a-li-yun-0')
              }}</span>{{ $t('shi-yong-hu-zaiali-yun-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
            <p><span>{{
                $t('ya-ma-xun-aws-0')
              }}</span>{{ $t('shi-yong-hu-zai-ya-ma-xun-aws-shang-gou-mai-de-shu-ju-ku-shi-li') }}
            </p>
            <p><span>{{
                $t('wei-ruan-azure-0')
              }}</span>{{ $t('shi-yong-hu-zai-wei-ruan-azure-shang-gou-mai-de-shu-ju-ku-shi-li') }}
            </p>
            <p><span>{{
                $t('hua-wei-yun')
              }}</span>{{ $t('shi-yong-hu-zai-hua-wei-yun-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
          </div>
        </Tooltip>
      </div>
      <div v-if="type !== 'source'">
        <RadioGroup v-model="getInstanceType" type="button"
                    @on-change="handleDeployTypeChange(type)">
          <Radio v-for="(type) of taskInfo.dstDeployTypes" :label="type.deployEnvType"
                 :key="type.deployEnvType">
            {{ type.i18nName }}
          </Radio>
        </RadioGroup>
        <Tooltip placement="right-start" v-if="type==='source'">
          <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
          <div slot="content">
            <P><span>{{
                $t('zi-jian-shu-ju-ku')
              }}</span>{{ $t('shi-yong-hu-zi-ji-bu-shu-de-shu-ju-ku-shi-li') }}</P>
            <p><span>{{
                $t('a-li-yun-0')
              }}</span>{{ $t('shi-yong-hu-zaiali-yun-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
            <p><span>{{
                $t('ya-ma-xun-aws-0')
              }}</span>{{ $t('shi-yong-hu-zai-ya-ma-xun-aws-shang-gou-mai-de-shu-ju-ku-shi-li') }}
            </p>
            <p><span>{{
                $t('wei-ruan-azure-0')
              }}</span>{{ $t('shi-yong-hu-zai-wei-ruan-azure-shang-gou-mai-de-shu-ju-ku-shi-li') }}
            </p>
            <p><span>{{
                $t('hua-wei-yun')
              }}</span>{{ $t('shi-yong-hu-zai-hua-wei-yun-shang-gou-mai-de-shu-ju-ku-shi-li') }}</p>
          </div>
        </Tooltip>
      </div>
    </FormItem>
    <FormItem
      :label="$t('typesource-yuan-mu-biao-lei-xing', [type===`source`?$t('yuan'):$t('mu-biao')])"
      prop="sinkType">
      <RadioGroup v-model="getDataSourceType" type="button"
                  @on-change="handleTypeChange(type,$event)">
        <Radio v-for="(limit,type1) of getDataSourceTypes" :label="type1" :key="type1"
               :disabled="limit === AUTH_SHOW_TYPE.NOT_AUTHED" class="datasource-type"
               style="width: 190px;text-align: center">
          <div v-if="limit === AUTH_SHOW_TYPE.DIFF_AUTHED" class="diff-authed">{{
              $t('shang-ye')
            }}
          </div>
          <span v-if="[AUTH_SHOW_TYPE.AUTHED, AUTH_SHOW_TYPE.DIFF_AUTHED].includes(limit)">
            {{ getShowNameByDeployTypeAndDsName(getInstanceType, type1) }}
            <DataSourceIcon :type="type1" :instanceType="getInstanceType"/>
          </span>
          <Tooltip
            :content="$t('ru-xu-shi-yong-gai-gong-neng-qing-lian-xi-cloudcanal-de-gong-zuo-ren-yuan')"
            placement="bottom" v-else>
            {{ getShowNameByDeployTypeAndDsName(getInstanceType, type1) }}
            <DataSourceIcon :type="type1" :instanceType="getInstanceType"/>
          </Tooltip>
        </Radio>
      </RadioGroup>
    </FormItem>
    <FormItem :label="$t('wang-luo-lei-xing')" prop="sourceInstanceType">
      <RadioGroup v-model="getHostType" @on-change="handleChangeNetType(type,$event)">
        <Radio label="PRIVATE">
          {{ $t('nei-wang') }}
        </Radio>
        <Radio label="PUBLIC">
          {{ $t('wai-wang') }}
        </Radio>
      </RadioGroup>
    </FormItem>
    <FormItem
      :label="$t('typesource-yuan-mu-biao-shi-li', [type===`source`?$t('yuan'):$t('mu-biao')])"
      :prop="type==='source'?'sourceInstanceId':'sinkInstanceId'">
      <Select filterable style="width: 280px;margin-right: 6px"
              @on-change="handleInstanceChange(type,$event)" v-model="getInstanceValue">
        <Option
          v-for="(instance,index) in type==='source'?taskInfo.sourceInstanceList:taskInfo.sinkInstanceList"
          :value="instance.privateHost+'|'+instance.id + '|' + instance.privateHost + '|' + instance.publicHost + '|' +
                          instance.instanceId"
          :key="index"
          :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`"
        >
          <p>{{ instance.instanceId }}</p>
          <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
        </Option>
      </Select>
      <pd-button @click="handleTestConnectionOperator(type==='source'?1:2)"
                 :loading="type==='source'?taskInfo.sourceConnectionLoading:taskInfo.sinkConnectionLoading"
                 type="primary"
                 :disabled="type==='source'?taskInfo.showSourceAddWhiteList||!taskInfo.sourceInstanceId:taskInfo.showSinkAddWhiteList||!taskInfo.sinkInstanceId"
                 ghost>{{ $t('ce-shi-lian-jie') }}
      </pd-button>
      <span style="margin-left: 10px" v-if="type==='source'&&taskInfo.showSourceConnection">
                <Icon :type="taskInfo.sourceConnection?'ios-checkmark-circle':'ios-close-circle'"
                      :style="taskInfo.sourceConnection?
                      'font-size: 14px;margin-right: 5px;color: #19be6b;':'font-size: 14px;margin-right: 5px;color:#ed4014'"/>
            {{
          $t('lian-jie-taskinfosourceconnection-cheng-gong-shi-bai', [taskInfo.sourceConnection ? $t('cheng-gong') : $t('shi-bai')])
        }}
            </span>
      <span style="margin-left: 10px" v-if="type==='sink'&&taskInfo.showSinkConnection">
                <Icon :type="taskInfo.sinkConnection?'ios-checkmark-circle':'ios-close-circle'"
                      :style="taskInfo.sinkConnection?
                      'font-size: 14px;margin-right: 5px;color: #19be6b;':'font-size: 14px;margin-right: 5px;color:#ed4014'"/>
            {{
          $t('lian-jie-taskinfosourceconnection-cheng-gong-shi-bai', [taskInfo.sinkConnection ? $t('cheng-gong') : $t('shi-bai')])
        }}
            </span>
      <span style="margin-right: 6px"
            v-if="type==='source'?taskInfo.showSourceAddWhiteList:taskInfo.showSinkAddWhiteList">
              <span style="color: #FF6E0C"><i style="margin-left: 10px;margin-right: 8px"
                                              class="iconfont iconTIP"></i>{{
                  $t('gai-ji-qun-cun-zai-ji-qi-wei-tian-jia-guo-bai-ming-dan')
                }}</span>
              <a @click="handleAddWhiteList(type)">{{ $t('tian-jia-bai-ming-dan') }}</a></span>
    </FormItem>
    <FormItem :label="$t('zi-fu-ji')" :prop="type==='source'?'sourceCharset':'targetCharset'">
      <Select style="width: 280px" v-if="type==='source'" v-model="taskInfo.sourceCharset">
        <Option v-for="charset in taskInfo.sourceCharsetList" :key="charset.dsCharset"
                :value="charset.dsCharset">
          {{ charset.dsCharset }}
        </Option>
      </Select>
      <Select style="width: 280px" v-else v-model="taskInfo.targetCharset">
        <Option v-for="charset in taskInfo.targetCharsetList" :key="charset.dsCharset"
                :value="charset.dsCharset">
          {{ charset.dsCharset }}
        </Option>
      </Select>
    </FormItem>
    <FormItem :label="$t('mo-ren-topic')" prop="dstMqDefaultTopic"
              v-if="type!=='source'&&taskInfo.schemaWhiteListLevel==='DB'&&DataSourceGroup.mq.includes(taskInfo.sinkType)">
      <Select style="width: 280px" filterable v-model="taskInfo.dstMqDefaultTopic"
              @on-change="handleChangeDefaultTopic">
        <Option v-for="topic in sinkTableList" :key="topic.tableName" :value="topic.tableName">
          {{ topic.tableName }}
        </Option>
      </Select>
    </FormItem>
    <div
      v-if="type==='source'&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)>-1&&taskInfo.sourceInstanceType==='ALIBABA_CLOUD_HOSTED'"
      style="margin-top: 16px">
      <FormItem label="Consumer Group" prop="consumerGroupId">
        <Input v-model="taskInfo.consumerGroupId" style="width: 280px;margin-right: 6px"/>
      </FormItem>
      <!--          <FormItem label="RabbitMqVhost">-->
      <!--            <Input v-model="taskInfo.srcRabbitMqVhost" style="width: 280px;margin-right: 6px"/>-->
      <!--          </FormItem>-->
      <!--          <FormItem label="RabbitExchange">-->
      <!--            <Input v-model="taskInfo.srcRabbitExchange" style="width: 280px;margin-right: 6px"/>-->
      <!--          </FormItem>-->
    </div>
    <div
      v-if="type==='source'&&taskInfo.sourceType==='RabbitMQ'"
      style="margin-top: 16px">
      <FormItem label="Vhost" prop="srcRabbitMqVhost">
        <Select style="width: 280px" v-model="taskInfo.srcRabbitMqVhost">
          <Option value="cloudcanal">cloudcanal</Option>
          <Option v-for="vhost in rabbitVhosts" :key="vhost" :value="vhost">{{ vhost }}</Option>
        </Select>
      </FormItem>
    </div>
    <div
      v-if="type==='sink'&&taskInfo.sinkType==='RabbitMQ'"
      style="margin-top: 16px">
      <FormItem label="Vhost" prop="dstRabbitMqVhost">
        <Select style="width: 280px" v-model="taskInfo.dstRabbitMqVhost" filterable>
          <Option value="cloudcanal">cloudcanal</Option>
          <Option v-for="vhost in rabbitVhosts" :key="vhost" :value="vhost">{{ vhost }}</Option>
        </Select>
      </FormItem>
      <FormItem label="Exchange" prop="dstRabbitExchange">
        <Select style="width: 280px" v-model="taskInfo.dstRabbitExchange" filterable>
          <Option :value="taskInfo.sinkInstanceId">{{ taskInfo.sinkInstanceId }}</Option>
          <Option v-for="exchange in rabbitExchange" :key="exchange" :value="exchange">
            {{ exchange }}
          </Option>
        </Select>
      </FormItem>
    </div>
    <FormItem label="DDL Topic" prop="ddlTopic"
              v-if="type!=='source'&&isMQ(this.taskInfo.sinkType)">
      <Select style="width: 280px" filterable v-model="taskInfo.dstMqDdlTopic"
              @on-change="handleChangeDdlTopic">
        <Option v-for="topic in sinkTableList" :key="topic.tableName" :value="topic.tableName">
          {{ topic.tableName }}
        </Option>
      </Select>
    </FormItem>
    <FormItem :label="$t('tong-bu-dui-xiang-mo-shi')" prop="schemaWhiteListLevel"
              v-if="type==='source'&&showMigrationModeSetting">
      <RadioGroup v-model="taskInfo.schemaWhiteListLevel">
        <Radio label="">{{ $t('bai-ming-dan-mo-shi-mo-ren') }}</Radio>
        <Radio label="DB">{{ $t('quan-ku-tong-bu') }}</Radio>
      </RadioGroup>
    </FormItem>
    <AdvancedSetting :type="type" :taskInfo="taskInfo" :source-config="sourceConfig"
                     :target-config="targetConfig"
                     :sink-table-list="sinkTableList"></AdvancedSetting>
    <DbSource
      v-if="type==='source'&&schemaMapping.dstSchemaLevel==='NONE'&&schemaMapping.srcSchemaLevel!=='NONE'"
      :dbMap="dbMap" type="source" :handleDbChange="handleDbChange" :hasTheDb="hasTheDb"
      :taskInfo="taskInfo" :handleSchemaChange="handleSchemaChange"
      :getSchemaList="getSchemaList" :store="store" :handleRemoveMap="handleRemoveMap"
      :handleAddMap="handleAddMap" :schemaMapping="schemaMapping"
      :containsDb="containsDb"></DbSource>
    <DbSource
      v-if="type==='sink'&&schemaMapping.dstSchemaLevel!=='NONE'&&schemaMapping.srcSchemaLevel==='NONE'"
      :dbMap="dbMap" type="sink" :handleDbChange="handleDbChange" :hasTheDb="hasTheDb"
      :taskInfo="taskInfo" :handleSchemaChange="handleSchemaChange"
      :getSchemaList="getSchemaList" :store="store" :handleRemoveMap="handleRemoveMap"
      :handleAddMap="handleAddMap" :schemaMapping="schemaMapping"
      :containsDb="containsDb"></DbSource>
  </div>
</template>
<script>
import { getTableList } from '@/services/cc/api/datasource';
import { AUTH_SHOW_TYPE } from '@/const';
import Mapping from '@/views/util';
import utilMixin from '@/mixins/utilMixin';
import {
  isMQ, isMySQL, isOracle, isPolar, isStarRocks
} from '@/utils';
import store from '@/store';
import DataSourceGroup from '@/views/dataSourceGroup';
import AdvancedSetting from './AdvancedSetting';
import DataSourceIcon from '../../../DataSourceIcon';
import DbSource from './DbSource';

export default {
  name: 'DataSourceSetting',
  mixins: [utilMixin],
  components: {
    AdvancedSetting,
    DataSourceIcon,
    DbSource
  },
  props: {
    type: String,
    taskInfo: Object,
    handleDeployTypeChange: Function,
    handleTypeChange: Function,
    handleInstanceChange: Function,
    showSinkAddWhiteList: Boolean,
    handleAddWhiteList: Function,
    handleTestConnection: Function,
    handleChangeNetType: Function,
    handleTaskName: Function,
    test2: Boolean,
    test1: Boolean,
    showSourceAddWhiteList: Boolean,
    handleDbChange: Function,
    hasTheDb: Function,
    getSchemaList: Function,
    handleRemoveMap: Function,
    handleAddMap: Function,
    containsDb: Function,
    dbMap: Array,
    charsetList: Array,
    dsAttrs: Object,
    handleSchemaChange: Function,
    sourceConfig: Array,
    targetConfig: Array,
    schemaMapping: Object
  },
  computed: {
    getInstanceType: {
      get() {
        return this.type === 'source' ? this.taskInfo.sourceInstanceType : this.taskInfo.sinkInstanceType;
      },
      set(v) {
        if (this.type === 'source') {
          this.taskInfo.sourceInstanceType = v;
        } else {
          this.taskInfo.sinkInstanceType = v;
        }
      }
    },
    getDataSourceType: {
      get() {
        return this.type === 'source' ? this.taskInfo.sourceType : this.taskInfo.sinkType;
      },
      set(v) {
        if (this.type === 'source') {
          this.taskInfo.sourceType = v;
        } else {
          this.taskInfo.sinkType = v;
        }
      }
    },
    getDataSourceTypes: {
      get() {
        return this.type === 'source' ? this.taskInfo.sourceDataSourceTypes : this.taskInfo.sinkDataSourceTypes;
      },
      set(v) {
        if (this.type === 'source') {
          this.taskInfo.sourceDataSourceTypes = v;
        } else {
          this.taskInfo.sinkDataSourceTypes = v;
        }
      }
    },
    getInstanceValue: {
      get() {
        return this.type === 'source' ? this.taskInfo.sourceInstanceValue : this.taskInfo.sinkInstanceValue;
      },
      set(v) {
        if (this.type === 'source') {
          this.taskInfo.sourceInstanceValue = v;
        } else {
          this.taskInfo.sinkInstanceValue = v;
        }
      }
    },
    getHostType: {
      get() {
        return this.type === 'source' ? this.taskInfo.sourceHostType : this.taskInfo.targetHostType;
      },
      set(v) {
        if (this.type === 'source') {
          this.taskInfo.sourceHostType = v;
        } else {
          this.taskInfo.targetHostType = v;
        }
      }
    },
    showMigrationModeSetting() {
      const { sourceType, sinkType } = this.taskInfo;
      return ((isMQ(sinkType) || isMySQL(sinkType) || isPolar(sinkType) || isStarRocks(sinkType)) && (isMySQL(sourceType) || isPolar(sourceType))) || (isMySQL(sourceType) && isStarRocks(sinkType))
        || (isOracle(sourceType) && (isStarRocks(sinkType) || isMySQL(sinkType)));
    }
  },
  data() {
    return {
      AUTH_SHOW_TYPE,
      store,
      Mapping,
      DataSourceGroup,
      sinkTableList: [],
      rabbitVhosts: [],
      rabbitExchange: [],
      isMQ
    };
  },
  mounted() {
    if (this.taskInfo.sinkTableList && this.taskInfo.sinkTableList.kafka) {
      this.sinkTableList = this.taskInfo.sinkTableList.kafka;
    }
    this.sinkInstanceValue = this.taskInfo.sinkInstanceValue;
    this.sourceInstanceValue = this.taskInfo.sourceInstanceValue;
  },
  methods: {
    getTopicList() {
      getTableList({
        host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
        privateHost: this.taskInfo.sinkPrivateHost,
        publicHost: this.taskInfo.sinkPublicHost,
        hostType: this.taskInfo.targetHostType,
        type: this.taskInfo.sinkType,
        userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sinkType) > -1 && this.taskInfo.sinkAccountRole ? `${this.taskInfo.sinkAccount} as SYSDBA` : this.taskInfo.sinkAccount,
        dbName: '',
        dataSourceId: this.taskInfo.targetDataSourceId,
        tableSchema: '',
        clusterId: this.taskInfo.clusterId,
        rabbitMqVhost: this.taskInfo.dstRabbitMqVhost,
        rabbitExchange: this.taskInfo.dstRabbitExchange
      })
        .then((response) => {
          this.taskInfo.sinkTableList.kafka = response.data.data;

          this.sinkTableList = response.data.data;
        });
    },
    handleChangeDefaultTopic(data) {
      this.sinkTableList.forEach((topic) => {
        if (topic.tableName === data) {
          this.taskInfo.dstMqDefaultTopicPartitions = topic.mqTopicPartitions;
          this.taskInfo.dstMqDefaultTopic = topic.tableName;
        }
      });
    },
    handleTestConnectionOperator(n) {
      this.handleTestConnection(n);
      if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) && n === 2) {
        this.getTopicList();
      }
    },
    handleChangeDdlTopic(data) {
      this.sinkTableList.forEach((topic) => {
        if (topic.tableName === data) {
          this.taskInfo.dstMqDdlTopicPartitions = topic.mqTopicPartitions;
          this.taskInfo.dstMqDdlTopic = topic.tableName;
        }
      });
    }
  },
  watch: {
    'taskInfo.sinkConnection': {
      handler() {
        const vhost = this.taskInfo.dstRabbitMqVhost;
        this.taskInfo.dstRabbitMqVhost = '';
        this.taskInfo.dstRabbitMqVhost = vhost;
      },
      deep: true
    },
    'taskInfo.schemaWhiteListLevel': {
      handler() {
        if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) && this.taskInfo.sinkInstanceId && this.taskInfo.sinkConnection && this.taskInfo.showSourceConnection) {
          this.getTopicList();
        }
      },
      deep: true
    },
    dsAttrs(val) {
      if (this.getDataSourceType === 'RabbitMQ') {
        this.rabbitVhosts = val.rabbitMqVhosts || [];
        this.rabbitExchange = val.rabbitMqExchanges[this.taskInfo.dstRabbitMqVhost] || [];
        if (this.rabbitVhosts.indexOf('cloudcanal') > -1) {
          this.rabbitVhosts.splice(this.rabbitVhosts.indexOf(this.taskInfo.sinkInstanceId), 1);
        }
        if (this.rabbitExchange.indexOf('cloudcanal') > -1) {
          this.rabbitExchange.splice(this.rabbitExchange.indexOf(this.taskInfo.sinkInstanceId), 1);
        }
      }
    },
    'taskInfo.dstRabbitMqVhost': {
      handler(val) {
        if (val && this.rabbitVhosts.length > 0 && this.rabbitExchange.length > 0) {
          if (DataSourceGroup.mq.includes(this.taskInfo.sinkType) && this.taskInfo.sinkInstanceId && this.taskInfo.sinkConnection) {
            this.getTopicList();
          }
        }
        if (val && this.dsAttrs.rabbitMqExchanges) {
          this.rabbitExchange = this.dsAttrs.rabbitMqExchanges[val];
          if (this.rabbitExchange.indexOf(this.taskInfo.sinkInstanceId) > -1) {
            this.rabbitExchange.splice(this.rabbitExchange.indexOf(this.taskInfo.sinkInstanceId), 1);
          }
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.diff-authed {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 20px;
  font-size: 12px;
  border: 1px solid #0087C7;
  padding: 0 6px;
  transform: scale(.5);
  transform-origin: top right;
}
</style>
