<template>
    <div style="padding: 16px;padding-bottom: 74px">
        <Breadcrumb style="margin-bottom: 14px">
            <BreadcrumbItem>{{ $t('yi-bu-ren-wu') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="page-header-container">
            <Form ref="formInline" :model="searchKey" inline label-position="right"
                  style="padding-right: 300px">
                <FormItem>
                    <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
                        <Option value="type" :label="$t('ren-wu-lei-xing')">
                            <span>{{ $t('ren-wu-lei-xing') }}</span>
                        </Option>
                        <Option value="status" :label="$t('zhuang-tai')">
                            <span>{{ $t('ren-wu-zhuang-tai') }}</span>
                        </Option>
                      <Option value="workerIds" :label="$t('ji-qi-id')">
                        <span>{{ $t('ji-qi-id') }}</span>
                      </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='type'">
                    <Select v-model="searchKey.label" style="width: 250px">
                        <Option value="">{{ $t('quan-bu') }}</Option>
                        <Option v-for="(label) of consoleJobLabels" :value="label.consoleJobLabel" :key="label.consoleJobLabel">{{label.i18nName}}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='status'">
                    <Select v-model="searchKey.consoleTaskState" style="width: 250px">
                        <Option value="">{{ $t('quan-bu') }}</Option>
                        <Option v-for="(state) of consoleTaskStates" :value="state" :key="state">{{Mapping.consoleJobStatus[state]}}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem v-if="searchType==='workerIds'">
                  <Input v-model="searchKey.workerIds"/>
                </FormItem>
                <FormItem>
                    <pd-button type="primary" @click="getConsoleJobList">{{ $t('cha-xun') }}</pd-button>
                </FormItem>
            </Form>
            <div class="page-header-function">
                <pd-button type="default" style="margin-right: 6px" @click="getConsoleJobList"
                        :loading="refreshLoading">
                    {{ $t('shua-xin') }}
                </pd-button>
            </div>
        </div>
        <Table stripe style="margin-top: 20px" size="small" border :columns="asyncColumn" :data="showData">
          <template slot-scope="{ row }" slot="label">
            <div>{{getLabel(row)}}</div>
          </template>
            <template slot-scope="{ row }" slot="link">
                <a @click="handleDetail(row.id)" style="color: #0BB9F8;width:100%;display: block">{{row.id}}</a>
            </template>
            <template slot-scope="{ row }" slot="resourceId">
                <a @click="getJob(row)" v-if="row.dataJobName">{{row.dataJobName}}</a>
                <span v-if="row.workerName">{{row.workerName}}</span>
                <span v-if="row.dsInstanceId">{{row.dsInstanceId}}</span>
            </template>
            <template slot-scope="{ row }" slot="resourceDesc">
                <span v-if="row.dataJobDesc">{{row.dataJobDesc}}</span>
                <span v-if="row.workerDesc">{{row.workerDesc}}</span>
                <span v-if="row.datasourceDesc">{{row.datasourceDesc}}</span>
            </template>
            <template slot-scope="{ row }" slot="action">
                <a @click="handleGoDetail(row)" style="margin-right: 16px">{{ $t('xiang-qing') }}</a>
<!--                <a v-if="row.taskState!=='SUCCESS'&&row.taskState!=='CANCELED'" @click="handleCancelJob(row)">取消</a>-->
            </template>
            <template slot="taskState" slot-scope="{row}">
                <div :style="`color:${row.taskState==='FAILED'?'#FF1815':row.taskState==='SUCCESS'?'#52C41A':row.taskState==='CANCELED'?'#555555':''}`">
                  <span class="status-point" :style="`background-color:${row.taskState==='FAILED'?'#FF1815':row.taskState==='SUCCESS'?'#52C41A':row.taskState==='CANCELED'?'#555555':''}`">
                  </span>{{Mapping.consoleJobStatus[row.taskState]}}</div>
            </template>
        </Table>
        <div class="page-footer-container">
            <div class="page-footer-paging">
                <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
                <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
                <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
                  <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
            </div>
        </div>
    </div>
</template>
<script>
import { listConsoleTaskStates, listConsoleJobLabels } from '@/services/cc/api/constant';
import fecha from 'fecha';
import { getConsoleJobList, cancelConsoleJob } from '@/services/cc/api/consoleJob';
import { queryJobById } from '@/services/cc/api/noError';
import Mapping from '../util';

export default {
  mounted() {
    this.getConsoleJobList();
    this.listConsoleTaskStates();
    this.listConsoleJobLabels();
  },
  data() {
    return {
      searchType: 'type',
      Mapping,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      noMoreData: false,
      refreshLoading: false,
      searchKey: {
        consoleTaskState: '',
        label: '',
        workerIds: '',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      size: 20,
      consoleTaskStates: [],
      consoleJobLabels: [],
      showData: [],
      asyncColumn: [
        {
          title: this.$t('ming-cheng'),
          key: 'label',
          slot: 'label',
          minWidth: 200
        },
        {
          title: this.$t('fa-qi-zhe'),
          key: 'launcher',
          width: 100
        },
        {
          title: this.$t('fa-qi-shi-jian'),
          key: 'launchTime',
          width: 200,
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.launchTime), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('zhuang-tai'),
          key: 'taskState',
          width: 160,
          slot: 'taskState'
        },
        {
          title: this.$t('lei-xing'),
          key: 'resourceType',
          width: 120,
          render: (h, params) => h('div', {}, Mapping.resourceType[params.row.resourceType])
        },
        {
          title: this.$t('zi-yuan-id'),
          key: 'dataJobName',
          width: 240,
          slot: 'resourceId'
        },
        {
          title: this.$t('zi-yuan-miao-shu'),
          key: 'dataJobDesc',
          slot: 'resourceDesc',
          minWidth: 200
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action',
          width: 160
        }

      ],
      asyncData: []
    };
  },
  computed: {
    getLabel() {
      return (row) => {
        let labelI18Name = row.label;
        this.consoleJobLabels.forEach((label) => {
          if (label.consoleJobLabel === row.label) {
            labelI18Name = label.i18nName;
          }
        });
        return labelI18Name;
      };
    }
  },
  created() {
    const { workerIds } = this.$route.query;

    if (workerIds) {
      this.searchType = 'workerIds';
      this.searchKey.workerIds = workerIds;
    }
  },
  methods: {
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        consoleTaskState: '',
        label: '',
        pageData: {
          startId: 0,
          pageSize: 10
        }
      };
    },
    handleRefresh() {
      this.getConsoleJobList();
    },
    handleCancelJob(row) {
      this.$Modal.confirm({
        title: this.$t('qu-xiao-yi-bu-ren-wu-que-ren'),
        content: this.$t('p-qu-xiao-zhi-hou-zheng-ge-ren-wu-jiang-shi-bai-bing-jie-shu-que-ren-yao-qu-xiao-gai-yi-bu-ren-wu-ma-p'),
        onOk: () => {
          cancelConsoleJob({
            consoleJobId: row.id,
            consoleTaskId: ''
          }).then((res) => {
            if (res.data.code === '1') {
              this.getConsoleJobList();
            }
          });
        }
      });
    },
    handleGoDetail(row) {
      this.$router.push({ path: `/ccsystem/state/task/${row.id}` });
    },
    getConsoleJobList(type) {
      this.refreshLoading = true;
      const workerIds = this.searchKey.workerIds ? this.searchKey.workerIds.split(',').map((workerId) => parseInt(workerId, 10)) : null;
      const consoleTaskData = {
        workerIds,
        consoleTaskState: this.searchKey.consoleTaskState,
        label: this.searchKey.label,
        startId: this.searchKey.pageData.startId,
        pageSize: this.searchKey.pageData.pageSize
      };

      getConsoleJobList(consoleTaskData).then((res) => {
        if (res.data.code === '1') {
          this.asyncData = res.data.data;
          this.showData = this.asyncData;
          if (type === 'next') {
            if (!this.prevFirst[this.page - 1]) {
              this.prevFirst.push(this.firstId);
            }
          }
          this.firstId = this.showData[0].id;
          this.lastId = this.showData[this.showData.length - 1].id;
          this.noMoreData = res.data.data.length < this.searchKey.pageData.pageSize;
        }
        this.refreshLoading = false;
      }).catch(() => {
        this.refreshLoading = false;
      });
    },
    listConsoleTaskStates() {
      listConsoleTaskStates().then((res) => {
        if (res.data.code === '1') {
          this.consoleTaskStates = res.data.data;
        }
      });
    },
    listConsoleJobLabels() {
      listConsoleJobLabels().then((res) => {
        if (res.data.code === '1') {
          this.consoleJobLabels = res.data.data;
        }
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchKey.pageData.startId = startId;
      this.getConsoleJobList('prev');
    },
    handleNext() {
      this.searchKey.pageData.startId = this.lastId;
      this.getConsoleJobList('next');
      this.page++;
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    getJob(row) {
      queryJobById({ jobId: row.resourceId }).then((res) => {
        if (res.data.code === '1') {
          this.$router.push({ path: `/data/job/${row.resourceId}` });
        } else {
          this.$Modal.info({
            title: this.$t('tiao-zhuan-shi-bai'),
            content: this.$t('gai-ren-wu-yi-bu-cun-zai')
          });
        }
      });
    }
  }
};
</script>
