import Vue from 'vue';

export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItem = (key) => localStorage.getItem(key);

export const removeItem = (key) => localStorage.removeItem(key);

export const removeAll = () => {
  localStorage.removeAll();
};

Vue.prototype.$localstorage = {
  setItem,
  getItem,
  removeItem,
  removeAll
};
