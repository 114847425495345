<template>
  <div>
    <Table :row-class-name="rowClassName" :no-data-text="$t('nin-huan-mei-you-xiang-guan-ren-wu')" class="job-list-table" border
           :columns="batchEditInTable?jobColumnBatch:jobColumn" :data="showData" row-key="dataJobId"
           @on-sort-change="handleTableSort" :stripe="true" @on-select-all="handleSelectAllBatch"
           @on-select-all-cancel="handleCancelAllBatch"
           @on-select="handleSelectBatch" @on-select-cancel="handleCancelBatch"
           ref="job-list-table">
      <template slot-scope="{ row }" slot="instanceId">
        <div style="padding: 30px 10px 30px 12px">
          <div style="display:flex;">
            <router-link :to="`/data/job/${row.dataJobId}${row.parentDataJobId ? `?parentDataJobId=${row.parentDataJobId}` : ''}`">{{ row.dataJobName }}</router-link>
            <Tooltip placement="right" class="dataSource-icon" transfer style="width: 40px"
                     :content="`${row.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.sourceDsVO.dataSourceType]:
                           row.sourceDsVO.dataSourceType}->${row.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.targetDsVO.dataSourceType]:row.targetDsVO.dataSourceType}`">
              <div class="job-list-datasource job-list-source">
                <DataSourceIcon
                                :type="row.sourceDsVO.dataSourceType"
                                :instanceType="row.sourceDsVO.deployType"></DataSourceIcon>
              </div>
              <div class="job-list-datasource job-list-sink">
                <DataSourceIcon
                                :type="row.targetDsVO.dataSourceType"
                                :instanceType="row.targetDsVO.deployType"></DataSourceIcon>
              </div>
            </Tooltip>
            <Tooltip placement="right" transfer :content="Mapping.jobType[row.dataJobType]" style="margin-left: 18px">
              <i :class="`iconfont ${Mapping.jobTypeIcon[row.dataJobType]}`"></i>
            </Tooltip>
            <Tooltip transfer v-if="row.havePackage" :content="$t('cun-zai-zi-ding-yi-dai-ma-chu-li-de-ren-wu')" placement="right">
              <svg style="font-size: 14px;margin-left: 6px" class="icon job-info-status job-info-status-running" aria-hidden="true">
                <use xlink:href="#icon-zidingyishujuchuli"></use>
              </svg>
            </Tooltip>
            <div>
              <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('zhang-hu-yuebu-zu-ren-wu-bei-dong-jie')"
                       v-if="row.lifeCycleState==='LOCKED'&&row.consoleTaskState!=='FAILED'">
            <span style="display: block" @click="handleGoUserCenter"><i
              class="iconfont iconfreeze"></i></span>
              </Tooltip>
              <Tooltip placement="right" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                       v-if="row.consoleTaskState==='FAILED'">
            <span style="display: inline-block" @click="handleGoConsoleJob(row)"><i
              class="iconfont iconyibuforce"></i></span>
              </Tooltip>
              <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('gai-ren-wu-cun-zai-yi-chang-dian-ji-cha-kan-xiang-qing')"
                       v-if="row.haveException">
            <span style="display: block" @click="handleGoExceptionLog(row)"><i
              class="iconfont iconyibuforce" style="color: red;"></i></span>
              </Tooltip>
            </div>
          </div>
          <div class="data-job-desc" style="position:relative;padding-right: 20px;margin-top: 4px">
            <Tooltip :content="row.dataJobDesc?row.dataJobDesc:$t('zan-wu-miao-shu')" placement="bottom" transfer>
                <span style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;
                    -o-text-overflow:ellipsis;max-width: 150px;display: inline-block;vertical-align: middle">
                      {{ row.dataJobDesc ? row.dataJobDesc : $t('zan-wu-miao-shu') }}</span>
            </Tooltip>
            <i class="iconfont iconBIANJI job-list-desc-edit icon" v-if="myAuth.includes('CC_DATAJOB_MANAGE')"
               @click="handleEditJobDesc(row)"></i>
          </div>
          <Poptip placement="right" width="680" class="show-job-info-icon" transfer>
            <i class="iconfont iconinfo"></i>
            <div slot="content" style="line-height: 24px">
              <DataJobDetail :dataJobDetail="row"></DataJobDetail>
            </div>
          </Poptip>
        </div>
      </template>
      <template slot-scope="{ row }" slot="dataJobType">
        <div>
          {{Mapping.jobType[row.dataJobType]}}
          <Tooltip transfer v-if="row.havePackage" :content="$t('cun-zai-zi-ding-yi-dai-ma-chu-li-de-ren-wu')" placement="right">
            <svg style="font-size: 14px;margin-left: 6px" class="icon job-info-status job-info-status-running" aria-hidden="true">
              <use xlink:href="#icon-zidingyishujuchuli"></use>
            </svg>
          </Tooltip>
        </div>
      </template>
      <template slot-scope="{ row }" slot="progress">
        <div style="padding: 7px">
          <div style="display: flex;">
            <div v-if="getDataTask(row.dataTasks)['BUILD_STRUCT']" class="job-state-item-container"
                 :style="`width:${(650-10*row.dataTasks.length)/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!==$t('zheng-chang')&&getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus,getDataTask(row.dataTasks)['BUILD_STRUCT'].healthLevel)"
                  :percent="parseInt(Number(JSON.parse(getDataTask(row.dataTasks)['BUILD_STRUCT'].taskPosition).builtPercent)*100, 10)"
                  :stroke-width="20" text-inside/>
              </div>
              <div class="job-state-item-content">
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ $t('jie-gou-qian-yi') }}</p>
                <p class="job-info-progress-content">
                  {{ $t('ben-ci-yi-qian-yi-jsonparsegetdatataskdatajobinfodatatasksbuildstructtaskpositionbuilt-zhang-biao', [JSON.parse(getDataTask(row.dataTasks)['BUILD_STRUCT'].taskPosition).built]) }}</p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['FULL']" class="job-state-item-container"
                 :style="`width:${(650-10*row.dataTasks.length)/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!==$t('zheng-chang')&&getDataTask(row.dataTasks)['FULL'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['FULL'].dataTaskStatus,getDataTask(row.dataTasks)['FULL'].healthLevel)"
                  :percent="Number(JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).transPercent)"
                  :stroke-width="20" text-inside/>
              </div>
              <div class="job-state-item-content">
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['FULL'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ row.dataJobType === 'SYNC' ? $t('shu-ju-chu-shi-hua') : $t('quan-liang-qian-yi-0')}}</p>
                <p class="job-info-progress-content">
                  {{ $t('formatcountjsonparsegetdatataskdatajobinfodatatasksfulltaskpositiontrans-hang', [formatData(JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).trans)]) }}
                <p v-if="getDataTask(row.dataTasks).FULL.nextFireTime">
                  <span
                    class="job-state-schedule-stop">{{
                      Mapping.taskStatus[getDataTask(row.dataTasks)['FULL'].dataTaskStatus]
                    }}</span>
                  {{ $t('xia-ci-getdatataskrowdatatasksfullnextfiretime', [getDataTask(row.dataTasks).FULL.nextFireTime]) }}
                </p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['INCREMENT']" class="job-state-item-container"
                 :style="`width:${(650-10*row.dataTasks.length)/row.dataTasks.length}px`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!==$t('zheng-chang')&&getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus,getDataTask(row.dataTasks)['INCREMENT'].healthLevel)"
                  :percent="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'?0:99.99"
                  :stroke-width="20" hide-info/>
              </div>
              <div class="job-state-item-content">
                <p class="job-info-incre-status">
                  {{ Mapping.taskStatus[getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus] }}
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ row.dataJobType === 'SYNC' ? $t('shu-ju-tong-bu'): $t('zeng-liang-tong-bu-1') }}</p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus!=='INIT'">{{ $t('yan-chi') }}
                  <span :style="getDelayColor(JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis<=60000?
                                    getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs:JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis)"
                        v-text="                      formatSeconds(JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis <= 60000 ?
                        getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs === -1 ?
                          0.001 : getDataTask(row.dataTasks)['INCREMENT'].dataDelayMs / 1000 : JSON.parse(getDataTask(row.dataTasks)['INCREMENT'].taskPosition).delayMillis / 1000)"
                  />
                </p>
                <p class="job-info-progress-content"
                   v-if="getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus==='INIT'">&nbsp;&nbsp;</p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['CHECK']" class="job-state-item-container" :style="`width:${(650-10*row.dataTasks.length)/row.dataTasks.length}px;`">
              <div class="job-state-item">
                <Progress
                  :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                  :status="getProgressStatus(getDataTask(row.dataTasks)['CHECK'].dataTaskStatus,getDataTask(row.dataTasks)['CHECK'].healthLevel)"
                  :percent="Number(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).checkedPercent)"
                  :stroke-width="20" text-inside/>
              </div>
              <div class="job-state-item-content">
                <p
                  :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                  {{ $t('shu-ju-xiao-yan-0') }}</p>
              </div>
              <div class="job-state-item-container" style="text-align: center;width: 100%">
                <p style="text-align: center;width: 100%;white-space:nowrap;">
                  {{ $t('xiao-yan') }} <span
                  style="color:rgb(25, 190, 107)">{{
                    formatData(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).checked)
                  }}</span>
                  {{ $t('diu-shi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).loss)
                  }}</span>
                  {{ $t('bu-yi-zhi') }} <span
                  style="color: #ed4014">{{
                    formatData(JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).diff)
                  }}</span>
                </p>
              </div>
            </div>
            <div v-if="getDataTask(row.dataTasks)['REVISE']" class="job-state-check-container">
              <div class="job-state-item-container" :style="`width:${(650-10*row.dataTasks.length)/row.dataTasks.length}px`">
                <div class="job-state-item">
                  <Progress
                    :stroke-color="getStatusName(row)!=='正常'&&getDataTask(row.dataTasks)['REVISE'].dataTaskStatus==='COMPLETE'?['#D5D5D5', '#D5D5D5']:''"
                    :status="getProgressStatus(getDataTask(row.dataTasks)['REVISE'].dataTaskStatus,getDataTask(row.dataTasks)['REVISE'].healthLevel)"
                    :percent="revisePercentage(row)"
                    :stroke-width="20" text-inside/>
                </div>
                <div class="job-state-item-content">
                  <p
                    :class="`job-info-progress-title ${getProgressStatus(getDataTask(row.dataTasks)['REVISE'].dataTaskStatus==='wrong'?'job-info-progress-error':'')}`">
                    {{ $t('shu-ju-ding-zheng-0') }}</p>
                  <div class="job-state-item-container" style="text-align: center;width: 100%">
                    <p style="text-align: center;width: 100%;white-space:nowrap;">
                      {{ $t('ding-zheng-diu-shi') }} <span
                      style="color: #ed4014">{{
                        formatData(JSON.parse(JSON.parse(getDataTask(row.dataTasks)['REVISE'].taskPosition).revisePosDetail).revisedLossCount)
                      }}</span>
                      {{ $t('ding-zheng-bu-yi-zhi') }} <span
                      style="color: #ed4014">{{
                        formatData(JSON.parse(JSON.parse(getDataTask(row.dataTasks)['REVISE'].taskPosition).revisePosDetail).revisedDiffCount)
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <div class="schedule-time-container" v-if="getDataTask(row.dataTasks).CHECK&&getDataTask(row.dataTasks).CHECK.nextFireTime">
<!--                  <span-->
<!--                    class="job-state-schedule-stop">{{-->
<!--                      Mapping.taskStatus[getDataTask(row.dataTasks)['CHECK'].dataTaskStatus]-->
<!--                    }}</span>-->
            {{ $t('xia-ci-zhi-hang-getdatataskdatajobinfodatatasksfullnextfiretime', [getDataTask(row.dataTasks).CHECK.nextFireTime]) }}</div>
        </div>
      </template>
      <template slot-scope="{ row,index }" slot="action">
        <div class="action-container">
          <a type="primary" @click="handleDetail(row)">{{ $t('xiang-qing') }}</a>
          <a type="primary" @click="handleStartFsm(row,index)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && getDataTask(row.dataTasks)['BUILD_STRUCT']?
                        (!row.fsmActive&&row.dataTaskState==='INIT'&&(getDataTask(row.dataTasks)['BUILD_STRUCT']&&getDataTask(row.dataTasks)['BUILD_STRUCT'].dataTaskStatus==='COMPLETE')):
                        (!row.fsmActive&&row.dataTaskState==='INIT')"
          >
            {{ $t('qi-dong') }}
          </a>
          <a type="primary" @click="handleStart(row,index)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && (getDataTask(row.dataTasks)['FULL'] &&
                         getDataTask(row.dataTasks)['FULL'].dataTaskStatus === 'STOP'||
                          getDataTask(row.dataTasks)['INCREMENT'] &&
                           getDataTask(row.dataTasks)['INCREMENT'].dataTaskStatus === 'STOP' &&
                            getDataTask(row.dataTasks)['INCREMENT'].workerIp ||
                           getDataTask(row.dataTasks)['CHECK'] && getDataTask(row.dataTasks)['CHECK'].dataTaskStatus === 'STOP'||
                           getDataTask(row.dataTasks)['REVISE'] && getDataTask(row.dataTasks)['REVISE'].dataTaskStatus === 'STOP' ||
                            (loadingData[row.dataJobId] && loadingData[row.dataJobId].starting))"
             :disabled="loadingData[row.dataJobId] ? loadingData[row.dataJobId].starting : false">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].starting ? $t('qi-dong-zhong') : $t('qi-dong')
            }}
          </a>
          <a type="primary" @click="handleResumSchedule(row)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && row.dataTaskState === 'TIMING_SCHEDULE_PAUSE'">{{ $t('hui-fu-tiao-du') }}
          </a>
          <a type="primary" @click="handleRestart(row,index)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && (row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                          loadingData[row.dataJobId] &&
                           loadingData[row.dataJobId].stopping||
                           (row.dataTaskState==='CHECK'&&
                           (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                           getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))"
             :disabled:="loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping || loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting ? $t('chong-qi-chong') : $t('chong-qi')
            }}
          </a>
          <a type="warning" @click="handleStop(row,index)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && (row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                           loadingData[row.dataJobId] &&
                            loadingData[row.dataJobId].stopping||
                            (row.dataTaskState==='CHECK'&&
                            (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                            getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING')))"
             :disabled="loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping || loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting">
            {{
              loadingData[row.dataJobId] && loadingData[row.dataJobId].stopping ||
              loadingData[row.dataJobId] && loadingData[row.dataJobId].restarting ? $t('ting-zhi-zhong') : $t('ting-zhi')
            }}
          </a>
          <a @click="handleShowHistory(row,'CHECK')"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && getDataTask(row.dataTasks)['CHECK']&&JSON.parse(getDataTask(row.dataTasks)['CHECK'].taskPosition).resultHistory">{{ $t('xiao-yan-li-shi') }}</a>
          <a @click="handleShowHistory(row,'REVISE')"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && getDataTask(row.dataTasks)['REVISE']&&JSON.parse(getDataTask(row.dataTasks)['REVISE'].taskPosition).resultHistory">{{ $t('ding-zheng-li-shi') }}</a>
          <a @click="handleShowHistory(row,'FULL')"
            v-if="myAuth.includes('CC_DATAJOB_MANAGE') && getDataTask(row.dataTasks)['FULL']&&JSON.parse(getDataTask(row.dataTasks)['FULL'].taskPosition).resultHistory">{{ $t('cha-kan-li-shi') }}</a>
          <a type="primary" @click="handleStartTimeScheduleJob(row)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && row.dataTaskState === 'TIMING_SCHEDULE'">{{ $t('li-ji-zhi-hang') }}
          </a>
          <a type="warning" @click="handleStopSchedule(row)"
             v-if="myAuth.includes('CC_DATAJOB_MANAGE') && row.dataTaskState === 'TIMING_SCHEDULE'">{{ $t('zan-ting-tiao-du') }}
          </a>
          <a type="error" v-if="myAuth.includes('CC_DATAJOB_MANAGE')" @click="handleDelete(row)"
             :disabled="row.currentStatus === 'ABNORMAL'||
                        row.currentStatus === 'RUNNING'||
                        row.currentStatus === 'WAIT_START' ||
                        row.dataTaskState === 'TIMING_SCHEDULE'||
                         loadingData[row.dataJobId] &&
                          loadingData[row.dataJobId].restarting ||
                           loadingData[row.dataJobId] &&
                            loadingData[row.dataJobId].stopping||
                            (row.dataTaskState==='CHECK'&&
                            (getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='WAIT_START'||
                            getDataTask(row.dataTasks)['CHECK'].dataTaskStatus==='RUNNING'))">
            {{ $t('shan-chu') }}
          </a>
          <Dropdown transfer trigger="click" v-if="myAuth.includes('CC_DATAJOB_MANAGE') && (showReplayBtn(row)||showDetachBtn(row)||showAttachBtn(row))">
            <a>
              {{ $t('geng-duo') }}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem class="datasource-operation-dropdown" v-if="myAuth.includes('CC_DATAJOB_MANAGE') && showReplayBtn(row)">
                <a class="dropdown-content" type="primary" @click="handleReplay(row)" v-permission="'dataJob'">{{ $t('zhong-pao') }}
                </a>
              </DropdownItem>
              <DropdownItem class="datasource-operation-dropdown" v-if="myAuth.includes('CC_DATAJOB_MANAGE') && showDetachBtn(row)">
                <a class="dropdown-content" type="primary" @click="handleDetachIncreTask(row)" v-permission="'dataJob'">{{ $t('zhai-chu-ren-wu') }}
                </a>
              </DropdownItem>
              <DropdownItem class="datasource-operation-dropdown" v-if="myAuth.includes('CC_DATAJOB_MANAGE') && showAttachBtn(row)">
                <a class="dropdown-content" type="primary" @click="handleAttachIncreTask(row)" v-permission="'dataJob'">{{ $t('gua-zai-ren-wu') }}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </template>
    </Table>
    <Modal
      v-model="showEditDesc"
      :title="$t('xiu-gai-ren-wu-miao-shu')"
      @on-ok="handleConfirmEdit(selectedRow,dataJobDesc)"
    >
      <div>
        <Form label-position="right" :label-width="100">
          <FormItem :label="$t('shi-li-id')">
            {{ selectedRow.dataJobName }}
          </FormItem>
          <FormItem :label="$t('miao-shu')">
            <Input type="textarea" v-model="dataJobDesc"/>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import { permissionList } from '@/const/permission';
import { EDITIONS } from '@/const';
import Mapping from '@/views/util';
import store from '@/store';
import { mapState } from 'vuex';
import DataSourceIcon from '../../DataSourceIcon';
import DataJobDetail from './DataJobDetail';
import { formatCount, formatEnCount, formatSeconds } from '../../../util';

export default {
  components: {
    DataSourceIcon,
    DataJobDetail
  },
  props: {
    showData: Array,
    getJobList: Function,
    handleRestart: Function,
    handleStartFsm: Function,
    handleStop: Function,
    handleDelete: Function,
    handleStart: Function,
    handleResumSchedule: Function,
    handleStopSchedule: Function,
    handleStartTimeScheduleJob: Function,
    handleConfirmEdit: Function,
    handleBatchEdit: Function,
    getProgressStatus: Function,
    handleShowGroup: Function,
    getFilters: Function,
    batchEditInTable: Boolean,
    handleTableSort: Function,
    handleShowHistory: Function,
    updateAllBatchList: Function,
    handleCancelAllBatch: Function,
    handleSelectBatch: Function,
    handleCancelBatch: Function,
    handleReplay: Function,
    handleDetachIncreTask: Function,
    handleAttachIncreTask: Function
  },
  created() {
    this.showData.forEach((item) => {
      this.showEdit[item.instanceId] = false;
    });
  },
  // mounted () {
  //
  //     if (localStorage.getItem('userInfo')) {
  //
  //         const userId = JSON.parse(localStorage.getItem('userInfo')).id;
  //
  //         if (localStorage.getItem(`job_list_sort_${userId}`)) {
  //
  //             const sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
  //
  //             if (sort) {
  //
  //                 const { sortTime } = sort;
  //
  //                 if (sortTime) {
  //
  //                     this.$refs['job-list-table'].handleSort(2, sortTime);
  //
  //                 }
  //
  //             }
  //
  //         }
  //
  //     }
  //
  // },
  data() {
    return {
      selectedRow: '',
      showEditDesc: false,
      formatCount,
      formatSeconds,
      store,
      showEdit: {},
      dataJobDesc: '',
      Mapping,
      sortDelay: 'normal',
      sortTime: 'normal',
      statusTime: 10000,
      filterValue: '',
      jobId: 0,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      loadingData: [],
      jobColumnBatch: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('ren-wu-ming-cheng'),
          key: 'dataJobName',
          width: 260,
          slot: 'instanceId',
          tree: true
        },
        {
          title: this.$t('zhuang-tai'),
          width: 120,
          render: (h, params) => {
            const type = Mapping.taskSateToTaskType[params.row.dataTaskState];

            if (type) {
              if (!this.getDataTask(params.row.dataTasks)[type]) {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zan-ting'))
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].dataTaskStatus === 'STOP') {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zan-ting'))
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].healthLevel === 'Health') {
                return h('div', {
                  style: {
                    color: '#52C41A'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#52C41A'
                    }
                  }),
                  h('Tooltip', {
                    props: {
                      transfer: true,
                      placement: 'right',
                      content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                    },
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zheng-chang'))
                ]);
              }
              return h('div', {
                style: {
                  color: '#FF1815'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#FF1815'
                  }
                }),
                h('Tooltip', {
                  props: {
                    transfer: true,
                    placement: 'right',
                    content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                  },
                  style: {
                    marginLeft: '8px'
                  }
                }, this.$t('yi-chang'))
              ]);
            }
            if (params.row.dataTaskState === 'COMPLETE') {
              return h('div', {
                style: {
                  color: '#CCCCCC'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#CCCCCC'
                  }
                }),
                h('span', {
                  style: {
                    marginLeft: '8px'
                  }
                }, this.$t('wan-cheng'))
              ]);
            }
            return h('div', {
              style: {
                color: '#52C41A'
              }
            }, [
              h('span', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  background: '#52C41A'
                }
              }),
              h('Tooltip', {
                props: {
                  transfer: true,
                  placement: 'right',
                  content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                },
                style: {
                  marginLeft: '8px'
                }
              }, this.$t('zheng-chang'))
            ]);
          }
        },
        {
          title: this.$t('jin-du'),
          slot: 'progress',
          key: 'dataTasks',
          sortable: 'custom',
          width: 700
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          key: 'gmtCreated',
          width: 200,
          sortable: 'custom',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreated), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('chuang-jian-ren'),
          key: 'creatorUserName',
          width: 120
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          minWidth: 220,
          fixed: 'right',
          slot: 'action'
        }
      ],
      jobColumn: [
        {
          title: this.$t('ren-wu-id-miao-shu'),
          key: 'dataJobName',
          width: 280,
          slot: 'instanceId',
          tree: true,
          className: 'job-info-instance'
        },
        {
          title: this.$t('zhuang-tai'),
          width: 120,
          render: (h, params) => {
            const type = Mapping.taskSateToTaskType[params.row.dataTaskState];

            if (type) {
              if (!this.getDataTask(params.row.dataTasks)[type]) {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zan-ting'))
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].dataTaskStatus === 'STOP') {
                return h('div', {
                  style: {
                    color: '#FFA30E'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#FFA30E'
                    }
                  }),
                  h('span', {
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zan-ting'))
                ]);
              }
              if (this.getDataTask(params.row.dataTasks)[type].healthLevel === 'Health') {
                return h('div', {
                  style: {
                    color: '#52C41A'
                  }
                }, [
                  h('span', {
                    style: {
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      background: '#52C41A'
                    }
                  }),
                  h('Tooltip', {
                    props: {
                      transfer: true,
                      placement: 'right',
                      content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                    },
                    style: {
                      marginLeft: '8px'
                    }
                  }, this.$t('zheng-chang'))
                ]);
              }
              return h('div', {
                style: {
                  color: '#FF1815'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#FF1815'
                  }
                }),
                h('Tooltip', {
                  props: {
                    transfer: true,
                    placement: 'right',
                    content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                  },
                  style: {
                    marginLeft: '8px'
                  }
                }, this.$t('yi-chang'))
              ]);
            }
            if (params.row.dataTaskState === 'COMPLETE') {
              return h('div', {
                style: {
                  color: '#CCCCCC'
                }
              }, [
                h('span', {
                  style: {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    background: '#CCCCCC'
                  }
                }),
                h('span', {
                  style: {
                    marginLeft: '8px'
                  }
                }, this.$t('wan-cheng'))
              ]);
            }
            return h('div', {
              style: {
                color: '#52C41A'
              }
            }, [
              h('span', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  background: '#52C41A'
                }
              }),
              h('Tooltip', {
                props: {
                  transfer: true,
                  placement: 'right',
                  content: this.$t('jin-cheng-cun-huo-bing-qie-chi-xu-jin-hang-shu-ju-tong-bu-ji-shi-wei-zheng-chang-xu-zhu-yi-dang-jin-hang-wei-dian-hui-su-huo-zhe-yu-dao-zeng-liang-liu-liang-jiao-da-shi-jin-cheng-reng-ran-shi-cun-huo-bing-qie-zai-tong-bu-shu-ju-de-shi-wei-zhuang-tai-zheng-chang')
                },
                style: {
                  marginLeft: '8px'
                }
              }, this.$t('zheng-chang'))
            ]);
          }
          // filters: [
          //   {
          //     label: '正常',
          //     value: 'normal'
          //   },
          //   {
          //     label: '异常',
          //     value: 'error'
          //   },
          //   {
          //     label: '已暂停',
          //     value: 'stop'
          //   },
          //   {
          //     label: '已完成',
          //     value: 'finish'
          //   }
          // ],
          // filterMultiple: false,
          // filterRemote(value) {
          //   this.getFilters(value);
          // }
        },
        {
          title: this.$t('jin-du'),
          slot: 'progress',
          key: 'dataTasks',
          sortable: 'custom',
          width: 700
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          key: 'gmtCreated',
          width: 200,
          sortable: 'custom',
          render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreated), 'YYYY-MM-DD HH:mm:ss'))
        },
        {
          title: this.$t('chuang-jian-ren'),
          key: 'creatorUserName',
          width: 120
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          minWidth: 220,
          fixed: 'right',
          slot: 'action'
        }
      ]
    };
  },
  computed: {
    getDelayColor() {
      return (delay) => {
        // 位点延迟小于1分钟，正常，为绿色，展示数据延迟。1分钟<位点延迟<5分钟，黄色，展示位点延迟。位点延迟>5分钟，异常，为红色，展示位点延迟。
        if (delay <= 60000) {
          return 'color: #19be6b';
        }
        if (delay < 300000) {
          return 'color: #ff9900';
        }
        return 'color: #ed4014';
      };
    },
    getStatusName() {
      return (row) => {
        const type = Mapping.taskSateToTaskType[row.dataTaskState];

        if (type) {
          if (!this.getDataTask(row.dataTasks)[type]) {
            return this.$t('chu-shi-hua');
          }
          if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
            return this.$t('yi-ting-zhi');
          }
          if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
            return this.$t('zheng-chang');
          }
          return this.$t('yi-chang');
        }
        if (row.dataTaskState === 'COMPLETE') {
          return this.$t('yi-wan-cheng');
        }
        return this.$t('zheng-chang');
      };
    },
    ...mapState(['myAuth'])
  },
  methods: {
    formatData(data) {
      if (this.$i18n.locale === 'en-US') {
        return formatEnCount(data);
      }
      return formatCount(data);
    },
    revisePercentage(row) {
      return Number(JSON.parse(JSON.parse(this.getDataTask(row.dataTasks).REVISE.taskPosition).revisePosDetail).percentage);
    },
    showReplayBtn(row) {
      return ((row.dataJobType === 'CHECK' || row.dataJobType === 'REVISE') && (row.currentStatus === 'STOP' || row.dataTaskState === 'COMPLETE')
        || ((this.getDataTask(row.dataTasks).FULL
          && (row.dataTaskState === 'COMPLETE'
            || row.currentStatus === 'STOP'))));
    },
    showDetachBtn(row) {
      return (row.dataTaskState === 'CATCH_UP' || row.dataTaskState === 'INCRE') && row.currentStatus === 'STOP'
      && this.getDataTask(row.dataTasks).INCREMENT && this.getDataTask(row.dataTasks).INCREMENT.workerIp;
    },
    showAttachBtn(row) {
      return (row.currentStatus === 'STOP' || row.currentStatus === 'WAIT_STOP') && this.getDataTask(row.dataTasks).INCREMENT && !this.getDataTask(row.dataTasks).INCREMENT.workerIp;
    },
    handleTSort(data) {
      // let userId = '',
      //     sort = {};
      //
      // if (localStorage.getItem('userInfo')) {
      //
      //     userId = JSON.parse(localStorage.getItem('userInfo')).id;
      //
      // }
      //
      // if (data.key === 'gmtCreated') {
      //     this.$refs['job-list-table'].handleSort(2, data.order);
      //
      // } else if (data.key === 'dataTasks') {
      //     this.$refs['job-list-table'].handleSort(5, data.order);
      //
      // }
      this.handleTableSort(data);
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.forEach((item) => {
        dataTasks[item.dataTaskType] = item;
      });
      return dataTasks;
    },
    handleDetail(row) {
      this.$router.push({ path: `/data/job/${row.dataJobId}${row.parentDataJobId ? `?parentDataJobId=${row.parentDataJobId}` : ''}` });
    },
    handleEditJobDesc(row) {
      this.dataJobDesc = row.dataJobDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handleCancelEdit(row) {
      row.showEditDesc = false;
    },
    handleEditConfirm(row) {
      row.showEditDesc = false;
      this.handleConfirmEdit(row, this.dataJobDesc);
    },
    handleSelectAllBatch(selection) {
      this.updateAllBatchList(selection);
    },
    rowClassName(row) {
      if (!row.isParent) {
        return 'sub-job-info';
      }
      return '';
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/ccsystem/state/task/${row.consoleJobId}` });
    },
    handleGoUserCenter() {
      this.$router.push({ path: '/userCenter' });
    },
    handleGoExceptionLog(row) {
      localStorage.setItem('exception_ids', JSON.stringify(row));

      this.$router.push({ path: '/monitor/exception' });
    }
  }
};
</script>
<style lang="less" scoped>
.task-list-body {
  padding: 24px;
  background: #ffffff;
  margin-top: 16px;

  .demo-table-info-row {
    .ivu-table-cell-slot {
      display: inline-block;
      vertical-align: middle;
    }

    .ivu-table-cell-tree {
      display: none;
    }

    .ivu-table-cell-tree-level {
      display: none;
    }
  }

  .ivu-progress-inner-text {
    color: #17233d;
  }
}

.job-edit-desc-a {
  opacity: 0;
}

.ivu-table-row :hover .job-edit-desc-a {
  opacity: 1;
}

.task-step-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.job-info-container {
  margin-top: 16px;
}

.job-list-table {
  margin-top: 16px;
  margin-bottom: 76px;
}

.job-list-datasource {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #C9C9C9;
  font-size: 14px;
  border-radius: 50%;
  top: -12px;
  background: #ffffff;
  text-align: center;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  .datasource-icon .icon {
    margin-right: 4px;
  }
}

.job-list-source {
  left: 12px;
}

.job-list-sink {
  left: 28px;
}

.job-state-title {
  width: 76px;
  height: 28px;
  border-radius: 13px;
  background: #EEEEEE;
  color: #333;
  text-align: center;
  line-height: 28px;
  margin: 0 auto;
}

.job-state-content {
  font-size: 12px;
  text-align: center;
  width: 150px;
  margin-top: 2px;
}

.job-state-item-container {
  display: inline-block;
  position: relative;
  margin-right: 10px;

  .job-state-schedule-stop {
    color: #FFA30E;
  }

  .job-info-incre-status {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.job-state-check-container {
  display: flex;
  display: -webkit-flex;
  //align-items: center;
}

.job-state-item {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.job-state-item-content {
  margin-top: 10px;
  width: 100%;
  text-align: center;

  .job-state-error {
    color: #FC3F00;
  }
}

.action-container {
  a {
    /*display: block;*/
    /*margin-bottom: 12px;*/
    /*margin-top: 12px;*/
    margin-right: 16px;
  }
}

.schedule-time-container {
  text-align: center;
}

.job-list-desc-edit {
  font-size: 14px;
  height: 20px;
  cursor: pointer;
  margin-left: 8px;
}

.button-group {
  margin-top: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}

.sub-job-info {
  background-color: #F4F4F4;
}

.job-info-title {
  color: #333;
  vertical-align: middle;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ivu-table-cell-slot {
  display: inline-block !important;
}

.ivu-table-cell-tree-empty {
  display: inline-block !important;
}

.ivu-table-cell-tree-level {
  display: inline-block !important;
}

.job-info-progress-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  //margin-left: 50px;
  position: relative;
  top: 4px;

  .iconyibuforce {
    font-size: 14px;
  }

  .iconfreeze {
    font-size: 19px;
  }
}

.dataSource-icon {
  //position: absolute;
  //left: 0;
  //top: 40px;
  cursor: pointer;
}

.data-job-desc {
  .iconfont {
    visibility: hidden;
  }

  &:hover .iconfont {
    visibility: visible;
  }
}

.show-job-info-icon {
  color: #0BB9F8;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 36px;
  cursor: pointer;
  .iconfont {
    color: #8D95A6;
    cursor: pointer;
    font-size: 14px;
  }
}

.diff-auth-btns {
  margin-top: 5px;
  //border: 1px solid #0087C7;
}
</style>
