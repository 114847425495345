<script>
import dayjs from 'dayjs';
import { TAB_TYPE } from '@/const';

export default {
  name: 'CreateTableItem',
  props: {
    tab: Object,
    currentSchema: Object,
    formData: [Object, Array],
    nodeType: String,
    selectedIndex: Number,
    selectedNode: Object
  },
  computed: {
    isAdd() {
      if (this.tab.type === TAB_TYPE.STRUCT && this.formData[this.nodeType][this.selectedIndex]) {
        return this.formData[this.nodeType][this.selectedIndex].isAdd;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      selectedData: {},
      selectedColumn: {},
      schema: {},
      inputVisible: false,
      inputValue: ''
    };
  },
  watch: {
    currentSchema: {
      handler(newVal) {
        this.schema = newVal;
        if (newVal.children && newVal.children.length) {
          newVal.children.forEach((item) => {
            if (!(item.field in this.selectedData)) {
              this.selectedData[item.field] = item.defaultVal;
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    nodeType: {
      handler(newVal) {
        if (newVal === 'tableInfo') {
          this.selectedData = this.formData.tableInfo;
        } else {
          this.selectedColumn = {};
          if (this.selectedIndex > -1) {
            this.selectedData = this.formData[newVal][this.selectedIndex];
          }
        }
      },
      immediate: true
    },
    'selectedNode.key': {
      handler(newVal, oldVal) {
        if (this.tab.type === TAB_TYPE.QUERY && newVal !== oldVal) {
          if (this.selectedNode.configType === 'tableConfig') {
            this.formData.forEach((table) => {
              if (table.name === this.selectedNode.title) {
                this.selectedData = table;
                this.currentSchema.children.forEach((item) => {
                  if (!(item.field in this.selectedData)) {
                    this.selectedData[item.field] = item.defaultVal;
                  }
                });
              }
            });
          } else {
            this.formData.forEach((table) => {
              if (table.name === this.selectedNode.tableName) {
                table.columnConfigs.forEach((column) => {
                  if (column.name === this.selectedNode.title) {
                    this.selectedData = column;
                  }
                  this.currentSchema.children.forEach((item) => {
                    if (!(item.field in this.selectedData)) {
                      this.selectedData[item.field] = item.defaultVal;
                    }
                  });
                });
              }
            });
          }
        }
      },
      immediate: true,
      deep: true
    },
    'tab.selectedNode.key': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.nodeType !== 'tableInfo') {
          this.selectedData = this.formData[this.nodeType][this.selectedIndex];
        }
      },
      immediate: true,
      deep: true
    },
    'tab.key': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.nodeType === 'tableInfo') {
            this.selectedData = this.formData.tableInfo;
          } else {
            this.selectedColumn = {};
            if (this.selectedIndex > -1) {
              this.selectedData = this.formData[this.nodeType][this.selectedIndex];
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    isReadOnly(schema) {
      if (this.isAdd) {
        return false;
      } else {
        return schema.readOnly;
      }
    },
    handleInputChange(field, event) {
      console.log(this.selectedData[field], event.target.value);

      if (field === 'name') {
        if (this.tab.formData.keys) {
          this.tab.formData.keys.forEach((indexKey) => {
            indexKey.columns.forEach((column) => {
              if (column.name === this.selectedData[field]) {
                column.name = event.target.value;
              }
            });
          });
        }

        if (this.tab.formData.indexes) {
          this.tab.formData.indexes.forEach((indexKey) => {
            indexKey.columns.forEach((column) => {
              if (column.name === this.selectedData[field]) {
                column.name = event.target.value;
              }
            });
          });
        }
      }

      this.selectedData[field] = event.target.value;
    },
    handleCheckChange() {
      this.$forceUpdate();
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let tags = this.selectedData[this.schema.field];
      if (!tags) {
        tags = [];
      }
      if (!tags.includes(this.inputValue)) {
        tags.push(this.inputValue);
      }

      this.selectedData[this.schema.field] = tags;
      this.inputValue = '';
      this.inputVisible = false;
    },
    generateOptionSchema(options, value, schema) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.value === value && option.children && option.children.length) {
          schema.children = option.children;
          option.children.forEach((child) => {
            this.selectedData[child.field] = child.defaultVal;
            if (child.type === 'Tag') {
              this.selectedData[child.field] = [];
            } else if (child.type === 'Options') {
              this.generateOptionSchema(child.options, child.defaultVal || '', child);
            } else if (child.type === 'SelectColumns') {
              this.selectedData[child.field] = [];
            }
          });
        }
      }
    },
    handleOptionChange(options, value) {
      this.schema.children = [];
      this.generateOptionSchema(options, value, this.schema);
      this.$forceUpdate();
    },
    handleClickColumn(column) {
      this.selectedColumn = column;
    },
    handleAddColumn(schema) {
      const column = {
        key: `${dayjs().valueOf()}`
      };
      if (schema.children && schema.children.length) {
        schema.children.forEach((child) => {
          column[child.field] = child.defaultVal;
        });
      }
      if (this.selectedData[schema.field]) {
        this.selectedData[schema.field].push(column);
      } else {
        this.selectedData[schema.field] = [column];
      }
      this.selectedColumn = column;
    },
    handleDeleteColumn(schema) {
      const index = this.selectedData[schema.field].findIndex((column) => column.key === this.selectedColumn.key);
      this.selectedData[schema.field].splice(index, 1);
      if (this.selectedData[schema.field].length) {
        this.selectedColumn = this.selectedData[schema.field][0];
      } else {
        this.selectedColumn = {};
      }
    },
    handleCloseTag(removedTag) {
      let tags = this.selectedData[this.schema.field];
      tags = tags.filter((tag) => tag !== removedTag);
      this.selectedData[this.schema.field] = tags;
    }
  }
};
</script>

<template>
  <div style="display: flex;margin-top: 5px;" v-if="!schema.hide">
    <div style="width: 100px;">{{ schema.titleI18N }}:</div>
    <div style="flex: 1;">
      <div style="display: flex;align-items: center;justify-content: space-between">
        <div v-if="schema.type === 'Tag'">
          <a-tag v-for="tag in selectedData[schema.field]" :key="tag"
                 @close="() => handleCloseTag(tag)" closable :disabled="isReadOnly(schema)">
            {{ tag }}
          </a-tag>
          <a-input
            v-if="inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            v-model="inputValue"
            @keyup.enter="handleInputConfirm()"
            :disabled="isReadOnly(schema)"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <a-icon type="plus"/>
            {{ $t('xin-zeng') }}
          </a-tag>
        </div>
        <a-input v-else-if="schema.type === 'Input'" :value="selectedData[schema.field]"
                 size="small" @change.native="handleInputChange(schema.field, $event)"
                 style="width: 100%;" :disabled="isReadOnly(schema)"/>
        <a-select v-else-if="schema.type === 'Options'" v-model="selectedData[schema.field]"
                  @change="handleOptionChange(schema.options, $event)" size="small"
                  style="width: 100%" allow-clear show-search :disabled="isReadOnly(schema)">
          <a-select-option v-for="option in schema.options" :key="option.value"
                           :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
        <div v-else-if="schema.type === 'DataType'">
          <a-select v-model="selectedData[schema.field]" size="small" :disabled="isReadOnly(schema)">
            <a-select-option v-for="option in schema.options" :key="option.key"
                             :value="option.value">
              {{ option.key }}
            </a-select-option>
          </a-select>
        </div>
        <a-checkbox v-else-if="schema.type === 'Check'" v-model="selectedData[schema.field]"
                    size="small" @change="handleCheckChange" :disabled="isReadOnly(schema)"/>
        <div v-else-if="schema.type === 'AutoIncrement'">
          <a-checkbox v-model="selectedData[schema.field].enable" size="small" :disabled="isReadOnly(schema)"/>
          <a-input v-model="selectedData[schema.field].value"
                   :disabled="!selectedData[schema.field].enable || isReadOnly(schema)" size="small"/>
        </div>
        <div v-else-if="schema.type === 'SelectColumns'"
             style="display: flex;width: 100%;border: 1px solid #ccc;">
          <div class="left" style="width: 200px;border-right: 1px solid #ccc;">
            <div style="width: 100%;display: flex;">
              <a-button @click="handleAddColumn(schema)" size="small" style="flex: 1" :disabled="isReadOnly(schema)">{{ $t('zeng-jia') }}
              </a-button>
              <a-button @click="handleDeleteColumn(schema)" size="small" style="flex: 1" :disabled="isReadOnly(schema)">{{ $t('shan-chu') }}
              </a-button>
            </div>
            <div :style="`min-height: 80px;height: ${selectedData[schema.field] ? selectedData[schema.field].length * 20 : 0}px`">
              <div v-for="column in selectedData[schema.field]" :key="column.key" :disabled="isReadOnly(schema)"
                   @click="handleClickColumn(column)"
                   :style="`background: ${column.key === selectedColumn.key ? '#ccc' : ''};height: 20px;`">
                {{ column.name || column[schema.field] || 'null' }}
              </div>
            </div>
          </div>
          <div class="right" style="flex: 1;padding: 5px;">
            <div v-if="selectedColumn&&selectedColumn.key">
              <div v-for="childSchema in schema.children" :key="childSchema.field">
                <div v-if="!childSchema.hide" style="display: flex;margin-top: 5px;">
                  <div style="width: 80px;">{{ childSchema.titleI18N }}:</div>
                  <a-select v-model="selectedColumn[childSchema.field]" style="width: 100%;"
                            size="small" v-if="childSchema.type === 'Columns'" allow-clear show-search  :disabled="childSchema.readOnly">
                    <a-select-option v-for="column in formData.columns" :key="column.key"
                                     :value="column.name">{{ column.name }}
                    </a-select-option>
                  </a-select>
                  <a-input v-else-if="childSchema.type === 'Input'"
                           v-model="selectedColumn[childSchema.field]" size="small"
                           style="width: 100%;" :disabled="childSchema.readOnly"/>
                  <a-select v-else-if="childSchema.type === 'Options'"
                            v-model="selectedColumn[childSchema.field]"
                            size="small"
                            style="width: 100%" allow-clear show-search :disabled="childSchema.readOnly">
                    <a-select-option v-for="option in childSchema.options" :key="option.value"
                                     :value="option.value">
                      {{ option.label }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-tooltip placement="left" style="margin-left: 3px;">
          <a-icon type="question-circle" v-if="schema.descI18N"/>
          <template slot="title">
            {{ schema.descI18N }}
          </template>
        </a-tooltip>
      </div>
      <template v-if="schema.children&&schema.children.length&&schema.type!=='SelectColumns'">
        <CreateTableItem
          v-for="childSchema in schema.children" :key="childSchema.field"
          style="flex: 1;margin-left: -100px;"
          :current-schema="childSchema"
          :node-type="nodeType"
          :selected-index="selectedIndex"
          :form-data="formData"
          :selected-node="selectedNode"
          :tab="tab"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.left {
  /deep/ .ant-btn {
    border: none;
    border-bottom: 1px solid #ccc;

    &:first-child {
      border-right: 1px solid #ccc;
    }
  }
}
</style>
